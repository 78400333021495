/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import PropTypes from 'prop-types';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { TIB_DAYS, DAYS } from '../../../constants';
import { logEvent, getPreviousTibetanDate, getNextTibetanDate, getNumberInTibetan, getMoonIcon } from '../../../utils';
import styles from './DayNav.module.scss';

const DayNav = ({ onNavigateNext, onNavigatePrev, currentDate, className }) => {
    const { date, westernDate } = currentDate;
    const wDate = westernDate.getDate();
    const dayNum = currentDate.getDay();

    const handlePrevDayClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User navigated to <Previous Day> via button click',
        });

        onNavigatePrev(getPreviousTibetanDate(currentDate));
    };

    const handleNextDayClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User navigated to <Next Day> via button click',
        });

        onNavigateNext(getNextTibetanDate(currentDate));
    };

    const getClickableStyle = (baseStyle, condition = true) =>
        condition ? `${baseStyle} ${styles.clickable}` : baseStyle;

    return (
        <div className={`${styles.dayNav} ${className}`}>
            <button className={getClickableStyle(styles.prevButton)} onClick={handlePrevDayClick}>
                <FaAngleLeft />
            </button>
            <div className={styles.westCount}>
                <span className={styles.large}>{date}</span>
            </div>
            <div className={styles.divider}>
                <span className={styles.large}>|</span>
            </div>
            <div className={styles.tibCount}>
                <span className={`tib-text ${styles.large}`}>{getNumberInTibetan(date)}</span>
                <span>{(currentDate.isDoubledDay || currentDate.isPreviousSkipped) && '*'}</span>
            </div>
            <div key="moon-phase" className={styles.moon}>
                {getMoonIcon(date, { className: styles.moonIcon })}
            </div>
            <div className={`tib-text ${styles.tibDay}`}>{TIB_DAYS[dayNum]}</div>
            <div className={styles.day}>{DAYS[dayNum]}</div>
            <div className={`${styles.eng} ${styles.westernMonthContainer}`}>{wDate}</div>
            <button className={getClickableStyle(styles.nextButton)} onClick={handleNextDayClick}>
                <FaAngleRight />
            </button>
        </div>
    );
};

DayNav.propTypes = {
    /** a function to be triggered when next month button is clicked */
    onNavigateNext: PropTypes.func.isRequired,
    /** a function to be triggered when previous month button is clicked */
    onNavigatePrev: PropTypes.func.isRequired,
    /** TibetanDate object for the current date (month details will be extracted from there) */
    currentDate: PropTypes.object.isRequired,
};

export default DayNav;
