import * as React from 'react';
import PropsTypes from 'prop-types';
import styles from './PechaFrame.module.scss';

const PechaFrame = (props) => {
    const { children, ...rest } = props;

    return (
        <div className={styles.wrapper} {...rest}>
            <div className={styles.innerWrapper}>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

PechaFrame.propTypes = {
    /** The content to be shown inside the frame */
    children: PropsTypes.any,
    /** additional props will be applied to the top container */
};

PechaFrame.defaultProps = {
    children: null,
};

export default PechaFrame;
