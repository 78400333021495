import {
    MONTHLY_HOLIDAYS,
    MONTHLY_CHANGING_HOLIDAYS,
    YEARLY_HOLIDAYS,
    TIBETAN_ANNIVERSARIES,
    WESTERN_ANNIVERSARIES,
    HAIR_CUTTING,
} from '../data';

/**
 * Return all the special occasions that repeat monthly
 * @param {TibetanDate} tibDate a date object for the current date
 * @returns {Occasion[]}
 */
export const repeatedMonthly = (tibDate) => {
    const date = tibDate.getDate();
    const month = tibDate.getMonth();
    let specials = [];

    if (tibDate.isPreviousSkipped) {
        specials = [
            ...specials,
            ...(MONTHLY_HOLIDAYS[date - 1] || []),
            ...(MONTHLY_CHANGING_HOLIDAYS[month][date - 1] || []),
        ];
    }
    if (!tibDate.isSkippedDay) {
        if (!tibDate.isDoubledDay || tibDate.isLeapDay) {
            specials = [
                ...specials,
                ...(MONTHLY_HOLIDAYS[date] || []),
                ...(MONTHLY_CHANGING_HOLIDAYS[month][date] || []),
            ];
        }
        specials = [...specials, ...HAIR_CUTTING[date]];
    }

    return specials;
};

/**
 * Return all the special occasions that repeat yearly
 * @param {TibetanDate} tibDate a date object for the current date
 * @returns {Occasion[]}
 */
export const repeatedYearly = (tibDate) => {
    const monthObj = tibDate.getMonthObj();

    const month = tibDate.getMonth();
    const date = tibDate.getDate();
    let specials = [];
    if (!(monthObj.isDoubledMonth && !monthObj.isLeapMonth)) {
        if (tibDate.isPreviousSkipped && YEARLY_HOLIDAYS[month][date - 1]) {
            specials = [...specials, ...YEARLY_HOLIDAYS[month][date - 1]];
        }
        if (tibDate.isPreviousSkipped && TIBETAN_ANNIVERSARIES[month][date - 1]) {
            specials = [...specials, ...TIBETAN_ANNIVERSARIES[month][date - 1]];
        }
        if (!tibDate.isSkippedDay) {
            if ((!tibDate.isDoubledDay || tibDate.isLeapDay) && YEARLY_HOLIDAYS[month][date]) {
                specials = [...specials, ...YEARLY_HOLIDAYS[month][date]];
            }
            if ((!tibDate.isDoubledDay || tibDate.isLeapDay) && TIBETAN_ANNIVERSARIES[month][date]) {
                specials = [...specials, ...TIBETAN_ANNIVERSARIES[month][date]];
            }
        }
    }

    // according to Western calendar
    const wMonth = tibDate.westernDate.getMonth() + 1;
    const wDate = tibDate.westernDate.getDate();

    if (WESTERN_ANNIVERSARIES[wMonth][wDate]) {
        specials = [...specials, ...WESTERN_ANNIVERSARIES[wMonth][wDate]];
    }

    return specials;
};

/**
 * Get the color for an icon of a special occasion
 * @param {{type: string, icon?}} dayAttribute the type of the occasion
 * @returns {string}
 */
export const getIconColor = (dayAttribute) => {
    let iconColor;
    if (dayAttribute.type === 'holiday' && dayAttribute.icon === undefined) {
        iconColor = 'gold';
    }
    if (dayAttribute.type === 'western-anniversary' || dayAttribute.type === 'western-birthday') {
        iconColor = 'blue';
    }

    return iconColor;
};

/**
 * Sort day attributes according to their types
 * @param {{type: string}[]} attributes a list of attributes to be sorted
 * @param {string[][]} sortGroups list of lists of types
 * @returns
 */
export const sortAttributesByType = (attributes, sortGroups) => {
    const sorted = [];
    sortGroups.forEach((group) => {
        const filtered = attributes.filter((attribute) => group.includes(attribute.type));
        sorted.push(...filtered);
    });

    // sanity check
    if (attributes.length !== sorted.length) {
        // eslint-disable-next-line no-console
        console.error("While sorting attributes some got lost or duplicated, final count don't match");
    }
    return sorted;
};
