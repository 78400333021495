/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getNumberInTibetan, repeatedMonthly, repeatedYearly, getMoonIcon, getIconColor } from '../../utils';
import { DAYS, TIB_DAYS } from '../../constants';
import Icon from '../Icon';
import styles from './DayInMonth.module.scss';

const OMIT_FROM_OVERVIEW = ['haircutting', 'moon'];

const DayInMonth = ({ date, globalSetters }) => {
    const navigate = useNavigate();
    const wDate = date.westernDate.getDate();
    const tDate = date.getDate();
    const dayNum = date.getDay();
    const specials = [...repeatedYearly(date), ...repeatedMonthly(date)];
    const isToday = date.westernDate.toDateString() === new Date().toDateString();
    const { westernDateStr } = date;

    const navigateToDate = () => {
        const { setDate } = globalSetters;

        setDate(date);
        navigate('/day');
    };

    const classes = [styles.container];
    if (dayNum === 0) {
        classes.push(styles.sunday);
    }
    if (isToday) {
        classes.push(styles.today);
    }

    return (
        <div className={classes.join(' ')} id={westernDateStr} role="button" onClick={navigateToDate} tabIndex="0">
            <div className={styles.day}>
                <div className={styles.tibetan}>
                    <div>
                        {tDate}
                        {(date.isDoubledDay || date.isPreviousSkipped) && ' *'}
                        {'  |  '}
                        <span className="tib-text">{getNumberInTibetan(date.getDate())}</span>
                    </div>
                    <span className="tib-text">{TIB_DAYS[dayNum]}</span>
                </div>
                <div className={styles.details}>
                    <div className={styles.overview}>
                        <span key="moon-phase">{getMoonIcon(date.getDate(), { className: styles.moon })}</span>
                        {specials.map((sp) => {
                            if (OMIT_FROM_OVERVIEW.includes(sp.type)) {
                                return null;
                            }
                            return (
                                <Icon
                                    type={sp.icon}
                                    color={getIconColor(sp)}
                                    key={sp.description.title || sp.description.name}
                                    className={styles.icon}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={styles.western}>
                    <span>{wDate} </span>
                    <span>{DAYS[dayNum]}</span>
                </div>
            </div>
        </div>
    );
};

DayInMonth.propTypes = {
    /** A TibetanDate object which to be rendered */
    date: PropTypes.object.isRequired,
    /** Callback function to manage application state */
    globalSetters: PropTypes.shape({
        setDate: PropTypes.func.isRequired,
    }).isRequired,
};

export default DayInMonth;
