/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { getNextWesternMonth } from '../../utils';
import { MONTHS } from '../../constants';
import { MonthNav } from '../Navigation';
import Day from './DayInMonth';
import navStyles from '../Navigation/MonthNav/MonthNav.module.scss';
import styles from './MonthList.module.scss';

const MonthList = React.forwardRef((props, ref) => {
    const { month, onNavigateNext, onNavigatePrev, globalSetters, ...rest } = props;
    // which month name should be shown in the navigation
    const [isFirstMonthActive, setIsFirstMonthActive] = React.useState(true);
    const scrollContainerRef = React.createRef();
    const nextMonthRef = React.createRef();

    const firstDay = month.getDays()[0];
    const currentWMonth = firstDay.westernDate.getMonth();

    /** Scroll handler to manage western month in top nav */
    React.useEffect(() => {
        const handleScroll = () => {
            const nextMonthDiv = nextMonthRef.current;
            if (!nextMonthDiv) {
                return;
            }

            const nextMonthTop = nextMonthDiv.getBoundingClientRect().top;
            if (nextMonthTop < 70 && isFirstMonthActive) {
                setIsFirstMonthActive(false);
            } else if (nextMonthTop > 70 && !isFirstMonthActive) {
                setIsFirstMonthActive(true);
            }
        };
        const debounceHandleScroll = debounce(handleScroll, 20);

        const scrollContainer = scrollContainerRef.current;
        scrollContainer.addEventListener('scroll', debounceHandleScroll);

        return () => {
            debounceHandleScroll.cancel();
            scrollContainer.removeEventListener('scroll', debounceHandleScroll);
        };
    }, [isFirstMonthActive, nextMonthRef, scrollContainerRef]);

    return (
        <div ref={ref} {...rest}>
            <MonthNav
                onNavigateNext={onNavigateNext}
                onNavigatePrev={onNavigatePrev}
                currentDate={firstDay}
                isFirstMonthActive={isFirstMonthActive}
                className={styles.monthNav}
            />
            <div ref={scrollContainerRef} style={{ overflow: 'scroll', height: '100%' }}>
                <div style={{ height: '70px' }} />
                {month.getDays().map((day, i) => {
                    if (day.westernDate.getDate() === 1 && i !== 0) {
                        return (
                            <React.Fragment key={day.westernDateStr}>
                                <div
                                    key={MONTHS[getNextWesternMonth(currentWMonth)]}
                                    ref={nextMonthRef}
                                    className={navStyles.monthInsert}
                                >
                                    <span className={navStyles.eng}>{MONTHS[getNextWesternMonth(currentWMonth)]}</span>
                                </div>
                                <Day key={day.westernDateStr} date={day} globalSetters={globalSetters} />
                            </React.Fragment>
                        );
                    }
                    return <Day key={day.westernDateStr} date={day} globalSetters={globalSetters} />;
                })}
            </div>
        </div>
    );
});

MonthList.propTypes = {
    /** TibetanMonth object for the month to be rendered */
    month: PropTypes.object.isRequired,
    /** Callback when navigation to next month happens */
    onNavigateNext: PropTypes.func.isRequired,
    /** Callback when navigation to previous month happens */
    onNavigatePrev: PropTypes.func.isRequired,
    /** Callback function to manage application state */
    globalSetters: PropTypes.shape({
        setDate: PropTypes.func.isRequired,
    }).isRequired,
};

export default MonthList;
