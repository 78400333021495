/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropsTypes from 'prop-types';
import { TibetanMonth } from 'tibetan-date-calculator';
import useSwipeNavigation from '../SlidingView';
import { getNextTibetanMonth, getPreviousTibetanMonth } from '../../utils';
import { YearNav } from '../Navigation';
import MonthList from './MonthList';
import styles from './MonthView.module.scss';

function getScrollParent(node) {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    }
    return getScrollParent(node.parentNode);
}

const scrollToDay = (currentDayId) => {
    const dayEl = document.getElementById(currentDayId);
    if (dayEl) {
        dayEl.scrollIntoView(false); // for Safari
        const top = dayEl.offsetTop - 160;
        const scrollingParent = getScrollParent(dayEl);
        scrollingParent.scrollTo(0, top);
    } else {
        window.scrollTo(0, 0);
    }
};

const MonthView = (props) => {
    const { date, globalSetters } = props;
    const month = React.useMemo(() => (date ? date.monthObj : new TibetanMonth()), [date]);
    const westernDateStr = React.useMemo(() => (date ? date.westernDateStr : new Date().toDateString()), [date]);
    const prevMonthRef = React.createRef();
    const currentMonthRef = React.createRef();
    const nextMonthRef = React.createRef();

    // stop Chrome from knowing better where to scroll to ;-)
    React.useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }, []);

    // scroll to today if month changed
    React.useEffect(() => {
        setTimeout(() => {
            scrollToDay(westernDateStr);
        }, 175);
    }, [westernDateStr]);

    /**
     * @param {TibetanDate} targetDate
     */
    const navigate = (targetDate) => {
        const { setDate } = globalSetters;

        setDate(targetDate);
    };

    const handleMonthNavigation = (direction) => {
        if (direction === 'Right') {
            navigate(getPreviousTibetanMonth(month).getDays()[0]);
        } else if (direction === 'Left') {
            navigate(getNextTibetanMonth(month).getDays()[0]);
        }
    };

    const [Swipeable, slideToNext, slideToPrev] = useSwipeNavigation({
        nextViewRef: nextMonthRef,
        currentViewRef: currentMonthRef,
        prevViewRef: prevMonthRef,
        onNavigation: handleMonthNavigation,
    });

    const nextMonth = getNextTibetanMonth(month);
    const prevMonth = getPreviousTibetanMonth(month);

    return (
        <>
            <YearNav onNavigatePrev={navigate} onNavigateNext={navigate} currentDate={date} />
            <div className={styles.monthContainer}>
                <Swipeable trackMouse>
                    {(getViewStyles) => (
                        <>
                            <MonthList
                                key={nextMonth.toString()}
                                ref={nextMonthRef}
                                month={nextMonth}
                                onNavigateNext={slideToNext}
                                onNavigatePrev={slideToPrev}
                                globalSetters={globalSetters}
                                {...getViewStyles('next')}
                            />
                            <MonthList
                                key={month.toString()}
                                ref={currentMonthRef}
                                month={month}
                                onNavigateNext={slideToNext}
                                onNavigatePrev={slideToPrev}
                                globalSetters={globalSetters}
                                {...getViewStyles('current')}
                            />
                            <MonthList
                                key={prevMonth.toString()}
                                ref={prevMonthRef}
                                month={prevMonth}
                                onNavigateNext={slideToNext}
                                onNavigatePrev={slideToPrev}
                                globalSetters={globalSetters}
                                {...getViewStyles('prev')}
                            />
                        </>
                    )}
                </Swipeable>
            </div>
        </>
    );
};

MonthView.propTypes = {
    /** TibetanDate object if different from current date - a month containing that day will be rendered */
    date: PropsTypes.object,
    /** Callback function to manage application state */
    globalSetters: PropsTypes.shape({
        setDate: PropsTypes.func.isRequired,
    }).isRequired,
};

MonthView.defaultProps = {
    date: null,
};

export default MonthView;
