import * as React from 'react';
import { Modal, Button } from '../../../ui-components';

const UpdateAvailableModal = (isOpen) => {
    const handleDismiss = () => {
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={handleDismiss} contentLabel="New update is available">
            <h1>A new update is available</h1>
            <p>Click below to reload the page</p>

            <Button onClick={handleDismiss}>Refresh</Button>
        </Modal>
    );
};

export default UpdateAvailableModal;
