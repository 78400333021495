import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from '../../ui-components';
import styles from './NewsModal.module.scss';

const NewsModal = () => {
    const yesterdayTimestamp = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
    const storeValue = +window.localStorage.getItem('news-seen');
    const initState = !(storeValue === 1 || storeValue > yesterdayTimestamp);

    const [isOpen, setIsOpen] = React.useState(initState);

    const handleSeenClick = () => {
        window.localStorage.setItem('news-seen', '1');
        setIsOpen(false);
    };

    const handleLaterClick = () => {
        window.localStorage.setItem('news-seen', new Date().getTime());
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen}>
            <h1>Latest changes</h1>
            <p>In this current update we extended the functionality of recently introduced Anniversary Overview page</p>
            <p>
                Now <strong>you can click</strong> on any event on that page, and it will take you to the day in the
                calendar, when it will next occur.
            </p>
            <p>
                Now you will see not only anniversaries and birthdays on that page, but also the{' '}
                <strong>major Tibetan holidays</strong> such as Wheel days. According the page got renamed to
                &rdquo;Events Overview&rdquo;
            </p>
            <div>
                <Link to="/yearly-events-overview">
                    <Button onClick={handleSeenClick} context="large">
                        Show me
                    </Button>
                </Link>
            </div>
            <div className={styles.buttons}>
                <Button onClick={handleLaterClick}>Remind me later</Button>
                <Button onClick={handleSeenClick} context="outline">
                    Thanks, got it
                </Button>
            </div>
            <p> </p>
        </Modal>
    );
};

export default NewsModal;
