/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import PropTypes from 'prop-types';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getOccurringDate, logEvent } from '../../../utils';
import YearDetailsModal, { Animals } from '../../YearDetailsModal';
import styles from './YearNav.module.scss';

const YearNav = ({ onNavigateNext, onNavigatePrev, currentDate }) => {
    const [isDetailsModalOpen, setIsDetailsModalOpen] = React.useState(false);
    const { year, month, date, yearObj, westernDateStr } = currentDate;

    const wYear = westernDateStr.slice(0, 4);

    const handlePrevYearClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User navigated to <Previous Year>',
        });

        const newDate = getOccurringDate(year - 1, month, date);

        onNavigatePrev(newDate);
    };

    const handleNextYearClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User navigated to <Next Year>',
        });

        const newDate = getOccurringDate(year + 1, month, date);

        onNavigateNext(newDate);
    };

    const toggleYearModal = () => {
        if (isDetailsModalOpen) {
            logEvent({
                category: 'User Event',
                action: 'User opened the year details modal',
            });
        }
        setIsDetailsModalOpen(!isDetailsModalOpen);
    };

    const getClickableStyle = (baseStyle) => `${baseStyle} ${styles.clickable}`;

    return (
        <>
            <div className={styles.yearNav}>
                <button className={getClickableStyle(styles.prevButton)} onClick={handlePrevYearClick}>
                    <FaAngleDoubleLeft />
                </button>
                <div className={getClickableStyle(`${styles.trans} ${styles.year}`)} onClick={toggleYearModal}>
                    <span>
                        {yearObj.element} {yearObj.animal} Year *
                    </span>
                    <Animals name={yearObj.animal} width={30} className={styles.animal} />
                </div>
                <div className={getClickableStyle(styles.eng)} onClick={toggleYearModal}>
                    {wYear}
                </div>
                <button className={getClickableStyle(styles.nextButton)} onClick={handleNextYearClick}>
                    <FaAngleDoubleRight />
                </button>
            </div>
            <YearDetailsModal isOpen={isDetailsModalOpen} onRequestClose={toggleYearModal} year={yearObj} />
        </>
    );
};

YearNav.propTypes = {
    /** a function to be triggered when next year button is clicked */
    onNavigateNext: PropTypes.func.isRequired,
    /** a function to be triggered when previous year button is clicked */
    onNavigatePrev: PropTypes.func.isRequired,
    /** TibetanDate object for the current date (year details will be extracted from there) */
    currentDate: PropTypes.object.isRequired,
};

export default YearNav;
