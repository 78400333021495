/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import PropsTypes from 'prop-types';
import { TibetanDate } from 'tibetan-date-calculator';
import useSwipeNavigation from '../SlidingView';
import { YearNav, MonthNav } from '../Navigation';
import SingleDay from './SingleDay';
import { getNextTibetanDate, getPreviousTibetanDate } from '../../utils';
import styles from './DayView.module.scss';

const DayView = ({ date, globalSetters: { setDate } }) => {
    const prevDayRef = React.createRef();
    const currentDayRef = React.createRef();
    const nextDayRef = React.createRef();

    const nextDay = getNextTibetanDate(date);
    const prevDay = getPreviousTibetanDate(date);

    /**
     * @param {TibetanDate} targetDate
     */
    const navigate = (targetDate) => {
        setDate(targetDate);
    };

    const handleDayNavigation = (direction) => {
        if (direction === 'Right') {
            navigate(prevDay);
        } else if (direction === 'Left') {
            navigate(nextDay);
        }
    };

    const [Swipeable, slideToNext, slideToPrev] = useSwipeNavigation({
        nextViewRef: nextDayRef,
        currentViewRef: currentDayRef,
        prevViewRef: prevDayRef,
        onNavigation: handleDayNavigation,
    });

    return (
        <>
            <YearNav onNavigatePrev={setDate} onNavigateNext={setDate} currentDate={date} />
            <MonthNav
                onNavigatePrev={setDate}
                onNavigateNext={setDate}
                currentDate={date}
                className={styles.monthNav}
                isFirstMonthActive
            />
            <div className={styles.dayContainer}>
                <Swipeable trackMouse>
                    {(getViewStyles) => (
                        <>
                            <SingleDay
                                key={nextDay.toString()}
                                ref={nextDayRef}
                                date={nextDay}
                                onNavigateNext={slideToNext}
                                onNavigatePrev={slideToPrev}
                                {...getViewStyles('next')}
                            />
                            <SingleDay
                                key={date.toString()}
                                ref={currentDayRef}
                                date={date}
                                onNavigateNext={slideToNext}
                                onNavigatePrev={slideToPrev}
                                {...getViewStyles('current')}
                            />
                            <SingleDay
                                key={prevDay.toString()}
                                ref={prevDayRef}
                                date={prevDay}
                                onNavigateNext={slideToNext}
                                onNavigatePrev={slideToPrev}
                                {...getViewStyles('prev')}
                            />
                        </>
                    )}
                </Swipeable>
            </div>
        </>
    );
};

DayView.propTypes = {
    /** TibetanDate object if different from current date - a month containing that day will be rendered */
    date: PropsTypes.object,
    /** Callback function to manage application state */
    globalSetters: PropsTypes.shape({
        setDate: PropsTypes.func.isRequired,
    }).isRequired,
};

DayView.defaultProps = {
    date: new TibetanDate(),
};

export default DayView;
