import React from 'react';
import WaterTigerLosarCard from '../statics/images/Losarcard2022.jpg';
import WaterRabbitLosarCard from '../statics/images/Losarcard2023.jpg';
import WoodDragonLosarCard from '../statics/images/Losarcard2024.jpg';

export const MONTHLY_HOLIDAYS = {
    8: [
        { type: 'holiday', icon: 'MEDICINE', description: { title: 'Medicine Buddha Day' }, prayer: 'medBud' },
        { type: 'holiday', icon: 'TARA', description: { title: 'Tārā Day' }, prayer: 'tara' },
    ],
    15: [
        { type: 'moon', icon: 'FULL_MOON', description: { title: 'Full moon' } },
        { type: 'holiday', icon: 'AMITABHA', description: { title: 'Amitābha Buddha Day' }, prayer: 'amitBud' },
    ],
    25: [{ type: 'holiday', icon: 'DAKINI', description: { title: 'Ḍākini Day' }, prayer: 'dd{month}' }],
    29: [{ type: 'holiday', icon: 'PROTECTOR', description: { title: 'Dharmapālas Day' }, prayer: 'dp' }],
    30: [
        { type: 'moon', icon: 'NEW_MOON', description: { title: 'New moon' } },
        { type: 'holiday', icon: 'BUDDHA', description: { title: 'Śākyamuni Buddha Day' }, prayer: 'bud' },
    ],
};

export const MONTHLY_CHANGING_HOLIDAYS = {
    1: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the first month, the hare month, when he renounces the throne, practices yogic disciplines of union and liberation in the Chilly Grove charnel ground, Shitavana, and brings all the mātṛkās and ḍākinīs under his power, he is known as Guru Śāntarakṣita.',
                },
                prayer: 'grd01',
            },
        ],
    },
    2: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the second month, the dragon month, when Guru Rinpoche gives the appearance of taking ordination from Ananda, studying with many learned scholars and realized teachers, mastering all the sūtras and tantras and perfecting infinite fields of knowledge, he is known as Guru Loden Choksé.',
                },
                prayer: 'grd02',
            },
        ],
    },
    3: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the third month, the snake month, when the Zahor king tries to burn him alive, he arouses faith by magically transforming the pyre into a lake, (called Tsopema, at Rewalsar), establishing the entire kingdom in Dharma. By depending on Princess Mandārāva, he then arises in the vajra body, and is known as Guru Chimé Pemajungné, the Immortal Lotus-born.',
                },
                prayer: 'grd03',
            },
        ],
    },
    4: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the fourth month, the horse month, when the demonic ministers of Oḍḍiyāna seek to burn him and his consort Mandarava alive, he transforms the pyre into a lake, and they both appear on a lotus blossom, miraculously shimmering in majesty. Inspiring faith in all, he ripens and liberates the entire kingdom by bestowing the Kadü Chökyi Gyatso teachings. This is when he is known as Guru Padma Vajra Tsal.',
                },
                prayer: 'grd04',
            },
        ],
    },
    5: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the fifth month, the sheep month, when the tīrthikas of southern India are causing great harm to the teaching of Buddha, through a vast display of magical power, Guru Rinpoche smashes them to dust along with their gods and guardians, and raises the victory banner of the teaching of the buddhas. Then he is known as Guru Sengé Dradok.',
                },
                prayer: 'grd05',
            },
        ],
    },
    6: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'When, at sunrise on the tenth day of the sixth month, the monkey month, Guru Rinpoche is born within a lotus blossom on Lake Dhanakośa and turns the wheel of Dharma for the ḍākinīs of the island, he is known as Guru Tsokyé Dorjé.',
                },
                prayer: 'grd06',
            },
        ],
    },
    7: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the seventh month, the bird month, Guru Rinpoche is flung into the river Ganges by the tīrthikas of Zangling. By miraculously performing a vajra dance in the sky and reversing the flow of the river, he inspires devotion and establishes the kingdom in the teaching of Buddha. Then he is known as Guru Khading Tsal, ‘Guru soaring in the sky like the garuda’.',
                },
                prayer: 'grd07',
            },
        ],
    },
    8: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the eighth month, the dog month, when the tīrthikas give him poison, not only is he unharmed, turning the poison into nectar, but his radiance becomes even more majestic and dazzling than before. With such a miraculous feat, he arouses faith, and the tīrthikas along with all their entourages embrace the teaching of the buddhas. Then he is known as Guru Nyima Özer.',
                },
                prayer: 'grd08',
            },
        ],
    },
    9: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the ninth month, the pig month, when at Yangleshö in Nepal, Guru Rinpoche manifests in the form of Vajrakumāra, binds under oath the gods and spirits of Nepal and Tibet, accomplishes the practice of the great glorious Yangdak, and attains the supreme vidyādhara stage of mahamudra, he is known as Guru Dorjé Tötreng Tsal.',
                },
                prayer: 'grd09',
            },
        ],
    },
    10: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the tenth month, the rat month, when Guru Rinpoche arrives in central Tibet, subjugates all the wild unruly gods and spirits of Tibet, raises the dharmachakra of glorious Samyé, lights the lamp of the sacred Dharma of sūtras and tantras, and ripens and liberates the fortunate ones, the king and his subjects, the twenty-five disciples, he is known as Guru Padmasambhava.',
                },
                prayer: 'grd10',
            },
        ],
    },
    11: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the eleventh month, the ox month, when Guru Rinpoche assumes a wrathful crazy form at Paro Taktsang in Mön and other places, he swears in all the earth lords of Tibet as guardians of the termas. Over the whole of the land of snows, on the borders and in the centre, he conceals unimaginable troves of terma teachings, precious materials, sacred substances and so on, and he gives predictions and advice for safeguarding what will remain of the teachings by means of the termas. Then he is known as Guru Dorjé Drolö.',
                },
                prayer: 'grd11',
            },
        ],
    },
    12: {
        10: [
            {
                type: 'holiday',
                icon: 'GR',
                description: {
                    title: 'Guru Rinpoche Day',
                    details:
                        'On the tenth day of the twelfth month, the tiger month, when he is welcomed by Indrabhuti, the king of Oḍḍiyāna, who invests him as crown prince, and he takes Prabhavati as his princess and governs the kingdom according to the Dharma, he is known as Guru Pema Gyalpo.',
                },
                prayer: 'grd12',
            },
        ],
    },
};

const getLosarCard = ({ year }) => {
    switch (year) {
        case 2149:
            return WaterTigerLosarCard;
        case 2150:
            return WaterRabbitLosarCard;
        case 2151:
            return WoodDragonLosarCard;
        default:
            return null;
    }
};

export const YEARLY_HOLIDAYS = {
    1: {
        1: [
            {
                type: 'holiday',
                description: { title: 'Losar - New year' },
                prayer: 'hd0101',
                img: getLosarCard,
                imgAlt: 'Losar Tashi Delek',
            },
        ],
        15: [
            {
                type: 'holiday',
                icon: 'WHEEL',
                description: {
                    title: 'Chotrul Düchen',
                    details: (
                        <>
                            <strong>The Display of Miracles</strong>: The leaders of India&apos;s six main philosophical
                            schools challenged the Buddha to a contest of miraculous powers. The Buddha displayed a
                            different miracle each day for fifteen days, and, utterly defeating his opponents, inspired
                            many individuals to follow the Dharma.
                        </>
                    ),
                },
                prayer: 'hd0115',
            },
        ],
    },
    2: {},
    3: {},
    4: {
        7: [
            {
                type: 'holiday',
                icon: 'WHEEL',
                description: {
                    title: 'The Birth of the Buddha',
                },
                prayer: 'hd0407',
            },
        ],
        15: [
            {
                type: 'holiday',
                icon: 'WHEEL',
                description: {
                    title: 'Saga Dawa Düchen',
                    details: (
                        <>
                            Upon his enlightenment at the age of thirty-five, the Buddha proclaimed:{' '}
                            <i>
                                Profound peace, natural simplicity, uncompounded luminosity, I have found a nectar-like
                                Dharma
                            </i>
                            .<br />
                            This day also marks the anniversary of the Buddha&apos;s paranirvana. When the Buddha lay
                            dying in the forest grove in Kushinagara, surrounded by five hundred of his disciples, he
                            said to them with his last breath:{' '}
                            <i>
                                It is in the nature of all things that take form to dissolve again. strive with your
                                whole being to attain perfection.
                            </i>
                        </>
                    ),
                },
                prayer: 'hd0415',
            },
        ],
    },
    5: {
        15: [
            {
                type: 'holiday',
                description: { title: "Local Deities' Day", details: 'Universal Prayer Day' },
                prayer: 'hd0515',
            },
        ],
    },
    6: {
        4: [
            {
                type: 'holiday',
                icon: 'WHEEL',
                description: {
                    title: 'Chökhor Düchen',
                    details: (
                        <>
                            <strong>The first turning of the Wheel of Dharma</strong>: For seven weeks after his
                            enlightenment, Buddha did not teach. Finally, encouraged by Indra and Brahma, he turned the
                            Wheel of Dharma for the first time, at Sarnath, teaching the Four Noble Truths.
                        </>
                    ),
                },
                prayer: 'hd0604',
            },
        ],
    },
    7: {},
    8: {},
    9: {
        22: [
            {
                type: 'holiday',
                icon: 'WHEEL',
                description: {
                    title: 'Lha Bab Düchen',
                    details: (
                        <>
                            <strong>Buddha Śākyamuni&apos;s Descent from Heaven</strong>: Buddhas mother was born in the
                            heaven of Indra. to repay her kindness and liberate her, and also to benefit the gods,
                            Buddha spent three months teaching in the realm of the gods.
                        </>
                    ),
                },
                prayer: 'hd0922',
            },
        ],
    },
    10: {},
    11: {},
    12: {
        29: [
            {
                type: 'holiday',
                description: { title: 'Gutor', details: 'Averting negativity of the year' },
                prayer: 'hd1229',
            },
        ],
    },
};
