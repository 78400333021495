import ReactGA from 'react-ga4';
import { GA_TRACKING_ID } from '../constants';

/**
 * Initializes Google Analytics tracking
 */
export const gaInit = () => {
    ReactGA.initialize(GA_TRACKING_ID);
};

/**
 * GA page visit logger
 */
export const logPage = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

/**
 * Logs events to GA
 * @param args an object with all things to be logged
 */
export const logEvent = (args) => {
    ReactGA.event(args);
};
