import * as React from 'react';
import PropsTypes from 'prop-types';
import styles from './Divider.module.scss';

const Divider = (props) => {
    const { children, ...rest } = props;

    return (
        <div className={styles.icon} {...rest}>
            <span className={styles.imgWrapper}>{children}</span>
        </div>
    );
};

Divider.propTypes = {
    /** The icon to be shown in the Divider */
    children: PropsTypes.any,
    /** additional props will be applied to the top container */
};

Divider.defaultProps = {
    children: null,
};

export default Divider;
