import React from 'react';
import PropTypes from 'prop-types';
import Bird from './images/Bird.png';
import Dog from './images/Dog.png';
import Dragon from './images/Dragon.png';
import Horse from './images/Horse.png';
import Monkey from './images/Monkey.png';
import Mouse from './images/Mouse.png';
import Ox from './images/Ox.png';
import Pig from './images/Pig.png';
import Rabbit from './images/Rabbit.png';
import Sheep from './images/Sheep.png';
import Snake from './images/Snake.png';
import Tiger from './images/Tiger.png';

const ANIMALS = {
    Mouse: {
        src: Mouse,
        alt: 'Mouse',
    },
    Ox: {
        src: Ox,
        alt: 'Ox',
    },
    Tiger: {
        src: Tiger,
        alt: 'Tiger',
    },
    Rabbit: {
        src: Rabbit,
        alt: 'Rabbit',
    },
    Dragon: {
        src: Dragon,
        alt: 'Dragon',
    },
    Snake: {
        src: Snake,
        alt: 'Snake',
    },
    Horse: {
        src: Horse,
        alt: 'Horse',
    },
    Sheep: {
        src: Sheep,
        alt: 'Sheep',
    },
    Monkey: {
        src: Monkey,
        alt: 'Monkey',
    },
    Bird: {
        src: Bird,
        alt: 'Bird',
    },
    Dog: {
        src: Dog,
        alt: 'Dog',
    },
    Pig: {
        src: Pig,
        alt: 'Pig',
    },
};

const Animals = (props) => {
    const { name, width = 100, ...rest } = props;
    const icon = ANIMALS[name];

    return <img alt={icon.alt} src={icon.src} width={width} {...rest} />;
};

Animals.propTypes = {
    name: PropTypes.string,
};

Animals.defaultProps = {
    name: 'Horse',
};

export default Animals;
