import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { TibetanDate } from 'tibetan-date-calculator';
import { Dock } from 'react-dock';
import MonthView from './components/MonthView';
import DayView from './components/DayView';
import { useAppInstaller } from './hooks';
import { AboutUs, Feedback, Vitality, Legend } from './components/TextPages';
import YearlyEventsOverview from './components/YearlyEventsOverview';
import { FooterNav, Sidebar } from './components/Navigation';
import { PageTracker } from './components/GoogleAnalytics';
import NewsModal from './components/NewsModal';
import { gaInit, logPage } from './utils';
import TigerBG from './statics/images/WaterTigerBG.jpg';
import RabbitBG from './statics/images/WaterRabbitBG.jpg';
import DragonBG from './statics/images/WoodDragonBG.jpg';
import styles from './App.module.scss';

const App = () => {
    const [currentDate, setCurrentDate] = React.useState(new TibetanDate());
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [showInstallButton, onClick, Modal] = useAppInstaller();

    const currentAnimal = React.useMemo(() => currentDate?.yearObj?.animal, [currentDate]);

    React.useEffect(() => {
        if (window.innerWidth > 560) {
            if (currentAnimal === 'Tiger') {
                document.querySelector('body').style.backgroundImage = `url(${TigerBG})`;
            } else if (currentAnimal === 'Rabbit') {
                document.querySelector('body').style.backgroundImage = `url(${RabbitBG})`;
            } else {
                document.querySelector('body').style.backgroundImage = `url(${DragonBG})`;
            }
        }
    }, [currentAnimal]);

    // Init GA and log first page
    gaInit();
    logPage();

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <Router>
            <div className={styles.App}>
                <main className={styles.main}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/day" replace />} />
                        <Route
                            path="/month"
                            element={<MonthView date={currentDate} globalSetters={{ setDate: setCurrentDate }} />}
                        />
                        <Route
                            path="/day"
                            element={<DayView date={currentDate} globalSetters={{ setDate: setCurrentDate }} />}
                        />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/feedback" element={<Feedback />} />
                        <Route path="/vitality" element={<Vitality />} />
                        <Route path="/legend" element={<Legend />} />
                        <Route
                            path="/yearly-events-overview"
                            element={<YearlyEventsOverview globalSetters={{ setDate: setCurrentDate }} />}
                        />
                    </Routes>
                    <PageTracker />
                </main>
                <FooterNav
                    className={styles.AppFooter}
                    showInstallButton={showInstallButton}
                    onInstallClick={onClick}
                    setDate={setCurrentDate}
                    currentDate={currentDate}
                    onMoreClick={toggleSidebar}
                />
                <Dock isVisible={sidebarOpen} onVisibleChange={toggleSidebar} fluid={false} defaultSize={270}>
                    <Sidebar toggleSidebar={toggleSidebar} />
                </Dock>
                {!!Modal && <Modal />}
                {!Modal && <NewsModal />}
            </div>
        </Router>
    );
};

export default App;
