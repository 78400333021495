export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const TIB_DAYS = ['ཉི།', 'ཟླ།', 'དམར།', 'ལྷག', 'ཕུར།', 'སངས།', 'སྤེན།'];

export const TIB_NUMBERS = ['༠', '༡', '༢', '༣', '༤', '༥', '༦', '༧', '༨', '༩'];
