/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { IoShareOutline } from 'react-icons/io5';
import { logEvent } from '../../utils';
import { Modal, Button } from '../../ui-components';

/**
 * Check if the OS is iOS and id it is Safari
 * @returns {[boolean, Boolean]} [isIOS, isSafari]
 */
const isIOSInstallable = () => {
    if (window.navigator.standalone) {
        // user has already installed the app
        return [false];
    }

    const ua = window.navigator.userAgent;
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIOS = isIPad || isIPhone;

    const isWebkit = !!ua.match(/WebKit/i);
    const isSafari = isIOS && isWebkit && !ua.match(/CriOS/i);
    return [isIOS, isSafari];
};

/**
 * Creates a React component that will render a modal containing instruction on how to install PWA on iOS
 * @param {boolean} isOpen if the modal is currently showing
 * @param {boolean => void} setIsOpen a callback that allows change of open state
 * @returns {React.Component}
 */
const makeIOSInstallModal = (isOpen, setIsOpen) => () =>
    (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
            contentLabel="IOS Installation Instructions"
        >
            <h1>Install the app</h1>
            <p>Open the website in Safari (if needed)</p>
            <p>
                Tap <IoShareOutline color="blue" /> then &lsquo;Add to Home Screen&lsquo;
            </p>
            <Button
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                Close
            </Button>
        </Modal>
    );

/**
 * A hook to manage Android install prompt
 * @returns {[installPromptEvent, triggerInstallPrompt]}
 */
const useWebInstallPrompt = () => {
    const [installPromptEvent, setInstallPromptEvent] = React.useState();

    React.useEffect(() => {
        const beforeInstallPromptHandler = (event) => {
            event.preventDefault();
            setInstallPromptEvent(event);
        };
        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }, []);

    const triggerInstallPrompt = () => {
        // show native prompt
        installPromptEvent.prompt();

        // decide what to do after the user chooses
        installPromptEvent.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                logEvent({
                    category: 'User Event',
                    action: 'User accepted the install prompt',
                });
            } else {
                logEvent({
                    category: 'User Event',
                    action: 'User dismissed the install prompt',
                });
            }
        });
    };
    return [installPromptEvent, triggerInstallPrompt];
};

/**
 * a hook to manage OS dependant app installation flow
 * @returns {[boolean, function?, React.Component?]} [showInstallButton, onClick, Modal]
 */
const useAppInstaller = () => {
    const [isIOS] = isIOSInstallable();
    const [installPrompt, triggerInstallPrompt] = useWebInstallPrompt();
    const [isOpen, setIsOpen] = React.useState(false);

    if (installPrompt) {
        return [true, triggerInstallPrompt];
    }

    if (!isIOS) {
        return [false];
    }

    const handleOpen = () => {
        logEvent({
            category: 'User Event',
            action: 'User pressed <Install> button',
        });

        setIsOpen(true);
    };

    return [true, handleOpen, makeIOSInstallModal(isOpen, setIsOpen)];
};

export default useAppInstaller;
