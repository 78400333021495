/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import PropTypes from 'prop-types';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { MONTHS } from '../../../constants';
import {
    logEvent,
    getNextWesternMonth,
    getPreviousTibetanMonth,
    getNextTibetanMonth,
    getNumberInTibetan,
} from '../../../utils';
import { Modal } from '../../../ui-components';
import styles from './MonthNav.module.scss';

const MonthNav = ({ onNavigateNext, onNavigatePrev, currentDate, isFirstMonthActive, className }) => {
    const [isDetailsModalOpen, setIsDetailsModalOpen] = React.useState(false);
    const { month, monthObj, westernDate } = currentDate;
    const wMonth = westernDate.getMonth();

    const handlePrevMonthClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User navigated to <Previous Month> via button click',
        });

        onNavigatePrev(getPreviousTibetanMonth(monthObj).getDays()[currentDate.date - 1]);
    };

    const handleNextMonthClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User navigated to <Next Month> via button click',
        });

        onNavigateNext(getNextTibetanMonth(monthObj).getDays()[currentDate.date - 1]);
    };

    const toggleModal = () => {
        if (monthObj.isDoubledMonth) {
            if (isDetailsModalOpen) {
                logEvent({
                    category: 'User Event',
                    action: 'User opened the month details modal',
                });
            }
            setIsDetailsModalOpen(!isDetailsModalOpen);
        }
    };

    const getClickableStyle = (baseStyle, condition = true) =>
        condition ? `${baseStyle} ${styles.clickable}` : baseStyle;

    const getWesternMonthStyle = (isFirst = true) =>
        (isFirst && isFirstMonthActive) || (!isFirst && !isFirstMonthActive)
            ? `${styles.westernMonth} ${styles.westernMonthActive}`
            : styles.westernMonth;

    return (
        <>
            <div className={`${styles.monthNav} ${className}`}>
                <button className={getClickableStyle(styles.prevButton)} onClick={handlePrevMonthClick}>
                    <FaAngleLeft />
                </button>
                <div className={styles.westCount}>
                    <span className={styles.large}>{month}.</span>
                </div>
                <div className={getClickableStyle(styles.trans, monthObj.isDoubledMonth)} onClick={toggleModal}>
                    {' Tibetan month'}
                    {monthObj.isDoubledMonth && '*'}
                </div>
                <div
                    className={getClickableStyle(`tib-text ${styles.tib}`, monthObj.isDoubledMonth)}
                    onClick={toggleModal}
                >
                    བོད་ཟླ་
                </div>
                <div
                    className={getClickableStyle(`tib-text ${styles.tibCount}`, monthObj.isDoubledMonth)}
                    onClick={toggleModal}
                >
                    <span className={styles.large}>{getNumberInTibetan(month)}</span>
                </div>
                <div className={`${styles.eng} ${styles.westernMonthContainer}`}>
                    <span className={getWesternMonthStyle(true)}>{MONTHS[wMonth]}</span>
                    <span className={getWesternMonthStyle(false)}>{MONTHS[getNextWesternMonth(wMonth)]}</span>
                </div>
                <button className={getClickableStyle(styles.nextButton)} onClick={handleNextMonthClick}>
                    <FaAngleRight />
                </button>
            </div>
            <Modal isOpen={isDetailsModalOpen} onRequestClose={toggleModal} contentLabel="Double month">
                <p>This month is doubled in the Tibetan calendar</p>
                <p>
                    Following the guidelines of the Tibetan Medicinal and Astrological Institute, <em>Men Tse Khang</em>
                    , anniversaries and special days appear on the first repetition of the month.
                </p>
            </Modal>
        </>
    );
};

MonthNav.propTypes = {
    /** a function to be triggered when next month button is clicked */
    onNavigateNext: PropTypes.func.isRequired,
    /** a function to be triggered when previous month button is clicked */
    onNavigatePrev: PropTypes.func.isRequired,
    /** TibetanDate object for the current date (month details will be extracted from there) */
    currentDate: PropTypes.object.isRequired,
    /** are we showing the first western month of this tibetan month or the second */
    isFirstMonthActive: PropTypes.bool,
};

MonthNav.defaultProps = {
    isFirstMonthActive: false,
};

export default MonthNav;
