import React from 'react';
import { Frame } from '../../ui-components';
import styles from './TextPages.module.scss';

const AboutUs = () => (
    <div className={styles.container}>
        <Frame>
            <h1>About</h1>
            <h2>The app</h2>
            <p>
                This app makes the{' '}
                <a target="_blank" href="https://en.wikipedia.org/wiki/Tibetan_calendar" rel="noopener noreferrer">
                    tibetan calendar
                </a>{' '}
                and special days easily accessible to everyone.
            </p>
            <p>
                This calendar can be found at <em>TibetanBuddhistCalendar.org</em>. To install it click
                &lsquo;Install&rsquo; at the bottom of the screen. If the install button is not showing then you can
                still select &lsquo;Add to Home screen&rsquo; from your browser settings.
            </p>
            <p>
                We hope that this app will be an inspiration for Buddhist practitioners and will be a helpful aid to
                their practice, especially those who follow the Tibetan traditions. It provides a handy reminder of the
                special days in the calendar, such as the 10<sup>th</sup> and 25<sup>th</sup> days of the lunar
                calendar, and the anniversaries of great masters of all lineages.
            </p>
            <p>
                We also hope that the app can serve as an inspiring resource to those who are interested in and would
                like to learn more about Buddhism and/or Tibet and its cultural heritage.
            </p>
            <h2>More about the calendar</h2>
            <p>
                There are two main variants of the Tibetan calendar: Phugpa and Tsurphu. For this app we have used the
                Phugpa variant.
            </p>
            <p>
                We have also followed the guidelines of the Tibetan Medicinal and Astrological Institute,{' '}
                <em>Men Tse Khang</em>:
            </p>
            <ul>
                <li>
                    If a <b>date is missing</b> from the month, then any special events for that day will appear on the
                    next day.
                </li>
                <li>
                    If a <b>date happens twice</b> in a given month then any anniversary or special event on that date
                    will fall on the first day.
                </li>
            </ul>
            <p>
                To calculate the dates of the tibetan calendar we have created an{' '}
                <a
                    target="_blank"
                    href="https://www.npmjs.com/package/tibetan-date-calculator"
                    rel="noopener noreferrer"
                >
                    open source library
                </a>{' '}
                available for anyone to use who might find it useful.
            </p>
            <h2>The Rigpa calendar</h2>
            <p>
                The Tibetan Buddhist Calendar app is inspired by the{' '}
                <a
                    href="https://www.rigpa.org/posts-rigpa-news/2018/12/21/astrology-art-and-rim-the-rigpa-calendars-40th-edition"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Rigpa Tibetan Calendar
                </a>
                .
            </p>
            <p>
                This calendar has been published yearly since 1979. It is beautifully illustrated with many inspiring
                images and filled with features: prayers for auspiciousness and to remove obstacles, special practice
                days and festivals, anniversaries of masters of all the Tibetan lineages, key national holidays, and
                much more.
            </p>
            <p>
                The Tibetan Calendar app does not aim to reproduce all the content of the printed version. We encourage
                you to also purchase that for its beautiful images and additional content.
            </p>
            <h2>Us</h2>
            <p>
                The Tibetan Buddhist Calendar app is an independent project and initiative started by a group of Rigpa
                students. Its content is based on the Rigpa calendar, but it does not claim to represent it fully or to
                restrict its content to that of the Rigpa calendar.
            </p>
            <p>
                <b>Thanks to</b>: Eszter Hoffmann, Tobias Satzger,{' '}
                <a
                    href="https://www.lotsawahouse.org/translators/stefan-mang/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Stefan Mang
                </a>
                , Margot Cheda,{' '}
                <a target="_blank" href="http://themeditationcafe.nl" rel="noopener noreferrer">
                    Steve Cope
                </a>
                ,{' '}
                <a
                    target="_blank"
                    href="https://www.instagram.com/alex.makes.drawings/?hl=en"
                    rel="noopener noreferrer"
                >
                    Alex Peters
                </a>
                , Pete Fry, and countless friends and sangha who gave feedback, advice and encouragement along the way.
            </p>
        </Frame>
    </div>
);

export default AboutUs;
