/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
    WiMoonAltNew,
    WiMoonAltWaxingCrescent1,
    WiMoonAltWaxingCrescent2,
    WiMoonAltWaxingCrescent3,
    WiMoonAltWaxingCrescent4,
    WiMoonAltWaxingCrescent5,
    WiMoonAltWaxingCrescent6,
    WiMoonAltFirstQuarter,
    WiMoonAltWaxingGibbous1,
    WiMoonAltWaxingGibbous2,
    WiMoonAltWaxingGibbous3,
    WiMoonAltWaxingGibbous4,
    WiMoonAltWaxingGibbous5,
    WiMoonAltWaxingGibbous6,
    WiMoonAltFull,
    WiMoonAltWaningGibbous1,
    WiMoonAltWaningGibbous2,
    WiMoonAltWaningGibbous3,
    WiMoonAltWaningGibbous4,
    WiMoonAltWaningGibbous5,
    WiMoonAltWaningGibbous6,
    WiMoonAltThirdQuarter,
    WiMoonAltWaningCrescent1,
    WiMoonAltWaningCrescent2,
    WiMoonAltWaningCrescent3,
    WiMoonAltWaningCrescent4,
    WiMoonAltWaningCrescent5,
    WiMoonAltWaningCrescent6,
} from 'react-icons/wi';
import Icon from '../components/Icon';

/**
 * Creates a node with the icon representing the phase of the moon
 * @param {number} date the Tibetan date's number (1-30)
 * @param {*} props additional props to be applied to the component
 * @returns {React.Component}
 */
export const getMoonIcon = (date, props) => {
    switch (date) {
        case 1:
            return <WiMoonAltNew {...props} />;
        case 2:
            return <WiMoonAltWaxingCrescent1 {...props} />;
        case 3:
            return <WiMoonAltWaxingCrescent2 {...props} />;
        case 4:
            return <WiMoonAltWaxingCrescent3 {...props} />;
        case 5:
            return <WiMoonAltWaxingCrescent4 {...props} />;
        case 6:
            return <WiMoonAltWaxingCrescent5 {...props} />;
        case 7:
            return <WiMoonAltWaxingCrescent6 {...props} />;
        case 8:
            return <WiMoonAltFirstQuarter {...props} />;
        case 9:
            return <WiMoonAltWaxingGibbous1 {...props} />;
        case 10:
            return <WiMoonAltWaxingGibbous2 {...props} />;
        case 11:
            return <WiMoonAltWaxingGibbous3 {...props} />;
        case 12:
            return <WiMoonAltWaxingGibbous4 {...props} />;
        case 13:
            return <WiMoonAltWaxingGibbous5 {...props} />;
        case 14:
            return <WiMoonAltWaxingGibbous6 {...props} />;
        case 15:
            return <Icon type="FULL_MOON" style={{ height: '20px' }} {...props} />;
        case 16:
            return <WiMoonAltFull {...props} />;
        case 17:
            return <WiMoonAltWaningGibbous1 {...props} />;
        case 18:
            return <WiMoonAltWaningGibbous2 {...props} />;
        case 19:
            return <WiMoonAltWaningGibbous3 {...props} />;
        case 20:
            return <WiMoonAltWaningGibbous4 {...props} />;
        case 21:
            return <WiMoonAltWaningGibbous5 {...props} />;
        case 22:
            return <WiMoonAltWaningGibbous6 {...props} />;
        case 23:
            return <WiMoonAltThirdQuarter {...props} />;
        case 24:
            return <WiMoonAltWaningCrescent1 {...props} />;
        case 25:
            return <WiMoonAltWaningCrescent2 {...props} />;
        case 26:
            return <WiMoonAltWaningCrescent3 {...props} />;
        case 27:
            return <WiMoonAltWaningCrescent4 {...props} />;
        case 28:
            return <WiMoonAltWaningCrescent5 {...props} />;
        case 29:
            return <WiMoonAltWaningCrescent6 {...props} />;
        case 30:
            return <Icon type="NEW_MOON" style={{ height: '18px' }} {...props} />;
        default:
            return null;
    }
};
