/* eslint-disable no-param-reassign */
import * as React from 'react';
import { useSwipeable } from 'react-swipeable';
import styles from './SlidingView.module.scss';

const SWIPE_OFFSET = 70;
const TRANSITION = 'transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)';

/**
 * hook to make a swipable navigation component
 * @param {React.useRef} args.prevViewRef a ref to access the previous view (to the left of the current one)
 * @param {React.useRef} args.currentViewRef a ref to access the current view
 * @param {React.useRef} args.nextViewRef a ref to access the next view (to the right of the current one)
 * @param {(direction: 'Left'|'Right') => void} onNavigation a callback to be called with navigation direction on navigtion
 * @returns {[Swipeable, slideRight, slideLeft]} Swipable to have 3 children with refs as above, slide function for button navigation
 */
const useSwipeNavigation = ({ nextViewRef, currentViewRef, prevViewRef, onNavigation }) => {
    const handleSwiping = (event) => {
        const { deltaX, dir, first } = event;

        // prevent bug on anddroid when this fucntion is called also on button click
        if (first) {
            return;
        }

        if (dir === 'Left') {
            nextViewRef.current.style.transform = `translateX(calc(100% - ${-deltaX}px))`;
            currentViewRef.current.style.transform = `translateX(${deltaX}px)`;
        } else if (dir === 'Right') {
            prevViewRef.current.style.transform = `translateX(calc(-100% + ${deltaX}px))`;
            currentViewRef.current.style.transform = `translateX(${deltaX}px)`;
        }
    };

    const handleSwiped = (event) => {
        const { deltaX, dir, preventDefault } = event;
        if (preventDefault) {
            preventDefault();
        }

        if (!nextViewRef.current || !currentViewRef.current || !prevViewRef.current) {
            return;
        }

        nextViewRef.current.style.transition = TRANSITION;
        currentViewRef.current.style.transition = TRANSITION;
        prevViewRef.current.style.transition = TRANSITION;

        const resetTransition = () => {
            if (nextViewRef.current && prevViewRef.current) {
                nextViewRef.current.style.transition = `none`;
                currentViewRef.current.style.transition = `none`;
                prevViewRef.current.style.transition = `none`;
            }
        };

        if (deltaX < -SWIPE_OFFSET) {
            nextViewRef.current.style.transform = `translateX(0)`;
            currentViewRef.current.style.transform = `translateX(-100%)`;
            setTimeout(() => {
                resetTransition();
                onNavigation(dir);
            }, 300);
        } else if (deltaX > SWIPE_OFFSET) {
            currentViewRef.current.style.transform = `translateX(100%)`;
            prevViewRef.current.style.transform = `translateX(0)`;
            setTimeout(() => {
                resetTransition();
                onNavigation(dir);
            }, 300);
        } else {
            // reset partial swipe animation
            prevViewRef.current.style.transform = `translateX(-100%)`;
            currentViewRef.current.style.transform = `translateX(0)`;
            nextViewRef.current.style.transform = `translateX(100%)`;
            resetTransition();
        }
    };

    /**
     * React component that handles navigation between 3 views through swipe movements
     * @param props as of https://github.com/FormidableLabs/react-swipeable documentation. onSwipe, onSwiping is set internally
     * @param children render childern function called with getViewStyles
     * @returns {React.Component}
     */
    const Swipeable = ({ children, ...props }) => {
        const handlers = useSwipeable({ onSwiped: handleSwiped, onSwiping: handleSwiping, ...props });

        /**
         * @param {'prev' | 'current' | 'next'} position the position of the view
         * @return {{className: string, style: obj}} to be set on the child
         */
        const getViewStyles = (position) => {
            let className;
            let style;

            switch (position) {
                case 'prev':
                    className = `${styles.view} ${styles.leftView}`;
                    style = { transform: 'translateX(-100%)' };
                    break;
                case 'current':
                    className = `${styles.view}`;
                    style = { transform: 'translateX(0)' };
                    break;
                case 'next':
                    className = `${styles.view} ${styles.rightView}`;
                    style = { transform: 'translateX(100%)' };
                    break;
                default:
                    throw new Error('getViewProps of Swipeable child was called with unknown position');
            }

            return { style, className };
        };

        return (
            <div className={styles.container} {...handlers}>
                {children(getViewStyles)}
            </div>
        );
    };

    const slideToNext = () => handleSwiped({ deltaX: -200, dir: 'Left' });
    const slideToPrev = () => handleSwiped({ deltaX: 200, dir: 'Right' });

    return [Swipeable, slideToNext, slideToPrev];
};

export default useSwipeNavigation;
