import * as React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Frame } from '../Frame';
import styles from './Modal.module.scss';

const Modal = (props) => {
    const { children, overlayClass, className, ...rest } = props;

    return (
        <ReactModal
            {...rest}
            className={[styles.content, className].join(' ')}
            overlayClassName={[styles.overlay, overlayClass].join(' ')}
        >
            <Frame>{children}</Frame>
        </ReactModal>
    );
};

Modal.propTypes = {
    /** the content of the modal */
    children: PropTypes.any,
    /** additional className to be applied to the overlay */
    overlayClass: PropTypes.string,
    /** additional className to be applied to the content wrapper */
    className: PropTypes.string,
};

Modal.defaultProps = {
    children: null,
    overlayClass: '',
    className: '',
};

export default Modal;
