/* eslint-disable import/prefer-default-export */
export const WESTERN_ANNIVERSARIES = {
    1: {},
    2: {},
    3: {
        13: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Drubwang Tsoknyi Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Tsoknyi_Rinpoche' },
                    dates: '1966-',
                },
            },
        ],
    },
    4: {
        4: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Chögyam Trungpa Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Ch%C3%B6gyam_Trungpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Eleventh-Trungpa-Chogyam-Trungpa/11231',
                    },
                    dates: '1940-1987',
                },
            },
        ],
        14: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Garchen Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Garchen_Rinpoche' },
                    dates: '1936-',
                },
            },
        ],
        15: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Shenphen Dawa Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Shenphen_Dawa_Rinpoche' },
                    dates: '1950–2018',
                },
            },
        ],
    },
    5: {},
    6: {
        18: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Dzongsar Jamyang Khyentse Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Dzongsar_Khyentse_Rinpoche' },
                    dates: '1961-',
                },
            },
        ],
        24: [
            {
                type: 'western-birthday',
                description: {
                    name: '7th Dzongchen Pönlop Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Dzogchen_Ponlop_Rinpoche' },
                    dates: '1965-',
                },
            },
        ],
        26: [
            {
                type: 'western-birthday',
                description: {
                    name: '17th Karmapa, Ogyen Trinley Dorjé',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Ogyen_Trinley_Dorje' },
                    dates: '1985-',
                },
            },
        ],
    },
    7: {
        6: [
            {
                type: 'western-birthday',
                description: {
                    name: 'His Holiness the 14th Dalai Lama Tenzin Gyatso',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=H.H._the_Fourteenth_Dalai_Lama_Tenzin_Gyatso',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/fourteenth-dalai-lama/',
                    },
                    dates: '1935-',
                    prayer: {
                        tibetan:
                            'སྟོང་ཉིད་སྙིང་རྗེ་ཟུང་དུ་འཇུག་པའི་ལམ། །ཆེས་ཆེར་གསལ་མཛད་གངས་ཅན་བསྟན་འགྲོའི་མགོན། །ཕྱག་ན་པདྨོ་བསྟན་འཛིན་རྒྱ་མཚོ་ལ། །གསོལ་བ་འདེབས་སོ་བཞེད་དོན་ལྷུན་གྲུབ་ཤོག །',
                        english:
                            'You make the path that combines emptiness and compassion grow clearer and clearer, | Lord of the teachings and beings in the Snowy Land of Tibet, | To you, the Lotus Holder Tenzin Gyatso, | We pray: may all your wishes be spontaneously fulfilled!',
                    },
                },
            },
        ],
    },
    8: {
        27: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Nyoshul Khenpo Jamyang Dorjé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Nyoshul_Khen_Rinpoche',
                        tol: 'https://treasuryoflives.org/biographies/view/Nyoshul-Khenpo-Jamyang-Dorje/8177',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/nyoshul-khenpo-jamyang-dorje/',
                    },
                    dates: '1932-1999',
                    prayer: {
                        tibetan:
                            'བརྟན་མཁས་ཡོན་ཏན་ཀུན་རྫོགས་སྡེ་སྣོད་འཆང་། །འཇམ་དབྱངས་བླ་མའི་བྱིན་ཐོབ་རིག་པ་འཛིན། །ངེས་གསང་རྡོ་རྗེ་སྙིང་པོའི་ཤིང་རྟ་མཆོག །དཔལ་ལྡན་བླ་མའི་ཞབས་ལ་གསོལ་བ་འདེབས། །',
                        english:
                            'You have perfected all the precious qualities of discipline and learning, and hold all the classes of Buddha’s teaching, | Graced with the blessing of the lama Mañjuśrī, you are a vidyādhara master | Supreme at conveying the definitive secret teaching of Dzogpachenpo—the Vajra Essence. | Glorious lama, at your feet I pray!',
                    },
                },
            },
            {
                type: 'western-anniversary',
                description: {
                    name: 'Dudjom Sangyum Kusho Rigdzin Wangmo',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Sangyum_Rigdzin_Wangmo' },
                    dates: '-2014',
                },
            },
        ],
        28: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Sogyal Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Sogyal_Rinpoche' },
                    dates: '1947-2019',
                },
            },
        ],
    },
    9: {
        4: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Chökyi Nyima Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Ch%C3%B6kyi_Nyima_Rinpoche' },
                    dates: '1951-',
                },
            },
        ],
        7: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Kyabgön Gongma Trichen Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Kyabgon_Gongma_Trichen_Rinpoche' },
                    dates: '1945-',
                },
            },
        ],
        27: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Namkhai Norbu Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Namkhai_Norbu_Rinpoche' },
                    dates: '1938-2018',
                },
            },
        ],
    },
    10: {
        23: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Dzigar Kongtrul Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Dzigar_Kongtrul_Rinpoche' },
                    dates: '1964-',
                },
            },
        ],
        29: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Lodi Gyari Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Lodi_Gyari_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/lodi-gyari-rinpoche/',
                    },
                    dates: '1949-2018',
                },
            },
        ],
    },
    11: {
        17: [
            {
                type: 'western-anniversary',
                description: {
                    name: 'Chagdud Tulku Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Chagdud_Tulku_Rinpoche' },
                    dates: '1930-2002',
                },
            },
        ],
        18: [
            {
                type: 'western-birthday',
                description: {
                    name: '7th Ling Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Ling_Choktrul_Rinpoche' },
                    dates: '1985-',
                },
            },
        ],
        19: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Kyabgon Gongma Trizin Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Kyabgon_Gongma_Trizin_Rinpoche' },
                    dates: '1974-',
                },
            },
        ],
        20: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Yongey Mingyur Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Mingyur_Rinpoche' },
                    dates: '1975-',
                },
            },
        ],
    },
    12: {
        17: [
            {
                type: 'western-birthday',
                description: {
                    name: 'Dzogchen Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Dzogchen_Rinpoche' },
                    dates: '1964-',
                },
            },
        ],
        25: [
            {
                type: 'western-anniversary',
                description: {
                    name: '6th Ling Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Ling_Rinpoche' },
                    dates: '1903-1983',
                },
            },
            {
                type: 'western-birthday',
                description: {
                    name: 'Shechen Rabjam Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Shechen_Rabjam_Rinpoche' },
                    dates: '1967-',
                },
            },
        ],
    },
};
