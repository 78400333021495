/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Frame } from '../../ui-components';
import generalStyles from './TextPages.module.scss';
import styles from './Vitality.module.scss';

import bird from '../YearDetailsModal/Animals/images/Bird.png';
import dog from '../YearDetailsModal/Animals/images/Dog.png';
import dragon from '../YearDetailsModal/Animals/images/Dragon.png';
import horse from '../YearDetailsModal/Animals/images/Horse.png';
import monkey from '../YearDetailsModal/Animals/images/Monkey.png';
import mouse from '../YearDetailsModal/Animals/images/Mouse.png';
import ox from '../YearDetailsModal/Animals/images/Ox.png';
import pig from '../YearDetailsModal/Animals/images/Pig.png';
import rabbit from '../YearDetailsModal/Animals/images/Rabbit.png';
import sheep from '../YearDetailsModal/Animals/images/Sheep.png';
import snake from '../YearDetailsModal/Animals/images/Snake.png';
import tiger from '../YearDetailsModal/Animals/images/Tiger.png';

const SIGNS = [
    { name: 'Mouse', img: mouse, essence: 'Wednesday', force: 'Tuesday', bad: 'Saturday' },
    { name: 'Ox', img: ox, essence: 'Saturday', force: 'Wednesday', bad: 'Thursday' },
    { name: 'Tiger', img: tiger, essence: 'Thursday', force: 'Saturday', bad: 'Friday' },
    { name: 'Rabbit', img: rabbit, essence: 'Thursday', force: 'Saturday', bad: 'Friday' },
    { name: 'Dragon', img: dragon, essence: 'Sunday', force: 'Wednesday', bad: 'Thursday' },
    { name: 'Snake', img: snake, essence: 'Tuesday', force: 'Friday', bad: 'Wednesday' },
    { name: 'Horse', img: horse, essence: 'Tuesday', force: 'Friday', bad: 'Wednesday' },
    { name: 'Sheep', img: sheep, essence: 'Friday', force: 'Monday', bad: 'Thursday' },
    { name: 'Monkey', img: monkey, essence: 'Friday', force: 'Thursday', bad: 'Tuesday' },
    { name: 'Bird', img: bird, essence: 'Friday', force: 'Thursday', bad: 'Tuesday' },
    { name: 'Dog', img: dog, essence: 'Monday', force: 'Wednesday', bad: 'Thursday' },
    { name: 'Pig', img: pig, essence: 'Wednesday', force: 'Tuesday', bad: 'Saturday' },
];

const Vitality = () => {
    const [selectedSign, setSelectedSign] = React.useState(SIGNS[0]);

    const getSignClickHandler = (sign) => () => {
        setSelectedSign(sign);
    };

    return (
        <div className={generalStyles.container}>
            <Frame>
                <h1>
                    Vitality days <span className="tib-text">བླ་གཟའ།</span>
                </h1>
                <p>
                    A person’s year of birth relates to a particular animal in the Tibetan and Chinese zodiac. Based on
                    this, certain planetary configurations are considered either auspicious or inauspicious. In this
                    way, some days are considered favourable for spiritual and worldly endeavours, while others are
                    unfavourable.
                </p>
                <p>For each of the birth year animals this interactive table will show the:</p>
                <ol>
                    <li>
                        <strong>Life-essence days</strong> (<span className="tib-text">བླ་གཟའ་</span>): ideal for
                        initiating important works
                    </li>
                    <li>
                        <strong>Life-force days</strong> (<span className="tib-text">སྲོག་གཟའ་</span>): ideal for
                        spiritual practice
                    </li>
                    <li>
                        <strong>Inauspicious days</strong> (<span className="tib-text">གཤེད་གཟའ་</span>): unfavourable
                        for engaging with important matters
                    </li>
                </ol>
                <p>Click one of the signs to see associated days.</p>
                <div className={styles.signs}>
                    {SIGNS.map((sign) => (
                        <div
                            key={sign.name}
                            role="button"
                            tabIndex={0}
                            onClick={getSignClickHandler(sign)}
                            className={
                                sign.name === selectedSign.name ? `${styles.sign} ${styles.selected}` : styles.sign
                            }
                        >
                            <img src={sign.img} alt={sign.name} width={30} height={30} />
                            <span>{sign.name}</span>
                        </div>
                    ))}
                </div>
                <div className={styles.results}>
                    <div className={styles.result}>
                        <span className={styles.type}>Life-essence day: </span>
                        <span className={styles.day}>{selectedSign.essence}</span>
                    </div>
                    <div className={styles.result}>
                        <span className={styles.type}>Life-force day: </span>
                        <span className={styles.day}>{selectedSign.force}</span>
                    </div>
                    <div className={styles.result}>
                        <span className={styles.type}>Inauspicious day: </span>
                        <span className={styles.day}>{selectedSign.bad}</span>
                    </div>
                </div>
            </Frame>
        </div>
    );
};

export default Vitality;
