/* eslint-disable import/prefer-default-export */
export const QUOTES = {
    template: {
        tibetan: [''],
        english: [''],
        title: { tibetan: '', english: '' },
        author: { tibetan: '', english: '' },
        links: { lh: '' },
        isPartial: false,
    },
    bud: {
        tibetan: [
            'སྙིང་རྗེ་ཆེན་པོས་རྩོད་ལྡན་སྙིགས་མའི་ཞིང༌། །',
            'བཟུང་ནས་སྨོན་ལམ་ཆེན་པོ་ལྔ་བརྒྱ་བཏབ། །',
            'པད་དཀར་ལྟར་བསྔགས་མཚན་ཐོས་ཕྱིར་མི་ལྡོག །',
            'སྟོན་པ་ཐུགས་རྗེ་ཅན་ལ་ཕྱག་འཚལ་ལོ། །',
            'བླ་མ་སྟོན་པ་བཅོམ་ལྡན་འདས་དེ་བཞིན་གཤེགས་པ་དགྲ་བཅོམ་པ་ཡང་དག་པར་རྫོགས་པའི་སངས་རྒྱས་དཔལ་རྒྱལ་བ་ཤཱཀྱ་ཐུབ་པ་ལ་ཕྱག་འཚལ་ལོ། །མཆོད་དོ་སྐྱབས་སུ་མཆིའོ། །བྱིན་གྱིས་བརླབ་ཏུ་གསོལ། །',
            'ཏདྱ་ཐཱ། ཨོཾ་མུ་ནེ་མུ་ནེ་མ་ཧཱ་མུ་ན་ཡེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'With your great compassion, you embraced this turbulent and degenerate world,',
            'And made five hundred mighty aspirations.',
            'You are as exalted as the white lotus; whoever hears your name shall never return to saṃsāra—',
            'Most compassionate teacher, to you I pay homage!',
            'Supreme teacher, bhagavān, tathāgata, arhat, complete and perfect buddha, glorious conqueror, Śākyamuni, to you I bow! To you I pay homage! In you I take refuge! Grant your blessings, I pray!',
            'tadyathā | oṃ mune mune mahāmunaye svāhā',
        ],
        title: {
            tibetan: 'བདག་ཅག་གི་སྟོན་པ་ཐུགས་རྗེ་ཅན་ཤཱཀྱ་ཐུབ་པའི་བསྟོད་པ།',
            english: 'In Praise of Our Compassionate Teacher, Śākyamuni',
        },
        author: { tibetan: 'སྐྱབས་རྗེ་འཁྲུལ་ཞིག་རིན་པོ་ཆེ།', english: 'Kyabjé Trulshik Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/trulshik-rinpoche/homage-buddhas-and-bodhisattvas' },
        isPartial: false,
    },
    dp: {
        tibetan: [
            'གང་ཞིག་ཡིད་ལ་དྲན་པ་ཙམ་གྱིས་ཀྱང༌། །',
            'འདོད་པའི་དངོས་གྲུབ་སྟེར་བར་མཛད་པ་ཡི། །',
            'ཆོས་སྐྱོང་ཡིད་བཞིན་ནོར་བུ་ཁྱེད་རྣམས་ཀྱིས། །',
            'བདག་གི་བསམ་དོན་མ་ལུས་འགྲུབ་པར་མཛོད། །',
        ],
        english: [
            'All you dharmapālas, you wish-fulfilling jewels,',
            'Who grant us the accomplishments we desire,',
            'Merely by thinking upon you, ',
            'Let all our wishes and aspirations be fulfilled.',
        ],
        title: {
            tibetan: 'ཆོས་སྐྱོང་འདོད་གསོལ།',
            english: 'Requesting the Dharmapālas for the Fulfillment of Our Aims',
        },
        author: { tibetan: 'འགོས་ལོ་ཙཱ་བ་གཞོན་ནུ་དཔལ།', english: 'Gö Lotsawa Shyönnu Pal' },
        links: { lh: '' },
        isPartial: false,
    },
    amitBud: {
        tibetan: [
            'བདེ་ཆེན་ཞིང་དུ་ཆོས་ཀྱི་འཁོར་ལོ་བསྐོར། །',
            'སེམས་ཅན་རྣམས་ལ་རྟག་ཏུ་ཐུགས་རྗེས་གཟིགས། །',
            'དམ་བཅའ་ཇི་བཞིན་འགྲོ་བའི་དོན་མཛད་པ། །',
            'སྣང་མཐའ་མཉམ་བཞག་མཛད་ལ་ཕྱག་འཚལ་ལོ། །',
            'ཨོཾ་ཨ་མི་དྷེ་ཝ་ཧྲཱིཿ',
        ],
        english: [
            'In the realm of Sukhāvatī, you turn the wheel of Dharma,',
            'Gazing on living beings with all your compassion,',
            'And acting for their benefit, just as you vowed—',
            'To you, Amitābha resting in meditation, I pay homage!',
            'oṃ amitābha hrīḥ',
        ],
        title: { tibetan: 'སངས་རྒྱས་འོད་དཔག་མེད་ཀྱི་བསྟོད་པ།', english: 'In Praise of Buddha Amitābha' },
        author: { tibetan: 'སྐྱབས་རྗེ་འཁྲུལ་ཞིག་རིན་པོ་ཆེ།', english: 'Kyabjé Trulshik Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/trulshik-rinpoche/homage-buddhas-and-bodhisattvas' },
        isPartial: false,
    },
    tara: {
        tibetan: [
            'བདག་གིས་ཚེ་རབས་སྔོན་ནས་བསྒྲུབས་པའི་ལྷ། །',
            'དུས་གསུམ་སངས་རྒྱས་ཀུན་གྱི་ཕྲིན་ལས་མ། །',
            'རབ་དཀར་ཞལ་གཅིག་ཕྱག་གཉིས་སྤྱན་བདུན་མ། །',
            'ཡུམ་གྱུར་ཨུཏྤལ་བསྣམས་ལ་ཕྱག་འཚལ་ལོ།',
            'ཨོཾ་ཏཱ་རེ་ཏུཏྟཱ་རེ་ཏུ་རེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'Deity on whom I meditated in lives gone by,',
            'You are the enlightened activity of all buddhas, past, present, and future,',
            'Brilliant white, with your one face, two hands, and seven eyes,',
            'Mother of the buddhas, holder of the utpala flower, to you I pay homage!',
            'oṃ tāre tuttāre ture svāhā',
        ],
        title: { tibetan: 'རྗེ་བཙུན་འཕགས་མ་སྒྲོལ་མའི་བསྟོད་པ།', english: 'In Praise of the Noble Saviouress Tārā' },
        author: { tibetan: 'སྐྱབས་རྗེ་འཁྲུལ་ཞིག་རིན་པོ་ཆེ།', english: 'Kyabjé Trulshik Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/trulshik-rinpoche/homage-buddhas-and-bodhisattvas' },
        isPartial: false,
    },
    medBud: {
        tibetan: [
            'ཐུགས་རྗེ་ཀུན་ལ་སྙོམས་པའི་བཅོམ་ལྡན་འདས། །',
            'མཚན་ཙམ་ཐོས་པས་ངན་འགྲོའི་སྡུག་བསྔལ་སེལ། །',
            'དུག་གསུམ་ནད་སེལ་སངས་རྒྱས་སྨན་གྱི་བླ། །',
            'བཻ་ཌཱུརྻ་ཡི་འོད་ལ་ཕྱག་འཚལ་ལོ། །',
            'བཅོམ་ལྡན་འདས་དེ་བཞིན་གཤེགས་པ་དགྲ་བཅོམ་པ་ཡང་དག་པར་རྫོགས་པའི་སངས་རྒྱས་སྨན་གྱི་བླ་བཻ་ཌཱུརྻ་འོད་ཀྱི་རྒྱལ་པོ་ལ་ཕྱག་འཚལ་ལོ། །མཆོད་དོ་སྐྱབས་སུ་མཆིའོ། །',
            'ཏ་དྱ་ཐཱ༔ ཨོཾ་བྷཻ་ཥ་ཛྱེ་བྷཻ་ཥ་ཛྱེ་མ་ཧཱ་བྷཻ་ཥ་ཛྱེ་བྷཻ་ཥ་ཛྱེ༔ རཱ་ཛ་ས་མུདྒ་ཏེ་སྭཱ་ཧཱ༔',
        ],
        english: [
            'Blessed Bhaiṣajya Guru, Buddha of Medicine',
            'Blessed one, whose compassion for all is equal,',
            'Simply hearing your name dispels the suffering of lower realms,',
            'Buddha of Medicine, you who heal the sickness of the three poisons—',
            'Light of Lapis Lazuli, to you I pay homage!',
            'Blessed one, tathāgata, arhat, complete and perfect buddha, Buddha of Medicine, Radiant Light of Lapis Lazuli King, to you I bow! To you I pay homage! In you I take refuge!',
            'tadyathā | oṃ bhaiṣajye bhaiṣajye mahā-bhaiṣajye bhaiṣajyarāja-samudgate svāhā',
        ],
        title: { tibetan: 'བཅོམ་ལྡན་འདས་སྨན་གྱི་བླའིབསྟོད་པ།', english: 'In Praise of the Buddha of Medicine' },
        author: { tibetan: 'སྐྱབས་རྗེ་འཁྲུལ་ཞིག་རིན་པོ་ཆེ།', english: 'Kyabjé Trulshik Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/trulshik-rinpoche/homage-buddhas-and-bodhisattvas' },
        isPartial: false,
    },
    dd01: {
        tibetan: [
            'དཔལ་ཆེན་ཐུགས་ལས་སྤྲུལ་པའི་ལྷ་མོ་ནི། །',
            'བདག་མེད་སྟོང་པ་དོན་དམ་ཆོས་ཀྱི་དབྱིངས། །',
            'རྣམ་པ་ཀུན་གྱི་མཆོག་དང་རབ་ལྡན་མ། །',
            'འཁོར་འདས་ཀུན་ཁྱབ་ཀུན་ཏུ་བཟང་མོར་བསྟོད། །',
        ],
        english: [
            'Goddess emanated from the heart of the great and glorious deity,',
            'The ultimate space of phenomena, empty and devoid of self,',
            'You who fully possess the supreme of all aspects',
            'And pervade saṃsāra and nirvāṇa—Samantabhadrī, to you I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd02: {
        tibetan: [
            'དེ་བཞིན་ཉིད་དབྱིངས་སྤྲོས་མཚན་མི་རྟོག་ཀྱང་། །',
            'སྐྱེ་མེད་ངང་ལས་སྐྱེ་བའི་ཆོ་འཕྲུལ་སྟོན། །',
            'ཞི་དང་ཁྲོ་ཆགས་སྣ་ཚོགས་རབ་བསྒྱུར་མ། །',
            'མིང་བརྒྱ་མཚན་སྟོང་མ་ལ་བསྟོད་པར་བགྱི། །',
        ],
        english: [
            'The basic space of reality has no conceivable features or attributes,',
            'Yet, out of this state beyond arising, the display of arising magically unfolds,',
            'And you take on various manifestations, both peaceful and wrathful—',
            'To you, mother of a hundred names and thousand attributes, I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd03: {
        tibetan: [
            'ལྷ་མོ་པརྞ་ཤ་བ་རི་དང་ནི། །',
            'གཽ་རཱི་བརྒྱད་སོགས་རྣམ་འགྱུར་ཅིར་ཡང་འཆར། །',
            'སྲིད་གསུམ་དབང་བསྒྱུར་རྡོ་རྗེ་འཕྲུལ་མོ་ཆེ། །',
            'དཔལ་ལྡན་དབྱིངས་ཀྱི་རྒྱལ་མོར་ཕྱག་འཚལ་བསྟོད། །',
        ],
        english: [
            'You who manifest as the goddess Parṇaśavarī,',
            'As the eight Gaurī, and in various other forms—',
            'Great vajra enchantress, mistress of the three realms,',
            'Glorious Queen of Space, to you I offer homage and praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd04: {
        tibetan: [
            'སྣང་སྲིད་འབྱུང་ཆེན་ལྔ་པོའི་རང་བཞིན་ནི། །',
            'སྲ་མཉན་དྲོ་དང་སྐྱོད་བྱེད་བདག་ཉིད་ཆེ། །',
            'དུས་བཞིའི་དཔལ་འཛིན་ནམ་མཁའི་གོས་ཅན་མ། །',
            'འཁོར་འདས་སྐྱེད་བའི་ཡུམ་ཆེན་མ་ལ་བསྟོད། །',
        ],
        english: [
            'Magnificent Goddess whose nature is that of the five elements',
            'Of appearance and existence—steady, fluid, warm and agile,',
            'Bearer of the splendour of the four times, lady cloaked in space,',
            'Great Mother from whom saṃsāra and nirvāṇa are born, to you I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd05: {
        tibetan: [
            'རྡོ་རྗེ་བཙུན་མོའི་ཆུ་སྐྱེས་རྒྱས་པའི་དབུས། །',
            'རབ་འབྱམས་དཀྱིལ་འཁོར་ཏིལ་གྱི་གོང་བུ་བཞིན། །',
            'རྟག་ཏུ་བཞུགས་པའི་འོག་མིན་གསང་བ་ཆེར། །',
            'གསང་འཛིན་དབང་མོ་རྣལ་འབྱོར་མ་དཔལ་བསྟོད། །',
        ],
        english: [
            "In the heart of the Vajra Queen's blossoming lotus",
            'Infinite maṇḍalas abide forever, like heaps of sesame seeds,',
            'And in the great and mysterious Akaniṣṭha,',
            'You are the powerful yoginī, keeper of secrets—to you I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd06: {
        tibetan: [
            'ངོ་བོ་རང་བཞིན་མེད་ཅིང་བདག་རྟོག་སྤངས། །',
            'སྤྲོས་པའི་མཚན་མ་ཐམས་ཅད་ཉེར་ཞི་ཞིང་། །',
            'འོད་གསལ་རྣམ་པ་ཀུན་གྱི་མཆོག་ལྡན་མ། །',
            'ཤེས་རབ་ཕ་རོལ་ཕྱིན་མའི་ངོ་བོར་བསྟོད། །',
        ],
        english: [
            'Without essence or nature and transcending any notion of self,',
            'You have entirely pacified the signs of conceptual elaboration',
            'And are endowed with luminosity, the supreme of all aspects—',
            'To you, the very essence of Prajñāpāramitā, I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd07: {
        tibetan: [
            'སྟོང་པའི་དབྱིངས་ལས་གཟུགས་ཀྱི་སྐུར་བཞེངས་པ། །',
            'ལོངས་སྤྱོད་ཡོངས་རྫོགས་དེ་བཞིན་གཤེགས་མ་ལྔ། །',
            'ས་དང་ཕ་རོལ་ཕྱིན་བཅུའི་རྩེ་མོར་ཕྱིན། །',
            'དཔལ་ལྡན་རང་བྱུང་རྒྱལ་མོར་ཕྱག་འཚལ་བསྟོད། །',
        ],
        english: [
            'Out of empty basic space, you arise in your kāya of form',
            'As the five female buddhas of perfect enjoyment,',
            'At the pinnacle of the ten stages and transcendent perfections—',
            'Glorious self-arisen queen, to you I offer homage and praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd09: {
        tibetan: [
            'འཛག་པའི་དྲི་མ་ཐམས་ཅད་རྣམ་སྤངས་ཤིང་། །',
            'ཟག་མེད་བདེ་བ་མཆོག་གི་ལོངས་སྤྱོད་ཀྱིས། །',
            'རྟག་ཏུ་མྱོས་ཤིང་ཧཾ་ཡིག་རི་བོང་གི །',
            'བདུད་རྩིའི་ཆ་ཡིས་ཡོངས་སུ་གང་ལ་བསྟོད། །',
        ],
        english: [
            'To you who fully possess the qualities of nectar',
            'From the rabbit-bearing moon of the syllable Haṃ',
            'And constantly savour the supreme undefiled bliss',
            'In which any fault of emission is absent, I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd08: {
        tibetan: [
            'ཧེ་རུ་ཀ་དང་དྲེགས་པའི་ལྷ་ཆེན་སོགས། །',
            'གཅིག་ཏུ་མ་ངེས་ཐབས་ཆེན་རྣམ་མང་དང་། །',
            'ཁ་སྦྱོར་བཞི་ཡི་རོལ་རྩེད་སྟོན་མཛད་མ། །',
            'སྒྱུ་འཕྲུལ་ལྷ་མོ་ཆེན་མོར་ཕྱག་འཚལ་བསྟོད། །',
        ],
        english: [
            'Together with Heruka, haughty Mahādeva and others,',
            'Various different partners of great skilful methods,',
            'You demonstrate the play of fourfold union—',
            'Great goddess of illusion, to you I offer homage and praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd10: {
        tibetan: [
            'དམིགས་མེད་སྙིང་རྗེས་སྐྱེ་འགྲོ་ཐམས་ཅད་ལ། །',
            'མ་གཅིག་བུ་ལ་བརྩེ་བཞིན་རྟག་ཏུ་གདུང་། །',
            'བྱམས་པ་ཆེན་པོའི་སྤྱན་གྱིས་གཟིགས་མཛད་པ། །',
            'མ་གཅིག་བྱང་ཆུབ་སེམས་མ་ཆེན་མོར་བསྟོད། །',
        ],
        english: [
            'You who have non-referential compassion for all beings',
            'And care for them constantly with motherly love.',
            'You who look upon them with eyes of deep loving kindness—',
            'Sole mother, great bodhisattva, to you I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd11: {
        tibetan: [
            'དུས་གསུམ་ཀུན་ཏུ་སྔོན་འགྲོ་རྗེས་སུ་འཇུག །',
            'དུས་བཞི་ཀུན་ཁྱབ་སྣོད་བཅུད་ཕྱྭ་ཆེན་མོ། །',
            'སྤྲུལ་པའི་གཟུགས་ནི་དུ་མ་མང་པོར་སྟོན། །',
            'རྒྱུན་མི་ཆད་པས་འགྲོ་བ་འདུལ་ལ་བསྟོད། །',
        ],
        english: [
            'You who course throughout ages past, present and future,',
            'Pervading the four times as prosperity in lands and beings;',
            'You who display countless forms of emanation',
            'And train beings continuously—to you I offer praise.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    dd12: {
        tibetan: [
            'དབྱིངས་ལ་གནས་ཀྱང་ཞི་བའི་མཐར་མ་ལྷུང་། །',
            'སྲིད་པར་རྗེས་སུ་ཆགས་པའི་སྒྱུ་ཐབས་ཀྱིས། །',
            'ནམ་ཡང་འགོགས་པ་མེད་པའི་ཕྲིན་ལས་ནི། །',
            'འཁོར་བ་གནས་ཀྱི་བར་དུ་སྟོན་ལ་འདུད། །',
        ],
        english: [
            'You who abide in basic space without lapsing into the quiescent extreme,',
            'And through illusory means engage passionately in conditioned existence;',
            'You who display enlightened activity that will never come to an end',
            'But continue for as long as saṃsāra itself endures—to you I bow down.',
        ],
        title: { tibetan: 'དཔལ་ལྷ་དགྱེས་པའི་དབྱངས་སྙན།', english: 'Sweet Melody to Delight the Glorious Deity' },
        author: { tibetan: 'འཇམ་དབྱངས་མཁྱེན་བརྩེ་ཆོས་ཀྱི་བློ་གྲོས།', english: 'Jamyang Khyentsé Chökyi Lodrö' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/kalidevi-praise' },
        isPartial: false,
    },
    hd1229: {
        tibetan: [
            'ཧཱུྃ། གཞི་དབྱིངས་འགྱུར་མེད་ཆོས་སྐུའི་ཀློང་ཡངས་ནས། །',
            'རྩལ་སྣང་འགགས་མེད་རྡོ་རྗེ་ཕུར་པའི་ལྷ། །',
            'དབྱིངས་རིག་དབྱེར་མེད་གཏོར་མ་འདི་བཞེས་ལ། །',
            'འཁྲུལ་སྣང་ནད་གདོན་དགྲ་བགེགས་དབྱིངས་སུ་ཟློག །',
        ],
        english: [
            "Hūṃ! Out of the unchanging space of the ground, dharmakāya's vast expanse,",
            'You manifest unimpededly as its dynamic power, deities of Kīla—',
            'Please accept this torma of indivisible space and rigpa,',
            'And dispel all delusory appearances, sickness, harmful influences, enemies and obstructing forces into all-pervading space!',
        ],
        title: { tibetan: 'ཕུར་བཟློག་བསྡུས་པ།', english: 'Short Vajrakīla Dokpa' },
        author: { tibetan: 'ཟ་ཧོར་གྱི་བན་དེ།', english: 'Fifth Dalai Lama' },
        links: { lh: '' },
        isPartial: false,
    },
    hd0515: {
        tibetan: [
            'གང་ཞིག་ཡིད་ལ་དྲན་པ་ཙམ་གྱིས་ཀྱང༌། །',
            'འདོད་པའི་དངོས་གྲུབ་སྟེར་བར་མཛད་པ་ཡི། །',
            'ཆོས་སྐྱོང་ཡིད་བཞིན་ནོར་བུ་ཁྱེད་རྣམས་ཀྱིས། །',
            'བདག་གི་བསམ་དོན་མ་ལུས་འགྲུབ་པར་མཛོད། །',
        ],
        english: [
            'All you dharmapālas, you wish-fulfilling jewels,',
            'Who grant us the accomplishments we desire,',
            'Merely by thinking upon you, ',
            'Let all our wishes and aspirations be fulfilled.',
        ],
        title: {
            tibetan: 'ཆོས་སྐྱོང་འདོད་གསོལ།',
            english: 'Requesting the Dharmapālas for the Fulfillment of Our Aims',
        },
        author: { tibetan: 'འགོས་ལོ་ཙཱ་བ་གཞོན་ནུ་དཔལ།', english: 'Gö Lotsawa Shyönnu Pal' },
        links: { lh: '' },
        isPartial: false,
    },
    hd0922: {
        tibetan: [
            'གང་གིས་དང་པོར་བྱང་ཆུབ་ཐུགས་བསྐྱེད་ནས། །',
            'བསོད་ནམས་ཡེ་ཤེས་ཚོགས་གཉིས་རྫོགས་མཛད་ཅིང༌། །',
            'དུས་འདིར་མཛད་པ་རྒྱ་ཆེན་འགྲོ་བ་ཡི། །',
            'མགོན་གྱུར་ཁྱོད་ལ་བདག་གིས་བསྟོད་པར་བགྱི། །',
            'ཏདྱ་ཐཱ། ཨོཾ་མུ་ནེ་མུ་ནེ་མ་ཧཱ་མུ་ན་ཡེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'You I shall praise, who first awakened the mind of enlightenment,',
            'Then completed the accumulations of merit and wisdom,',
            'And now in this age, through the vast sway of your actions,',
            'Have become the lord and protector of living beings.',
            'tadyathā | oṃ mune mune mahāmunaye svāhā',
        ],
        title: { tibetan: 'ཐུབ་པའི་བསྟོད་པ།', english: 'Praise of the Buddha' },
        author: { tibetan: 'སློབ་དཔན་དཔའ་བོ།', english: 'Āryaśūra' },
        links: { lh: 'https://www.lotsawahouse.org/indian-masters/arya-shura/short-praise-buddhas-deeds' },
        isPartial: true,
    },
    hd0604: {
        tibetan: [
            'གང་གིས་དང་པོར་བྱང་ཆུབ་ཐུགས་བསྐྱེད་ནས། །',
            'བསོད་ནམས་ཡེ་ཤེས་ཚོགས་གཉིས་རྫོགས་མཛད་ཅིང༌། །',
            'དུས་འདིར་མཛད་པ་རྒྱ་ཆེན་འགྲོ་བ་ཡི། །',
            'མགོན་གྱུར་ཁྱོད་ལ་བདག་གིས་བསྟོད་པར་བགྱི། །',
            'ཏདྱ་ཐཱ། ཨོཾ་མུ་ནེ་མུ་ནེ་མ་ཧཱ་མུ་ན་ཡེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'You I shall praise, who first awakened the mind of enlightenment,',
            'Then completed the accumulations of merit and wisdom,',
            'And now in this age, through the vast sway of your actions,',
            'Have become the lord and protector of living beings.',
            'tadyathā | oṃ mune mune mahāmunaye svāhā',
        ],
        title: { tibetan: 'ཐུབ་པའི་བསྟོད་པ།', english: 'Praise of the Buddha' },
        author: { tibetan: 'སློབ་དཔན་དཔའ་བོ།', english: 'Āryaśūra' },
        links: { lh: 'https://www.lotsawahouse.org/indian-masters/arya-shura/short-praise-buddhas-deeds' },
        isPartial: true,
    },
    hd0415: {
        tibetan: [
            'གང་གིས་དང་པོར་བྱང་ཆུབ་ཐུགས་བསྐྱེད་ནས། །',
            'བསོད་ནམས་ཡེ་ཤེས་ཚོགས་གཉིས་རྫོགས་མཛད་ཅིང༌། །',
            'དུས་འདིར་མཛད་པ་རྒྱ་ཆེན་འགྲོ་བ་ཡི། །',
            'མགོན་གྱུར་ཁྱོད་ལ་བདག་གིས་བསྟོད་པར་བགྱི། །',
            'ཏདྱ་ཐཱ། ཨོཾ་མུ་ནེ་མུ་ནེ་མ་ཧཱ་མུ་ན་ཡེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'You I shall praise, who first awakened the mind of enlightenment,',
            'Then completed the accumulations of merit and wisdom,',
            'And now in this age, through the vast sway of your actions,',
            'Have become the lord and protector of living beings.',
            'tadyathā | oṃ mune mune mahāmunaye svāhā',
        ],
        title: { tibetan: 'ཐུབ་པའི་བསྟོད་པ།', english: 'Praise of the Buddha' },
        author: { tibetan: 'སློབ་དཔན་དཔའ་བོ།', english: 'Āryaśūra' },
        links: { lh: 'https://www.lotsawahouse.org/indian-masters/arya-shura/short-praise-buddhas-deeds' },
        isPartial: true,
    },
    hd0407: {
        tibetan: [
            'གང་ཚེ་རྐང་གཉིས་གཙོ་བོ་ཁྱོད་བལྟམས་ཚེ། །',
            'ས་ཆེན་འདི་ལ་གོམ་པ་བདུན་བོར་ནས། །',
            'ང་ནི་འཇིག་རྟེན་འདི་ན་མཆོག་ཅེས་གསུངས། །',
            'དེ་ཚེ་མཁས་པ་ཁྱོད་ལ་ཕྱག་འཚལ་ལོ། །',
            'ཏདྱ་ཐཱ། ཨོཾ་མུ་ནེ་མུ་ནེ་མ་ཧཱ་མུ་ན་ཡེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'When you were born, chief among human beings,',
            'You took seven steps on this earth and said:',
            '“In this world I am supreme.”',
            'To you, O wise one, I pay homage!',
            'tadyathā | oṃ mune mune mahāmunaye svāhā',
        ],
        title: { tibetan: 'ཐུབ་པའི་བསྟོད་པ།', english: 'Praise of the Buddha' },
        author: { tibetan: 'སློབ་དཔན་དཔའ་བོ།', english: 'Āryaśūra' },
        links: { lh: 'https://www.lotsawahouse.org/indian-masters/arya-shura/short-praise-buddhas-deeds' },
        isPartial: true,
    },
    hd0101: {
        tibetan: [
            'སྟོན་པ་བླ་མེད་སངས་རྒྱས་རིན་པོ་ཆེ། །',
            'སྐྱོབས་པ་བླ་མེད་དམ་ཆོས་རིན་པོ་ཆེ། །',
            'འདྲེན་པ་བླ་མེད་དགེ་འདུན་རིན་པོ་ཆེ། །',
            'སྐྱབས་གནས་དཀོན་མཆོག་གསུམ་གྱི་བཀྲ་ཤིས་ཤོག །',
        ],
        english: [
            'Precious Buddha, the unsurpassable teacher',
            'Precious Dharma, the unsurpassable protection',
            'Precious Saṅgha, the unsurpassable guides—',
            'Three rare and supreme sources of refuge let everything be auspicious!',
        ],
        title: {
            tibetan: 'བཀྲ་ཤིས་བརྗོད་པ།',
            english: 'Prayer of Auspiciousness',
        },
    },
    grd01: {
        tibetan: [
            'ཡོས་ཟླའི་ཚེས་བཅུར་རྐྱེན་དང་བརྡས་བསྐུལ་ཏེ། །',
            'རྒྱལ་སྲིད་སྤངས་ནས་བསིལ་བའི་ཚལ་དུ་ཕེབས། །',
            'འབྲེལ་ཚད་དོན་ལྡན་སྦྱོར་སྒྲོལ་བརྟུལ་ཞུགས་གྲུབ། །',
            'ཤཱནྟ་རཀྵི་ཏ་ལ་གསོལ་བ་འདེབས། །',
            'མངའ་ཐང་ལོངས་སྤྱོད་འཕེལ་ཞིང་རྐང་འགྲོས་འདུ། །',
            'འགྲོ་ཀུན་དབང་འདུས་གཞི་བདག་བྲན་བཞིན་ཉན། །',
            'རྨི་ལམ་རྟགས་མཚན་བཟང་པོར་མཐོང་བ་དང༌། །',
            'གྲུབ་གཉིས་གཏེར་ཁ་བརྡོལ་བར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the hare month, driven by circumstances and by signs',
            'You relinquish the kingdom for the Chilly Grove charnel ground.',
            'There, through your yogic disciplines of union and liberation, you make every connection of the highest meaning—',
            'Śāntarakṣita, to you we pray.',
            'Grant your blessing so that our power, position and wealth increase, all human beings are attracted,',
            'All living things are magnetized, earth lords obey us like servants,',
            'We see good dreams and auspicious signs,',
            'And burst open the treasure of siddhis, ordinary and supreme.',
            'om ah hung benza guru pema siddhi hung',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    hd0115: {
        tibetan: [
            'གང་གིས་དང་པོར་བྱང་ཆུབ་ཐུགས་བསྐྱེད་ནས། །',
            'བསོད་ནམས་ཡེ་ཤེས་ཚོགས་གཉིས་རྫོགས་མཛད་ཅིང༌། །',
            'དུས་འདིར་མཛད་པ་རྒྱ་ཆེན་འགྲོ་བ་ཡི། །',
            'མགོན་གྱུར་ཁྱོད་ལ་བདག་གིས་བསྟོད་པར་བགྱི། །',
            'ཏདྱ་ཐཱ། ཨོཾ་མུ་ནེ་མུ་ནེ་མ་ཧཱ་མུ་ན་ཡེ་སྭཱ་ཧཱ།',
        ],
        english: [
            'You I shall praise, who first awakened the mind of enlightenment,',
            'Then completed the accumulations of merit and wisdom,',
            'And now in this age, through the vast sway of your actions,',
            'Have become the lord and protector of living beings.',
            'tadyathā | oṃ mune mune mahāmunaye svāhā',
        ],
        title: { tibetan: 'ཐུབ་པའི་བསྟོད་པ།', english: 'Praise of the Buddha' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Ārya Nāgārjuna' },
        links: { lh: 'https://www.lotsawahouse.org/indian-masters/nagarjuna/great-praise-twelve-acts-buddha' },
        isPartial: false,
    },
    grd02: {
        tibetan: [
            'འབྲུག་ཟླའི་ཚེས་བཅུར་ཀུན་དགར་རབ་ཏུ་བྱུང༌། །',
            'ཤེས་བྱ་མཐར་ཕྱིན་མཁས་གྲུབ་དུ་མ་ལས། །',
            'མདོ་སྔགས་ཀུན་ཆུབ་ཤཱཀྱ་སེང་གེར་གྲགས། །',
            'བློ་ལྡན་མཆོག་སྲེད་ཞབས་ལ་གསོལ་བ་འདེབས། །',
            'འཆི་བདག་གཟའ་བདུད་སྡེ་བརྒྱད་གནོད་འཚེ་ཞི། །',
            'ཚུལ་ཁྲིམས་རྣམ་དག་བསླབ་གསུམ་ཡོངས་སུ་རྫོགས། །',
            'རིག་པའི་གནས་དང་མདོ་རྒྱུད་ཐམས་ཅད་ལ། །',
            'མཁྱེན་རབ་ཕྱོགས་མེད་རྒྱས་པར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the dragon month, you are ordained by Ānanda,',
            'And with countless scholars and adepts who have achieved the ultimate in knowledge,',
            'You attain consummate mastery over all the sūtras and tantras, ',
            'And are famed as both Shakya Senge and Loden Choksé—at your feet we pray.',
            'Grant your blessing so that the lords of death, planetary demons and the eight classes who bring harm all are pacified,',
            'Pure discipline and the three higher trainings are perfected,',
            'And our knowledge of every field of learning, and of all the sūtras and tantras, ',
            'Grows to become limitless.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd03: {
        tibetan: [
            'སྦྲུལ་ཟླའི་ཚེས་བཅུར་ཟ་ཧོར་རྒྱལ་པོ་ཡིས། །',
            'མེར་བསྲེགས་མཚོར་སྒྱུར་པད་སྡོང་དབུས་སུ་བཞུགས། །',
            'ལྷ་ལྕམ་ཡུམ་བསྟེན་རྡོ་རྗེའི་སྐུ་རུ་གྱུར། །',
            'འཆི་མེད་པདྨ་འབྱུང་གནས་ལ་གསོལ་བ་འདེབས། །',
            'གཟའ་སྐར་ཕྱོགས་ངན་དགྲ་རྐུན་གནོད་འཚེ་ཞི། །',
            'གཞི་བྱེས་ཀུན་ཏུ་བཀྲ་ཤིས་བདེ་ལེགས་འཕེལ། །',
            'དཀར་ཕྱོགས་སྲུང་མས་རྟག་ཏུ་སྡོང་གྲོགས་མཛད། །',
            'བསམ་རྒུ་ལྷུན་གྱིས་འགྲུབ་པར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the snake month, the king of Zahor',
            'Burns you in a fire, which you transform into a lake, emerging resplendent upon the heart of a lotus blossom.',
            'Relying on Princess Mandāravā as consort, you attain the vajra body—',
            'Immortal Lotus-born, to you we pray.',
            'Grant your blessing so that all harm is eliminated from ill-omened planets and stars, from enemies and thieves,',
            'When at home or abroad, auspiciousness, prosperity and well-being increase,',
            'Well-intentioned guardians act always as our allies and support,',
            'And all our wishes are spontaneously fulfilled.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd04: {
        tibetan: [
            'རྟ་ཟླའི་ཚེས་བཅུར་ཨོ་རྒྱན་བདུད་བློན་གྱིས། །',
            'བསྲེགས་ཀྱང་མ་ཚིག་ངོ་མཚར་རྫུ་འཕྲུལ་བསྟན། །',
            'བཀའ་འདུས་ཆོས་བསྟན་འཁོར་བཅས་བྱང་ཆུབ་བཀོད། །',
            'པདྨ་བཛྲ་རྩལ་ལ་གསོལ་བ་འདེབས། །',
            'བདུད་སྡེ་ལྷ་མིན་གདུག་པའི་ས་བདག་ཀླུའི། །',
            'གནོད་པ་ཀུན་ཞི་བསྒོ་བའི་བཀའ་ལ་ཉན། །',
            'ཆོས་སྐྱོང་སྲུང་མས་ཅི་བཅོལ་ལས་སྒྲུབ་ཅིང༌། །',
            'འགོ་བའི་ལྷ་ཡིས་སྐྱོབ་པར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the horse month, when the malicious ministers of Uḍḍiyāna',
            'Burn you alive, you display the amazing miracle of remaining unscathed,',
            'And, by teaching the Kadü Chökyi Gyatso, lead the king and his entourage to enlightenment—',
            'Padma Vajra Tsal, to you we pray,',
            'Grant your blessing so that all harm from māras, asuras, vicious earth lords and nāgas is completely eliminated,',
            'And they obey the instructions they are given,',
            'Dharmapālas and guardians carry out all our commands,',
            'And patron gods afford their protection.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd05: {
        tibetan: [
            'ལུག་ཟླའི་ཚེས་བཅུར་རྒྱ་གར་ལྷོ་ཕྱོགས་སུ། །',
            'མུ་སྟེགས་ལྷ་སྲུང་དང་བཅས་ཐལ་བར་བརླགས། །',
            'སངས་རྒྱས་བསྟན་པའི་རྒྱལ་མཚན་དགུང་དུ་ཕྱར། །',
            'སེང་གེ་སྒྲ་སྒྲོག་རྩལ་ལ་གསོལ་བ་འདེབས། །',
            'བར་ཆད་ཀུན་ཞི་ཚེ་དཔལ་ཡོན་ཏན་རྒྱས། །',
            'སྣང་བ་དབང་འདུས་ཕས་རྒོལ་རྩད་ནས་ཆོམས། །',
            'བདུད་བཞི་ལས་རྒྱལ་ཁམས་གསུམ་འགྲོ་ཀུན་ལ། །',
            'སྦྱིན་བཏང་ཕན་ཡོན་ཐོབ་པར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            "On the tenth day of the sheep month, in India's southern reaches,",
            'You grind to dust the tīrthikas, along with their gods and guardians,',
            "And hoist aloft the victory banner of Buddha's teaching—",
            'Mighty Senge Dradok, to you we pray.',
            'Grant your blessing so that all obstacles are pacified, longevity, prosperity and perfect qualities are increased,',
            'We gain control over all perceptions, utterly vanquish all opponents,',
            'We are victorious over the four māras, and actualize the benefits of generosity',
            'Towards all beings in the three worlds.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd06: {
        tibetan: [
            'སྤྲེལ་ཟླའི་ཚེས་བཅུར་དྷ་ན་ཀོ་ཤའི་མཚོར། །',
            'ངོ་མཚར་རང་བྱུང་པདྨའི་སྙིང་པོར་འཁྲུངས། །',
            'མཚོ་གླིང་མཁའ་འགྲོ་རྣམས་ལ་ཆོས་འཁོར་བསྐོར། །',
            'གུ་རུ་མཚོ་སྐྱེས་རྡོ་རྗེར་གསོལ་བ་འདེབས། །',
            'ནད་གདོན་བགེགས་དང་འགལ་རྐྱེན་བར་ཆད་ཞི། །',
            'ཚེ་བསོད་དཔལ་རྒྱས་ཉམས་རྟོགས་མངོན་ཤེས་འཆར། །',
            'གུ་རུའི་ཞལ་མཐོང་ཆོས་ཐོས་དཔལ་གྱི་རིར། །',
            'སྐྱེས་ནས་ཐུགས་ཀྱི་སྲས་འགྱུར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the monkey month, on the Dhanakośa lake,',
            'In the heart of a wondrous, naturally appearing lotus you are born,',
            'And turn the wheel of Dharma for the island dākinīs—',
            'Guru Tsokyé Dorje, to you we  pray.',
            'Grant your blessing so that all obstructions from illness and döns, and all negative circumstances and obstacles are pacified,',
            'Long life, merit and splendour increase, experiences, realization and clairvoyance arise,',
            'And we are reborn on the Mountain of Glory to look upon your face, Guru, hear your teaching ',
            'And become the son or daughter of your heart.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd07: {
        tibetan: [
            'བྱ་ཟླའི་ཚེས་བཅུར་ཟངས་གླིང་མུ་སྟེགས་ཀྱིས། །',
            'གངྒཱའི་ཆུར་བསྐྱུར་གར་མཛད་ཆུ་བཟློག་པས། །',
            'གནས་ཡུལ་ཉེན་ཚེ་སྐྱབས་གསོལ་བསྟན་ལ་བཀོད། །',
            'གུ་རུ་མཁའ་ལྡིང་རྩལ་ལ་གསོལ་བ་འདེབས། །',
            'གཅོང་ནད་ཀུན་བྱང་འཁོར་མང་བསམ་དོན་འགྲུབ། །',
            'བང་མཛོད་འབྲུ་དང་མཁོ་རྒུའི་ཡོ་བྱད་འཕེལ། །',
            'སེམས་བདེ་ངག་ལ་ནུས་པ་འབར་བ་དང༌། །',
            'ཏིང་འཛིན་མཆོག་གྱུར་སྐྱེ་བར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the rooster month, the tīrthikas of Zangling,',
            'Fling you into the river Ganges, but you dance in the sky, and reverse the current.',
            'When the whole land is in peril, and they beg you for refuge and protection, you bring them all within the Dharma—',
            'Guru Khanding Tsal, to you we pray.',
            'Grant your blessing so that all chronic and immune system diseases are healed, our helpers grow in number, and our wishes are fulfilled, ',
            'Food and resources of every kind increase, as well as everything we need,',
            'Our mind is peaceful, our speech blazes with power,',
            'And our samādhi meditation becomes sublime and supreme.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd08: {
        tibetan: [
            'ཁྱི་ཟླའི་ཚེས་བཅུར་མུ་སྟེགས་དུག་སྦྱོར་གྱིས། །',
            'མ་ཚུགས་བདུད་རྩིར་བསྒྱུར་ནས་གཟི་མདངས་འབར། །',
            'འཁོར་བཅས་དད་ཐོབ་ནང་གི་བསྟན་ལ་བཅུག །',
            'བདེ་ཆེན་ཉི་མ་འོད་ཟེར་ལ་གསོལ་བ་འདེབས། །',
            'ལོ་ཟླ་ཞག་དུས་ཀེག་དང་ལྟས་ངན་ཞི། །',
            'ཆོས་དང་ཐར་པའི་བར་དུ་གཅོད་པ་སེལ། །',
            'དབང་ཐང་ཀླུང་རྟ་གུད་སོགས་སོར་ཆུད་ཅིང༌། །',
            'ཕྱོགས་ལས་རྣམ་པར་རྒྱལ་བར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the dog month, the tīrthikas give you poison,',
            'Unharmed, you transform it into nectar, and shine in splendour.',
            'They and all their followers are inspired with faith and enter the teaching of Buddha—',
            'Guru of Great Bliss, Nyima Özer, to you we pray.',
            'Grant your blessing so that obstacles of the year, month, day and time as well as ill omens are pacified,',
            'All obstacles in the way of the Dharma and liberation are dispelled,',
            'Any decline in our personal power and lungta is repaired',
            'And we are victorious in every direction.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd09: {
        tibetan: [
            'ཕག་ཟླའི་ཚེས་བཅུར་བལ་ཡུལ་ལྷ་འདྲེ་རྣམས། །',
            'ཕུར་བུས་བཏུལ་ནས་ཡང་དག་སྒྲུབ་པ་མཛད། །',
            'ཕྱག་རྒྱ་ཆེན་པོ་མཆོག་གི་རིག་འཛིན་གྲུབ། །',
            'རྡོ་རྗེ་ཐོད་ཕྲེང་རྩལ་ལ་གསོལ་བ་འདེབས། །',
            'གཤིན་རྗེ་མ་མོ་འབྱུང་པོའི་ནད་དང་གདོན། །',
            'གློ་བུར་ཡེ་འདྲོག་དུས་མིན་འཆི་བ་ཞི། །',
            'གཏད་སེལ་ལས་གྲོལ་ལྷ་འདྲེས་ཅི་བཅོལ་ཉན། །',
            'རང་ལུས་རྡོ་རྗེར་འགྱུར་བར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the pig month, the gods and demons of Nepal',
            'Are subjugated by Vajrakīla, and then through accomplishing Yangdak',
            'You attain the vidyādhara stage of supreme mahāmudrā—',
            'Dorje Tötreng Tsal, to you we pray.',
            'Grant your blessing so that all illnesses and harm caused by yamas, mamos and jungpos,',
            'Sudden accidents, and untimely death all are eliminated,',
            'We are freed from dangerous curses, gods and demons obey whatever their commands,',
            'And our own bodies become indestructible as vajra.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd10: {
        tibetan: [
            'བྱི་ཟླའི་ཚེས་བཅུར་བོད་ཡུལ་ལྷ་འདྲེ་བཏུལ། །',
            'བསམ་ཡས་ལེགས་བཞེངས་དམ་ཆོས་སྒྲོན་མེ་སྦར། །',
            'སྐལ་ལྡན་རྗེ་འབངས་སྨིན་ཅིང་གྲོལ་བར་མཛད། །',
            'པདྨ་སཾ་བྷ་བ་ལ་གསོལ་བ་འདེབས། །',
            'ངག་འཁྱལ་ཚིག་རྩུབ་རྫུན་སྨྲས་སྐུར་པ་བཏབ། །',
            'ཉན་ཐོས་འདུལ་ཁྲིམས་བྱང་སེམས་བསླབ་པ་ཉམས། །',
            'གསང་སྔགས་དམ་ཚིག་འགལ་སོགས་ཉེས་ལྟུང་རྣམས། །',
            'བྱང་ཞིང་བདག་རྒྱུད་དག་པར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the rat month, you tame the gods and spirits of Tibet,',
            'You build Samyé to perfection, and light the flame of the sacred Dharma,',
            'You ripen and liberate your fortunate disciples, the king and subjects—',
            'Padmasambhava, to you we pray.',
            'Grant your blessing so that idle chatter, harsh words, lies and slander,',
            "Impairments of the śrāvaka' vinaya discipline and bodhisattva precepts,",
            'Breakages of samaya vows and the like, wrongdoing and downfalls, all are healed,',
            'And our whole being is transformed and purified.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd11: {
        tibetan: [
            'གླང་ཟླའི་ཚེས་བཅུར་མཆོད་ཡོན་བཀའ་བགྲོས་ཏེ། །',
            'གངས་ཅན་མཐའ་དབུས་གཏེར་ཁ་བྱེ་སྙེད་སྦས། །',
            'བསྟན་ཞབས་གཏེར་སྐྱོང་བཀའ་དྲིན་རྒྱུན་ཆད་མེད། །',
            'རྡོ་རྗེ་གྲོ་ལོད་རྩལ་ལ་གསོལ་བ་འདེབས། །',
            'དུས་མིན་འཆི་སོགས་འཕྲལ་རྐྱེན་མི་འབྱུང་ཞིང༌། །',
            'འཕོས་མ་ཐག་ཏུ་བདེ་ཆེན་ཞིང་ཁམས་དང༌། །',
            'པདྨ་འོད་དུ་གུ་རུའི་དྲུང་སྐྱེས་ནས། །',
            'འགྲོ་དོན་དཔག་མེད་འབྱུང་བར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the ox month, master and disciples confer,',
            'And in the centre and on the borders of the land of snows you conceal millions of terma troves.',
            'With these treasures you safeguard what remains of the teachings, all with your constant kindness—',
            'All-powerful Dorje Drolö, to you we pray.',
            'Grant your blessing so that sudden misfortune, like untimely death, never befalls us, ',
            'And as soon as we transfer to the next life, we are born in the pure realm of Great Bliss,',
            'And in the paradise of Lotus Light, in your very presence, Guru,',
            'And become a source of limitless benefit to living beings.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    grd12: {
        tibetan: [
            'སྟག་ཟླའི་ཚེས་བཅུར་ཨོ་རྒྱན་རྒྱལ་སར་ཕེབས། །',
            'ཨིནྡྲ་བྷཱུ་ཏིའི་སྲས་ཀྱི་སྐལ་པར་མཛད། །',
            'འོད་འཆང་ཁབ་བཞེས་འཁོར་ལོས་སྒྱུར་རྒྱལ་གྱུར། །',
            'གུ་རུ་པདྨ་རྒྱལ་པོར་གསོལ་བ་འདེབས། །',
            'གཟི་བརྗིད་སྟོབས་རྒྱས་ནད་རིགས་འབྱུང་འཁྲུགས་ཞི། །',
            'དབང་ལྔ་ལས་རུང་ཡིད་བདེ་སྤོབས་པ་མྱུར། །',
            'མི་དང་མི་མིན་རླུང་སེམས་དབང་དུ་འདུས། །',
            'བསམ་དོན་ཡིད་བཞིན་འགྲུབ་པར་བྱིན་གྱིས་རློབས། །',
            'ཨོཾ་ཨཱཿཧཱུྃ་བཛྲ་གུ་རུ་པདྨ་སིདྡྷི་ཧཱུྃ།',
        ],
        english: [
            'On the tenth day of the tiger month, you arrive at the palace in Oḍḍiyāna,',
            'And are adopted by Indrabhūti as his crown prince.',
            'Taking Prabhāvatī as your wife, you rule as a universal monarch—',
            'Guru Pema Gyalpo, to you we pray.',
            'Grant your blessing so that our brilliance, charisma and strength increase, epidemics and turmoil in the elements are pacified,',
            'The five senses become pliable, and happiness, peace of mind and confidence quickly arise,',
            'We gather under our power the prāṇa-mind of humans and non-humans,',
            'And all our aspirations are fulfilled, just as we desire.',
            'oṃ āḥ hūṃ vajra guru padma siddhi hūṃ',
        ],
        title: { tibetan: 'བྱིན་རླབས་ཐིག་ལེ།', english: 'The Nucleus of Blessings' },
        author: { tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་རིན་པོ་ཆེ།', english: 'Jamgön Kongtrul Rinpoche' },
        links: { lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/nucleus-of-blessings' },
        isPartial: true,
    },
    1001: {
        tibetan: [
            'མང་དུ་བཤད་ལྟ་ཅི་ཞིག་དགོས། །',
            'བྱིས་པ་རང་གི་དོན་བྱེད་དང༌། །',
            'ཐུབ་པ་གཞན་གྱི་དོན་མཛད་པ། །',
            'འདི་གཉིས་ཀྱི་ནི་ཁྱད་པར་ལྟོས། །',
        ],
        english: [
            'What need is there to say a whole lot more?',
            'Buddhas work for the benefit of others,',
            'Ordinary people work for the benefit of themselves,',
            'And just look at the difference between them!',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1002: {
        tibetan: [
            'བདག་བདེ་གཞན་གྱི་སྡུག་བསྔལ་དག །',
            'ཡང་དག་བརྗེ་བ་མ་བྱས་ན། །',
            'སངས་རྒྱས་ཉིད་དུ་མི་འགྲུབ་ཅིང༌། །',
            'འཁོར་བ་ན་ཡང་བདེ་བ་མེད། །',
        ],
        english: [
            'Unless I can give away my happiness',
            "In exchange for others' suffering,",
            'I will not attain the awakening I seek,',
            "And even in saṃsāra I'll find no joy.",
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1003: {
        tibetan: [
            'འཇིག་རྟེན་དག་ན་འཚེ་བ་གང་ཡོད་དང༌། །',
            'འཇིགས་དང་སྡུག་བསྔལ་ཇི་སྙེད་ཡོད་གྱུར་པ། །',
            'དེ་ཀུན་བདག་ཏུ་འཛིན་པ་ལས་བྱུང་ན། །',
            'འདྲེ་ཆེན་དེས་ཀོ་བདག་ལ་ཅི་ཞིག་བྱ། །',
        ],
        english: [
            'If all the harms throughout the world,',
            'And all its fears and sufferings,',
            'All spring from clinging to a self,',
            'What need have I for such a demon?',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1004: {
        tibetan: [
            'ཡན་ལག་འདི་དག་ཐམས་ཅད་ནི། །',
            'ཐུབ་པས་ཤེས་རབ་དོན་དུ་གསུངས། །',
            'དེ་ཡི་ཕྱིར་ན་སྡུག་བསྔལ་དག །',
            'ཞི་བར་འདོད་པས་ཤེས་རབ་བསྐྱེད། །',
        ],
        english: [
            'All these different aspects of the teaching,',
            'The Buddha taught for the sake of wisdom.',
            'Therefore those who wish to pacify their ills',
            'Should generate this wisdom in their minds.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1005: {
        tibetan: [
            'ཀུན་རྫོབ་དང་ནི་དོན་དམ་སྟེ། །',
            'འདི་ནི་བདེན་པ་གཉིས་སུ་འདོད། །',
            'དོན་དམ་བློ་ཡི་སྤྱོད་ཡུལ་མིན། །',
            'བློ་ནི་ཀུན་རྫོབ་ཡིན་པར་བརྗོད། །',
        ],
        english: [
            'The relative and the ultimate—',
            'These, we claim, are the two truths.',
            'The ultimate lies beyond the reach of mind,',
            'For the ordinary mind is only relative.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1006: {
        tibetan: [
            'སྟོང་ཉིད་བག་ཆགས་གོམས་པས་ནི། །',
            'དངོས་པོའི་བག་ཆགས་སྤོང་འགྱུར་ཞིང༌། །',
            'ཅི་ཡང་མེད་ཅེས་གོམས་པས་ནི། །',
            'དེ་ཡང་ཕྱི་ནས་སྤོང་བར་འགྱུར། །',
        ],
        english: [
            'Through cultivating familiarity with emptiness,',
            'The habit of perceiving real entities will fade.',
            "By training in the view that nothing's real,",
            'In time this view itself will also disappear.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1007: {
        tibetan: [
            'གང་ཚེ་དངོས་དང་དངོས་མེད་དག །',
            'བློ་ཡི་མདུན་ན་མི་གནས་པ། །',
            'དེ་ཚེ་རྣམ་པ་གཞན་མེད་པས། །',
            'དམིགས་པ་མེད་པར་རབ་ཏུ་ཞི། །',
        ],
        english: [
            'When the notions of real and unreal',
            'Are absent from before the mind,',
            'Then, there is no other possibility,',
            'But to rest in total peace, beyond concepts.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1008: {
        tibetan: [
            'ཇི་སྲིད་ནམ་མཁའ་གནས་པ་དང༌། །',
            'འགྲོ་བ་ཇི་སྲིད་གནས་གྱུར་པ། །',
            'དེ་སྲིད་བདག་ནི་གནས་གྱུར་ནས། །',
            'འགྲོ་བའི་སྡུག་བསྔལ་སེལ་བར་ཤོག །',
        ],
        english: [
            'For as long as space exists,',
            'And as long as living beings endure,',
            'Until then, may I too remain,',
            'To dispel the misery of all beings.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    1009: {
        tibetan: [
            'དོན་དམ་ལྷན་ཅིག་སྐྱེས་པའི་ཡེ་ཤེས་ནི། །',
            'ཚོགས་བསགས་སྒྲིབ་པ་དག་པའི་ལག་རྗེས་དང༌། །',
            'རྟོགས་ལྡན་བླ་མའི་བྱིན་རླབས་ཁོ་ན་ལས། །',
            'ཐབས་གཞན་བསྟེན་པ་རྨོངས་པར་ཤེས་པར་བྱ། །',
        ],
        english: [
            'Ultimate co-emergent wisdom can only come',
            'From gathering accumulations and purifying obscurations,',
            'And through the blessings of a realized master—',
            "You should know that it's foolish to rely on any other method.",
        ],
        title: { tibetan: 'དོ་ཧ་མཛོད།', english: 'Treasury of Dohās' },
        author: { tibetan: 'མདའ་བསྣུན།', english: 'Saraha' },
        links: { lh: '' },
        isPartial: '',
    },
    1010: {
        tibetan: ['དངོས་པོར་འཛིན་པ་ཕྱུགས་དང་འདྲ། །', 'དངོས་མེད་འཛིན་པ་དེ་བས་བླུན། །'],
        english: [
            'To cling to things as real is to be like cattle,',
            'But to cling to things as unreal is even more foolish.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མདའ་བསྣུན།', english: 'Saraha' },
        links: { lh: '' },
        isPartial: '',
    },
    1011: {
        tibetan: ['བུ་སྣང་བས་མི་འཆིང་ཞེན་པས་འཆིང༌། །', 'ཞེན་པ་ཆོད་ཅིག་ནཱ་རོ་པ། །'],
        english: [
            'Son, it is not appearances that bind you, it is grasping.',
            'Cut through your attachment, O Nāropa.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'ཏི་ལོ་པ།', english: 'Tilopa' },
        links: { lh: '' },
        isPartial: '',
    },
    1012: {
        tibetan: ['ལས་ལས་འཇིག་རྟེན་སྣ་ཚོགས་སྐྱེ། །'],
        english: ['From karma the various worlds arise.'],
        title: { tibetan: 'མངོན་པ་མཛོད།', english: 'Treasury of Abhidharma' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1013: {
        tibetan: [
            'འཕགས་པ་མིན་ཡང་ཕ་མ་དང༌། །',
            'ནད་པ་དང་ནི་ཆོས་སྨྲ་དང༌། །',
            'སྐྱེ་མཐའི་བྱང་ཆུབ་སེམས་དཔའ་ལ། །',
            'ཡོན་ནི་གཞལ་དུ་མེད་པ་ཡིན། །',
        ],
        english: [
            'Even though they are not Āryas,',
            "Offerings to one's parents or the sick,",
            'To Dharma teachers or bodhisattvas in their final birth,',
            'Are without measure.',
        ],
        title: { tibetan: 'མངོན་པ་མཛོད།', english: 'Treasury of Abhidharma' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1014: {
        tibetan: [
            'ཕྲ་རྒྱས་སྤངས་པ་མ་ཡིན་དང༌། །',
            'ཡུལ་ནི་ཉེ་བར་གནས་པ་དང༌། །',
            'ཚུལ་བཞིན་མ་ཡིན་ཡིད་བྱེད་ལས། །',
            'ཉོན་མོངས་རྒྱུ་ན་ཚང་བ་ཡིན། །',
        ],
        english: [
            'Not having abandoned their subtle potential,',
            'Being close to objects that provoke them,',
            'And thinking in a way that is mistaken—',
            'With these, the causes for the afflictions are complete.',
        ],
        title: { tibetan: 'མངོན་པ་མཛོད།', english: 'Treasury of Abhidharma' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1015: {
        tibetan: [
            'གང་ལ་བཅོམ་དང་བློ་ཡིས་གཞན། །',
            'བསལ་ན་དེ་བློ་མི་འཇུག་པ། །',
            'བུམ་ཆུ་བཞིན་དུ་ཀུན་རྫོབ་ཏུ། །',
            'ཡོད་དེ་དོན་དམ་ཡོད་གཞན་ནོ། །',
        ],
        english: [
            'Things which, when destroyed or mentally dissected,',
            'Can no longer be identified by the mind,',
            'Such as pots or water, are relative;',
            'All else besides is ultimately existent.',
        ],
        title: { tibetan: 'མངོན་པ་མཛོད།', english: 'Treasury of Abhidharma' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1016: {
        tibetan: ['ཚུལ་གནས་ཐོས་དང་བསམ་ལྡན་པས། །', 'སྒོམ་པ་ལ་ནི་རབ་ཏུ་སྦྱོར།'],
        english: [
            'Observing discipline, and having heard and contemplated the teachings,',
            'One applies oneself intensively to meditation.',
        ],
        title: { tibetan: 'མངོན་པ་མཛོད།', english: 'Treasury of Abhidharma' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1017: {
        tibetan: [
            'སྟོན་པའི་བསྟན་པ་རྣམ་གཉིས་ཏེ། །',
            'ལུང་དང་རྟོགས་པའི་བདག་ཉིད་དོ། །',
            'དེ་འཛིན་བྱེད་པ་སྨྲ་བ་དང༌། །',
            'སྒྲུབ་པ་བྱེད་པ་ཁོ་ནའོ། །',
        ],
        english: [
            'The teaching of the Buddha has two aspects:',
            'The elements of scripture and realization.',
            'These are maintained only through teaching',
            'And through practice.',
        ],
        title: { tibetan: 'མངོན་པ་མཛོད།', english: 'Treasury of Abhidharma' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1018: {
        tibetan: [
            'དྲུག་གིས་གཅིག་ཅར་སྦྱར་བ་ན། །',
            'ཕྲ་རབ་རྡུལ་ཆ་དྲུག་ཏུ་འགྱུར། །',
            'དྲུག་པོ་རྣམས་ནི་གོ་གཅིག་ན། །',
            'གོང་བུའང་རྡུལ་ཕྲན་ཙམ་དུ་འགྱུར། །',
        ],
        english: [
            'When six other particles are joined to it,',
            'The subtle particle will have six parts.',
            'If the six all simply converge,',
            'Then even compounds will be infinitesimal.',
        ],
        title: { tibetan: 'ཉི་ཤུ་པ།', english: 'Twenty Verses' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1019: {
        tibetan: [
            'ང་རྒྱལ་དང་ནི་མ་དད་དང༌། །',
            'དོན་དུ་གཉེར་བ་མེད་ཉིད་དང༌། །',
            'ཕྱི་རོལ་རྣམ་གཡེང་ནང་དུ་སྡུད། །',
            'སྐྱོ་བས་ཉན་པ་དྲི་མ་ཡིན། །',
        ],
        english: [
            'Arrogance, lack of faith,',
            'Lack of any interest,',
            'Outward distraction, inward tension,',
            'And discouragement are the six stains.',
        ],
        title: { tibetan: 'རྣམ་བཤད་རིགས་པ།', english: 'Well Explained Reasoning' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1020: {
        tibetan: [
            'ཉོན་མོངས་དགྲ་རྣམས་མ་ལུས་འཆོས་པ་དང༌། །',
            'ངན་འགྲོའི་སྲིད་ལས་སྐྱོབ་པ་གང་ཡིན་པ། །',
            'འཆོས་སྐྱོབ་ཡོན་ཏན་ཕྱིར་ན་བསྟན་བཅོས་ཏེ། །',
            'གཉིས་པོ་འདི་དག་གཞན་གྱི་ལུགས་ལ་མེད། །',
        ],
        english: [
            "That which subdues all the enemies, one's own afflictions,",
            'And guards against future existence in the lower realms,',
            "Is called a 'treatise', because it subdues and protects,",
            'These two features are not found in other traditions.',
        ],
        title: { tibetan: 'རྣམ་བཤད་རིགས་པ།', english: 'Well Explained Reasoning' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1021: {
        tibetan: [
            'ཆོས་ནི་ཤེས་བྱ་ལམ་དང་ནི། །',
            'མྱ་ངན་འདས་དང་ཡིད་ཀྱི་ཡུལ། །',
            'བསོད་ནམས་ཚེ་དང་གསུང་རབ་དང༌། །',
            'འབྱུང་འགྱུར་ངེས་དང་ཆོས་ལུགས་ལའོ། །',
        ],
        english: [
            'Dharma means something knowable, the path,',
            'Nirvāṇa, a mental object,',
            'Merit, life, excellent teaching,',
            'A conditioned thing, regulation and religion.',
        ],
        title: { tibetan: 'རྣམ་བཤད་རིགས་པ།', english: 'Well Explained Reasoning' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1022: {
        tibetan: [
            'མདོ་དོན་སྨྲ་བར་འདོད་རྣམས་ལ། །',
            'མན་ངག་ཅུང་ཞིག་སྦྱིན་པར་བྱ། །',
            'དེ་ལ་མན་ངག་འདི་ཡིན་ཏེ། །',
            'དགོས་པ་བསྡུས་པའི་དོན་བཅས་དང༌། །',
            'ཚིག་དོན་བཅས་དང་མཚམས་སྦྱོར་བཅས། །',
            'བརྒལ་ལན་གཅས་ཏེ་བསྙད་པར་བྱ། །',
        ],
        english: [
            'Those who wish to teach the meaning of the sūtras,',
            'Should be offered a few words of practical advice.',
            'The practical instruction I refer to is as follows:',
            "Relate the teaching's purpose and its basic theme,",
            'The meaning of the words and the logic of their sequence,',
            'And offer a response to any possible objections.',
        ],
        title: { tibetan: 'རྣམ་བཤད་རིགས་པ།', english: 'Well Explained Reasoning' },
        author: { tibetan: 'དབྱིག་གཉེན།', english: 'Vasubandhu' },
        links: { lh: '' },
        isPartial: true,
    },
    1023: {
        tibetan: [
            'བླ་མ་སྐུ་བཞིའི་གངས་རི་ལ། །',
            'མོས་གུས་ཉི་མ་མ་ཤར་ན། །',
            'བྱིན་རླབས་ཆུ་རྒྱུན་མི་འབེབས་པས། །',
            'སེམས་མོས་གུས་འདི་ལ་ནན་ཏན་མཛོད། །',
        ],
        english: [
            'Unless the sun of devotion shines',
            "Upon the snowy peak of the master's four kāyas,",
            'The stream of his blessings will never flow.',
            'So strive to arouse devotion in your mind!',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'འབྲི་གུང་སྐྱོབ་པ་འཇིག་རྟེན་གསུམ་མགོན།',
            english: 'Drikung Kyobpa Jikten Sumgön',
        },
        links: { lh: '' },
        isPartial: true,
    },
    1024: {
        tibetan: [
            'རྒྱུ་ནི་བདེ་གཤེགས་སྙིང་པོ་སྟེ། རྟེན་ནི་མི་ལུས་རིན་ཆེན་མཆོག རྐྱེན་ནི་དགེ་བའི་བཤེས་གཉེན་ཡིན། ཐབས་ནི་དེ་ཡི་གདམས་ངག་སྟེ། འབྲས་བུ་རྫོགས་སངས་རྒྱས་ཀྱི་སྐུ། འཕྲིན་ལས་རྟོག་མེད་འགྲོ་དོན་མཛད།',
        ],
        english: [
            "The cause is the buddha nature. The support is the most precious human body. The circumstance is the spiritual friend. The method consists of his instructions. The result consists of the kāyas of perfect buddhahood. The activity is the spontaneous accomplishment of beings' benefit.",
        ],
        title: {
            tibetan: 'དྭགས་པོ་ཐར་རྒྱན།',
            english: 'Jewel Ornament of Liberation',
        },
        author: {
            tibetan: 'སྒམ་པོ་པ་བསོད་ནམས་རིན་ཆེན།',
            english: 'Gampopa Sönam Rinchen',
        },
        links: { lh: '' },
        isPartial: '',
    },
    1025: {
        tibetan: ['ཆོས་ཆོས་བཞིན་མ་སྤྱད་ན། །', 'ཆོས་ཀྱིས་སླར་ངན་སོང་དུ་འགྲོ་བའི་རྒྱུ་བྱེད། །'],
        english: [
            'Unless you practise Dharma according to the Dharma,',
            'Dharma itself will become the cause of lower rebirth.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'སྒམ་པོ་པ་བསོད་ནམས་རིན་ཆེན།',
            english: 'Gampopa Sönam Rinchen',
        },
        links: { lh: '' },
        isPartial: '',
    },
    1026: {
        tibetan: [
            'བསམ་པ་བཟང་ན་ས་དང་ལམ་ཡང་བཟང༌། །',
            'བསམ་པ་ངན་ན་ས་དང་ལམ་ཡང་ངན། །',
            'ཐམས་ཅད་བསམ་པ་དག་ལ་རག་ལས་པས། །',
            'རྟག་པར་བསམ་པ་བཟང་ལ་འབད་པར་བྱ། །',
        ],
        english: [
            'If the intentions are good, then so will be the levels and paths.',
            'If the intentions are bad, then the levels and paths will be too.',
            "Since everything depends on one's intentions,",
            'Always strive to make sure that they are positive.',
        ],
        title: {
            tibetan: 'སྙན་ངག་སྒྲ་རྒྱན།',
            english: 'A Literary Gem of Poetry',
        },
        author: { tibetan: 'རྗེ་ཙོང་ཁ་པ།', english: 'Jé Tsongkhapa' },
        links: { lh: '' },
        isPartial: '',
    },
    1027: {
        tibetan: [
            'དང་པོར་རྒྱ་ཆེར་ཐོས་པ་མང་དུ་བཙལ། །',
            'བར་དུ་གཞུང་ལུགས་ཐམས་ཅད་གདམས་པར་ཤར། །',
            'ཐ་མར་ཉིན་མཚན་ཀུན་ཏུ་ཉམས་སུ་བླངས། །',
            'ཀུན་ཀྱང་བསྟན་པ་རྒྱས་པའི་ཆེད་དུ་བསྔོས། །',
        ],
        english: [
            'At first, seek a learning that is vast and comprehensive,',
            'Next, take all the statements of the texts as practical advice,',
            'Finally, take this to heart by practising by day and night.',
            'Dedicating it all to the flourishing of the teachings.',
        ],
        title: {
            tibetan: 'རྟོགས་བརྗོད་རིན་པོ་ཆེ་འདུན་ལེགས་མ།',
            english: 'Noble Intentions',
        },
        author: { tibetan: 'རྗེ་ཙོང་ཁ་པ།', english: 'Jé Tsongkhapa' },
        links: { lh: '' },
        isPartial: '',
    },
    1028: {
        tibetan: [
            'སྣང་བ་རྟེན་འབྲེལ་བསླུ་བ་མེད་པ་དང༌། །',
            'སྟོང་པ་ཁས་ལེན་བྲལ་བའི་གོ་བ་གཉིས། །',
            'ཇི་སྲིད་སོ་སོར་སྣང་བ་དེ་སྲིད་དུ། །',
            'ད་དུང་ཐུབ་པའི་དགོངས་པ་རྟོགས་པ་མེད། །',
        ],
        english: [
            'For as long as the understanding of appearances—unfailing interdependence—',
            'And emptiness—the freedom from all assertions—',
            'Appear to be in conflict, for that long',
            "There can be no realization of Śākyamuni's intent.",
        ],
        title: {
            tibetan: 'ལམ་གཙོ་རྣམ་གསུམ།',
            english: 'Three Principal Aspects of the Path',
        },
        author: { tibetan: 'རྗེ་ཙོང་ཁ་པ།', english: 'Jé Tsongkhapa' },
        links: { lh: '' },
        isPartial: '',
    },
    1029: {
        tibetan: [
            'ས་བླ་མཐོན་པོར་གཤེགས་པའི་མཁའ་ལྡིང་གི །',
            'གྲིབ་མ་རེ་ཞིག་བལྟ་བར་མི་མངོན་ཡང༌། །',
            'གཟུགས་ཅན་ཉིད་དང་འདུ་འབྲལ་སྤངས་པ་བཞིན། །',
            'ཚོགས་ཤིང་དུས་ལ་བབས་ཚེ་ལྷག་པར་མངོན། །',
        ],
        english: [
            'When the eagle soars up, high above the ground,',
            'For a while its shadow is nowhere to be seen;',
            'Yet bird and shadow still are linked. So it is with our actions:',
            'When the right conditions come together, their effects will manifest.',
        ],
        title: {
            tibetan: 'ཡོན་ཏན་མཛོད།',
            english: 'Treasury of Precious Qualities',
        },
        author: {
            tibetan: 'རིག་འཛིན་འཇིགས་མེད་གླིང་པ།',
            english: 'Rigdzin Jikmé Lingpa',
        },
        links: { lh: '' },
        isPartial: '',
    },
    1030: {
        tibetan: [
            'སྦྱོར་བ་སེམས་བསྐྱེད་དངོས་གཞི་དམིགས་པ་མེད། །',
            'རྗེས་ལ་བསྔོ་བས་ཡོངས་སུ་ཟིན་པ་ནི། །',
            'ཐར་ལམ་བསྒྲོད་པའི་གཅེས་པ་རྣམ་གསུམ་ཡིན། །',
        ],
        english: [
            'Begin with bodhicitta, do the main practice without concepts,',
            'Conclude by dedicating the merit. These, together and complete,',
            'Are the three vital supports for progressing on the path to liberation.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྒྱལ་བ་ཀློང་ཆེན་པ།', english: 'Longchenpa' },
        links: { lh: '' },
        isPartial: '',
    },
    1031: {
        tibetan: ['ཐམས་ཅད་མཉམ་རྫོགས་སྒྱུ་མའི་རང་བཞིན་ལ། །', 'བཟང་ངན་བླང་དོར་མེད་པས་དགོད་རེ་བྲོ། །'],
        english: [
            'Since everything is but an illusion,',
            'Perfect in being what it is,',
            'Having nothing to do with good or bad,',
            'Acceptance or rejection,',
            'One might as well burst out laughing!',
        ],
        title: {
            tibetan: 'རྫོགས་པ་ཆེན་པོ་སེམས་ཉིད་རང་གྲོལ།',
            english: "The Great Perfection's Self-Liberation in the Nature of Mind",
        },
        author: { tibetan: 'རྒྱལ་བ་ཀློང་ཆེན་པ།', english: 'Longchenpa' },
        links: { lh: '' },
        isPartial: true,
    },
    1101: {
        tibetan: [
            'མ་བཅོས་མ་བཅོས་རང་གི་སེམས་མ་བཅོས། །',
            'མ་འཛིན་མ་འཛིན་རང་གི་སེམས་མ་འཛིན། །',
            'བཅོས་ཤིང་བཅོས་ཤིང་སེམས་ཀྱི་རྙོགས་ལངས་པས། །',
            'བཅོས་མའི་སེམས་ཀྱིས་སྙིང་པོའི་དོན་ལ་བསྒྲིབས། །',
        ],
        english: [
            'Do not alter, do not alter. Do not alter this mind of yours.',
            'Do not grasp, do not grasp. Do not grasp at this mind of yours.',
            'Alter and alter, and you will stir up the cloudy depths of the  mind',
            'And a mind that is altered obscures its own true nature.',
        ],
        title: {
            tibetan: 'རྫོགས་པ་ཆེན་པོ་སེམས་ཉིད་རང་གྲོལ།',
            english: "The Great Perfection's Self-Liberation in the Nature of Mind",
        },
        author: { tibetan: 'རྒྱལ་བ་ཀློང་ཆེན་པ།', english: 'Longchenpa' },
        links: { lh: '' },
        isPartial: true,
    },
    1102: {
        tibetan: [
            'ཤེས་བྱ་ནམ་མཁའི་གཟའ་སྐར་འདྲ། །',
            'བསླབ་དགོས་གནས་ལ་ཟད་པ་མེད། །',
            'ད་རེས་ཆོས་སྐུའི་སྙིང་པོའི་དོན། །',
            'འགྱུར་མེད་བཙན་ས་བཟུང་ན་ལེགས། །',
        ],
        english: [
            'Knowledge is as infinite as the stars in the sky;',
            'There is no end to all the subjects one could study.',
            'It is better to grasp straight away their very essence—',
            'The unchanging fortress of the dharmakāya.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྒྱལ་བ་ཀློང་ཆེན་པ།', english: 'Longchenpa' },
        links: { lh: '' },
        isPartial: '',
    },
    1103: {
        tibetan: [
            'འགྲོ་བའི་དུས་བབས་མགྲོན་པོ་ལམ་ཞུགས་བཞིན། །',
            'འཆི་བས་དགའ་བའི་རྙེད་པ་ལེགས་གྲུབ་པ། །',
            'རྒྱ་མཚོའི་དོན་ཡོངས་གྲུབ་པའི་ཚོད་ལ་དར། །',
            'གཡུལ་ལས་རྣམ་པར་རྒྱལ་བའི་ལྷ་དབང་དང༌། །',
            'བསམ་གཏན་གྲུབ་པའི་བདེ་བས་ཆེས་ལྷག་གོ །',
            'ད་ནི་མི་སྡོད་པདྨ་ལས་འབྲེལ་རྩལ། །',
            'འཆི་མེད་བདེ་ཆེན་བཙན་ས་ཟིན་དུ་འགྲོ། །',
        ],
        english: [
            'My delight in death is far, far greater than',
            'The delight of traders at making vast fortunes at sea,',
            'Or the lords of the gods who vaunt their victory in battle;',
            'Or of those sages who have entered the rapture of perfect absorption.',
            'So just as a traveller sets out on the road when it is time,',
            'I, Pema Ledrel Tsal, will not remain in this world any longer,',
            'But will go to dwell in the stronghold of the great bliss of deathlessness.',
        ],
        title: {
            tibetan: 'ཞལ་ཆེམས་དྲི་མ་མེད་པའི་འོད།',
            english: "Last Testament, entitled 'Immaculate Radianc",
        },
        author: { tibetan: 'རྒྱལ་བ་ཀློང་ཆེན་པ།', english: 'Longchenpa' },
        links: { lh: '' },
        isPartial: '',
    },
    1104: {
        tibetan: ['གྲིམས་ཀྱིས་སྒྲིམ་ལ་ལྷོད་ཀྱིས་གློད། །', 'དེ་ན་ལྟ་བའི་གནད་ཅིག་ཡོད། །'],
        english: ['Be firmly concentrated yet loosely relaxed:', 'Here is an essential point for the View.'],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མ་ཅིག་ལབ་སྒྲོན།', english: 'Machik Labdrön' },
        links: { lh: '' },
        isPartial: '',
    },
    1105: {
        tibetan: [
            'ང་འཆི་བས་འཇིགས་ནས་རི་ལ་ཕྱིན། །',
            'ནམ་འཆི་ཆ་མེད་བསྒོམས་བསྒོམས་པས། །',
            'འཆི་མེད་གཉུགས་མའི་བཙན་ས་ཟིན། །',
            'ད་འཆི་བའི་འཇིགས་པ་བོར་ཏེ་ཐལ། །',
        ],
        english: [
            'In horror of death, I took to the mountains—',
            'Again and again I meditated on the uncertainty of the hour of death,',
            'Capturing the fortress of the deathless unending nature of mind,',
            'Now all fear of death is over and done.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྗེ་བཙུན་མི་ལ་རས་པ།', english: 'Jetsün Milarepa' },
        links: { lh: '' },
        isPartial: '',
    },
    1106: {
        tibetan: [
            'མི་མེད་ལུང་པའི་བྲག་ཕུག་ན། །',
            'སྐྱོ་ཆད་དེ་བ་ལ་སངས་དུས་མེད། །',
            'བླ་མ་དུས་གསུམ་སངས་རྒྱས་ལ། །',
            'ཡིད་དུང་ངེ་བ་ལ་འབྲལ་བ་མེད། །',
        ],
        english: [
            'In a rocky cave in a solitary place,',
            'I am never without disenchantment.',
            'For my teacher, the Buddha of the three times,',
            'I am never without a mind of yearning devotion.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྗེ་བཙུན་མི་ལ་རས་པ།', english: 'Jetsün Milarepa' },
        links: { lh: '' },
        isPartial: '',
    },
    1107: {
        tibetan: [
            'རི་ལ་སྒོམ་པའི་སྒོམ་ཆེན་དང༌། །',
            'འཚོ་བ་སྦྱོར་བའི་ཡོན་བདག་གཉིས། །',
            'སངས་མཉམ་པོར་རྒྱ་བའི་རྟེན་འབྲེལ་ཡོད། །',
            'རྟེན་འབྲེལ་གྱི་སྙིང་པོ་བསྔོ་བ་ཡིན། །',
        ],
        english: [
            'Between the hermit meditating in the mountains',
            'And the sponsor who provides his sustenance',
            'Is a connection that will lead them to enlightenment together.',
            'The heart of that connection is dedication of meri',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྗེ་བཙུན་མི་ལ་རས་པ།', english: 'Jetsün Milarepa' },
        links: { lh: '' },
        isPartial: '',
    },
    1108: {
        tibetan: ['ཟས་མཐོང་བས་མི་ཆོག་ཟ་དགོས་པར་འདུག །', 'ཆོས་གོ་བས་མི་ཆོག་སྒོམ་དགོས་པར་འདུག །'],
        english: [
            'It is not enough simply to look at food: you need to eat it.',
            'It is not enough simply to hear the Dharma: you must meditate.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྗེ་བཙུན་མི་ལ་རས་པ།', english: 'Jetsün Milarepa' },
        links: { lh: '' },
        isPartial: '',
    },
    1109: {
        tibetan: [
            'འཕགས་ཡུལ་དཔལ་ལྡན་ཟླ་བ་དང༌། །',
            'བོད་ན་རོང་ཟོམ་ཆོས་བཟང་གཉིས། །',
            'དགོངས་པ་གཅིག་དང་དབྱངས་གཅིག་གིས། །',
            'ཀ་དག་སྟོང་པ་ཆེན་པོ་སྒྲུབ། །',
        ],
        english: [
            'Glorious Candrakīrti in India,',
            'And Rongzom Chökyi Zangpo in Tibet,',
            'Both proclaimed the great emptiness of primordial purity,',
            'One in their intention and with a single voice.',
        ],
        title: { tibetan: 'ངེས་ཤེས་སྒྲོན་མེ།', english: 'Torch of Certainty' },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoché' },
        links: { lh: '' },
        isPartial: '',
    },
    1110: {
        tibetan: [
            'དཔྱད་པ་བཟང་པོས་མ་དྲངས་ན། །',
            'ངེས་ཤེས་བཟང་པོ་ག་ལ་སྐྱེ། །',
            'ངེས་ཤེས་བཟང་པོ་མ་སྐྱེས་ན། །',
            'སྒྲོ་འདོགས་ངན་པ་ག་ལ་ནུབ། །',
        ],
        english: [
            "If you don't begin with thorough analysis,",
            'How will perfect certainty ever arise?',
            'If this noble confidence does not arise,',
            'How will false projections ever cease?',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoché' },
        links: { lh: '' },
        isPartial: '',
    },
    1111: {
        tibetan: [
            'དེང་སང་སྤྱིར་བདེན་པ་བཤད་ན་ཉན་མཁན་ནི་མེད། རྫུན་པར་བཤད་ན་ཐམས་ཅད་བདེན་པར་འཛིན་པས། དེ་སྔོན་སུ་ལའང་མ་སྨྲས། ཕྲན་སོ་སྐྱེ་ཐ་མལ་བ་ཞིག་མིན། བྱང་ཆུབ་སེམས་དཔའ་སྨོན་ལམ་གྱི་དབང་གིས་སྐྱེ་བ་བླངས་པ་ཞིག་ཡིན།',
        ],
        english: [
            'These days if you tell the truth none listens, and if anyone says something false everybody holds it to be true. That is why I have never said this to anyone before: I am not an ordinary being, I am a bodhisattva who took birth intentionally as a result of prayers of aspiration.',
        ],
        title: {
            tibetan: '',
            english: 'Spoken to his attendant Lama Ösel and recorded in his biography.',
        },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoché' },
        links: { lh: '' },
        isPartial: '',
    },
    1112: {
        tibetan: [
            'བཻ་རོ་ཙ་ན་ནམ་མཁའ་མཐའ་དང་མཉམ། །',
            'སྐ་ཅོག་ཞང་གསུམ་ཉི་ཟླ་ཟུང་འབྲེལ་དང༌། །',
            'རིན་ཆེན་བཟང་པོ་ཐོ་རངས་སྐར་ཆེན་འདྲ། །',
            'ཁོ་བོ་ཅག་ནི་སྲིན་བུ་མེ་ཁྱེར་ཙམ། །',
        ],
        english: [
            'Vairotsana was like the boundless sky itself,',
            'Kawa, Chok and Shyang were like the sun and moon,',
            'Rinchen Zangpo was like the great dawn star—',
            'Compared to them, we are like mere fireflies.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྔོག་བློ་ལྡན་ཤེས་རབ།', english: 'Ngok Loden Sherab' },
        links: { lh: '' },
        isPartial: '',
    },
    1113: {
        tibetan: [
            'གླང་རྒན་སླས་ན་ཉལ་བའི་འདུན་ཕུག་ཨེ་གཟིགས། །',
            'ཟ་ཉལ་བཤང་གཅི་འདོར་འདི་མི་བྱེད་ཁ་མེད། །',
            'བྱ་བྱེད་དེ་ལས་མ་མང་ཁྱོད་ཀྱི་ཡུལ་མིན། །',
        ],
        english: [
            'Take the example of an old cow,',
            "She's content to sleep in a barn,",
            'You have to eat, sleep, and shit—',
            "That's unavoidable—",
            'But beyond that is none of your business.',
        ],
        title: { tibetan: 'གཏམ་ཚོགས།', english: 'Collection of Advice' },
        author: {
            tibetan: 'རྫ་དཔལ་སྤྲུལ་རིན་པོ་ཆེ།',
            english: 'Dza Patrul Rinpoche',
        },
        links: { lh: '' },
        isPartial: '',
    },
    1114: {
        tibetan: [
            'ཡོད་པ་མ་ཡིན་རྒྱལ་བས་ཀྱང་མ་གཟིགས། །',
            'མེད་པ་མ་ཡིན་འཁོར་འདས་ཀུན་གྱི་གཞི། །',
            'འགལ་འདུ་མ་ཡིན་ཟུང་འཇུག་དབུ་མའི་ལམ། །',
            'མཐའ་བྲལ་སེམས་ཀྱི་ཆོས་ཉིད་རྟོགས་པར་ཤོག །',
        ],
        english: [
            'It is not existent, because even the buddhas have not seen it.',
            'It is not non-existent, because it is the basis for all saṃsāra and nirvana.',
            'This is not a contradiction; it is the middle way path of unity.',
            "May we realize the mind's intrinsic nature free from all extremes!",
        ],
        title: { tibetan: 'ཕྱག་ཆེན་སྨོན་ལམ།', english: 'Aspiration of Mahāmudrā' },
        author: {
            tibetan: 'ཀརྨ་པ་རང་བྱུང་རྡོ་རྗེ།',
            english: 'Karmapa Rangjung Dorjé',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/third-karmapa-rangjung-dorje/mahamudra-aspiration',
        },
        isPartial: '',
    },
    1115: {
        tibetan: [
            'རྗེ་ལ་གསོལ་བ་མི་འདེབས་པའི། །',
            'མི་རྟོག་ཡེ་ཤེས་འདོད་པ་དེ། །',
            'བྱང་བསྟན་ཕུག་པ་ཉི་སྒུག་འདྲ། །',
            'སྣང་སེམས་འདྲེས་དུས་དེ་ལ་མེད། །',
        ],
        english: [
            'Expecting to realize non-conceptual wisdom,',
            'Without praying to the precious master,',
            'Is like waiting for sunshine in a cave facing north,',
            'That way, appearances and mind will never merge.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'རང་རིག་རས་པ་ཀུན་དགའ་བློ་གྲོས།',
            english: 'Rangrik Repa Kunga Lodrö',
        },
        links: { lh: '' },
        isPartial: '',
    },
    1116: {
        tibetan: ['བླུན་པོ་ཕྱག་རྒྱ་ཆེ་བསྒོམ་པ། །', 'ཕལ་ཆེར་དུད་འགྲོའི་རྒྱུ་རུ་གསུངས། །'],
        english: [
            'When fools meditate on Mahāmudrā',
            'It generally becomes a cause for the animal realm, it is taught.',
        ],
        title: {
            tibetan: 'སྡོམ་གསུམ་རབ་དབྱེ།',
            english: 'Clear Differentiation of the Three Sets of Vows',
        },
        author: { tibetan: 'ས་སྐྱ་པཎྜིཏ།', english: 'Sakya Paṇḍita' },
        links: { lh: '' },
        isPartial: '',
    },
    1117: {
        tibetan: [
            'རིག་པ་ནངས་པར་འཆི་ཡང་བསླབ། །',
            'ཚེ་འདིར་མཁས་པར་མ་གྱུར་ཀྱང༌། །',
            'སྐྱེ་བ་ཕྱི་མར་བཅོལ་བ་ཡི། །',
            'ནོར་ལ་རང་ཉིད་ལེན་པ་འདྲ། །',
        ],
        english: [
            'Even if you must die tomorrow, study today.',
            'Though you may not become a sage in this life,',
            'Your knowledge will be preserved for the future',
            'Just like wealth deposited and then reclaimed.',
        ],
        title: {
            tibetan: 'ལེགས་པར་བཤད་པ་རིན་པོ་ཆེའི་གཏེར།',
            english: 'The Precious Treasury of Elegant Sayings',
        },
        author: { tibetan: 'ས་སྐྱ་པཎྜིཏ།', english: 'Sakya Paṇḍita' },
        links: { lh: '' },
        isPartial: '',
    },
    1118: {
        tibetan: [
            'དབང་ཤེས་ལྐུགས་པ་མིག་ཅན་འདྲ། །',
            'རྟོག་པ་ལོང་བ་སྨྲ་མཁས་འདྲ། །',
            'རང་རིག་དབང་པོ་ཚང་བ་ཡིས། །',
            'གཉིས་པོ་དེ་ཡི་བརྡ་སྤྲོད་བྱེད། །',
        ],
        english: [
            'Sense consciousness is like a dumb person who can see.',
            'And conceptual mind is like a blind person who can talk.',
            'Self-cognition is like someone with all the senses complete,',
            'Who facilitates communication between the other two.',
        ],
        title: {
            tibetan: 'ཚད་མ་རིགས་གཏེར།',
            english: 'The Treasury of Valid Reasoning',
        },
        author: { tibetan: 'ས་སྐྱ་པཎྜིཏ།', english: 'Sakya Paṇḍita' },
        links: { lh: '' },
        isPartial: true,
    },
    1119: {
        tibetan: [
            'ཕ་རོལ་ཕྱིན་པའི་སྤྲོས་བྲལ་ལས། །',
            'ལྷག་པའི་ལྟ་བ་ཡོད་ན་ནི། །',
            'ལྟ་དེ་སྤྲོས་པ་ཅན་དུ་འགྱུར། །',
            'སྤྲོས་བྲལ་ཡིན་ན་ཁྱད་པར་མེད། །',
        ],
        english: [
            'If there were a view higher than the non-elaboration',
            'Of the transcendental perfection of wisdom,',
            'It would be a view with conceptual elaboration.',
            'If it were without elaboration, there would be no difference.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'ས་སྐྱ་པཎྜིཏ།', english: 'Sakya Paṇḍita' },
        links: { lh: '' },
        isPartial: '',
    },
    1120: {
        tibetan: [
            'ཐོག་མར་ཤེས་བྱ་ཀུན་ལ་མཁས་པར་སྦྱངས། །',
            'བར་དུ་མཁས་པའི་ཚོགས་ལ་ལེགས་པར་བཤད། །',
            'ཐ་མ་འདྲིས་པའི་ཚོགས་ལ་བརྩོན་པས་བསྒོམ། །',
            'དུས་གསུམ་རྒྱལ་བ་ཀུན་གྱི་བཞེད་གཞུང་ཡིན། །',
        ],
        english: [
            'First, become well versed in all fields of knowledge,',
            'Later, discourse eloquently before learned gatherings,',
            'Finally, meditate diligently on all that you have learnt—',
            'This is the approach of all the buddhas of the three times.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'ས་སྐྱ་པཎྜིཏ།', english: 'Sakya Paṇḍita' },
        links: { lh: '' },
        isPartial: '',
    },
    1121: {
        tibetan: [
            'ཆོས་རྣམས་ཡིད་ཀྱི་རང་བཞིན་ཏེ། །',
            'ཡིད་འདི་གཙོ་ཞིང་སྔོན་དུ་འགྲོ། །',
            'གང་དག་མ་རུངས་སེམས་བཅས་ཀྱིས། །',
            'སྨྲས་སམ་ཡང་ན་བྱས་ཀྱང་རུང་། །',
            'དེ་ལ་དེ་ཡི་སྡུག་བསྔལ་ནི། །',
            'རང་གི་གྲིབ་མ་ཇི་བཞིན་འགྲོགས། །',
            '',
            'ཆོས་རྣམས་ཡིད་ཀྱི་རང་བཞིན་ཏེ། །',
            'ཡིད་ནི་གཙོ་ཞིང་སྔོན་ལ་འགྲོ །',
            'གལ་ཏེ་གདུག་པའི་ཡིད་ཀྱིས་ནི། །',
            'སྨྲས་སམ་ཡང་ན་བྱས་ཀྱང་རུང་། །',
            'དེ་ལ་དེ་ཡིས་སྡུག་བསྔལ་འཐོབ། །',
            'ཤིང་རྟ་རྗེས་སུ་འབྲང་བ་བཞིན། །',
        ],
        english: [
            'We are what we think.',
            'All that we are arises with our thoughts.',
            'With our thoughts we make the world.',
            'Speak or act with a pure mind',
            'And happiness will follow you',
            'As your shadow, unshakable.',
            '',
            'We are what we think.',
            'All that we are arises with our thoughts.',
            'With our thoughts we make the world.',
            'Speak or act with an impure mind',
            'And trouble will follow you',
            'As the wheel follows the ox that draws the cart.',
        ],
        title: { tibetan: 'ཆོས་ཀྱི་ཚིགས་སུ་བཅད་པ།', english: 'Dhammapada' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    1122: {
        tibetan: [
            'བདག་འདྲའི་སེམས་ཅན་ལས་ངན་སྡིག་ཏོ་ཅན། །',
            'ཐོག་མེད་དུས་ནས་འཁོར་བར་ཡུན་རིང་འཁྱམས། །',
            'ད་དུང་སྡུག་བསྔལ་མཐའ་མེད་མྱོང་འགྱུར་བས། །',
            'སྐྱོ་ཤས་སྐད་ཅིག་ཙམ་ཡང་མ་སྐྱེས་པས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ངེས་འབྱུང་གཏིང་ནས་སྐྱེ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'I and beings like me, with negative karma and misdeeds,',
            'Have long wandered in saṃsāra, from time immemorial.',
            'Our suffering is constant; it knows no end,',
            'Yet we feel not the slightest disillusion, not even for a moment.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that renunciation may arise deep within my heart.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1123: {
        tibetan: [
            'དལ་འབྱོར་ཐོབ་ཀྱང་མི་ཚེ་སྟོང་ཟད་མཁན། །',
            'དོན་མེད་ཚེ་འདིར་བྱ་བས་རྟག་ཏུ་གཡེང༌། །',
            'དོན་ཆེན་ཐར་པ་སྒྲུབ་ལ་ལེ་ལོས་ཁྱེར། །',
            'ནོར་བུའི་གླིང་ནས་ལག་སྟོང་ལོག་གྱུར་པས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'མི་ལུས་དོན་ལྡན་འགྲུབ་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            "Though I've obtained the freedoms and riches, I waste my time,",
            'Perpetually distracted by the trivial concerns of this life.',
            'Laziness prevents my pursuit of what is meaningful, liberation,',
            'So I must return empty-handed from this island of jewels.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that my human birth may yet prove significant.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1124: {
        tibetan: [
            'མ་ཤི་ས་སྟེང་ལུས་པ་གཅིག་ཀྱང་མེད། །',
            'ད་ལྟ་གཅིག་རྗེས་གཉིས་མཐུད་ཕ་རོལ་འགྲོ། །',
            'རང་ཡང་མྱུར་བ་ཉིད་དུ་འཆི་དགོས་ཀྱང༌། །',
            'ཡུན་རིང་སྡོད་གྲབས་བྱེད་པའི་སྙིང་རུལ་པོ། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ལོང་མེད་བློ་སྣ་ཐུང་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Death spares no one upon this earth:',
            'Beings depart even now, one after another.',
            'And though I too must pass very soon,',
            'My foolish heart still expects to endure.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may curtail my impossible plans.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1125: {
        tibetan: [
            'སྙིང་དུ་སྡུག་པའི་མཛའ་བཤེས་སོ་སོར་བྲལ། །',
            'སེར་སྣས་བསགས་པའི་ནོར་རྫས་གཞན་གྱིས་སྤྱོད། །',
            'གཅེས་པའི་ལུས་ཀྱང་ཤུལ་དུ་བོར་ནས་སུ། །',
            'རྣམ་ཤེས་བར་དོ་གཏོལ་མེད་འཁོར་བར་འཁྱམས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ཅིས་ཀྱང་དགོས་མེད་རྟོགས་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Friends and family, held so dear, must go their separate ways;',
            'My wealth, hoarded so jealously, will be enjoyed by others.',
            'Even my cherished body will be cast aside,',
            'As my consciousness wanders aimlessly through the bardo.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may realize the futility of it all.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1126: {
        tibetan: [
            'འཇིགས་པའི་མུན་པ་ནག་པོས་སྔོན་ནས་བསུ། །',
            'ལས་ཀྱི་རླུང་དམར་དྲག་པོས་རྒྱབ་ནས་དེད། །',
            'མི་སྡུག་གཤིན་རྗེའི་ཕོ་ཉས་བརྡེག་ཅིང་བཙོག །',
            'བཟོད་དཀའ་ངན་འགྲོའི་སྡུག་བསྔལ་མྱོང་དགོས་ན། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ངན་སོང་གཡང་ལས་ཐར་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Terrifying, pitch-black darkness looms before me,',
            'While the ferocious winds of karma lash at my back.',
            "Yama's gruesome minions strike and assault me—",
            "When I'm subjected to the lower realms' unbearable pain,",
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may escape the abyss of the lower realms.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1127: {
        tibetan: [
            'རང་སྐྱོན་རི་བོ་ཙམ་ཡང་ཁོང་དུ་སྦས། །',
            'གཞན་སྐྱོན་ཏིལ་འབྲུ་ཙམ་ཡང་སྒྲོགས་ཅིང་སྨོད། །',
            'ཡོན་ཏན་ཅུང་ཟད་མེད་ཀྱང་བཟང་པོར་རློམ། །',
            'ཆོས་པའི་མིང་བཏགས་ཆོས་མིན་ཁོ་ནར་སྤྱོད། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'རང་འདོད་ང་རྒྱལ་ཞི་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'My own faults, though mountainous, I conceal within;',
            "Others' flaws, though negligible, I proclaim and condemn.",
            'I lack even the slightest qualities but consider myself great;',
            'I call myself a Dharma practitioner but act only to the contrary.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may quell my selfishness and pride.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1128: {
        tibetan: [
            'གཏན་ཕུང་བདག་འཛིན་འགོང་པོ་ཁོང་དུ་བཅུག །',
            'བསམ་ཚད་ཐམས་ཅད་ཉོན་མོངས་འཕེལ་བའི་རྒྱུ། །',
            'བྱས་ཚད་ཐམས་ཅད་མི་དགེའི་འབྲས་བུ་ཅན། །',
            'ཐར་པའི་ལམ་དུ་ཕྱོགས་ཙམ་མ་ཕྱིན་པས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ངར་འཛིན་རྩད་ནས་ཆོད་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'I keep the ruinous demon of ego-clinging hidden within,',
            'While all my thoughts cause the kleśas to increase,',
            'And whatever I do bears the fruit of non-virtue.',
            "Since I've not made the slightest progress on the path to liberation,",
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that ego-clinging may be severed at its root.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1129: {
        tibetan: [
            'བསྟོད་སྨད་ཙམ་ལ་དགའ་དང་མི་དགའ་སྐྱེ། །',
            'ཚིག་ངན་ཙམ་ལ་བཟོད་པའི་གོ་ཆ་ཤོར། །',
            'ཉམ་ཐག་མཐོང་ཡང་སྙིང་རྗེའི་སེམས་མི་སྐྱེས། །',
            'སྦྱིན་ཡུལ་བྱུང་དུས་སེར་སྣའི་མདུད་པས་བཅིང༌། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'སེམས་རྒྱུད་ཆོས་དང་འདྲེས་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'The slightest praise or blame delights or depresses me.',
            'Harsh words alone detach the armour of my patience.',
            'Even seeing the wretched does not stir my compassion;',
            'When encountering the needy, I am constrained by greed.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that the Dharma may suffuse my mind.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1130: {
        tibetan: [
            'འཁོར་བ་སྙིང་པོ་མེད་ལ་སྙིང་པོར་བཟུང༌། །',
            'ལྟོ་གོས་ཕྱིར་དུ་གཏན་འདུན་ལིང་གིས་བོར། །',
            'མཁོ་རྒུ་ཚང་ཡང་དགོས་དགོས་ཕྱི་ཕྱིར་མང༌། །',
            'མི་བདེན་སྒྱུ་མའི་ཆོས་ཀྱིས་རང་སེམས་བསླུས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ཚེ་འདི་བློ་ཡིས་ཐོང་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Although saṃsāra is hollow, I take it to be meaningful.',
            'For the sake of food and clothing, I forsake the lasting goal.',
            'Though I have all that I need, I still crave even more.',
            'My mind is beguiled by these unreal, illusory phenomena.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may give up the concerns of this life.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1201: {
        tibetan: [
            'ལུས་སེམས་སྡུག་བསྔལ་ཕྲ་མོའང་མི་བཟོད་ཀྱང༌། །',
            'ངན་འགྲོར་འགྲོ་ལ་མི་འཚེར་སྙིང་རྡོས་ཅན། །',
            'རྒྱུ་འབྲས་བསླུ་མེད་མངོན་སུམ་མཐོང་བཞིན་དུ། །',
            'དགེ་བ་མི་འགྲུབ་སྡིག་པའི་ཡོ་ལང་འཕེལ། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ལས་ལ་ཡིད་ཆེས་སྐྱེ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'I cannot bear the slightest pain, physical or mental,',
            'Yet my stony heart does not fear the lower realms.',
            'Though I can see that cause and effect are unfailing,',
            'I neglect the practice of virtue and persist in my misdeeds.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may develop trust in the karmic process.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1202: {
        tibetan: [
            'དགྲ་ལ་སྡང་སེམས་གཉེན་ལ་ཆགས་སེམས་སྐྱེ། །',
            'བླང་དོར་གནས་ལ་གཏི་མུག་མུན་ལྟར་འཐོམ། །',
            'ཆོས་བཞིན་སྤྱོད་ཚེ་བྱིངས་རྨུགས་གཉིད་དབང་ཤོར། །',
            'ཆོས་མིན་སྤྱོད་ཚེ་དབང་པོ་གསལ་ཅིང་གྲུང༌། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ཉོན་མོངས་དགྲ་བོ་ཆོམ་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'With hatred for my enemies and attachment to my friends,',
            'I am lost in the dark, confused as to what to adopt and avoid.',
            'When practicing the Dharma I succumb to dullness and sleep;',
            'But when otherwise engaged my senses are keen and alert.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may conquer the enemy, disturbing emotions.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1203: {
        tibetan: [
            'ཕྱི་ནས་བལྟས་ན་ཡང་དག་ཆོས་པའི་གཟུགས། །',
            'ནང་དུ་རང་སེམས་ཆོས་དང་མ་འདྲེས་པས། །',
            'སྦྲུལ་གདུག་བཞིན་དུ་ཉོན་མོངས་ཁོང་ན་སྦས། །',
            'རྐྱེན་དང་འཕྲད་ཚེ་ཆོས་པའི་མཚང་རྟགས་སྟོན། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'རང་རྒྱུད་རང་གིས་ཐུལ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'From the outside, I may seem a genuine practitioner,',
            'But inside, my mind has not merged with the Dharma.',
            'Like a poisonous snake, I hide my afflictions within;',
            'Then, when conditions are ripe, my faults are exposed.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may tame my own mind.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1204: {
        tibetan: [
            'རང་སྐྱོན་ངན་པ་རང་གིས་མ་རྟོགས་པས། །',
            'ཆོས་པའི་གཟུགས་བཟུང་ཆོས་མིན་སྣ་ཚོགས་སྤྱོད། །',
            'ཉོན་མོངས་མི་དགེའི་ལས་ལ་ཤུགས་ཀྱིས་གོམས། །',
            'དགེ་བློ་ཡང་ཡང་སྐྱེད་ཀྱང་ཡང་ཡང་ཆད། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'རང་སྐྱོན་རང་གིས་མཐོང་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Since I cannot see my own rotten faults,',
            "I adopt a practitioner's guise and flout the Dharma.",
            'Disturbing emotions and non-virtue are now so ingrained',
            'That positive intentions, though repeatedly kindled, only subside.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may see my own shortcomings.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1205: {
        tibetan: [
            'ཞག་རེ་སོང་བཞིན་འཆི་ལ་ཕར་ཕར་ཉེ། །',
            'ཉིན་རེ་ལོན་བཞིན་རང་རྒྱུད་ཕྱིར་ཕྱིར་གྱོང༌། །',
            'བླ་མ་བསྟེན་བཞིན་མོས་གུས་རིམ་གྱིས་འགྲིབ། །',
            'མཆེད་ལ་བརྩེ་གདུང་དག་སྣང་ཇེ་ཆུང་སོང༌། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'རྨུ་རྒོད་རང་རྒྱུད་ཐུལ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'With each day that passes, death comes ever closer;',
            'With each day that goes by, mind grows ever wilder.',
            'While following the guru, my devotion has gradually waned,',
            'My affection and pure regard for Dharma siblings declined.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may tame this troublesome mind.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1206: {
        tibetan: [
            'སྐྱབས་འགྲོ་སེམས་བསྐྱེད་གསོལ་འདེབས་བྱས་ན་ཡང༌། །',
            'མོས་གུས་སྙིང་རྗེ་གཏིང་ནས་མ་སྐྱེས་པས། །',
            'ཚིག་ཙམ་དབང་གྱུར་ཆོས་སྤྱོད་དགེ་སྦྱོར་རྣམས། །',
            'བྱས་ལོ་ཙམ་ལས་རྒྱུད་ཐོག་མ་ཁེལ་བས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ཅི་བྱས་ཆོས་སུ་འགྲོ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Though I take refuge, generate bodhicitta, and supplicate,',
            'Devotion and compassion are not deeply felt from within.',
            'Dharma practice and virtuous deeds are merely routine;',
            'Going through the motions leaves my character untouched.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that whatever I do may become the Dharma.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1207: {
        tibetan: [
            'བདག་བདེ་འདོད་ལས་སྡུག་བསྔལ་ཐམས་ཅད་འབྱུང༌། །',
            'གཞན་ཕན་སེམས་ཀྱིས་སངས་རྒྱས་འགྲུབ་གསུངས་ཀྱང༌། །',
            'སེམས་མཆོག་བསྐྱེད་ཅིང་རང་འདོད་ཕུག་ཏུ་བཅུག །',
            'གཞན་ཕན་ལྟ་ཅི་གཞན་གནོད་ཞོར་ལ་སྒྲུབ། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'བདག་གཞན་བརྗེ་བར་ནུས་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'All suffering is born from seeking happiness for oneself,',
            "While buddhahood, it's taught, springs from wishing others well.",
            'I have formed the supreme resolve but retain my selfish aims.',
            'Never mind helping others; I somehow cause them harm.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may be able to exchange myself for others.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1208: {
        tibetan: [
            'སངས་རྒྱས་དངོས་སྣང་བླ་མ་མི་རུ་བཟུང༌། །',
            'གདམས་ཟབ་སྟོན་པའི་བཀའ་དྲིན་ངང་གིས་བརྗེད། །',
            'རང་འདོད་མ་བྱུང་ཚེ་ན་ཡི་ཆད་བསྒོམ། །',
            'མཛད་སྤྱོད་རྣམས་ལ་ཐེ་ཚོམ་ལོག་ལྟས་སྒྲིབ། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'མོས་གུས་འགྲིབ་མེད་འཕེལ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'I see the guru, a Buddha in the flesh, as a mere human being,',
            'And constantly forget the kindness of his profound instruction.',
            'I am disappointed whenever my expectations are not met,',
            'And beset by wrong views and mistrust of activity and conduct.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that my devotion may not diminish but increase.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1209: {
        tibetan: [
            'རང་སེམས་སངས་རྒྱས་ཡིན་ཀྱང་ངོ་མ་ཤེས། །',
            'རྣམ་རྟོག་ཆོས་སྐུ་ཡིན་ཀྱང་དོན་མ་རྟོགས། །',
            'མ་བཅོས་གཉུག་མ་ཡིན་ཀྱང་སྐྱོང་མ་ནུས། །',
            'རང་བབས་གནས་ལུགས་ཡིན་ཀྱང་ཡིད་མ་ཆེས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'རང་རིག་རང་སར་གྲོལ་བར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Although my mind is the buddha, I fail to recognize this.',
            'Although my thoughts are dharmakāya, I do not realize it.',
            'Although simplicity is the genuine state, I cannot sustain it.',
            'Although natural repose is the way of abiding, I distrust it.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that self-awareness may be liberated within itself.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1210: {
        tibetan: [
            'ཡོང་ངེས་འཆི་བ་སྙིང་ནས་དྲན་མ་ཐུབ། །',
            'ཕན་ངེས་དམ་ཆོས་ཚུལ་བཞིན་སྒྲུབ་མ་ནུས། །',
            'བདེན་ངེས་ལས་འབྲས་བླང་དོར་ཚུལ་བཞིན་མེད། །',
            'དགོས་ངེས་དྲན་ཤེས་མ་བརྟེན་གཡེང་བས་ཁྱེར། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ཡེངས་མེད་དྲན་པས་ཟིན་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'Though death will surely come, I cannot take this to heart.',
            'Though Dharma surely helps, I cannot practice it as I should.',
            'Though karmic laws are surely real, I do not act accordingly.',
            'Though mindfulness is surely needed, I am lost in distraction.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may maintain undistracted mindfulness.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1211: {
        tibetan: [
            'དང་པོར་བསམ་རྒྱུ་ཆོས་ལས་མེད་པ་ལ། །',
            'ཐ་མ་གྲུབ་འབྲས་འཁོར་བ་ངན་སོང་རྒྱུ། །',
            'ཐར་པའི་ལོ་ཏོག་མི་དགེའི་སད་ཀྱིས་བཅོམ། །',
            'གཏན་འདུན་ཉེས་པའི་རྨུ་རྒོད་བདག་འདྲ་རྣམས། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'དམ་ཆོས་མཐའ་རུ་ཕྱིན་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            'In the beginning, all my thoughts were of the Dharma;',
            "In the end, I've achieved saṃsāra and the lower realms.",
            "Liberation's crop has been ravaged by the frost of non-virtue:",
            'Ill-disciplined brutes like me sabotage our ultimate aims.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me that I may follow the sacred Dharma to the end.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1212: {
        tibetan: [
            'སྔོན་ལས་ངན་པས་སྙིགས་མའི་དུས་མཐར་སྐྱེས། །',
            'སྔར་བྱས་ཐམས་ཅད་སྡུག་བསྔལ་རྒྱུ་རུ་སོང༌། །',
            'གྲོགས་ངན་རྣམས་ཀྱིས་སྡིག་པའི་གྲིབ་མས་གཡོགས། །',
            'དོན་མེད་གླེང་མོས་དགེ་སྦྱོར་གཡེང་བས་ཁྱེར། །',
            'བླ་མ་མཁྱེན་ནོ་ཐུགས་རྗེས་མྱུར་དུ་གཟིགས། །',
            'ཆོས་ལ་སྙིང་རུས་ནུས་པར་བྱིན་གྱིས་རློབས། །',
        ],
        english: [
            "Due to past misdeeds, I was born at the dark age's end:",
            'All my previous actions are now the causes of suffering.',
            'Errant companions cast the shadow of their misdeeds.',
            'Meaningless chatter draws me away from virtuous acts.',
            'Guru, watch over me: swiftly look upon me with compassion!',
            'Bless me with the strength of heart to practice Dharma.',
        ],
        title: {
            tibetan: 'བླ་མ་རྒྱང་འབོད།',
            english: 'Calling the Guru From Afar',
        },
        author: {
            tibetan: 'འཇམ་མགོན་ཀོང་སྤྲུལ་བློ་གྲོས་མཐའ་ཡས།',
            english: 'Jamgön Kongtrul Lodrö Thaye',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/calling-the-guru-from-afar',
        },
        isPartial: '',
    },
    1213: {
        tibetan: [
            'གྲོགས་དག་བྱང་ཆུབ་མ་ཐོབ་བར་དུ་བླ་མ་དགོས་པས་བཤེས་གཉེན་དམ་པ་བསྟེན། །',
            'གནས་ལུགས་མ་རྟོགས་བར་དུ་ཐོས་པ་དགོས་པས་བླ་མའི་གདམས་ངག་ཉོན། །',
            'ཆོས་ནི་ཤེས་པ་ཙམ་གྱིས་སངས་མི་རྒྱ་བས་ཤེས་པས་མི་ཆོག་ཉམས་སུ་ལོངས། །',
            'སེམས་ལ་གནོད་པའི་ཡུལ་དང་ཐག་སྲིངས་རྟག་ཏུ་དགེ་བ་འཕེལ་སར་སྡོད། །',
        ],
        english: [
            'Friends, until you reach awakening you need a guru, so follow a sublime spiritual friend.',
            "Until you realize the natural state you need to receive teachings, so listen to the guru's instructions.",
            "You won't become buddhas through mere knowledge of the Dharma. Knowledge is not enough; you must practice!",
            'Avoid places that disturb your mind, and always stay in places that enhance virtue.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1214: {
        tibetan: [
            'བརྟན་པ་མ་ཐོབ་བར་དུ་འདུ་འཛིས་གནོད་པས་དབེན་པའི་ནགས་ཁྲོད་བསྟེན། །',
            'ཉོན་མོངས་སྐྱེ་བའི་གྲོགས་པོ་སྤོངས་ལ་དགེ་འཕེལ་གྲོགས་བསྟེན་དེ་སེམས་ཟུངས། །',
            'ནམ་ཡང་བྱ་བའི་ལས་ལ་ཟིན་པ་མེད་པས་ཞོག་ལ་ལྷན་གྱིས་སྡོད། །',
            'ཉིན་མཚན་རྟག་ཏུ་དགེ་བ་བསྔོ་ཞིང་རྟག་ཏུ་རང་སེམས་བྱ་ར་གྱིས། །',
        ],
        english: [
            'Until you gain stability, hustle and bustle is detrimental, so hold fast to isolated forest retreats.',
            'Avoid companions who stir up the afflictions; keep company with those who encourage virtue, and cherish them.',
            'Work and tasks will never end, so put projects aside and be still.',
            'Dedicate all your virtue, day and night, and keep constant guard over your mind.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1215: {
        tibetan: [
            'མན་ངག་ཡོད་པས་བསྒོམ་པ་མིན་ནམ་ཅི་བྱེད་བླ་མའི་གསུང་བཞིན་སྒྲུབས། །',
            'གུས་པ་ཆེན་པོས་བསྒྲུབས་ན་རིང་པོར་མི་ཐོགས་འབྲས་བུ་འཕྲལ་དུ་འབྱུང༌། །',
            'སྙིང་ནས་ཆོས་བཞིན་བྱས་ན་ལྟོ་རྒྱབ་གཉིས་ཀ་ཞོར་ལ་འབྱུང་ཚུལ་འདུག །',
            'གྲོགས་དག་འདོད་པ་རྣམས་ལ་ཚ་ཆུ་འཐུངས་བཞིན་ངོམས་པ་མེད་པས་ཆོག་ཤེས་མཛོད། །',
        ],
        english: [
            'It is a pith instruction to follow the words of the guru, so do whatever he tells you, be it meditation or anything else.',
            "Apply yourself to the guru's instructions with great devotion, and before long results will emerge on their own.",
            "If you practice Dharma wholeheartedly, food and clothing will always find you – that's how it goes.",
            'Dear friends, be content, for desire is like salt water; drinking it only makes you thirst for more.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1216: {
        tibetan: [
            'དྲེགས་རློམ་ང་རྒྱལ་ཁེངས་པའི་སེམས་ཀུན་སྨོད་ལ་ཞི་ཞིང་དུལ་བར་གྱིས། །',
            'བསོད་ནམས་ཟེར་བའི་འདུ་འཛི་དེ་ཡང་ཆོས་ཀྱི་བར་ཆད་ཡིན་པས་སྤོངས། །',
            'རྙེད་དང་བཀུར་སྟི་བདུད་ཀྱི་ཞགས་པ་ཡིན་པས་སྲང་ཁའི་རྡོ་བཞིན་སོལ། །',
            'བསྟོད་དང་གྲགས་པའི་ཚིག་རྣམས་བསླུ་བྲིད་ཡིན་པས་མཆིལ་སྣབས་བཞིན་དུ་དོར། །',
        ],
        english: [
            'Do away with conceit, arrogance, pretense, and pride, and make yourselves humble and tamed.',
            'So-called meritorious busyness is an obstacle for Dharma practice, so give it up.',
            'Honor and gain are the traps of Māra, so cast them away like stones from a weighing scale.',
            'Words of praise and fame will lead you astray, so toss them aside like revolting lumps of snot.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1217: {
        tibetan: [
            'ད་ལྟའི་བདེ་སྐྱིད་གཉེན་གསུམ་འཛོམ་ཡང་ཡུད་ཙམ་ཡིན་པས་རྒྱབ་ཏུ་སྐྱུར། །',
            'འདི་བས་ཕྱི་མ་རིང་བས་ཕྱི་མའི་རྒྱགས་ཁྱེར་ནོར་རྣམས་གཏེར་དུ་སྦོས། །',
            'ཐམས་ཅད་བོར་ནས་འགྲོ་བས་ཅིས་ཀྱང་བྱར་མེད་གང་ལའང་ཞེན་མེད་གྱིས། །',
            'དམན་པ་རྣམས་ལ་སྙིང་རྗེ་སྐྱེད་ལ་ཁྱད་དུ་གསོད་དང་བརྙས་པ་སྤོངས། །',
        ],
        english: [
            "Comfort, joy, and caring friends – you might have them all now, but they'll fade soon enough, so leave them behind.",
            'Your future lives are much longer than this one, so prepare your provisions for the future and bury your treasure now!',
            'All is lost in the end, so strive not and cling to naught.',
            'Arouse compassion for the needy and never ignore them, nor treat them with disdain.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1218: {
        tibetan: [
            'དགྲ་ཟིན་སྡེ་རིས་ཀུན་ལ་ཞེན་ཅིང་ཆགས་པའི་ཕྱོགས་རིས་མེད་པར་མཛོད། །',
            'ཡོན་ཏན་ཅན་ལ་ཕྲག་དོག་མི་བྱ་གུས་པར་གྱིས་ལ་ཡོན་ཏན་བླང༌། །',
            'གཞན་སྐྱོན་མ་བརྟགས་རང་སྐྱོན་བརྟགས་ལ་རང་སྐྱོན་ཁྲག་ངན་བཞིན་དུ་ཕྱུང༌། །',
            'རང་དགེ་མ་བསམས་གཞན་དགེ་བསམས་ལ་ཀུན་ལ་བྲན་བཞིན་གུས་པར་མཛོད། །',
        ],
        english: [
            'Rid yourself of bias and prejudice toward enemies and rival clans.',
            'Do not envy those with talent and learning; rouse respect, and learn from them.',
            "Look not at others' faults but at your own, and rid yourself of them as you would infected blood.",
            "Think not of your own but of others' virtue, and treat everyone with respect, as a servant would.",
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1219: {
        tibetan: [
            'འགྲོ་བ་ཀུན་ལ་ཕ་མའི་འདུ་ཤེས་བསྐྱེད་ལ་བུ་བཞིན་བྱམས་པར་གྱིས། །',
            'རྟག་ཏུ་འཛུམ་པའི་བཞིན་དང་བྱམས་པའི་ཡིད་ཀྱིས་ཁྲོ་མེད་གསོང་པོར་སྨྲོས། །',
            'འབྲེལ་མེད་སྨྲ་བ་མང་ན་འཁྲུལ་པ་འབྱུང་བས་ཚོད་བཟུང་རན་པར་སྨྲོས། །',
            'དོན་མེད་བྱ་བ་མང་ན་དགེ་སྦྱོར་འཆག་པས་ཆོས་མིན་བྱ་བ་ཞོག །',
        ],
        english: [
            'Regard all beings as your parents, and care for them with the love of a child.',
            'Keep a smile on your face and love in your heart, and speak frankly, free from anger.',
            'Irrelevant chatter will lead to confusion, so speak in a measured, appropriate way.',
            'Pointless activity will interrupt your spiritual practice, so set aside all deeds that are not Dharma.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1220: {
        tibetan: [
            'སྙིང་པོ་མེད་པའི་ལས་ལ་རྩོལ་བ་མ་ཆེ་ངལ་བ་དོན་མེད་ཡིན། །',
            'གང་ཡང་འདོད་ཁར་མི་མཆི་ཕ་རོལ་ལས་ལས་གྲུབ་པས་ལྷོད་ཀྱིས་གློད་ན་བདེ། །',
            'ཀ་ཡེ་དམ་པས་ཁྲེལ་ན་ཤི་དང་འདྲ་བས་གཡོ་མེད་དྲང་པོར་སྲོང༌། །',
            'ཚེ་འདིའི་བདེ་སྡུག་སྔ་མའི་ལས་ལས་བྱུང་བས་གཞན་ལ་གྱོད་མི་གདའ། །',
        ],
        english: [
            "Don't exert yourself in meaningless work; such efforts will only tire you out.",
            'Due to karma and external circumstances you may not accomplish what you have set out to do. Thus it is better to relax and let be, and you will be happy and at ease.',
            "Displeasing a noble being is as grave as death itself; thus don't do things that you will regret but be a trustworthy and honest person.",
            "The pleasure and pain of this life stem from your own past karma, so don't lay blame elsewhere.",
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1221: {
        tibetan: [
            'བདེ་བ་ཐམས་ཅད་བླ་མའི་བྱིན་རླབས་ལགས་པས་དྲིན་དུ་གཟོ་བར་མཛོད། །',
            'རང་རྒྱུད་མ་ཐུལ་བར་དུ་གཞན་རྒྱུད་མི་ཐུལ་བས་ན་ཐོག་མར་རང་རྒྱུད་ཐུལ། །',
            'མངོན་ཤེས་མེད་པར་གཞན་དག་སྨིན་པར་མི་ནུས་པས་ན་སྒྲུབ་ལ་བརྩོན་པར་མཛོད། །',
            'བསགས་པའི་ནོར་རྣམས་བཞག་ནས་འགྲོ་བར་ངེས་པས་ནོར་ཕྱིར་སྡིག་མ་སོགས། །',
        ],
        english: [
            "All happiness is the guru's blessing, so strive to repay his kindness and grace.",
            "Until you tame your own mind, you cannot tame others', so first tame yourself.",
            "If you lack clairvoyance you can't ripen others, so exert yourself in practice.",
            "When you die, all your wealth is sure to be left behind, so don't go amassing sins for the sake of wealth.",
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1222: {
        tibetan: [
            'ལོངས་སྤྱོད་གཡེང་བ་འདི་ལ་སྙིང་པོ་མེད་པས་སྦྱིན་པ་ཡོན་གྱིས་བརྒྱན་པ་ཐོང༌། །',
            'ཚེ་འདིར་མཛེས་ཤིང་ཕྱི་མར་བདེ་བས་རྟག་ཏུ་ཚུལ་ཁྲིམས་རྣམ་དག་སྲུངས། །',
            'སྙིགས་མའི་དུས་ན་ཞེ་སྡང་བདོ་བས་ཁྲོ་མེད་བཟོད་པའི་གོ་ཆ་གྱོན། །',
            'ལེ་ལོའི་དབང་གིས་ད་རུང་ལུས་པས་སྒྲུབ་པའི་བརྩོན་འགྲུས་མེ་ལྟར་སྤོར། །',
        ],
        english: [
            'It is futile to lose yourself to luxury and wealth. Instead, adorn yourself with the glory of giving.',
            'It will bring beauty in this life and bliss in the next, so maintain a pure and constant discipline.',
            'In these degenerate times aggression is on the rise, so don the armor of patience, free from aggression.',
            'It is our laziness that keeps us here, so rekindle your efforts in practice like a blazing pyre.',
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1223: {
        tibetan: [
            'ཡེངས་མ་ལམ་དུ་མི་ཚེ་ཟད་པས་ད་ནི་བསམ་གཏན་བྱ་བར་རན། །',
            'ལོག་ལྟའི་དབང་གིས་གནས་ལུགས་དོན་མི་རྟོགས་པས་ཡང་དག་དོན་ལ་གཞིགས། །',
            'གྲོགས་དག་འཁོར་བའི་འདམ་རྫབ་འདི་ན་སྐྱིད་མེད་ཐར་པའི་སྐམ་སར་གཤེགས། །',
            'བླ་མའི་གདམས་ངག་ཚུལ་བཞིན་བསྒོམས་ལ་འཁོར་བའི་སྡུག་བསྔལ་ཆུ་བོ་སྐེམས། །',
        ],
        english: [
            'Our lives run out in constant distraction, so now let us train in meditation.',
            'Swayed by wrong views, we fail to realize the natural state, so let us examine the genuine nature of things.',
            'Dear friends, here in the swamp of saṃsāra there is no happiness, so let us journey to the dry shore of liberation.',
            "Train correctly in the guru's instructions; dry up the ocean of saṃsāra's suffering.",
        ],
        title: {
            tibetan: 'ཐར་འདོད་དཔའ་བོའི་སྙིང་ནོར།',
            english: 'Heart Treasure for the Warriors Who Long for Liberation',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/heart-treasure-for-warriors',
        },
        isPartial: '',
    },
    1224: {
        tibetan: [
            'ཆོས་ལམ་ཞུགས་ཀྱང་ཉེས་སྤྱོད་མི་སྡོམ་ཞིང༌། །',
            'ཐེག་ཆེན་སྒོར་ཞུགས་གཞན་ཕན་སེམས་དང་བྲལ། །',
            'དབང་བཞི་ཐོབ་ཀྱང་བསྐྱེད་རྫོགས་མི་སྒོམ་པའི། །',
            'ལམ་གོལ་འདི་ལས་བླ་མས་བསྒྲལ་དུ་གསོལ། །',
        ],
        english: [
            "Though I have entered the path of the Dharma, I haven't put a stop to my erring ways.",
            'Though I have entered the door of the Mahāyāna, I am devoid of any beneficial thought for others.',
            'Though I have received the four empowerments, I do not practise the development and completion phases of meditation.',
            'O lama, free me from straying from the path!',
        ],
        title: {
            tibetan: 'ཀློང་ཆེན་སྙིང་ཏིག་གི་སྔོན་འགྲོ།',
            english: 'Longchen Nyingtik Ngöndro',
        },
        author: {
            tibetan: 'རྡོ་གྲུབ་ཆེན་འཇིགས་མེད་ཕྲིན་ལས་འོད་ཟེར།',
            english: 'Dodrupchen Jikme Trinle Özer',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/dodrupchen-I/longchen-nyingtik',
        },
        isPartial: '',
    },
    1225: {
        tibetan: [
            'ལྟ་བ་མ་རྟོགས་ཐོ་ཅོའི་སྤྱོད་པ་ཅན། །',
            'སྒོམ་པ་ཡེངས་ཀྱང་གོ་ཡུལ་འུད་གོག་འཐག །',
            'སྤྱོད་པ་ནོར་ཀྱང་རང་སྐྱོན་མི་སེམས་པའི། །',
            'ཆོས་དྲེད་འདི་ལས་བླ་མས་བསྒྲལ་དུ་གསོལ། །',
        ],
        english: [
            "Though I have not realized the View, I act as if 'a master of crazy wisdom'.",
            'Though I am distracted in my Meditation, I let myself get stuck in mental gossip and concepts.',
            "Though it's my own Actions that are at fault, it's someone else I blame.",
            'O lama, free me from becoming so arrogant and opinionated, so stubborn and insensitive!',
        ],
        title: {
            tibetan: 'ཀློང་ཆེན་སྙིང་ཏིག་གི་སྔོན་འགྲོ།',
            english: 'Longchen Nyingtik Ngöndro',
        },
        author: {
            tibetan: 'རྡོ་གྲུབ་ཆེན་འཇིགས་མེད་ཕྲིན་ལས་འོད་ཟེར།',
            english: 'Dodrupchen Jikme Trinle Özer',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/dodrupchen-I/longchen-nyingtik',
        },
        isPartial: '',
    },
    1226: {
        tibetan: [
            'ནང་པར་འཆི་ཡང་གནས་གོས་ནོར་ལ་སྲེད། །',
            'ན་ཚོད་ཡོལ་ཡང་ངེས་འབྱུང་སྐྱོ་ཤས་བྲལ། །',
            'ཐོས་པ་ཆུང་ཡང་ཡོན་ཏན་ཅན་དུ་རློམ། །',
            'མ་རིག་འདི་ལས་བླ་མས་བསྒྲལ་དུ་གསོལ། །',
        ],
        english: [
            'Though I may die tomorrow, I am full of craving for home, clothes and possessions.',
            "Though I am quite old, I'm not mature enough to have the slightest renunciation for saṃsāra.",
            'Though I have truly heard only a little Dharma teaching, I pride myself on all my knowledge.',
            'O lama, free me from such ignorance!',
        ],
        title: {
            tibetan: 'ཀློང་ཆེན་སྙིང་ཏིག་གི་སྔོན་འགྲོ།',
            english: 'Longchen Nyingtik Ngöndro',
        },
        author: {
            tibetan: 'རྡོ་གྲུབ་ཆེན་འཇིགས་མེད་ཕྲིན་ལས་འོད་ཟེར།',
            english: 'Dodrupchen Jikme Trinle Özer',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/dodrupchen-I/longchen-nyingtik',
        },
        isPartial: '',
    },
    1227: {
        tibetan: [
            'རྐྱེན་ཁར་འཆོར་ཡང་འདུ་འཛི་གནས་སྐོར་སེམས། །',
            'དབེན་པ་བརྟེན་ཀྱང་རང་རྒྱུད་ཤིང་ལྟར་རེངས། །',
            'དུལ་བར་སྨྲ་ཡང་ཆགས་སྡང་མ་ཞིག་པའི། །',
            'ཆོས་བརྒྱད་འདི་ལས་བླ་མས་བསྒྲལ་དུ་གསོལ། །',
        ],
        english: [
            "Though I may be rushing into danger, I go Dharma-socializing in crowds and public places, thinking I'm on a pilgrimage.",
            'Though going on solitary retreats, my basic character remains as tough as a block of wood.',
            "Though appearing calm and speaking softly, I haven't got rid of the attachment and aversion boiling inside.",
            'O lama, free me from these eight worldly concerns!',
        ],
        title: {
            tibetan: 'ཀློང་ཆེན་སྙིང་ཏིག་གི་སྔོན་འགྲོ།',
            english: 'Longchen Nyingtik Ngöndro',
        },
        author: {
            tibetan: 'རྡོ་གྲུབ་ཆེན་འཇིགས་མེད་ཕྲིན་ལས་འོད་ཟེར།',
            english: 'Dodrupchen Jikme Trinle Özer',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/dodrupchen-I/longchen-nyingtik',
        },
        isPartial: '',
    },
    1228: {
        tibetan: [
            'ཚོང་དཔོན་ལམ་ལ་མཁས་པ་དག་ལ་བརྟེན། །',
            'གྲུ་ཚགས་ལེགས་པས་རྒྱ་མཚོར་ཞུགས་གྱུར་ན། །',
            'རིན་ཆེན་འདོད་པ་བཞིན་དུ་རྙེད་གྱུར་ནས། །',
            'རང་གི་གནས་སུ་ལེགས་པར་ཕྱིན་པ་ཡིན། །',
            'བྱམས་དང་སྙིང་རྗེ་དགའ་དང་བཏང་སྙོམས་རྟེན། །',
            'བྱང་ཆུབ་སེམས་ལྡན་དགེ་བ་སྤྱད་གྱུར་ན། །',
            'ཡང་དག་རྫོགས་ཀྱི་ལམ་ལ་ཞུགས་འགྱུར་ཏེ། །',
            'ངེས་པར་མཁྱེན་ཉིད་ས་ལ་འགྲོ་བར་འགྱུར། །',
        ],
        english: [
            'If one relies on a merchant who knows the way',
            'And sets out to sea in an excellent ship,',
            'One will find whatever riches are desired',
            "And sail smoothly back to one's home.",
            'If one relies on love, compassion, joy, and equanimity',
            'And practices virtue endowed with the mind of awakening,',
            'One will set out on a truly perfect path',
            'And be sure to reach the land of wisdom.',
        ],
        title: {
            tibetan: 'སྒྲོལ་མ་འཇིགས་པ་བརྒྱད་ལས་སྐྱོབ་པ།',
            english: 'Tārā Who Protects from the Eight Dangers',
        },
        author: {
            tibetan: '',
            english: '',
        },
        links: { lh: 'https://read.84000.co/translation/toh731.html' },
        isPartial: '',
    },
    1229: {
        tibetan: ['གང་ཡང་ཡིད་ལ་མ་བྱས་ན་། །', 'གོལ་སར་འབྱུང་བ་ག་ལ་ཡོད། །', 'འདུ་ཤེས་རྣམ་པར་བཤིགས་ལ་ཞོག །', ''],
        english: [
            'When nothing whatsoever is conceptualized,',
            'How could you possibly go astray?',
            'Annihilate your conceptions. And rest.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མ་ཅིག་ལབ་སྒྲོན།', english: 'Machik Labdrön' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/patrul-rinpoche/two-truths-view-mahayana',
        },
        isPartial: '',
    },
    1230: {
        tibetan: [
            'སེམས་ལ་གཉིས་སུ་མ་མཆིས་པས། །',
            'བལྟ་རྒྱུ་མེད་པའི་ཚུལ་དུ་བལྟ། །',
            'བལྟས་པས་རང་གི་སེམས་མི་མཐོང་། །',
            'མཐོང་བས་སེམས་ཉིད་དོན་མི་རིག །',
            'དེ་བས་བལྟ་བར་བྱ་བ་ནི། །',
            'རྡུལ་ཙམ་ཡོད་པ་མ་ཡིན་ནོ། །',
        ],
        english: [
            'Since mind is not a duality,',
            'Look as if there is nothing to be looked at.',
            "This mind of ours is not seen by any 'looking'.",
            "Mind's very nature is not realized by being 'seen'.",
            'In fact, there is not the tiniest fraction',
            'Of something to be looked at.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མ་ཅིག་ལབ་སྒྲོན།', english: 'Machik Labdrön' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/patrul-rinpoche/two-truths-view-mahayana',
        },
        isPartial: '',
    },
    1231: {
        tibetan: [
            'ལྟ་བས་རང་སེམས་དྲན་པ་ལྕགས་ཀྱུས་བཟུང་། །',
            'སྒོམ་པས་ལས་འབྲས་ཡིད་ཆེས་བླང་དོར་ཞིབ། །',
            'སྤྱོད་པས་འགྲོ་དྲུག་བུ་བཞིན་བརྩེ་བས་སྐྱོངས། །',
        ],
        english: [
            'Use meditation to develop confidence in action and results',
            'And pay close attention to the details of what to do and what not to do.',
            'In your behavior, love beings from all six realms as your own children.',
        ],
        title: {
            tibetan: 'ཞལ་གདམས་བསྡུས་པ།',
            english: 'Concise Spiritual Advice',
        },
        author: { tibetan: 'སེ་ར་མཁའ་འགྲོ།', english: 'Sera Khandro' },
        links: { lh: '' },
        isPartial: '',
    },
    '0101': {
        tibetan: [
            'རང་རེ་སྔགས་པ་ཚོས།',
            'བླ་མའི་ཐུགས་མ་དཀྲུག་ཅིང་ཅི་གསུང་བཀའ་སྒྲུབ་པ་དང་གཅིག',
            'མཆེད་ལྕམ་དྲལ་རྩོད་འཛིང་ངོ་ལྐོག་མི་བྱེད་པ་དང་གཉིས།',
            'ཉི་མ་རེ་བཞིན་ཡི་དམ་ལྷ་ཡི་བསྒོམ་བཟླས་ཚེ་བཅུའི་ཚོགས་མཆོད་མ་ཆགས་པ་དང་གསུམ།',
            'ཁྲེགས་ཆོད་ཀྱི་ཉམས་ལེན་སྐྱོང་ཞིང་སེམས་ཅན་གྱི་སྲོག་མི་གཅོད་པ་དང་བཞི་པོ་འདི་སྲུང་མ་དེང་སང་དུས་ཀྱི་སྔགས་པ་རབ་ཡིན་ནོ། །',
        ],
        english: [
            "Do not disturb the guru's mind and fulfill their every command;",
            'Do not quarrel with vajra siblings and be honest and sincere towards them;',
            'Practice the meditation and recitation of the yidam deity every day and never fail to make a feast offering on the tenth day of the lunar month;',
            'Maintain the practice of trekchö (cutting through) and do not take the life of any sentient being.',
        ],
        title: {
            tibetan: 'དེང་དུས་ཀྱི་སྔགས་པ་ཚང་དགོས་པའི་ཁྱད་ཆོས་བཞི།',
            english: 'Four Pieces of Advice to Tantric Practitioners',
        },
        author: {
            tibetan: 'མཆོག་གྱུར་བདེ་ཆེན་གླིང་པ།',
            english: 'Chokgyur Dechen Lingpa',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0102': {
        tibetan: [
            'བསྟན་པ་ཚུགས་ན་རང་གི་སེམས་ལ་ཚུགས། །',
            'སེམས་ཀྱི་གཏིང་ནས་སངས་རྒྱས་རྙེད་ཡོང་ངོ་། །',
            'ཞིང་ཁམས་ཉུལ་ན་ཐ་མལ་འཁྲུལ་ཞེན་སྦྱོང་། །',
            'རྣམ་དག་ཞིང་ཁམས་སྐྱིད་པོ་འགྲམ་ན་ཡོད། །',
            'བསྟན་པའི་སྙིང་པོ་སྒྲུབ་ལ་བརྩོན་འགྲུས་བསྐྱེད། །',
            'སྒྲུབ་པ་མ་བྱས་གྲུབ་པ་སུ་ཡིས་ཐོབ། །',
            'རང་སྐྱོན་ངན་པ་རང་གིས་མཐོང་བར་དཀའ། །',
            'རང་སྐྱོན་ཐེར་འདོན་གདམས་པའི་གནས་གཅིག་ཡིན། །',
            'ནམ་ཞིག་སྐྱོན་གྱི་ཆ་རྣམས་སངས་རིམ་བཞིན། །',
            'ཡོན་ཏན་ཆ་ཤས་འཕེལ་ཞིང་རྒྱས་པར་འགྱུར། །',
        ],
        english: [
            "If you're going to plant the teachings, plant them in your mind:",
            "in the depths of mind is where you'll find buddha.",
            "If you're going to search out pure lands, purify your grasping to the deluded ordinary;",
            'the delightful pure lands lie right beside you.',
            'Rouse diligence when it comes to practicing the essential teachings—',
            'whoever reached accomplishment without practice?',
            'Your own worst faults are difficult for you to see;',
            'to expose your faults is the single most vital piece of advice.',
            'As all faulty aspects are cleared away, one by one,',
            'your part of good qualities will grow and flourish, more and more.',
        ],
        title: { tibetan: 'རང་སྐྱོན་ངོ་ཤེས།', english: 'Recognize My Own Faults' },
        author: { tibetan: 'བདུད་འཇོམས་རིན་པོ་ཆེ།', english: 'Dudjom Rinpoché' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/dudjom-rinpoche/prayer-to-recognize-own-faults',
        },
        isPartial: '',
    },
    '0103': {
        tibetan: [
            'བླ་མ་མ་བརྗེད་རྟག་ཏུ་གསོལ་བ་ཐོབ། །',
            'རང་སེམས་མ་ཡེངས་རང་ངོ་རང་གིས་ལྟོས། །',
            'འཆི་བ་མ་བརྗེད་ཆོས་ལ་བསྐུལ་མ་ཐོབ། །',
            'སེམས་ཅན་མ་བརྗེད་སྙིང་རྗེ་བསྔོ་སྨོན་གྱིས། །',
        ],
        english: [
            'Do not forget the guru;',
            'Pray to him at all times.',
            'Do not let your mind be distracted;',
            'Look into its very essence.',
            'Do not forget death;',
            'Let it spur you on to Dharma.',
            'Do not forget sentient beings;',
            'With compassion dedicate your merit to them and make prayers of aspiration.',
        ],
        title: {
            tibetan: 'ཞལ་གདམས་མདོར་བསྡུས།',
            english: 'Heart Advice in Four Lines',
        },
        author: {
            tibetan: 'དིལ་མགོ་མཁྱེན་བརྩེ་རིན་པོ་ཆེ།',
            english: 'Dilgo Khyentse Rinpoche',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/dilgo-khyentse/heart-advice-do-not-forget',
        },
        isPartial: '',
    },
    '0104': {
        tibetan: [
            'ཆོས་རྣམས་རང་བཞིན་རྣམ་དག་པས། །',
            'དངོས་པོ་མེད་པའི་འདུ་ཤེས་བསྒོམ། །',
            'བྱང་ཆུབ་སེམས་དང་རབ་ལྡན་པས། །',
            'སྙིང་རྗེ་ཆེན་པོའི་འདུ་ཤེས་བསྒོམ། །',
            'ཆོས་རྣམས་རང་བཞིན་འོད་གསལ་བས། །',
            'དམིགས་པ་མེད་པའི་འདུ་ཤེས་བསྒོམ། །',
            'དངོས་པོ་ཐམས་ཅད་མི་རྟག་པས། །',
            'ཆགས་པ་མེད་པའི་འདུ་ཤེས་བསྒོམ། །',
            'སེམས་ནི་ཡེ་ཤེས་འབྱུང་བའི་རྒྱུ། །',
            'སངས་རྒྱས་གཞན་དུ་མ་ཚོལ་ཅིག །',
        ],
        english: [
            'Since all things are by nature pure',
            'Cultivate the perception of insubstantiality.',
            'Since they are possessed of bodhicitta',
            'Cultivate the perception of great compassion.',
            'Since all phenomena are by nature luminous',
            'Cultivate the perception of referencelessness.',
            'Since all things are impermanent',
            'Cultivate the perception of non-attachment.',
            "The mind is what causes wisdom's arising",
            'And so, look not for buddhahood elsewhere!',
        ],
        title: {
            tibetan: 'འདའ་ཀ་ཡེ་ཤེས།',
            english: 'Wisdom of the Hour of Death',
        },
        author: { tibetan: 'སངས་རྒྱས་ཤཱཀྱ་ཐུབ་པ།', english: 'Buddha Śākyamuni' },
        links: {
            lh: 'https://www.lotsawahouse.org/words-of-the-buddha/sutra-wisdom-hour-of-death',
        },
        isPartial: '',
    },
    '0105': {
        tibetan: [
            'བློ་ཆོས་སུ་འགྲོ་བར་བྱིན་གྱིས་བརླབ་ཏུ་གསོལ། །',
            'ཆོས་ལམ་དུ་འགྲོ་བར་བྱིན་གྱིས་བརླབ་ཏུ་གསོལ། །',
            'ལམ་འཁྲུལ་པ་སེལ་བར་བྱིན་གྱིས་བརླབ་ཏུ་གསོལ། །',
            'འཁྲུལ་པ་ཡེ་ཤེས་སུ་འཆར་བར་བྱིན་གྱིས་བརླབ་ཏུ་གསོལ། །',
        ],
        english: [
            'Bless us so our minds may turn towards the Dharma.',
            'Bless us so our Dharma may become the path.',
            'Bless us so our path may clear away confusion.',
            'Bless us so confusion may dawn as wisdom.',
        ],
        title: {
            tibetan: 'སྒམ་པོ་པའི་ཆོས་བཞི།',
            english: 'The Four Dharmas of Gampopa',
        },
        author: {
            tibetan: 'སྒམ་པོ་པ་བསོད་ནམས་རིན་ཆེན།',
            english: 'Gampopa Sönam Rinchen',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gampopa-sonam-rinchen/four-dharmas-lamrim-precious-garland',
        },
        isPartial: '',
    },
    '0106': {
        tibetan: [
            'ཚེ་འདི་ལ་ཞེན་ན་ཆོས་པ་མིན། །',
            'ཁམས་གསུམ་ལ་ཞེན་ན་ངེས་འབྱུང་མིན། །',
            'བདག་དོན་ལ་ཞེན་ན་བྱང་སེམས་མིན། །',
            'འཛིན་པ་བྱུང་ན་ལྟ་བ་མིན། །',
        ],
        english: [
            'If you are attached to this life, you are not a true spiritual practitioner;',
            'If you are attached to saṃsāra, you have no renunciation;',
            'If you are attached to your own self-interest, you have no bodhicitta;',
            'If there is grasping, you do not have the View.',
        ],
        title: {
            tibetan: 'ཞེན་པ་བཞི་བྲལ།',
            english: 'Parting from the Four Attachments',
        },
        author: {
            tibetan: 'ས་ཆེན་ཀུན་དགའ་སྙིང་པོ།',
            english: 'Sachen Kunga Nyingpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/sachen-kunga-nyingpo/parting-four-attachments',
        },
        isPartial: '',
    },
    '0107': {
        tibetan: [
            'སྡུག་བསྔལ་ཅན་ལ་བྱམས་དང་སྙིང་རྗེ་སྒོམས། །',
            'བདེ་སྐྱིད་ཅན་ལ་རྗེས་སུ་ཡི་རང་གྱིས། །',
            'གཞན་ལ་མི་བསམ་བླ་མར་གསོལ་བ་ཐོབ། །',
            'རང་སེམས་ལ་ལྟོས་སེམས་ཉིད་གསལ་རིག་སྐྱོངས། །',
        ],
        english: [
            'Cultivate love and compassion for those who are suffering.',
            'Rejoice in the good fortune of those who are happy.',
            'Do not ponder anything else, but pray to the guru!',
            'Look into your own mind and sustain the clear awareness that is its true nature.',
        ],
        title: {
            tibetan: 'བསླབ་བྱ་ཚིག་བཞི།',
            english: '\t\nAdvice in Four Lines',
        },
        author: { tibetan: 'འཁྲུལ་ཞིག་རིན་པོ་ཆེ།', english: 'Trulshik Rinpoche' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/trulshik-rinpoche/advice-in-four-lines',
        },
        isPartial: '',
    },
    '0108': {
        tibetan: [
            'བདག་ནི་སེམས་ཅན་ཐམས་ཅད་ལ། །',
            'ཡིད་བཞིན་ནོར་བུ་ལས་ལྷག་པའི། །',
            'དོན་མཆོག་སྒྲུབ་པའི་བསམ་པ་ཡིས། །',
            'རྟག་ཏུ་གཅེས་པར་འཛིན་པར་ཤོག །',
        ],
        english: [
            'By thinking of all sentient beings',
            'As more precious than a wish-fulfilling jewel',
            'For accomplishing the highest aim,',
            'I will always hold them dear.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: '',
    },
    '0109': {
        tibetan: [
            'གང་དུ་སུ་དང་འགྲོགས་པའི་ཚེ། །',
            'བདག་ཉིད་ཀུན་ལས་དམན་བལྟ་ཞིང༌། །',
            'གཞན་ལ་བསམ་པ་ཐག་པ་ཡིས། །',
            'མཆོག་ཏུ་གཅེས་པར་འཛིན་པར་ཤོག །',
        ],
        english: [
            "Whenever I'm in the company of others,",
            'I will regard myself as the lowest among all,',
            'And from the depths of my heart',
            'Cherish others as supreme.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0110': {
        tibetan: [
            'སྤྱོད་ལམ་ཀུན་ཏུ་རང་རྒྱུད་ལ། །',
            'རྟོག་ཅིང་ཉོན་མོངས་སྐྱེས་མ་ཐག །',
            'བདག་གཞན་མ་རུངས་བྱེད་པས་ན། །',
            'བཙན་ཐབས་གདོང་ནས་བཟློག་པར་ཤོག །',
        ],
        english: [
            'In my every action, I will watch my mind,',
            'And the moment destructive emotions arise,',
            'I will confront them strongly and avert them,',
            'Since they will hurt both me and others.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0111': {
        tibetan: [
            'རང་བཞིན་ངན་པའི་སེམས་ཅན་རྣམས། །',
            'སྡིག་སྡུག་དྲག་པོས་ནོན་མཐོང་ཚེ། །',
            'རིན་ཆེན་གཏེར་དང་འཕྲད་པ་བཞིན། །',
            'རྙེད་པར་དཀའ་བས་གཅེས་འཛིན་ཤོག །',
        ],
        english: [
            'Whenever I see ill-natured beings,',
            'Or those overwhelmed by heavy misdeeds or suffering,',
            'I will cherish them as something rare,',
            "As though I'd found a priceless treasure.",
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0112': {
        tibetan: [
            'བདག་ལ་གཞན་གྱིས་ཕྲག་དོག་གིས། །',
            'གཤེ་བསྐུར་ལ་སོགས་མི་རིགས་པའི། །',
            'གྱོང་ཁ་རང་གིས་ལེན་པ་དང༌། །',
            'རྒྱལ་ཁ་གཞན་ལ་འབུལ་བར་ཤོག །',
        ],
        english: [
            'Whenever someone out of envy',
            'Does me wrong by attacking or belittling me,',
            'I will take defeat upon myself,',
            'And give the victory to others.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0113': {
        tibetan: [
            'གང་ལ་བདག་གིས་ཕན་བཏགས་པའི། །',
            'རེ་བ་ཆེ་བ་གང་ཞིག་གིས། །',
            'ཤིན་ཏུ་མི་རིགས་གནོད་བྱེད་ནའང༌། །',
            'བཤེས་གཉེན་དམ་པར་བལྟ་བར་ཤོག །',
        ],
        english: [
            'Even when someone I have helped,',
            'Or in whom I have placed great hopes',
            'Mistreats me very unjustly,',
            'I will view that person as a true spiritual teacher.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0114': {
        tibetan: [
            'མདོར་ན་དངོས་སམ་བརྒྱུད་པ་ཡིས། །',
            'ཕན་བདེ་མ་རྣམས་ཀུན་ལ་འབུལ། །',
            'མ་ཡི་གནོད་དང་སྡུག་བསྔལ་ཀུན། །',
            'གསང་བས་བདག་ལ་ལེན་པར་ཤོག །',
        ],
        english: [
            'In brief, directly or indirectly,',
            'I will offer help and happiness to all my mothers,',
            'And secretly take upon myself',
            'All their hurt and suffering.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0115': {
        tibetan: [
            'དེ་དག་ཀུན་ཀྱང་ཆོས་བརྒྱད་ཀྱི། །',
            'རྟོག་པའི་དྲི་མས་མ་སྦགས་ཤིང༌། །',
            'ཆོས་ཀུན་སྒྱུ་མར་ཤེས་པའི་བློས། །',
            'ཞེན་མེད་འཆིང་བ་ལས་གྲོལ་ཤོག །',
        ],
        english: [
            'I will learn to keep all these practices',
            'Untainted by thoughts of the eight worldly concerns.',
            'May I recognize all things as like illusions,',
            'And, without attachment, gain freedom from bondage.',
        ],
        title: {
            tibetan: 'བློ་སྦྱོང་ཚིག་བརྒྱད་མ།',
            english: 'Eight Verses of Training the Mind',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshé Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/eight-verses-training-mind',
        },
        isPartial: true,
    },
    '0116': {
        tibetan: [
            'དལ་འབྱོར་གྲུ་ཆེན་རྙེད་དཀའ་ཐོབ་དུས་འདིར། །',
            'བདག་གཞན་འཁོར་བའི་མཚོ་ལས་བསྒྲལ་བྱའི་ཕྱིར། །',
            'ཉིན་དང་མཚན་དུ་གཡེལ་བ་མེད་པར་ནི། །',
            'ཉན་སེམས་སྒོམ་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to study, reflect and meditate,',
            'Tirelessly, both day and night, without ever straying into idleness,',
            'In order to free oneself and others from this ocean of saṃsāra,',
            'Having gained this supreme vessel—a free, well-favoured human life, so difficult to find.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0117': {
        tibetan: [
            'གཉེན་གྱི་ཕྱོགས་ལ་འདོད་ཆགས་ཆུ་ལྟར་གཡོ། །',
            'དགྲ་ཡི་ཕྱོགས་ལ་ཞེ་སྡང་མེ་ལྟར་འབར། །',
            'བླང་དོར་བརྗེད་པའི་གཏི་མུག་མུན་ནག་ཅན། །',
            'ཕ་ཡུལ་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            "The practice of all the bodhisattvas is to leave behind one's homeland,",
            'Where our attachment to family and friends overwhelms us like a torrent,',
            'While our aversion towards enemies rages inside us like a blazing fire,',
            "And delusion's darkness obscures what must be adopted and abandoned.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0118': {
        tibetan: [
            'ཡུལ་ངན་སྤངས་པས་ཉོན་མོངས་རིམ་གྱིས་འགྲིབ། །',
            'རྣམ་གཡེང་མེད་པས་དགེ་སྦྱོར་ངང་གིས་འཕེལ། །',
            'རིག་པ་དྭངས་པས་ཆོས་ལ་ངེས་ཤེས་སྐྱེ། །',
            'དབེན་པ་བསྟེན་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to take to solitary places,',
            'Avoiding the unwholesome, so that destructive emotions gradually fade away,',
            'And, in the absence of distraction, virtuous practice naturally gains strength;',
            'Whilst, with awareness clearly focused, we gain conviction in the teachings.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0119': {
        tibetan: [
            'ཡུན་རིང་འགྲོགས་པའི་མཛའ་བཤེས་སོ་སོར་འབྲལ། །',
            'འབད་པས་སྒྲུབ་པའི་ནོར་རྫས་ཤུལ་དུ་ལུས། །',
            'ལུས་ཀྱི་མགྲོན་ཁང་རྣམ་ཤེས་མགྲོན་པོས་བོར། །',
            'ཚེ་འདི་བློས་བཏང་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            "The practice of all the bodhisattvas is to renounce this life's concerns,",
            'For friends and relatives, long acquainted, must all go their separate ways;',
            'Wealth and prized possessions, painstakingly acquired, must all be left behind;',
            'And consciousness, the guest who lodges in the body, must in time depart.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0120': {
        tibetan: [
            'གང་དང་འགྲོགས་ན་དུག་གསུམ་འཕེལ་འགྱུར་ཞིང༌། །',
            'ཐོས་བསམ་སྒོམ་པའི་བྱ་བ་ཉམས་འགྱུར་ལ། །',
            'བྱམས་དང་སྙིང་རྗེ་མེད་པར་སྒྱུར་བྱེད་པའི། །',
            'གྲོགས་ངན་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to avoid destructive friends,',
            'In whose company the three poisons of the mind grow stronger,',
            'And we engage less and less in study, reflection and meditation,',
            'So that love and compassion fade away until they are no more.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0121': {
        tibetan: [
            'གང་ཞིག་བསྟེན་ན་ཉེས་པ་ཟད་འགྱུར་ཞིང༌། །',
            'ཡོན་ཏན་ཡར་ངོའི་ཟླ་ལྟར་འཕེལ་འགྱུར་བའི། །',
            'བཤེས་གཉེན་དམ་པ་རང་གི་ལུས་ལས་ཀྱང༌། །',
            'གཅེས་པར་འཛིན་པར་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to cherish spiritual friends,',
            "By regarding them as even more precious than one's own body,",
            'Since they are the ones who will help to rid us of all our faults,',
            'And make our virtues grow ever greater just like the waxing moon.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0122': {
        tibetan: [
            'རང་ཡང་འཁོར་བའི་བཙོན་རར་བཅིངས་པ་ཡིས། །',
            'འཇིག་རྟེན་ལྷ་ཡིས་སུ་ཞིག་སྐྱོབ་པར་ནུས། །',
            'དེ་ཕྱིར་གང་ལ་སྐྱབས་ན་མི་བསླུ་བའི། །',
            'དཀོན་མཆོག་སྐྱབས་འགྲོ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to take refuge in the Three Jewels,',
            'Since they will never fail to provide protection for all who call upon them,',
            'For whom are the ordinary gods of this world ever capable of helping,',
            "As long as they themselves are trapped within saṃsāra's vicious cycle?",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0123': {
        tibetan: [
            'ཤིན་ཏུ་བཟོད་དཀའི་ངན་སོང་སྡུག་བསྔལ་རྣམས། །',
            'སྡིག་པའི་ལས་ཀྱི་འབྲས་བུར་ཐུབ་པས་གསུངས། །',
            'དེ་ཕྱིར་སྲོག་ལ་བབ་ཀྱང་སྡིག་པའི་ལས། །',
            'ནམ་ཡང་མི་བྱེད་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is never to commit a harmful act,',
            "Even though not to do so might put one's very life at risk,",
            'For the Sage himself has taught how negative actions will ripen',
            'Into the manifold miseries of the lower realms, so difficult to endure.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0124': {
        tibetan: [
            'སྲིད་གསུམ་བདེ་བ་རྩྭ་རྩེའི་ཟིལ་པ་བཞིན། །',
            'ཡུད་ཙམ་ཞིག་གིས་འཇིགས་པའི་ཆོས་ཅན་ཡིན། །',
            'ནམ་ཡང་མི་འགྱུར་ཐར་པའི་གོ་འཕང་མཆོག །',
            'དོན་དུ་གཉེར་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to strive towards the goal,',
            'Which is the supreme state of changeless, everlasting liberation,',
            'Since all the happiness of the three realms lasts but a moment,',
            'And then is quickly gone, just like dewdrops on blades of grass.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0125': {
        tibetan: [
            'ཐོག་མེད་དུས་ནས་བདག་ལ་བརྩེ་བ་ཅན། །',
            'མ་རྣམས་སྡུག་ན་རང་བདེས་ཅི་ཞིག་བྱ། །',
            'དེ་ཕྱིར་མཐའ་ཡས་སེམས་ཅན་བསྒྲལ་བྱའི་ཕྱིར། །',
            'བྱང་ཆུབ་སེམས་བསྐྱེད་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to arouse bodhicitta,',
            'So as to bring freedom to all sentient beings, infinite in number.',
            'For how can true happiness ever be found while our mothers,',
            'Who have cared for us throughout the ages, endure such pain?',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0126': {
        tibetan: [
            'སྡུག་བསྔལ་མ་ལུས་བདག་བདེ་འདོད་ལས་བྱུང༌། །',
            'རྫོགས་པའི་སངས་རྒྱས་གཞན་ཕན་སེམས་ལས་འཁྲུངས། །',
            'དེ་ཕྱིར་བདག་བདེ་གཞན་གྱི་སྡུག་བསྔལ་དག །',
            'ཡང་དག་བརྗེ་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to make a genuine exchange',
            "Of one's own happiness and wellbeing for all the sufferings of others.",
            'Since all misery comes from seeking happiness for oneself alone,',
            "Whilst perfect buddhahood is born from the wish for others' good.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0127': {
        tibetan: [
            'སུ་དག་འདོད་ཆེན་དབང་གིས་བདག་གི་ནོར། །',
            'ཐམས་ཅད་འཕྲོག་གམ་འཕྲོག་ཏུ་འཇུག་ན་ཡང༌། །',
            'ལུས་དང་ལོངས་སྤྱོད་དུས་གསུམ་དགེ་བ་རྣམས། །',
            'དེ་ལ་བསྔོ་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even if others, in the grips of great desire, should steal,',
            'Or encourage others to take away, all the wealth that I possess,',
            'To dedicate to them entirely my body, possessions and all my merits',
            'From the past, present and future— this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0128': {
        tibetan: [
            'བདག་ལ་ཉེས་པ་ཅུང་ཟད་མེད་བཞིན་དུ། །',
            'གང་དག་བདག་གི་མགོ་བོ་གཅོད་བྱེད་ན། །',
            'སྙིང་རྗེའི་དབང་གིས་དེ་ཡི་སྡིག་པ་རྣམས། །',
            'བདག་ལ་ལེན་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even if others should seek to cut off my head,',
            "Though I've done them not the slightest wrong,",
            'To take upon myself, out of compassion,',
            'All the harms they have amassed—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0129': {
        tibetan: [
            'འགའ་ཞིག་བདག་ལ་མི་སྙན་སྣ་ཚོགས་པ། །',
            'སྟོང་གསུམ་ཁྱབ་པར་སྒྲོགས་པར་བྱེད་ན་ཡང། །',
            'བྱམས་པའི་སེམས་ཀྱིས་སླར་ཡང་དེ་ཉིད་ཀྱི། །',
            'ཡོན་ཏན་བརྗོད་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even if others should declare before the world',
            'All manner of unpleasant things about me,',
            'To speak only of their qualities in return,',
            "With a mind that's filled with love—this is the practice of all the bodhisattvas.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0130': {
        tibetan: [
            'འགྲོ་མང་འདུས་པའི་དབུས་སུ་འགའ་ཞིག་གིས། །',
            'མཚང་ནས་བྲུས་ཤིང་ཚིག་ངན་སྨྲ་ན་ཡང༌། །',
            'དེ་ལ་དགེ་བའི་བཤེས་ཀྱིས་འདུ་ཤེས་ཀྱིས། །',
            'གུས་པར་འདུད་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even if others should expose my hidden faults or deride me',
            'When speaking amidst great gatherings of many people,',
            'To conceive of them as spiritual friends and to bow',
            'Before them in respect—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0131': {
        tibetan: [
            'བདག་གི་བུ་བཞིན་གཅེས་པར་བསྐྱངས་བའི་མིས། །',
            'བདག་ལ་དགྲ་བཞིན་ལྟ་བར་བྱེད་ན་ཡང༌། །',
            'ནད་ཀྱིས་བཏབ་པའི་བུ་ལ་མ་བཞིན་དུ། །',
            'ལྷག་པར་བརྩེ་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even if others whom I have cared for like children of my own,',
            'Should turn upon me and treat me as an enemy,',
            'To regard them only with special fondness and affection,',
            'As a mother would her ailing child—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0201': {
        tibetan: [
            'རང་དང་མཉམ་པའམ་དམན་པའི་སྐྱེ་བོ་ཡིས། །',
            'ང་རྒྱལ་དབང་གིས་བརྙས་ཐབས་བྱེད་ན་ཡང༌། །',
            'བླ་མ་བཞིན་དུ་གུས་པས་བདག་ཉིད་ཀྱི། །',
            'སྤྱི་བོར་ལེན་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even if others, equal or inferior to me in status,',
            'Should, out of arrogance, disparage me,',
            'To honour them, as I would my teacher,',
            'By bowing down my head before them—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0202': {
        tibetan: [
            'འཚོ་བས་འཕོངས་ཤིང་རྟག་ཏུ་མི་ཡིས་བརྙས། །',
            'ཚབས་ཆེན་ནད་དང་གདོན་གྱིས་བཏབ་ཀྱང་སླར། །',
            'འགྲོ་ཀུན་སྡིག་སྡུག་བདག་ལ་ལེན་བྱེད་ཅིང༌། །',
            'ཞུམ་པ་མེད་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even though I may be destitute and despised by all,',
            'Beset with terrible illness and plagued by evil spirits,',
            "Still to take upon myself all beings' ills and harmful actions,",
            'Without ever losing heart—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0203': {
        tibetan: [
            'སྙན་པར་གྲགས་ཤིང་འགྲོ་མང་སྤྱི་བོས་བཏུད། །',
            'རྣམ་ཐོས་བུ་ཡིས་ནོར་འདྲ་ཐོབ་གྱུར་ཀྱང༌། །',
            'སྲིད་པའི་དཔལ་འབྱོར་སྙིང་པོ་མེད་གཟིགས་ནས། །',
            'ཁེངས་པ་མེད་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'Even though I may be famous and revered by all,',
            'And as rich as Vaiśravaṇa, the god of wealth himself,',
            'To see the futility of all the glory and riches of this world,',
            'And to remain without conceit—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0204': {
        tibetan: [
            'རང་གི་ཞེ་སྡང་དགྲ་བོ་མ་ཐུལ་ན། །',
            'ཕྱི་རོལ་དགྲ་བོ་བཏུལ་ཞིང་འཕེལ་བར་འགྱུར། །',
            'དེ་ཕྱིར་བྱམས་དང་སྙིང་རྗེའི་དམག་དཔུང་གིས། །',
            'རང་རྒྱུད་འདུལ་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to subdue the mind,',
            'With the forces of loving kindness and compassion.',
            'For unless the real adversary—my own anger—is defeated,',
            'Outer enemies, though I may conquer them, will continue to appear.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0205': {
        tibetan: [
            'འདོད་པའི་ཡོན་ཏན་ལན་ཚྭའི་ཆུ་དང་འདྲ། །',
            'ཇི་ཙམ་སྤྱད་ཅིང་སྲེད་པ་འཕེལ་བར་འགྱུར། །',
            'གང་ལ་ཞེན་ཆགས་སྐྱེ་བའི་དངོས་པོ་རྣམས། །',
            'འཕྲལ་ལ་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to turn away immediately',
            'From those things which bring desire and attachment.',
            'For the pleasures of the senses are just like salty water:',
            'The more we taste of them, the more our thirst increases.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0206': {
        tibetan: [
            'ཇི་ལྟར་སྣང་བ་འདི་དག་རང་གི་སེམས། །',
            'སེམས་ཉིད་གདོད་ནས་སྤྲོས་པའི་མཐའ་དང་བྲལ། །',
            'དེ་ཉིད་ཤེས་ནས་གཟུང་འཛིན་མཚན་མ་རྣམས། །',
            'ཡིད་ལ་མི་བྱེད་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is never to entertain concepts,',
            'Which revolve around dualistic notions of perceiver and perceived,',
            'In the knowledge that all these appearances are but the mind itself,',
            "Whilst mind's own nature is forever beyond the limitations of ideas.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0207': {
        tibetan: [
            'ཡིད་དུ་འོང་པའི་ཡུལ་དང་འཕྲད་པ་ན། །',
            'དབྱར་གྱི་དུས་ཀྱི་འཇའ་ཚོན་ཇི་བཞིན་དུ། །',
            'མཛེས་པར་སྣང་ཡང་བདེན་པར་མི་ལྟ་ཞིང༌། །',
            'ཞེན་ཆགས་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to let go of grasping',
            'When encountering things one finds pleasant or attractive,',
            'Considering them to be like rainbows in the summer skies—',
            'Beautiful in appearance, yet in truth devoid of any substance.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0208': {
        tibetan: [
            'སྡུག་བསྔལ་སྣ་ཚོགས་རྨི་ལམ་བུ་ཤི་ལྟར། །',
            'འཁྲུལ་སྣང་བདེན་པར་བཟུང་བས་ཨ་ཐང་ཆད། །',
            'དེའི་ཕྱིར་མི་མཐུན་རྐྱེན་དང་འཕྲད་པའི་ཚེ། །',
            'འཁྲུལ་པར་ལྟ་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to recognize delusion',
            'Whenever one is confronted by adversity or misfortune.',
            'For these sufferings are just like the death of a child in a dream,',
            "And it's so exhausting to cling to delusory perceptions as real.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0209': {
        tibetan: [
            'བྱང་ཆུབ་འདོད་པས་ལུས་ཀྱང་བཏང་དགོས་ན། །',
            'ཕྱི་རོལ་དངོས་པོ་རྣམས་ལ་སྨོས་ཅི་དགོས། །',
            'དེ་ཕྱིར་ལན་དང་རྣམ་སྨིན་མི་རེ་བའི། །',
            'སྦྱིན་པ་གཏོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to give out of generosity,',
            'With no hopes of karmic recompense or expectation of reward.',
            'For if those who seek awakening must give even their own bodies,',
            'What need is there to mention mere outer objects and possessions?',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0210': {
        tibetan: [
            'ཚུལ་ཁྲིམས་མེད་པར་རང་དོན་མི་འགྲུབ་ན། །',
            'གཞན་དོན་སྒྲུབ་པར་འདོད་པ་གད་མོའི་གནས། །',
            'དེ་ཕྱིར་སྲིད་པའི་འདུན་པ་མེད་པ་ཡི། །',
            'ཚུལ་ཁྲིམས་སྲུང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to observe ethical restraint,',
            'Without the slightest intention of continuing in saṃsāric existence.',
            "For lacking discipline one will never secure even one's own wellbeing,",
            'And so any thought of bringing benefit to others would be absurd.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0211': {
        tibetan: [
            'དགེ་བའི་ལོངས་སྤྱོད་འདོད་པའི་རྒྱལ་སྲས་ལ། །',
            'གནོད་བྱེད་ཐམས་ཅད་རིན་ཆེན་གཏེར་དང་མཚུངས། །',
            'དེ་ཕྱིར་ཀུན་ལ་ཞེ་འགྲས་མེད་པ་ཡི། །',
            'བཟོད་པ་སྒོམ་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to cultivate patience,',
            'Free from any trace of animosity towards anyone at all,',
            'Since any potential source of harm is like a priceless treasure',
            'To the bodhisattva who is eager to enjoy a wealth of virtue.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0212': {
        tibetan: [
            'རང་དོན་འབའ་ཞིག་སྒྲུབ་པའི་ཉན་རང་ཡང༌། །',
            'མགོ་ལ་མེ་ཤོར་བཟློག་ལྟར་བརྩོན་མཐོང་ན། །',
            'འགྲོ་ཀུན་དོན་དུ་ཡོན་ཏན་འབྱུང་གནས་ཀྱི། །',
            'བརྩོན་འགྲུས་རྩོམ་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to strive with enthusiastic diligence—',
            'The source of all good qualities—when working for the sake of all who live;',
            'Seeing that even śrāvakas and pratyekabuddhas, who labour for themselves alone,',
            'Exert themselves as if urgently trying to extinguish fires upon their heads.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0213': {
        tibetan: [
            'ཞི་གནས་རབ་ཏུ་ལྡན་པའི་ལྷག་མཐོང་གིས། །',
            'ཉོན་མོངས་རྣམ་པར་འཇོམས་པར་ཤེས་བྱས་ནས། །',
            'གཟུགས་མེད་བཞི་ལས་ཡང་དག་འདའ་བ་ཡི། །',
            'བསམ་གཏན་སྒོམ་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to cultivate concentration,',
            'Which utterly transcends the four formless absorptions,',
            'In the knowledge that mental afflictions are overcome entirely',
            'Through penetrating insight suffused with stable calm.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0214': {
        tibetan: [
            'ཤེས་རབ་མེད་པར་ཕ་རོལ་ཕྱིན་ལྔ་ཡིས། །',
            'རྫོགས་པའི་བྱང་ཆུབ་འཐོབ་པར་མི་ནུས་པས། །',
            'ཐབས་དང་ལྡན་ཞིང་འཁོར་གསུམ་མི་རྟོག་པའི། །',
            'ཤེས་རབ་སྒོམ་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to cultivate wisdom,',
            'Beyond the three conceptual spheres, alongside skilful means,',
            'Since it is not possible to attain the perfect level of awakening',
            "Through the other five pāramitās alone, in wisdom's absence.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0215': {
        tibetan: [
            'རང་གི་འཁྲུལ་པ་རང་གིས་མ་བརྟགས་ན། །',
            'ཆོས་པའི་གཟུགས་ཀྱིས་ཆོས་མིན་བྱེད་སྲིད་པས། །',
            'དེ་ཕྱིར་རྒྱུན་དུ་རང་གི་འཁྲུལ་པ་ལ། །',
            'བརྟགས་ནས་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to scrutinize oneself',
            'Continually and to rid oneself of faults whenever they appear.',
            "For unless one checks carefully to find one's own confusion,",
            'One might appear to be practising Dharma, but act against it.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0216': {
        tibetan: [
            'ཉོན་མོངས་དབང་གིས་རྒྱལ་སྲས་གཞན་དག་གིས། །',
            'ཉེས་པ་གླེང་ན་བདག་ཉིད་ཉམས་འགྱུར་བས། །',
            'ཐེག་པ་ཆེ་ལ་ཞུགས་པའི་གང་ཟག་གིས། །',
            'ཉེས་པ་མི་སྨྲ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is never to speak ill',
            'Of others who have embarked upon the greater vehicle,',
            'For if, under the influence of destructive emotions,',
            "I speak of other bodhisattvas' failings, it is I who am at fault.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0217': {
        tibetan: [
            'རྙེད་བཀུར་དབང་གིས་ཕན་ཚུན་རྩོད་འགྱུར་ཞིང༌། །',
            'ཐོས་བསམ་སྒོམ་པའི་བྱ་བ་ཉམས་འགྱུར་བས། །',
            'མཛའ་བཤེས་ཁྱིམ་དང་སྦྱིན་བདག་ཁྱིམ་རྣམས་ལ། །',
            'ཆགས་པ་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to let go of attachment',
            'To the households of benefactors and of family and friends,',
            "Since one's study, reflection and meditation will all diminish",
            'When one quarrels and competes for honours and rewards.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0218': {
        tibetan: [
            'རྩུབ་མོའི་ཚིག་གིས་གཞན་སེམས་འཁྲུག་འགྱུར་ཞིང༌། །',
            'རྒྱལ་བའི་སྲས་ཀྱི་སྤྱོད་ཚུལ་ཉམས་འགྱུར་བས། །',
            'དེ་ཕྱིར་གཞན་གྱི་ཡིད་དུ་མི་འོང་བའི། །',
            'ཚིག་རྩུབ་སྤོང་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to avoid harsh words,',
            'Which others might find unpleasant or distasteful,',
            'Since abusive language upsets the minds of others,',
            "And thereby undermines a bodhisattva's conduct.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0219': {
        tibetan: [
            'ཉོན་མོངས་གོམས་ན་གཉེན་པོས་ཟློག་དཀའ་བས། །',
            'དྲན་ཤེས་སྐྱེས་བུས་གཉེན་པོའི་མཚོན་བཟུང་ནས། །',
            'ཆགས་སོགས་ཉོན་མོངས་དང་པོ་སྐྱེས་མ་ཐག །',
            'འབུར་འཇོམས་བྱེད་པར་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to slay attachment',
            "And the rest—mind's afflictions—at once, the very moment they arise,",
            'Taking as weapons the remedies held with mindfulness and vigilance.',
            "For once the kleshas have become familiar, they'll be harder to avert.",
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0220': {
        tibetan: [
            'མདོར་ན་གང་དུ་སྤྱོད་ལམ་ཅི་བྱེད་ཀྱང༌། །',
            'རང་གི་སེམས་ཀྱི་གནས་སྐབས་ཅི་འདྲ་ཞེས། །',
            'རྒྱུན་དུ་དྲན་དང་ཤེས་བཞིན་ལྡན་པ་ཡིས། །',
            'གཞན་དོན་སྒྲུབ་པ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'In short, no matter what one might be doing,',
            "By examining always the status of one's mind,",
            'With continuous mindfulness and alertness,',
            'To bring about the good of others—this is the practice of all the bodhisattvas.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0221': {
        tibetan: [
            'དེ་ལྟར་བརྩོན་པས་བསྒྲུབ་པའི་དགེ་བ་རྣམས། །',
            'མཐའ་ཡས་འགྲོ་བའི་སྡུག་བསྔལ་བསལ་བྱའི་ཕྱིར། །',
            'འཁོར་གསུམ་རྣམ་པར་དག་པའི་ཤེས་རབ་ཀྱིས། །',
            'བྱང་ཆུབ་བསྔོ་བ་རྒྱལ་སྲས་ལག་ལེན་ཡིན། །',
        ],
        english: [
            'The practice of all the bodhisattvas is to dedicate towards enlightenment',
            'All the virtue to be gained through making effort in these ways,',
            'With wisdom that is purified entirely of the three conceptual spheres,',
            'So as to dispel the sufferings of the infinity of beings.',
        ],
        title: {
            tibetan: 'རྒྱལ་སྲས་ལག་ལེན་སོ་བདུན་མ།',
            english: 'The Thirty-Seven Practices of All the Bodhisattvas',
        },
        author: {
            tibetan: 'རྒྱལ་སྲས་ཐོགས་མེད་བཟང་པོ།',
            english: 'Gyalsé Tokmé Zangpo',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gyalse-thogme-zangpo/37-practices-all-bodhisattvas',
        },
        isPartial: true,
    },
    '0222': {
        tibetan: [
            'ཐེ་ཚོམ་ཐམས་ཅད་རྣམ་སྤངས་ཤིང༌། །',
            'སྒྲུབ་ལ་ནན་ཏན་གཅེས་སུ་བྱ། །',
            'གཉིད་རྨུགས་ལེ་ལོ་སྤང་བྱ་ཞིང༌། །',
            'རྟག་ཏུ་བརྩོན་འགྲུས་འབད་པར་བྱ། །',
        ],
        english: [
            'Be done with doubt and indecision,',
            'And embrace your practice with all your heart.',
            'Shake off lethargy, dullness and laziness,',
            'And strive always with enthusiasm and joy.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0223': {
        tibetan: [
            'དྲན་དང་ཤེས་བཞིན་བག་ཡོད་པས། །',
            'དབང་པོའི་སྒོ་རྣམས་རྟག་ཏུ་སྲུངས། །',
            'ཉིན་མཚན་དུས་གསུམ་ཡང་དང་ཡང༌། །',
            'སེམས་ཀྱི་རྒྱུད་ལ་བརྟག་པར་བྱ། །',
        ],
        english: [
            'Mindful, vigilant and careful,',
            'Guard the doorways of your senses at every moment.',
            'Three times each day, three times at night,',
            'Again and again, examine your thoughts.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0224': {
        tibetan: [
            'བདག་གི་ཉེས་པ་བསྒྲག་བྱ་ཞིང༌། །',
            'གཞན་གྱི་འཁྲུལ་པ་བཙལ་མི་བྱ། །',
            'གཞན་གྱི་ཡོན་ཏན་བསྒྲག་བྱ་ཤིང་། །',
            'བདག་གི་ཡོན་ཏན་སྦ་བར་བྱ། །',
        ],
        english: [
            'Make plain your own failings,',
            "But don't look for faults in others.",
            'Make known the good points of others,',
            'But keep quiet about your own best qualities.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0225': {
        tibetan: ['རྙེད་དང་བཀུར་སྟི་སྤང་བར་བྱ། །', 'ཁེ་གྲགས་རྟག་ཏུ་སྤང་བར་བྱ། །'],
        english: ['Let go of craving for gain and honour,', 'And give up the urge for profit or fame.'],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0226': {
        tibetan: [
            'བྱམས་དང་སྙིང་རྗེ་སྒོམ་བྱ་ཞིང༌། །',
            'བྱང་ཆུབ་སེམས་ནི་བརྟན་པར་བྱ། །',
            'མི་དགེ་བཅུ་པོ་སྤང་བྱ་ཞིང༌། །',
            'རྟག་ཏུ་དད་པ་བརྟན་པར་བྱ། །',
        ],
        english: [
            'Cultivate love and compassion,',
            'And make your bodhicitta stable.',
            'Avoid the ten unwholesome actions,',
            'And make your faith and confidence be strong.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0227': {
        tibetan: ['འདོད་པ་ཆུང་ཞིང་ཆོག་ཤེས་དང༌། །', 'བྱས་པ་དྲིན་དུ་གཟོ་བར་བྱ། །'],
        english: [
            'With few wants, be content with what you have,',
            'And with gratitude repay any kindness you receive.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0228': {
        tibetan: [
            'ཁྲོ་དང་ང་རྒྱལ་གཞོམ་བྱ་ཞིང༌། །',
            'དམན་པའི་སེམས་ཀྱང་ལྡན་པར་བྱ། །',
            'ལོག་པས་འཚོ་བ་རྣམས་སྤང་ཞིང༌། །',
            'ཆོས་ཀྱི་འཚོ་བས་འཚོ་བར་བྱ། །',
        ],
        english: [
            'Overcome anger and arrogance,',
            'And let humility rule your mind.',
            'Give up any unwholesome kind of living,',
            'And pursue a livelihood in keeping with the Dharma.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0301': {
        tibetan: ['ཟང་ཟིང་ཐམས་ཅད་རྣམ་སྤང་ཞིང༌། །', 'འཕགས་པའི་ནོར་གྱིས་རྒྱན་པར་བྱ། །'],
        english: [
            'Do away with your addiction to material things,',
            'And adorn yourself with the riches of the Āryas.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0302': {
        tibetan: [
            'དད་པའི་ནོར་དང་ཚུལ་ཁྲིམས་ནོར། །',
            'ཁྲེལ་ཡོད་ངོ་ཚ་ཤེས་པའི་ནོར། །',
            'ཐོས་པའི་ནོར་དང་གཏོང་བའི་ནོར། །',
            'ཤེས་རབ་ཉིད་ཀྱི་ནོར་བདུན་ནོ། །',
            'ནོར་གྱི་རྣམ་པ་འདི་དག་ནི། །',
            'མི་ཟད་པའི་གཏེར་བདུན་ཏེ། །',
            'མི་མ་ཡིན་ལ་བརྗོད་མི་བྱ། །',
        ],
        english: [
            'The wealth of faith, of discipline,',
            'Generosity and learning,',
            'Decency, self-control,',
            'And wisdom—such are the seven riches.',
            'These most sacred forms of wealth',
            'Are seven treasures that never run out.',
            'Do not speak of this to those who are not human.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0303': {
        tibetan: ['རྟག་ཏུ་འདུ་འཛི་སྤང་བྱ་ཞིང༌། །', 'དགོན་པ་ལ་ནི་གནས་པར་བྱ།'],
        english: ['Leave all busyness and distraction behind,', 'And dwell instead in seclusion and solitude.'],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0304': {
        tibetan: ['འཁྱལ་པའི་ཚིག་ནི་སྤང་བྱ་ཞིང༌། །', 'ངག་རྣམས་རྟག་ཏུ་བསྡམ་པར་བྱ། །'],
        english: ['Refrain from meaningless chatter,', 'And always keep a check on what you say.'],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0305': {
        tibetan: [
            'བླ་མ་མཁན་པོ་མཐོང་བའི་ཚེ། །',
            'གུས་པར་རིམ་གྲོ་བསྐྱེད་པར་བྱ། །',
            'གང་ཟག་ཆོས་ཀྱི་མིག་ཅན་དང༌། །',
            'ལས་དང་པོ་པའི་སེམས་ཅན་ལ། །',
            'སྟོན་པའི་འདུ་ཤེས་བསྐྱེད་པར་བྱ། །',
        ],
        english: [
            'Whenever you see your master or preceptor,',
            'Offer to serve them with devotion and respect.',
            'Those who possess enlightened vision',
            'And those first setting out upon the path—',
            'Regard them both as your spiritual teachers.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0306': {
        tibetan: [
            'སེམས་ཅན་ཐམས་ཅད་མཐོང་བའི་ཚེ། །',
            'ཕ་མ་བུ་ཚའི་འདུ་ཤེས་བསྐྱེད། །',
            'སྡིག་པའི་གྲོགས་པོ་སྤང་བྱ་ཞིང༌། །',
            'དགེ་བའི་བཤེས་གཉེན་བསྟེན་པར་བྱ། །',
        ],
        english: [
            'Whenever you see any sentient beings,',
            'Regard them as your parents or your children.',
            "Don't befriend those who act in harmful ways;",
            'Instead rely on true spiritual friends.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0307': {
        tibetan: ['སྡང་དང་མི་བདེའི་ས་སྤང་ཞིང༌། །', 'གང་དུ་བདེ་བར་འགྲོ་བར་བྱ། །'],
        english: ['Drop any feelings of hostility or ill will,', 'And be happy, wherever you choose to go.'],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0308': {
        tibetan: [
            'གང་ལ་ཆགས་པ་སྤང་བྱ་ཞིང༌། །',
            'ཆགས་པ་མེད་པར་གནས་པར་བྱ། །',
            'ཆགས་པས་བདེ་འགྲོ་མི་འཐོབ་ཅིང༌། །',
            'ཐར་པའི་སྲོག་ཀྱང་གཅོད་པར་བྱེད། །',
        ],
        english: [
            'Avoid getting attached to anything at all,',
            'And stay free from craving and desire.',
            'Attachment not only keeps you from happy births,',
            'It kills the very life of liberation.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0309': {
        tibetan: [
            'གང་དུ་དགེ་བའི་ཆོས་མཐོང་བ། །',
            'དེར་ནི་རྟག་ཏུ་འབད་པར་བྱ། །',
            'ཐོག་མ་བརྩམས་པ་གང་ཡིན་པ། །',
            'དང་པོ་ཉིད་ཏུ་བསྒྲུབ་བྱ་སྟེ། །',
            'དེ་ལྟ་ན་ནི་ཀུན་ལེགས་བྱས། །',
            'གཞན་དུ་གཉིས་ཀ་འགྲུབ་མི་འགྱུར། །',
        ],
        english: [
            'Should you find a way to peace and happiness,',
            'Strive constantly to put it into practice.',
            'Whatever task you set out to do,',
            'Accomplish that very thing first.',
            'This way, everything will turn out well;',
            'If not, nothing will succeed.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0310': {
        tibetan: [
            'རྟག་ཏུ་སྡིག་ལ་དགའ་བྲལ་བྱ། །',
            'གང་དུ་མཐོ་བའི་སེམས་བྱུང་ཚེ། །',
            'དེ་ཚེ་ང་རྒྱལ་བཅག་བྱ་ཞིང༌། །',
            'བྲམ་ཟེའི་གདམས་ངག་དྲན་པར་བྱ། །',
            'ཞུམ་པའི་སེམས་ནི་བྱུང་བའི་ཚེ། །',
            'སེམས་ཀྱི་གཟེང་ནི་སྟོད་པར་བྱ། །',
            'རྟག་ཏུ་སྟོང་ཉིད་བསྒོམ་པར་བྱ། །',
        ],
        english: [
            'Never take pleasure in acts that harm.',
            'And when thoughts of superiority creep in,',
            'There and then, deflate your self-importance,',
            "And recall your master's personal advice.",
            'Or whenever you feel discouraged or inadequate,',
            'Raise your spirits and encourage yourself.',
            'And always meditate on emptiness.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0311': {
        tibetan: [
            'གང་དུ་ཆགས་སྡང་ཡུལ་བྱུང་དེར། །',
            'སྒྱུ་མ་སྤྲུལ་པ་བཞིན་དུ་བལྟ། །',
            'མི་སྙན་ཚིག་རྣམས་ཐོས་པའི་ཚེ། །',
            'བྲག་ཆ་བཞིན་དུ་ལྟ་བར་བྱ། །',
            'ལུས་ལ་གནོད་པ་བྱུང་བའི་ཚེ། །',
            'སྔོན་གྱི་ལས་སུ་བལྟ་བར་བྱ། །',
        ],
        english: [
            'Should the objects of attachment or aversion appear,',
            'View them as no more than illusions or projections.',
            'Should you hear unpleasant words,',
            'Consider them nothing more than echoes.',
            'Should you suffer physical harm,',
            'See it as the result of your past actions.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0312': {
        tibetan: [
            'བས་མཐའ་དགོན་པར་རབ་གནས་ཤིང༌། །',
            'རི་དྭགས་ཤི་བའི་རོ་བཞིན་དུ། །',
            'བདག་གིས་བདག་ནི་སྦ་བྱ་ཞིང༌། །',
            'ཆགས་པ་མེད་པར་གནས་པར་བྱ། །',
        ],
        english: [
            'Keep entirely to solitude, far away from town,',
            'And, like the carcass of some wild animal,',
            'Stay hidden in nature by yourself,',
            'Free of all entanglement and attachment.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0313': {
        tibetan: [
            'རྟག་ཏུ་ཡི་དམ་བརྟན་བྱ་ཞིང༌། །',
            'ལེ་ལོ་རྙོག་པའི་སེམས་བྱུང་ཚེ། །',
            'དེ་ཚེ་བདག་ལ་རྔན་བགྲང་ཞིང༌། །',
            'བརྟུལ་ཞུགས་སྙིང་པོ་དྲན་པར་བྱ། །',
        ],
        english: [
            'Always keep up your pledges and commitments,',
            'And should laziness or procrastination strike,',
            'Immediately take note of your errors, one by one,',
            'And remind yourself of the heart of your discipline.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0314': {
        tibetan: [
            'གལ་ཏེ་གཞན་དག་མཐོང་བའི་ཚེ། །',
            'ཞི་དེས་གསོང་པོར་སྨྲ་བ་དང༌། །',
            'ཁྲོ་གཉེར་ངོ་འཛུམ་སྤང་བྱ་ཞིང༌། །',
            'རྟག་ཏུ་འཛུམ་ཤིང་གནས་པར་བྱ། །',
        ],
        english: [
            'Whenever you meet another person,',
            'Speak calmly, sincerely and truthfully.',
            'Take care not to frown or glare,',
            'And always wear a cheerful smile.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0315': {
        tibetan: ['རྟག་ཏུ་གཞན་དག་མཐོང་བའི་ཚེ། །', 'སེར་སྣ་མེད་ཅིང་སྦྱིན་ལ་དགའ། །', 'རྟག་ཏུ་ཕྲག་དོག་སྤང་བར་བྱ། །'],
        english: [
            "And when you're with those you see every day,",
            "Don't be stingy, but be happy to give,",
            'And banish all feelings of envy.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0316': {
        tibetan: ['གཞན་གྱི་སེམས་ནི་བསྲུང་བའི་ཕྱིར། །', 'རྩོད་པ་ཐམས་ཅད་སྤང་བྱ་ཞིང༌། །', 'རྟག་ཏུ་བཟོད་དང་ལྡན་པར་བྱ། །'],
        english: [
            "So as to protect others' peace of mind,",
            'Stay clear of quarrels of any kind,',
            'And be patient and always forbearing.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0317': {
        tibetan: [
            'ངོར་དགའ་མེད་ཅིང་གསར་འགྲོགས་མེད། །',
            'རྟག་ཏུ་ཟུངས་ནི་ཐུབ་པར་བྱ། །',
            'གཞན་ལ་བརྙས་པ་སྤང་བར་བྱ། །',
            'གུས་པའི་ཚུལ་གྱིས་གནས་པར་བྱ། །',
        ],
        english: [
            "Don't flatter, or be a fickle friend,",
            'But be steadfast and reliable all the time.',
            'Never disparage or belittle other people,',
            'But treat everyone with respect.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0318': {
        tibetan: [
            'གཞན་ལ་གདམ་ངག་བྱེད་པའི་ཚེ། །',
            'སྙིང་རྗེ་ཕན་སེམས་ལྡན་པར་བྱ། །',
            'ཆོས་ལ་སྐུར་པ་གདབ་མི་བྱ། །',
            'གང་མོས་འདི་ལ་འདུན་པར་བྱ། །',
            'ཆོས་སྤྱོད་རྣམ་བཅུའི་སྒོ་ནས་ནི། །',
            'ཉིན་མཚན་མེད་པར་འབད་པར་བྱ། །',
        ],
        english: [
            'When giving advice or instructions,',
            'Do so with compassion and a genuine wish to help.',
            'Be sure never to criticize the teachings.',
            'Set your sights on what inspires you most,',
            'And, through the ten forms of Dharma practice,',
            'Exert yourself in sessions, day and night.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0319': {
        tibetan: ['མང་པོའི་ནང་དུ་ངག་ལ་བརྟག །', 'གཅིག་པུ་འདུག་ན་སེམས་ལ་བརྟག །'],
        english: ['Among others, keep a check on your speech;', 'When alone, keep a check on your mind.'],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0320': {
        tibetan: [
            'དུས་གསུམ་དགེ་རྩ་ཅི་བསགས་པ། །',
            'བླ་མེད་བྱང་ཆུབ་ཆེན་པོར་བསྔོ། །',
            'བསོད་ནམས་སེམས་ཅན་རྣམས་ལ་བརྡར། །',
            'རྟག་ཏུ་ཡན་ལག་བདུན་པ་ཡི། །',
            'སྨོན་ལམ་ཆེན་པོ་གདབ་པར་བྱ། །',
        ],
        english: [
            'Whatever virtues you amass in the past, present and future,',
            'Dedicate them all towards great, unsurpassable awakening,',
            'Share your merit among all sentient beings,',
            'And with the seven branch practice,',
            'Continually make great prayers of aspiration.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0321': {
        tibetan: [
            'དེ་ལྟར་བྱས་ན་བསོད་ནམས་དང༌། །',
            'ཚོགས་ཆེན་གཉིས་ཀྱང་རྫོགས་པར་འགྱུར། །',
            'སྒྲིབ་པ་གཉིས་ཀྱང་ཟད་པར་འགྱུར། །',
            'མི་ལུས་ཐོབ་པ་དོན་ཡོད་པས། །',
            'བླ་མེད་བྱང་ཆུབ་ཐོབ་པར་འགྱུར། །',
        ],
        english: [
            'Practising like this, you will complete',
            'Accumulations of both merit and wisdom,',
            'And eliminate the two forms of obscuration.',
            'You will make this human life meaningful,',
            'And, in time, gain unsurpassable awakening.',
        ],
        title: {
            tibetan: 'བྱང་སེམས་ནོར་བུའི་འཕྲེང་བ།',
            english: "The Bodhisattva's Garland of Jewels",
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/atisha/jewel-rosary-bodhisattvas',
        },
        isPartial: true,
    },
    '0322': {
        tibetan: ['དད་པ་ཅན་གྱི་གང་ཟག་ཕོ་མོ་ལ། །', 'པད་འབྱུང་གང་དུའང་མ་བཞུད་སྒོ་ཉལ་ཡོད། །'],
        english: [
            'For anyone, man or woman, who has faith in me,',
            'I, the Lotus Born, have never departed—I sleep on their threshold.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'གུ་རུ་རིན་པོ་ཆེ།', english: 'Guru Padmasambhava' },
        links: { lh: '' },
        isPartial: '',
    },
    '0323': {
        tibetan: ['རང་ཚུགས་མ་ཤོར། གཞན་སེམས་མ་དཀྲུགས།'],
        english: ['Do not lose your own integrity. Do not distrurb the minds of others.'],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'འཇིགས་མེད་ཕུན་ཚོགས་རིན་པོ་ཆེ།',
            english: 'Jigmé Phuntsok Rinpoche',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0324': {
        tibetan: [
            'གདམས་ངག་བཟང་པོའི་ཚིག་འདི་ལ། །',
            'བལྟ་ཞིན་དེའི་དོན་བསམ་པ་དང་། །',
            'བསམས་ནས་དེ་ལ་ཡིད་ཆེས་པས། །',
            'དེ་ཡི་དོན་བཞིན་སྤྱད་པར་བྱ། །',
        ],
        english: [
            'Read and reflect upon the meaning',
            'Of the words of wonderful instructions.',
            'Through contemplation, come to gain trust',
            'And thereby act in accordance with their truth.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoché' },
        links: { lh: '' },
        isPartial: '',
    },
    '0325': {
        tibetan: [
            'འདས་པའི་སེམས་ཀྱང་དམིགས་སུ་མེད།',
            'མ་འོངས་པའི་སེམས་ཀྱང་དམིགས་སུ་མེད།',
            'ད་ལྟར་བྱུང་བའི་སེམས་ཀྱང་དམིགས་སུ་མེད།',
        ],
        english: [
            'The mind of the past cannot be observed.',
            'The mind of the future also cannot be observed.',
            'The mind that is here at present likewise cannot be observed.',
        ],
        title: {
            tibetan: 'མདོ་རྡོ་རྗེ་གཅོད་པ།',
            english: 'The Diamond Cutter Sūtra',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0326': {
        tibetan: [
            'ཐོས་མང་ཉམས་སུ་མ་བླངས་ན། །',
            'རང་གི་རྒྱུད་ལ་མི་ཕན་ཏེ། །',
            'ཆུ་ནང་ལོ་བརྒྱར་གནས་པ་ཡི། །',
            'རྡོ་བའི་རང་བཞིན་སྐམ་པོར་གནས། །',
        ],
        english: [
            'If the learned do not engage in practice',
            'Their will be no benefit to their minds.',
            'Though it stays underwater for hundreds of years',
            'The nature of the stone remains dry.',
        ],
        title: {
            tibetan: 'ཆུ་ཤིང་བསྟན་བཅོས།',
            english: 'The Treatise of Water and Wood',
        },
        author: {
            tibetan: 'གུང་ཐང་བསྟན་པའི་སྒྲོན་མེ།',
            english: 'Gungtang Tenpé Drönmé',
        },
        links: { lh: '' },
        isPartial: true,
    },
    '0327': {
        tibetan: [
            'འདུ་འཛི་ཀུན་སྤང་བར་གྱིས། །',
            'གལ་ཏེ་དེ་སྤོང་མ་ནུས་ན། །',
            'དམ་པ་དག་དང་འདྲེ་བར་གྱིས། །',
            'དེ་ཉིད་དེ་ཡི་སྨན་ཡིན་ནོ། །',
        ],
        english: [
            'Abandon busyness in its entirety.',
            'Yet if you are unable to do so,',
            'Associate with sublime beings.',
            'They act as medicine to busyness.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoché' },
        links: { lh: '' },
        isPartial: '',
    },
    '0328': {
        tibetan: [
            'ཆོས་ཀུན་ཅིག་ཅར་མི་ཤེས་ཀྱང་། །',
            'ཅི་ཙམ་ཤེས་བཞིན་དོན་དུ་འགྱུར། །',
            'ཆུ་ཀླུང་རྫོགས་པར་མ་འཐུགས་ཀྱང་། །',
            'ཅི་ཙམ་བཏུངས་པས་སྐོམ་པ་སེལ། །',
        ],
        english: [
            'Though you may not know the Dharma instantaneously,',
            'However much you know will be of benefit.',
            'Though you may not drink the river in its entirety,',
            'However much you drink will dispel your thirst.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'གུང་ཐང་བསྟན་པའི་སྒྲོན་མེ།',
            english: 'Gungtang Tenpé Drönmé',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0329': {
        tibetan: [
            'ཕྱུག་པོ་ཕྱུག་པོའི་ས་ནས་སྡུག་གཏམ་བྱེད། །',
            'དབུལ་བོ་དབུལ་བོའི་ས་ནས་སྨྲེ་སྔགས་འདོན། །',
            'མི་རེ་སེམས་ལ་སྡུག་བསྔལ་ཁུར་བོ་རེ། །',
            'འཁོར་བའི་གནས་ན་བདེ་བའི་སྐབས་མི་མཆིས། །',
        ],
        english: [
            'The rich complain about being rich.',
            'The poor lament about being poor.',
            "In everyone's mind is a burden of suffering.",
            'There is no chance for any happiness in saṃsāra.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'དགེ་འདུན་ཆོས་འཕེལ།', english: 'Gendün Chöphel' },
        links: { lh: '' },
        isPartial: '',
    },
    '0330': {
        tibetan: [
            'ཁྱད་པར་ལྷ་དང་བླ་མ་དབྱེར་མེད་ལ། །',
            'མོས་གུས་ཤུགས་དྲག་བློ་སེམས་གཅིག་དྲིལ་གྱིས། །',
            'ཚིག་ཙམ་མིན་པར་གསོལ་བ་ལེགས་བཏབ་ན། །',
            'ཅི་བྱས་ལམ་དུ་འགྲོ་བ་གདོན་མི་ཟ། །',
        ],
        english: [
            'Above all, wholeheartedly bring your mind',
            'To the yidam and guru inseparable with fierce devotion.',
            "If you don't simply pay lip-service but supplicate well,",
            'Have no doubt that whatever you do will be successful.',
        ],
        title: {
            tibetan: 'བསླབ་བྱ་གཞན་ཕན་བདུད་རྩི་སྙིང་པོ།',
            english: 'The Universal Panacea of Benefitting Others',
        },
        author: {
            tibetan: 'ལྕང་སྐྱ་རོལ་པའི་རྡོ་རྗེ།',
            english: 'Changkya Rolpé Dorjé',
        },
        links: { lh: '' },
        isPartial: true,
    },
    '0331': {
        tibetan: [
            'གཞུང་དང་ཞལ་གདམས་ཟིན་བྲིས་ལ་སོགས་པ། །',
            'ཟབ་མོའི་དཔེ་ཆ་མཁོ་དགུ་འཛོམས་ན་ཡང་། །',
            'ཉམས་ལེན་མ་བྱས་འཆི་ཚེ་དཔེས་མི་ཕན། །',
            'སེམས་ལ་བལྟ་བ་ཁོ་བོའི་སྙིང་གཏམ་ཡིན། །',
        ],
        english: [
            'Though you may have amassed a multitude of profound texts—a',
            'Scriptural treatises, oral instructions, commentaries, and the like—',
            'If you do not practice them, books will be of no help at the time of death.',
            'To watch your mind is my heart advice.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'ཀློང་ཆེན་པ།', english: 'Longchenpa' },
        links: { lh: '' },
        isPartial: '',
    },
    '0401': {
        tibetan: [
            'གང་ཞིག་རྐྱེན་ལས་སྐྱེས་པས་དེ་མ་སྐྱེས། །',
            'དེ་ལ་སྐྱེ་བའི་རང་བཞིན་ཡོད་མ་ཡིན། །',
            'རྐྱེན་ལ་རག་ལས་གང་དེ་སྟོང་ཉིད་དེ། །',
            'གང་གིས་སྟོང་ཉིད་ཤེས་ཏེ་བག་ཡོད་ཡིན། །',
        ],
        english: [
            'Whatever arises from conditions is unarisen,',
            'For it has not the nature of arising.',
            'Whatever depends upon conditions is empty.',
            'He who knows emptiness is at ease.',
        ],
        title: {
            tibetan: 'མ་དྲོས་པས་ཞུས་པའི་མདོ།',
            english: 'The Sūtra of the Questions of the Nāga King Anavatapta',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0402': {
        tibetan: [
            'ས་འདི་མ་རབས་ཀྱིས་གང་ཡང་། །',
            'རང་ཉིད་ཡ་རབས་སྤྱོད་པར་བྱ། །',
            'དེ་ལྟ་ན་ནི་ཆོས་ཉིད་ཀྱིས། །',
            'རང་ལ་ཕུན་སུམ་ཚོགས་པར་འགྱུར། །',
        ],
        english: [
            'Even if the earth is filled with the ignoble',
            'You should nevertheless act nobly.',
            'By doing so, it is the nature of things',
            'For things to turn out perfectly for you.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoché' },
        links: { lh: '' },
        isPartial: '',
    },
    '0403': {
        tibetan: [
            'སྐྱོན་དང་ཡོན་ཏན་ཡོགས་ཤེས་པ། །',
            'དེ་དག་མཁས་པའི་མཚན་ཉིད་ཡིན། །',
            'སྐྱོན་ཡོན་ཡོངས་སུ་མི་ཤེས་པ། །',
            'དེ་དག་བླུན་པོའི་བྱེད་པ་ཡིན། །',
        ],
        english: [
            'To understand negative and positive qualities',
            'Is the mark of the wise.',
            'To lack knowledge of these qualities',
            'Is the sign of a fool.',
        ],
        title: {
            tibetan: 'མདོ་དྲན་པ་ཉེར་བཞག',
            english: 'The Sūtra on the Application of Mindfulness',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0404': {
        tibetan: ['བྱིས་པའི་བློ་ཅན་མཚན་མར་འཛིན་པ་རྣམས། །', 'འཇིག་རྟེན་ན་ནི་མེད་པའི་ཆོས་ལ་སྤྱོད། །'],
        english: [
            'Those with childish mindsets who grasp to characteristics',
            'Are engaged with the non-existent phenomena of this world.',
        ],
        title: {
            tibetan: 'ཡེ་ཤེས་སྣང་བ་རྒྱན་གྱི་མདོ།',
            english: 'The Ornament of the Light of Awareness',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0405': {
        tibetan: [
            'འཇིག་རྟེན་སེམས་ཀྱིས་བྲིས་པ་སྟེ། །',
            'སེམས་ནི་སེམས་ཀྱིས་མི་མཐོང་ངོ་། །',
            'དགེ་བའམ་འོན་ཏེ་མི་དགེ་བ། །',
            'ལས་ནི་སེམས་ཀྱིས་བསགས་པ་ཡིན། །',
        ],
        english: [
            'This world is but the painting of mind.',
            'And yet mind cannot be seen by mind.',
            'Whether it be virtue or non-virtue',
            'It is the mind that accumulates karma.',
        ],
        title: { tibetan: 'དཀོན་མཆོག་སྤྲིན།', english: 'Ratnamegha' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: '',
    },
    '0406': {
        tibetan: [
            'སྐྱིད་ན་མི་དགའ་སྡུག་ན་དགའ། །',
            'སྐྱིད་ན་ཉོན་མོངས་དུག་ལྔ་འབར། །',
            'སྡུག་ན་སྔོན་བསགས་ལས་ངང་འཛད། །',
            'སྡུག་བསྔལ་བླ་མའི་ཐུགས་རྗེ་ཡིན། །',
        ],
        english: [
            'It is better to be sad than it is to be happy;',
            "For the afflictions and five poison flare up when you're happy",
            "Whereas previously accumulated karma gets exhausted when you're sad.",
            'The experience of suffering is the compassion of the guru.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'རྫ་དཔལ་སྤྲུལ་རིན་པོ་ཆེ།',
            english: 'Dza Patrul Rinpoche',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0407': {
        tibetan: [
            'དུས་ཀྱི་བསྙེན་ནས་རྒྱལ་པོ་འགྲོ་འགྱུར་ན། །',
            'ལོགས་སྤྱོད་མཛའ་དང་གཉེན་བཤེས་རྗེས་མི་འབྲང་། །',
            'སྐྱེས་བུ་དག་ནི་གང་ནས་གར་འགྲོ་ཡང་། །',
            'ལས་ནི་གྲིབ་མ་བཞིན་དུ་རྗེས་སུ་འབྲང་། །',
        ],
        english: [
            'As the time approaches, o king, when you are to pass away,',
            'Your possessions, wives, and friends will not follow.',
            'No matter where beings may journey,',
            'Karma fallows them just like a shadow.',
        ],
        title: {
            tibetan: 'རྒྱལ་པོ་ལ་གདམས་པའི་མདོ།',
            english: 'The Sūtra on Advice to the King',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0408': {
        tibetan: [
            'ཅི་ནས་རང་གི་སེམས་ལ་མི་ཕན་པའི། །',
            'ཆོས་དེ་ཁ་ཆོས་ཡིན་གྱི་དོན་ཆོས་མིན། །',
            'རང་གི་སེམས་ལ་ཁྱད་པར་མི་འདུག་ན། །',
            'ལོ་བརྒྱ་མཚམས་བསྒྲུབ་བྱས་ཀྱང་ངལ་བའི་རྒྱུ། །',
        ],
        english: [
            "A practice that doesn't benefit your mind in the slightest",
            'Is a practice of lip-service, not a genuine practice.',
            "If it doesn't bring any change to your own mind,",
            'Then even a hundred-year retreat is just a cause for exhausation.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'རྫ་དཔལ་སྤྲུལ་རིན་པོ་ཆེ།',
            english: 'Dza Patrul Rinpoche',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0409': {
        tibetan: [
            'རིགས་ལམ་ཕྲ་མོ་འབྱེད་པའི་རྣམ་དཔྱོད་དང་། །',
            'གཞུང་ལུགས་གདམས་པར་ཤར་བའི་ཉམས་ལེན་དང་། །',
            'ཚིག་སྦྱོར་ཚུལ་ལ་མཁས་པའི་ངག་གི་དཔལ། །',
            'ས་སྟེང་རིན་ཆེན་རྣམ་གསུམ་སྣང་། །',
        ],
        english: [
            'Intellect that discerns even the subtlest of reasonsings,',
            'Practice in which the scriptures dawn as instructions,',
            'And eloquent speech that is versed in the art of expression—',
            'These are the three gems to be found upon this earth.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'རྗེ་ཙོང་ཁ་པ།', english: 'Jé Tsongkhapa' },
        links: { lh: '' },
        isPartial: '',
    },
    '0410': {
        tibetan: [
            'བཟོད་པ་ཚིག་ངན་བྱུང་དུས་སྒོམས། །',
            'སྦྱིན་པ་ཟས་ནོར་དོས་དུས་ཐོངས། །',
            'ཕྱག་སྐོར་སྒྱུ་ལུས་བདེ་དུས་གྱིས། །',
            'གདམས་ངག་བློ་རིག་གསལ་དུས་སྒོམས། །',
        ],
        english: [
            'Patience is to be cultivate when you encounter nasty words.',
            'Generosity is to be practice when you have food and wealth.',
            'Prostrations and kora is to be done when your illusory body is healthy.',
            'The instructions are to be contemplated when your intellect is clear.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'ཞབས་དཀར་ཚོགས་དྲུག་རང་གྲོལ།',
            english: 'Shabkar Tsokdruk Rangdrol',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0411': {
        tibetan: [
            'སྡིག་པ་ཆུང་ཡང་དུག་བཞིན་བསྲུང་། །',
            'དཀའ་ཡང་དགེ་བ་འབད་པས་བསྒྲུབ། །',
            'རྩ་བ་བདག་འཛིན་རྣམ་རྟོག་གཞོམ། །',
            'འདི་གསུམ་ལྡན་ན་མཁས་པ་ལགས། །',
        ],
        english: [
            'Guard against even the slightest misdeed, as if it were poison.',
            'Strive to practise virtue, now matter difficult it may be.',
            'Destroy the source, thoughts of self-clinging—',
            'One who adopts these three is truly wise.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'བུ་སྟོན་རིན་ཆེན་གྲུབ།', english: 'Butön Rinchen Drup' },
        links: { lh: '' },
        isPartial: '',
    },
    '0412': {
        tibetan: [
            'དུས་ཀུན་ཏུ་བསམ་སྤྱོད་གྲོགས་དང་བསྟུན། །',
            'རྒྱུད་དྲང་ཞིང་བསམ་པ་དཀར་ལ་བརྩོན། །',
            'ཕུགས་རང་དོན་རྒྱ་ཆེར་བསྒྲུབ་འདོད་ཀྱང་། །',
            'ཚུལ་གཞན་ཕན་བྱེད་པ་མན་ངག་ཡིན། །',
        ],
        english: [
            'At all times, let your thoughts and actions be in harmony with friends.',
            'Strive to be honest in character and pure in intentions.',
            'Though ultimately you may wish to accomplish great self-benefit,',
            'The pith instruction is to do so in the manner of benefitting others.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'འཇིགས་མེད་ཕུན་ཚོགས།',
            english: 'Jigmé Phuntsok Rinpoche',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0413': {
        tibetan: [
            'མེ་ནི་གྲང་བར་འགྱུར་ཡང་སྲིད། །',
            'རླུང་ནི་ཞབས་པས་ཟིན་ཨང་སྲིད། །',
            'ཉ་ཟླ་ཐང་ལ་ལྷུང་ཡང་སྲིད། །',
            'ལས་ཀྱི་རྣམ་སྨིན་བསླུ་མི་སྲིད། །',
        ],
        english: [
            'It may be possible for fire to be cold.',
            'It may be possible to catch the wind with a lasso.',
            'It may be possible for the sun and moon to fall to the ground.',
            'It is never possible for karmic ripening to decieve.',
        ],
        title: {
            tibetan: 'དྲན་པ་ཉེར་བཞག་པའི་མདོ།',
            english: 'The Sūtra on the Application of Mindfulness',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0414': {
        tibetan: ['དཀོན་མཆོག་གསུམ་སྐྱབས་སོང་བ། །', 'འཇིགས་པ་མེད་པ་ཐོབ་པར་འགྱུར། །'],
        english: ['He who goes for refuge in the Three Jewels,', 'Will attain a state beyond fear.'],
        title: {
            tibetan: 'མདོ་སྡེ་མྱང་འདས་ཆེན་པོ།',
            english: 'The Mahānirvāṇa Sūtra',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0415': {
        tibetan: [
            'དམྱལ་བ་བྲིས་པ་མཐོང་དང་ཐོས་པ་དང་། །',
            'དྲན་དང་བཀླག་དང་གཟུགས་སུ་བགྱིས་རྣམས་ཀྱང་། །',
            'འཇིགས་པ་སྐྱེས་པར་གྱུར་ན་མི་བཟད་པའི། །',
            'རྣམ་སྨིན་ཉམས་སུ་མྱོང་རྣམས་སྨོས་ཅི་འཚལ། །',
        ],
        english: [
            'If simply seeing pictures of the hells,',
            'Hearing, thinking, and reading about them,',
            'Or creating images of them is so terrifying,',
            'Need we mention how unbearable the actual experience is?',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0416': {
        tibetan: [
            'གང་གིས་བསྐལ་པ་སྟོང་རྣམས་སུ། །',
            'སྡིག་པ་ཤིན་ཏུ་མི་ཟད་བགྱིས། །',
            'ལན་གཅིག་རབ་ཏུ་བཤགས་པ་ཡིས། །',
            'དེ་དག་ཐམས་ཅད་བྱང་བར་འགྱུར། །',
        ],
        english: [
            'Someone may commit misdeeds without cessation',
            'For thousands upon thousands of eons.',
            'Yet by fully confessing them just once,',
            'They will be purified in their entirety.',
        ],
        title: {
            tibetan: 'གསེར་འོད་དམ་པའི་མདོ།',
            english: 'The Sūtra, The Sacred Golden Light',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0417': {
        tibetan: [
            'ཕ་ནི་ཡོངས་སུ་གསོ་བའི་ཆེད། །',
            'མ་དག་བཀུམ་པ་སྲིད་མིན་ལྟར། །',
            'རང་གི་བསྟན་པ་སྤེལ་བའི་ཕྱིར། །',
            'གཞན་གྱི་བསྟན་པ་ཇི་ལྟར་བཤིག །',
        ],
        english: [
            'Just as how you would never kill your mother',
            'In order to heal your father,',
            'How could you destroy the teachings of others',
            'In order to spread your own teachings?',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'རྗེ་བཙུན་ཀུན་དགའ་གྲོལ་མཆོག',
            english: 'Jetsün Kunga Drolchok',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0418': {
        tibetan: ['རིག་པ་ནམ་མཁའ་འདྲ་བའི་དོན་རྟོགས་ན། །', 'བརྗེད་པའི་གོ་སྐབས་ཤིན་ཏུ་ཉུང་བར་འགྱུར། །'],
        english: ['When you realize the truth of space-like awareness,', 'There will be few chances for forgetting.'],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མི་ཕམ་རིན་པོ་ཆེ།', english: 'Mipham Rinpoche' },
        links: { lh: '' },
        isPartial: '',
    },
    '0419': {
        tibetan: [
            'སེམས་ཅན་མོས་པ་བསམ་མི་ཁྱབ། །',
            'སངས་རྒྱས་རོལ་བ་བསམ་མི་ཁྱབ། །',
            'རང་གི་གཞུང་དང་མ་མཐུན་ཡང་། །',
            'སྨོད་ཅིང་སྤང་བར་མི་བྱ་སྟེ། །',
            'ནམ་ཞིག་གོ་བར་སྨོན་ལམ་ཐོབ། །',
        ],
        english: [
            'The interests of beings are inconcievable.',
            'The displays of buddhas are inconveible.',
            'Though something may not concord with your tradition',
            'Do not disparage or discard it—',
            'Make aspiration that you may come to understand it.',
        ],
        title: {
            tibetan: 'བདེ་མཆོག་རྩ་རྒྱུད།',
            english: 'The Cakrasaṃvara Root Tantra',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0420': {
        tibetan: [
            'གདོད་ནས་སྐྱེ་མེད་ཆོས་ཉིད་འདི། །',
            'བློ་ཡི་གཏིང་ནས་རྟོགས་གྱུར་ན། །',
            'འཁོར་བའི་འདམ་དུ་བྱིན་བ་ལ། །',
            'སྙིང་རྗེ་ངང་གིས་སྐྱེ་བར་འགྱུར། །',
        ],
        english: [
            'When you realize from the depths of mind',
            'This instrinsic nature, primordially birthless,',
            'Compassion naturally wells up',
            "For those drowing in saṃsāra's mud.",
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: '',
    },
    '0421': {
        tibetan: [
            'བལྟས་ན་སྐྱིད་སྐྱིད་འདྲ་བའི་འཇིག་རྟེན་གྱི་ཕུན་ཚོགས། །',
            'ཐོབ་ན་རང་རང་སྐབས་ཀྱི་སྡུག་བསྔལ་རེ་འདུག་པས། །',
            'འཕྲལ་སྣང་བདེ་བའི་གནས་སྐབས་རྨི་ལམ་ཙམ་སོང་རྗེས། །',
            'ཅིས་ཀྱང་རང་སེམས་སྐྱོ་བའི་རྐྱེན་སྣང་རེ་འདུག་གོ །',
        ],
        english: [
            'When seen, worldly abundance looks so wonderful.',
            'When attained, it is but suffering, each and every time.',
            'After those moments of temporary happiness fade to dreams',
            'They become nothing but conditions to depress your mind.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'དགེ་འདུན་ཆོས་འཕེལ།', english: 'Gendün Chöphel' },
        links: { lh: '' },
        isPartial: '',
    },
    '0422': {
        tibetan: [
            'འཆག་དང་འདུག་དང་འགྲེང་དང་ཉལ་བ་ན། །',
            'མི་གང་ཐུབ་པའི་ཟླ་བ་དྲན་བྱེད་པ། །',
            'དེ་ཡི་མདུན་ན་རྟག་ཏུ་སྟོན་པ་བཞུགས། །',
            'དེ་ནི་རྒྱ་ཆེན་མྱ་ངན་འདའ་བར་འགྱུར། །',
        ],
        english: [
            "Those who, while walking, sitting, standing, or sleeping, recollect the moon-like Buddha, will always be in Buddha's presence and will attain the vast nirvāṇa.",
        ],
        title: {
            tibetan: 'མདོ་ཏིང་འཛིན་རྒྱལ་པོ།',
            english: 'The Samādhirāja Sūtra',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/mipham/treasury-blessings-practice-buddha-shakyamuni',
        },
        isPartial: true,
    },
    '0423': {
        tibetan: [
            'བདག་གིས་བདེ་ལ་གཞན་སྦྱོར་ནུས། །',
            'བྱམས་པ་ཚད་དུ་ཕྱིན་པ་ལགས། །',
            'གཞན་གྱི་སྡུག་བསྔལ་བདག་ལེན་ནུས། །',
            'སྙིང་རྗེ་ཚད་དུ་ཕྱིན་པ་ལགས། །',
        ],
        english: [
            'To be able to give my own happiness to others—',
            'That is the true measure of loving kindness.',
            "To be able to take others' suffering upon myself—",
            'That is the true measure of compassion.',
        ],
        title: { tibetan: 'རིན་པོ་ཆེའི་འཕྲེང་བ།', english: 'A Precious Garland' },
        author: {
            tibetan: 'སྒམ་པོ་པ་བསོད་ནམས་རིན་ཆེན།',
            english: 'Gampopa Sönam Rinchen',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gampopa-sonam-rinchen/four-dharmas-lamrim-precious-garland',
        },
        isPartial: true,
    },
    '0424': {
        tibetan: [
            'གནོད་སྐྱེལ་གྱིས་བློ་མི་སྨད་ན། །',
            'བྱང་སེམས་ཚད་དུ་ཕྱིན་པ་ལགས། །',
            'བདེ་སྡུག་བླང་དོར་མི་འདོད་ན། །',
            'ལྟ་བ་ཚད་དུ་ཕྱིན་པ་ལགས། །',
        ],
        english: [
            'To remain unperturbed by deliberate harm—',
            'That is the true measure of bodhicitta.',
            'Not seeking to gain pleasure and avoid pain—',
            'That is the true measure of the view.',
        ],
        title: { tibetan: 'རིན་པོ་ཆེའི་འཕྲེང་བ།', english: 'A Precious Garland' },
        author: {
            tibetan: 'སྒམ་པོ་པ་བསོད་ནམས་རིན་ཆེན།',
            english: 'Gampopa Sönam Rinchen',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gampopa-sonam-rinchen/four-dharmas-lamrim-precious-garland',
        },
        isPartial: true,
    },
    '0425': {
        tibetan: [
            'རང་གི་སེམས་ཀྱི་ཆོས་ཉིད་དང༌། །',
            'གཞན་གྱི་སེམས་ཀྱི་ཆོས་ཉིད་ལ། །',
            'གཉིས་སུ་ཡོད་པ་མ་ཡིན་ན། །',
            'བདག་གཞན་འཛིན་པ་ཇི་ལྟར་འཐད། །',
        ],
        english: [
            'In the nature of my own mind',
            "And the nature of others' minds",
            'There is no dualistic separation,',
            'So how can clinging to self and other be right?',
        ],
        title: { tibetan: 'རིན་པོ་ཆེའི་འཕྲེང་བ།', english: 'A Precious Garland' },
        author: {
            tibetan: 'སྒམ་པོ་པ་བསོད་ནམས་རིན་ཆེན།',
            english: 'Gampopa Sönam Rinchen',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/gampopa-sonam-rinchen/four-dharmas-lamrim-precious-garland',
        },
        isPartial: true,
    },
    '0426': {
        tibetan: [
            'སྡིག་པ་ཆུང་ཡང་དུག་བཞིན་བསྲུང་། །',
            'དཀའ་ཡང་དགེ་བ་འབད་པས་བསྒྲུབ། །',
            'རྩ་བ་བདག་འཛིན་རྣམ་རྟོག་གཞོམ། །',
            'འདི་གསུམ་ལྡན་ན་མཁས་པ་ལགས། །',
        ],
        english: [
            'To guard against even the slightest misdeed, as if it were poison,',
            'Though it may be difficult, to strive to practise virtue,',
            'To destroy the source, thoughts of self-clinging—',
            'One who adopts these three is truly wise.',
        ],
        title: {
            tibetan: 'གནད་ཀྱི་གདམས་ངག་གསུམ་ཚན་གསུམ།',
            english: 'Advice in Three Sets of Three',
        },
        author: { tibetan: 'བུ་སྟོན་རིན་ཆེན་གྲུབ།', english: 'Butön Rinchen Drup' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/buton/advice-in-three-sets-of-three',
        },
        isPartial: true,
    },
    '0427': {
        tibetan: [
            'གཞན་གྱི་སྡིག་སྡུག་བདག་གིས་བླང་། །',
            'བདག་གི་དགེ་བདེ་གཞན་ལ་གཏང་། །',
            'སྟོང་ཉིད་སྙིང་རྗེ་རྟག་ཏུ་བསྒོམ། །',
            'འདི་གསུམ་ལྡན་ན་བྱང་སེམས་ལགས། །',
        ],
        english: [
            "To take others' misdeeds and suffering upon oneself,",
            "To give away one's own virtue and happiness to others,",
            'To meditate continually on emptiness and compassion—',
            'One who adopts these three is a bodhisattva.',
        ],
        title: {
            tibetan: 'གནད་ཀྱི་གདམས་ངག་གསུམ་ཚན་གསུམ།',
            english: 'Advice in Three Sets of Three',
        },
        author: { tibetan: 'བུ་སྟོན་རིན་ཆེན་གྲུབ།', english: 'Butön Rinchen Drup' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/buton/advice-in-three-sets-of-three',
        },
        isPartial: true,
    },
    '0428': {
        tibetan: [
            'ཅིར་སྣང་སྒྱུ་མ་ལྷ་སྐུར་ཤེས། །',
            'དྲན་རིག་བདེ་གསལ་མི་རྟོག་པ། །',
            'བྱིན་རླབས་བླ་མའི་རྣལ་འབྱོར་བསྒོམ། །',
            'འདི་གསུམ་ལྡན་ན་སྔགས་པ་ལགས། །',
        ],
        english: [
            "To know that whatever appears is the deity's illusory form,",
            'To be aware of bliss, clarity and absence of thought,',
            'To meditate on Guru Yoga, the source of blessings—',
            'One who adopts these three is a mantra adept.',
        ],
        title: {
            tibetan: 'གནད་ཀྱི་གདམས་ངག་གསུམ་ཚན་གསུམ།',
            english: 'Advice in Three Sets of Three',
        },
        author: { tibetan: 'བུ་སྟོན་རིན་ཆེན་གྲུབ།', english: 'Butön Rinchen Drup' },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/buton/advice-in-three-sets-of-three',
        },
        isPartial: true,
    },
    '0429': {
        tibetan: ['གང་ཟག་གི་ཚོད་གང་ཟག་གིས་མི་ཟིན་པས་སུ་ལ་ཡང་སྐུར་བ་མི་གདབ།'],
        english: ['As no ordinary being can truly take the measure of another, do not disparage anyone at all.'],
        title: {
            tibetan: 'དགེ་བཞེས་གླང་རི་ཐང་པའི་ཞལ་ནས།',
            english: 'The Sayings of Geshe Langri Tangpa',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/sayings-of-geshe-langri-tangpa',
        },
        isPartial: true,
    },
    '0430': {
        tibetan: ['སངས་རྒྱས་ཀྱི་ཆོས་ཐམས་ཅད་འབྲས་བུ་དང་བཅས་པ་ཡིན་པས་ཆོས་ལ་བཟང་ངན་དུ་མི་སྤྱད།'],
        english: [
            "As all the Buddha's teachings yield their own particular results, don't relate to them as good or bad.",
        ],
        title: {
            tibetan: 'དགེ་བཞེས་གླང་རི་ཐང་པའི་ཞལ་ནས།',
            english: 'The Sayings of Geshe Langri Tangpa',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/sayings-of-geshe-langri-tangpa',
        },
        isPartial: true,
    },
    '0501': {
        tibetan: ['ཐེག་པ་ཆེན་པོ་ལ་བྱ་རྒྱུ་སེམས་ཅན་གྱི་དོན་ལས་མེད་པས་གཞན་དོན་གྱི་གོ་ཆ་མ་ཆུང་།'],
        english: [
            "As there's nothing to do in the Great Vehicle but work for sentient beings, don't let your 'armour' for benefitting others be insubstantial.",
        ],
        title: {
            tibetan: 'དགེ་བཞེས་གླང་རི་ཐང་པའི་ཞལ་ནས།',
            english: 'The Sayings of Geshe Langri Tangpa',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/sayings-of-geshe-langri-tangpa',
        },
        isPartial: true,
    },
    '0502': {
        tibetan: ['རང་བཙན་ས་མ་ཟིན་པར་གཞན་དྲང་བར་མི་ནུས་པས་དབེན་པར་སྒོམ་སྒྲུབ་ལ་ནན་ཏན་བྱ།'],
        english: [
            "As there can be no guiding others until you've seized the stronghold of fruition for yourself, apply yourself to meditation in solitude.",
        ],
        title: {
            tibetan: 'དགེ་བཞེས་གླང་རི་ཐང་པའི་ཞལ་ནས།',
            english: 'The Sayings of Geshe Langri Tangpa',
        },
        author: {
            tibetan: 'དགེ་བཤེས་གླང་རི་ཐང་པ།',
            english: 'Geshe Langri Thangpa',
        },
        links: {
            lh: 'https://www.lotsawahouse.org/tibetan-masters/geshe-langri-thangpa/sayings-of-geshe-langri-tangpa',
        },
        isPartial: true,
    },
    '0503': {
        tibetan: ['ངས་ནི་ཁྱོད་ལ་ཐར་པའི་ཐབས་བསྟན་གྱི། །', 'ཐར་པ་རང་ལ་རགས་ལས་ཤེས་པར་གྱིས། །'],
        english: [
            'I have shown you the methods that lead to liberation,',
            'But you should know that liberation depends upon yourself.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: '',
    },
    '0504': {
        tibetan: [
            'ཆོས་རྣམས་ཐམས་ཅད་རྒྱུ་ལས་བྱུང་། །',
            'དེ་རྒྱུ་དེ་བཞིན་གཤེགས་པས་གསུངས། །',
            'རྒྱུ་ལ་འགོག་པ་གང་ཡིན་པ། །',
            'དགེ་སྦྱོང་ཆེན་པོས་འདི་སྐད་གསུངས། །',
        ],
        english: [
            'All dharmas originate from causes.',
            'The Tathagata has taught these causes,',
            'And also that which puts a stop to these causes--',
            'This too has been taught by the Great Shramana.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: '',
    },
    '0505': {
        tibetan: [
            'དགེ་སློང་དག་དང་མཁས་རྣམས་ཀྱིས། །',
            'བསྲེག་བཅད་བརྡར་བའི་གསེར་བཞིན་དུ། །',
            'ལེགས་པར་བརྟག་ལ་ང་ཡི་བཀའ། །',
            'བླང་བར་བྱ་ཡི་གུས་ཕྱིར་མིན། །',
        ],
        english: [
            'Monks and learned ones,',
            'Just as gold is burnt, cut and rubbed,',
            'Examine my words carefully and',
            'Do not accept them simply out of respect.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: '',
    },
    '0506': {
        tibetan: [
            'ལྔ་བརྒྱ་ཐ་མར་གྱུར་པ་ན། །',
            'ང་ཉིད་ཡི་གེའི་གཟུགས་སུ་གནས། །',
            'ང་ཡིན་སྙམ་དུ་ཡིད་བྱོས་ལ། །',
            'དེ་ཚེ་དེ་ལ་གུས་པར་གྱིས། །',
        ],
        english: [
            'In the last five hundred year period,',
            'I will appear in the form of scriptures.',
            'Consider them as identical to me,',
            'And treat them with due respect.',
        ],
        title: { tibetan: 'ཕལ་པོ་ཆེ།', english: 'Avatamsaka Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0507': {
        tibetan: [
            'ཐོས་པས་ཆོས་རྣམས་ཤེས་པར་འགྱུར། །',
            'ཐོས་པས་སྡིག་ལས་ལྡོག་པར་འགྱུར། །',
            'ཐོས་པས་དོན་མ་ཡིན་པ་སྤོང༌། །',
            'ཐོས་པས་མྱ་ངན་འདས་པ་ཐོབ། །',
        ],
        english: [
            'Through study we come to understand phenomena.',
            'Through study we turn away from negative actions.',
            'Through study we give up what is meaningless.',
            'Through study we reach the state beyond sorrow.',
        ],
        title: {
            tibetan: 'བྱང་ཆུབ་སེམས་དཔའི་སྡེ་སྣོད།',
            english: 'Bodhisattvapiṭaka',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: '',
    },
    '0508': {
        tibetan: [
            'བཅོམ་ལྡན་འདས་བྱང་ཆུབ་སེམས་དཔའ་ཆོས་རབ་ཏུ་མང་པོ་ལ་བསླབ་པར་མི་བགྱིའོ། །བཅོམ་ལྡན་འདས་བྱང་ཆུབ་སེམས་དཔས་ཆོས་གཅིག་རབ་ཏུ་བཟུང་ཞིང་རབ་ཏུ་རྟོག་པར་བགྱིས་ན།སངས་རྒྱས་ཀྱི་ཆོས་ཐམས་ཅད་དེའི་ལག་མཐིལ་དུ་མཆིས་པ་ལགས་སོ། །ཆོས་གཅིག་པུ་དེ་གང་ཞེ་ན། འདི་ལྟ་སྟེ་སྙིང་རྗེ་ཆེན་པོའོ། །',
        ],
        english: [
            "O Blessed One, a bodhisattva should not train in many practices. O Blessed One, if a bodhisattva keeps to one practice and learns it perfectly, he has all the Buddha's teachings in the palm of his hand. What is that one practice? It is great compassion.",
        ],
        title: {
            tibetan: 'ཆོས་ཡང་དག་པར་སྡུད་པའི་མདོ།',
            english: 'Compendium of Dharma Sūtra',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0509': {
        tibetan: [
            'གང་དག་ང་ལ་གཟུགས་སུ་མཐོང༌། །',
            'གང་དག་ང་ལ་སྒྲར་ཤེས་པ། །',
            'ལོག་པར་སྤོང་བས་ཞུགས་པ་སྟེ། །',
            'སྐྱེ་བོ་དེ་དག་ང་མི་མཐོང༌། །',
        ],
        english: [
            'Those who see my body as ordinary form,',
            'And hear my voice as ordinary sound,',
            'Have set out upon a mistaken path.',
            'Such people do not truly see me.',
        ],
        title: { tibetan: 'རྡོ་རྗེ་གཅོད་པ།', english: 'Diamond Cutter Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0510': {
        tibetan: [
            'སྐར་མ་རབ་རིབ་མར་མེ་དང༌། །',
            'སྒྱུ་མ་ཟིལ་པ་ཆུ་བུར་དང་། །',
            'རྨི་ལམ་གློག་དང་སྤྲིན་ལྟ་བུ། །',
            'འདུས་བྱས་དེ་ལྟར་བལྟ་བར་བྱ། །',
        ],
        english: [
            'Like a star, hallucination, candle,',
            'Magical illusion, dewdrop, bubble,',
            'Dream, lightning or a cloud—',
            'Know all compounded phenomena to be like this.',
        ],
        title: { tibetan: 'རྡོ་རྗེ་གཅོད་པ།', english: 'Diamond Cutter Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0511': {
        tibetan: ['སེམས་ལ་སེམས་མ་མཆིས་ཏེ། །', 'སེམས་ཀྱི་རང་བཞིན་ནི་འོད་གསལ་བའོ། །'],
        english: ['The mind is devoid of mind,', 'For the nature of mind is clear light.'],
        title: {
            tibetan: 'བརྒྱད་སྟོང་པ།',
            english: 'Eight Thousand Verse Prajñāparamitā',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0512': {
        tibetan: [
            'རིགས་ཀྱི་བུ་ཁྱོད་ཀྱིས་བདག་ཉིད་ལ་ནད་པའི་འདུ་ཤེས་བསྐྱེད་པར་བྱའོ། །',
            'ཆོས་ལ་སྨན་གྱི་འདུ་ཤེས་བསྐྱེད་པར་བྱའོ། །',
            'དགེ་བའི་བཤེས་གཉེན་ལ་སྨན་པ་མཁས་པའི་འདུ་ཤེས་བསྐྱེད་པར་བྱའོ། །',
            'ནན་ཏན་ཉམས་སུ་ལེན་པ་ནི་ནད་ཉེ་བར་འཚོ་བའི་འདུ་ཤེས་བསྐྱེད་པར་བྱའོ། །',
        ],
        english: [
            'Noble one, think of yourself as someone who is sick,',
            'Of the Dharma as the remedy,',
            'Of your spiritual teacher as a skilful doctor,',
            'And of diligent practice as the way to recovery.',
        ],
        title: { tibetan: 'སྡོང་པོ་བཀོད་པ།', english: 'Gaṇḍhavyūha Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0513': {
        tibetan: [
            'མྱ་ངན་མ་བྱེད་ཀུན་དགའ་བོ། །',
            'སྨྲེ་སྔགས་མ་འདོན་ཀུན་དགའ་བོ། །',
            'ང་ཉིད་ཕྱི་མའི་དུས་ཀྱི་ཚེ། །',
            'དགེ་བའི་བཤེས་གཉེན་ཉིད་སྤྲུལ་ནས། །',
            'ཁྱེད་ལ་སོགས་པའི་དོན་བྱེད་འགྱུར། །',
        ],
        english: [
            "Don't feel sad Ānanda,",
            "Don't lament Ānanda,",
            'In future times I will',
            'Take the form of spiritual teachers,',
            'To help you and others.',
        ],
        title: { tibetan: 'རྔ་བོ་ཆེའི་མདོ།', english: 'Great Drum Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0514': {
        tibetan: [
            'གཟུགས་སྟོང་པའོ། སྟོང་པ་ཉིད་གཟུགས་སོ།',
            'གཟུགས་ལས་སྟོང་པ་ཉིད་གཞན་མ་ཡིན།',
            'སྟོང་པ་ཉིད་ལས་ཀྱང་གཟུགས་གཞན་མ་ཡིན་ནོ།།',
        ],
        english: [
            'Form is emptiness; emptiness also is form.',
            'Emptiness is no other than form,',
            'Form is no other than emptiness.',
        ],
        title: { tibetan: 'ཤེས་རབ་སྙིང་པོ།', english: 'Heart Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0515': {
        tibetan: [
            'བདེ་གཤེགས་སྙིང་པོས་འགྲོ་བ་ཡོངས་ལ་ཁྱབ། །',
            'སེམས་ནི་རྒྱ་ཆེན་མཆོག་ཏུ་རབ་བསྐྱེད་དེ། །',
            'འགྲོ་བ་འདི་དག་མ་ལུས་སངས་རྒྱས་རྒྱུ། །',
            'འདི་ན་སྣོད་མིན་སེམས་ཅན་གང་ཡང་མེད། །',
        ],
        english: [
            'The essence of the sugatas pervades all beings.',
            'Generate the most vast and sublime of intentions,',
            'For each and every being has the cause of awakening—',
            'There is not a single sentient being who lacks this potential.',
        ],
        title: { tibetan: 'ཏིང་འཛིན་རྒྱལ་པོ།', english: 'King of Samādhi Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0516': {
        tibetan: [
            'གསེར་གྱི་ཁ་དོག་ལྟ་བུའི་སྐུ་ལུས་ཀྱིས། །',
            'འཇིག་རྟེན་མགོན་པོ་ཀུན་ཏུ་མཛེས་པ་སྟེ། །',
            'དམིགས་པ་དེ་ལ་གང་གི་སེམས་འཇོག་པ། །',
            'བྱང་ཆུབ་སེམས་དཔའ་དེ་མཉམ་བཞག་ཅེས་བྱ། །',
        ],
        english: [
            'His pure body is the colour of gold,',
            'Beautiful  is the Protector of the World.',
            'Whoever visualizes him like this,',
            'Practises the meditation of the bodhisattvas.',
        ],
        title: { tibetan: 'ཏིང་འཛིན་རྒྱལ་པོ།', english: 'King of Samādhi Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0517': {
        tibetan: [
            'ཡོད་དང་མེད་པ་འདི་ཡང་མཐའ་ཡིན་སྟེ། །',
            'གཙང་དང་མི་གཙང་འདི་ཡང་མཐའ་ཡིན་སྟེ། །',
            'དེ་ཕྱིར་གཉིས་ཀའི་མཐའ་ནི་རབ་སྤངས་ནས། །',
            'མཁས་པས་དབུ་ལའང་གནས་པར་ཡོང་མི་བྱེད། །',
        ],
        english: [
            'Existence and non-existence are extremes,',
            'Purity and impurity are extremes as well,',
            'Thus, having relinquished both extremes,',
            'The wise do not dwell even in the middle.',
        ],
        title: { tibetan: 'ཏིང་འཛིན་རྒྱལ་པོ།', english: 'King of Samādhi Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0518': {
        tibetan: [
            'ཇི་ལྟར་བུ་མོ་གཞོན་ནུའི་རྨི་ལམ་ན། །',
            'བུ་ཕོ་བྱུང་ཞིང་ཤི་བ་དེ་མཐོང་ན། །',
            'བྱུང་ནས་དགའ་ཞིང་ཤི་ནས་མི་དགའ་ལྟར། །',
            'ཆོས་རྣམས་ཐམས་ཅད་དེ་བཞིན་ཤེས་པར་གྱིས། །',
        ],
        english: [
            'If, in her dream, a young girl',
            'Has a baby boy who later dies,',
            'She feels joy at first, then grief—',
            'Know all things to be like this.',
        ],
        title: { tibetan: 'ཏིང་འཛིན་རྒྱལ་པོ།', english: 'King of Samādhi Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0519': {
        tibetan: [
            'སྒྱུ་མ་བྱེད་པ་དག་གི་གཟུགས་སྤྲུལ་ཏེ། །',
            'རྟ་དང་གླང་པོ་ཤིང་རྟ་སྣ་ཚོགས་བྱས། །',
            'དེ་ལ་ཇི་ལྟར་སྣང་བ་གང་ཡང་མེད། །',
            'ཆོས་རྣམས་ཐམས་ཅད་དེ་བཞིན་ཤེས་པར་གྱིས། །',
        ],
        english: [
            'Just as a magician makes illusions',
            'Of horses, oxen, carts and other things,',
            'Nothing whatsoever is as it appears—',
            'Know all things to be like this.',
        ],
        title: { tibetan: 'ཏིང་འཛིན་རྒྱལ་པོ།', english: 'King of Samādhi Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0520': {
        tibetan: [
            'ཟབ་ཞི་སྤྲོས་བྲལ་འོད་གསལ་འདུས་མ་བྱས། །',
            'བདུད་རྩི་ལྟ་བུའི་ཆོས་ཤིག་བདག་གིས་བརྙེས། །',
            'སུ་ལ་བསྟན་ཀྱང་གོ་བར་མི་འགྱུར་བས། །',
            'མི་སྨྲ་ནགས་འདབ་ཉིད་དུ་གནས་པར་བྱ། །',
        ],
        english: [
            'Profound and peaceful, free from complexity, uncompounded luminosity—',
            'I have found a nectar-like Dharma.',
            'Yet if I were to teach it, no-one would understand,',
            'So I shall remain silent here in the forest.',
        ],
        title: { tibetan: 'རྒྱ་ཆེར་རོལ་པ།', english: 'Lalitavistara Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0521': {
        tibetan: [
            'སྲིད་གསུམ་མི་རྟག་སྟོན་ཀའི་སྤྲིན་དང་འདྲ། །',
            'འགྲོ་བའི་སྐྱེ་འཆི་གར་ལ་བལྟ་དང་མཚུངས། །',
            'སྐྱེས་བུའི་ཚེ་འགྲོ་ནམ་མཁའི་གློག་འདྲ་སྟེ། །',
            'རི་གཟར་འབབ་ཆུ་བཞིན་དུ་མྱུར་མགྱོགས་འགྲོ །',
        ],
        english: [
            'This existence of ours is as transient as autumn clouds.',
            'To watch the birth and death of beings is like looking at the movement of a dance.',
            'A lifetime is like a flash of lightning in the sky,',
            'Rushing by, like a torrent down a steep mountain.',
        ],
        title: { tibetan: 'རྒྱ་ཆེར་རོལ་པ།', english: 'Lalitavistara Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0522': {
        tibetan: [
            'སྲེད་སྲིད་མ་རིག་དབང་གིས་སྐྱེ་བོ་རྣམས། །',
            'མི་དང་ལྷ་དང་ངན་སོང་རྣམ་གསུམ་པོ། །',
            'འགྲོ་བ་ལྔ་པོ་དག་ཏུ་མི་མཁས་འཁོར། །',
            'དཔེར་ན་རྫ་མཁན་འཁོར་ལོ་འཁོར་བ་བཞིན། །',
        ],
        english: [
            'Because of craving, attachment and ignorance',
            'Men, gods, animals, hungry ghosts and hell-beings',
            'Foolishly go round,',
            "Like the turning of a potter's wheel.",
        ],
        title: { tibetan: 'རྒྱ་ཆེར་རོལ་པ།', english: 'Lalitavistara Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0523': {
        tibetan: [
            'ང་ཡི་ཆོས་ཚུལ་རྣམ་གཉིས་ཏེ། །',
            'བསྟན་པ་དང་ནི་གྲུབ་མཐའ་འོ། །',
            'བྱིས་པ་རྣམས་ལ་བསྟན་པ་བཤད། །',
            'རྣལ་འབྱོར་པ་ལ་གྲུབ་མཐའ་འོ། །',
        ],
        english: [
            'My Dharma has two aspects,',
            'General advice and philosophy,',
            'To ordinary people I give advice,',
            'And to the yogis, philosophy.',
        ],
        title: { tibetan: 'ལང་ཀར་གཤེགས་པ།', english: 'Laṇkāvatāra Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0524': {
        tibetan: [
            'མིང་དུ་གདགས་པར་མ་མཛད་ན། །',
            'འཇིག་རྟེན་ཐམས་ཅད་རྨོངས་པར་གྱུར། །',
            'དེ་བས་མགོན་པོ་ཐབས་མཁས་པས། །',
            'ཆོས་རྣམས་མིང་དུ་གདགས་པར་མཛད། །',
        ],
        english: [
            'If things were not given names,',
            'The world would be bewildered.',
            'So Lord Buddha, skilled in means,',
            'Gives names to various phenomena.',
        ],
        title: { tibetan: 'ལང་ཀར་གཤེགས་པ།', english: 'Laṇkāvatāra Sūtra' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0525': {
        tibetan: [
            'སྡིག་པ་ཅི་ཡང་མི་བྱ་ཞིང༌། །',
            'དགེ་བ་ཕུན་སུམ་ཚོགས་པར་སྤྱད། །',
            'རང་གི་སེམས་ནི་ཡོངས་སུ་འདུལ། །',
            'འདི་ནི་སངས་རྒྱས་བསྟན་པ་ཡིན། །',
        ],
        english: [
            'Commit not a single unwholesome action,',
            'Cultivate a wealth of virtue,',
            'Tame completely this mind of ours—',
            'This is the teaching of the buddhas.',
        ],
        title: {
            tibetan: 'སོ་སོར་ཐར་པའི་མདོ།',
            english: 'Sūtra of Individual Liberation',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0526': {
        tibetan: [
            'དུས་ཀྱི་ཉེན་ཏེ་རྒྱལ་པོ་འགྲོ་གྱུར་ན། །',
            'ལོངས་སྤྱོད་མཛའ་དང་བཤེས་རྣམས་རྗེས་མི་འབྲང་། །',
            'སྐྱེས་བུ་དག་ནི་གང་ནས་གར་འགྲོ་ཡང༌། །',
            'ལས་ནི་གྲིབ་མ་བཞིན་དུ་རྗེས་སུ་འབྲང༌། །',
        ],
        english: [
            'When his time has come, even a king has to die,',
            'And neither his friends nor his wealth can follow him.',
            'So for us—wherever we stay, wherever we go—',
            'Karma follows us like a shadow.',
        ],
        title: {
            tibetan: 'རྒྱལ་པོ་ལ་གདམས་པ།',
            english: 'Sūtra of Instructions to the King',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0527': {
        tibetan: [
            'འཇིག་རྟེན་མཁྱེན་པས་བདེན་པ་འདི་གཉིས་ཏེ། །',
            'ཁྱེད་ཀྱིས་གཞན་ལས་མ་གསན་རང་གིས་རིག །',
            'དེ་ནི་ཀུན་རྫོབ་བདེན་དང་དོན་དམ་སྟེ། །',
            'བདེན་པ་གསུམ་པ་གང་ཡང་མ་མཆིས་སོ། །',
        ],
        english: [
            'You, the knower of the world,',
            'Realized the two levels of reality,',
            'By yourself, not studying them from others.',
            'They are the relative and the ultimate.',
            'There is not some third level of reality.',
        ],
        title: {
            tibetan: 'ཡབ་སྲས་མཇལ་བའི་མདོ།',
            english: 'Sūtra of the Meeting of Father and Son',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0528': {
        tibetan: [
            'སྡིག་པ་ཆུང་ངུ་དག་ལ་ཡང༌། །',
            'མི་གནོད་སྙམ་དུ་བརྙས་མི་བྱ། །',
            'མེ་སྟག་ཆུང་ངུ་དག་གིས་ཀྱང༌། །',
            'རྩྭ་ཕུང་རི་ཙམ་སྲེག་པར་བྱེད། །',
        ],
        english: [
            'Do not disregard small misdeeds,',
            'Thinking they are harmless,',
            'Because even tiny sparks of flame,',
            'Can set fire to a mountain of hay.',
        ],
        title: {
            tibetan: 'མདོ་མཛངས་བླུན།',
            english: 'Sūtra of the Wise and Foolish',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0529': {
        tibetan: [
            'དགེ་བ་ཆུང་ངུ་དག་ལ་ཡང༌། །',
            'མི་ཕན་སྙམ་དུ་བརྙས་མི་བྱ། །',
            'ཆུ་ཡིས་ཐིགས་པ་བསགས་པ་ཡིས། །',
            'སྣོད་ཆེན་རིམ་གྱིས་གང་བར་འགྱུར། །',
        ],
        english: [
            'Do not disregard small positive acts,',
            'Thinking they are without benefit,',
            'Because even tiny drops of water,',
            'Will eventually fill a large container.',
        ],
        title: {
            tibetan: 'མདོ་མཛངས་བླུན།',
            english: 'Sūtra of the Wise and Foolish',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0530': {
        tibetan: [
            'དུ་བ་ལས་ནི་མེར་ཤེས་དང༌། །',
            'ཆུ་སྐྱར་ལས་ནི་ཆུར་ཤེས་ལྟར། །',
            'བྱང་ཆུབ་སེམས་དཔའ་བློ་ལྡན་གྱི། །',
            'རིགས་ནི་མཚན་མ་དག་ལས་ཤེས། །',
        ],
        english: [
            'Just as one infers the presence of fire by seeing smoke,',
            'Or the presence of water by seeing aquatic birds,',
            "The presence of the intelligent bodhisattvas' disposition",
            'Can be understood from certain signs.',
        ],
        title: { tibetan: 'ས་བཅུ་པ་འི་མདོ།', english: 'Sūtra on the Ten Bhūmis' },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0531': {
        tibetan: [
            'ཆོས་རྣམས་ཐམས་ཅད་བཀའ་དང་བསྟན་བཅོས་ཏེ། །',
            'ལེགས་པར་གསུངས་དང་དེའི་དགོངས་འགྲེལ་བ། །',
            'དེའི་དབང་གིས་ཤཱཀྱའི་བསྟན་པ་ནི། །',
            'འཇིག་རྟེན་ཁམས་འདིར་ཡུན་རིང་གནས་པར་གྱུར། །',
        ],
        english: [
            "All Dharma teachings are included in the Buddha's word and the treatises,",
            'The excellent speech and the commentaries on its intended meaning,',
            'By means of these, the teaching of the Śakya',
            'Will remain for long within this world.',
        ],
        title: {
            tibetan: 'ལྷ་འབུས་ཞུས་པའི་མདོ།',
            english: 'Sūtra Requested by Devaputra',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0601': {
        tibetan: [
            'ཇི་ལྟར་ཆུ་ཐིགས་རྒྱ་མཚོ་ཆེ་ནང་ལྷུང༌། །',
            'རྒྱ་མཚོ་མ་ཟད་བར་དུ་དེ་མི་འཛད། །',
            'དེ་བཞིན་བྱང་ཆུབ་ཡོངས་བསྔོས་དགེ་བ་ཡང༌། །',
            'བྱང་ཆུབ་མ་ཐོབ་བར་དུ་དེ་མི་འཛད། །',
        ],
        english: [
            'Just as a drop of water that falls into the great ocean',
            'Will never disappear until the ocean itself runs dry,',
            'Merit totally dedicated to enlightenment',
            'Will never disappear until enlightenment is reached.',
        ],
        title: {
            tibetan: 'བློ་གྲོས་རྒྱ་མཚོས་ཞུས་པའི་མདོ།',
            english: 'Sūtra Requested by Sāgaramati',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0602': {
        tibetan: ['རྣམ་ཤེས་ནི་མི་རྟག་པའོ། །', 'ཡེ་ཤེས་ནི་རྟག་པའོ། །'],
        english: ['Consciousness is impermanent.', 'Wisdom is permanent.'],
        title: {
            tibetan: 'བློ་གྲོས་རྒྱ་མཚོས་ཞུས་པའི་མདོ།',
            english: 'Sūtra Requested by Sāgaramati',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0603': {
        tibetan: [
            'སྣ་ཚོགས་ཡིད་དགའ་མེ་ཏོག་ཁ་ཕྱེ་ཤིང་།​ །',
            'གསེར་གྱི་ཁ་མཆོག་འབར་བ་ཡིད་འོང་བ། །',
            'དེ་འདྲ་འདི་ལའང་བྱེད་པོ་འགའ་མེད་དེ། །',
            'དེ་དག་རྟོག་པའི་དབང་གིས་བཞག་པ་ཡིན། །',
            'རྟོག་པའི་དབང་གིས་འཇིག་རྟེན་རྣམ་བཏགས་ཏེ། །',
        ],
        english: [
            'All kinds of variety, lovely flowers in bloom,',
            'A golden palace gleaming and delightful,',
            'Even such as these have no ultimate creator,',
            'They are imputed by the power of thought',
            'The whole world is imputed by the power of thought.',
        ],
        title: {
            tibetan: 'ཉེ་བར་འཁོར་གྱིས་ཞུས་པའི་མདོ།',
            english: 'Sūtra Requested by Upāli',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0604': {
        tibetan: [
            'བྱང་ཆུབ་སེམས་ཀྱི་བསོད་ནམས་གང་། །',
            'གལ་ཏེ་དེ་ལ་གཟུགས་མཆིས་ན། །',
            'ནམ་མཁའི་ཁམས་ནི་ཀུན་གང་སྟེ། །',
            'དེ་ནི་དེ་བས་ལྷག་པར་འགྱུར། །',
        ],
        english: [
            'If the merit of bodhicitta',
            'Were to take physical form,',
            'Even the whole of space itself',
            'Would not contain its vastness.',
        ],
        title: {
            tibetan: 'དཔའ་སྦྱིན་ཞུས་པའི་མདོ།',
            english: 'Sūtra Requested by Vīryadatta',
        },
        author: { tibetan: 'སངས་རྒྱས་བཅོམ་ལྡན་འདས།', english: 'Buddha Śākyamuni' },
        links: { lh: '' },
        isPartial: true,
    },
    '0605': {
        tibetan: [
            'སེམས་ཅན་རྣམས་ནི་སངས་རྒྱས་ཉིད། །',
            'འོན་ཀྱང་གློ་བུར་དྲི་མས་བསྒྲིབས། །',
            'དྲི་མ་དེ་བསལ་སངས་རྒྱས་དངོས། །',
        ],
        english: [
            'All beings are buddhas',
            'But this is concealed by adventitious stains.',
            'When their stains are purified, their buddhahood is revealed.',
        ],
        title: { tibetan: 'རྒྱུད་བརྟག་གཉིས།', english: 'Hevajra Tantra' },
        author: { tibetan: '', english: '' },
        links: { lh: '' },
        isPartial: true,
    },
    '0606': {
        tibetan: ['ལོ་རྒྱུས་དོན་གྱིས་མ་བཤད་ན། །', 'གསང་ཆེན་ངེས་པའི་བཀའ་འདི་ལ། །', 'ཡིད་མི་ཆེས་པའི་སྐྱོན་དུ་འགྱུར། །'],
        english: [
            'If you do not recount the history',
            'Of the supremely secret definitive teachings,',
            'People may not trust their authenticity.',
        ],
        title: {
            tibetan: 'རྒྱུད་ཉི་ཟླ་ཁ་སྦྱོར།',
            english: 'Tantra of the Union of the Sun and Moon',
        },
        author: { tibetan: '', english: '' },
        links: { lh: '' },
        isPartial: true,
    },
    '0607': {
        tibetan: [
            'དོན་གཅིག་ན་ཡང་མ་རྨོངས་དང༌། །',
            'ཐབས་མང་དཀའ་བ་མེད་པ་དང༌། །',
            'དབང་པོ་རྣོན་པོའི་དབང་བྱས་པས། །',
            'སྔགས་ཀྱི་ཐེག་པ་ཁྱད་པར་འཕགས། །',
        ],
        english: [
            'It has the same goal but is free from all confusion,',
            'It is rich in methods and without difficulties.',
            'It is for those with sharp faculties.',
            'The mantra vehicle is especially sublime.',
        ],
        title: {
            tibetan: 'ཚུལ་གསུམ་སྒྲོན་མེ།',
            english: 'The Torch of the Three Methods',
        },
        author: { tibetan: '', english: '' },
        links: { lh: '' },
        isPartial: '',
    },
    '0608': {
        tibetan: [
            'ཆོས་རྣམས་ཐམས་ཅད་རྐྱེན་བཞིན་ཏེ། །',
            'འདུན་པའི་རྩེ་ལ་རབ་ཏུ་གནས། །',
            'གང་གིས་སྨོན་ལམ་ཅི་བཏབ་པ། །',
            'དེ་འདྲའི་འབྲས་བུ་ཐོབ་པར་འགྱུར། །',
        ],
        english: [
            'Everything is circumstantial',
            "And depends entirely on one's aspiration.",
            'Whatever prayers of aspiration people make',
            'They will gain results accordingly.',
        ],
        title: {
            tibetan: 'འཇམ་དཔལ་ཞིང་གི་ཡོན་ཏན་བསྟན་པ།',
            english: "Teaching on the Qualities of Mañjuśrī's Pure Land",
        },
        author: { tibetan: '', english: '' },
        links: { lh: '' },
        isPartial: true,
    },
    '0609': {
        tibetan: [
            'རྟོག་པ་ཐམས་ཅད་ལེགས་པར་བསྡུས་ནས་ནི། །',
            'ཤིན་ཏུ་བསམ་པ་བཟང་དང་ལྡན་པས་ཉོན། །',
            'བརྗེད་ངས་བློ་ལ་རྡོ་རྗེ་སེམས་དཔའ་སོགས། །',
            'དུས་གསུམ་རྒྱལ་བ་རྣམས་ཀྱིས་བྱིན་མི་རློབས། །',
        ],
        english: [
            'Gather together all your thoughts in the proper way,',
            'And listen with an exceptionally pure motivation.',
            'A forgetful mind will receive no blessings',
            'From Vajrasattva and the other buddhas of the three times.',
        ],
        title: {
            tibetan: 'རྒྱུད་རྡོ་རྗེ་རྩེ་མོ།',
            english: 'Vajra Pinnacle Tantra',
        },
        author: { tibetan: '', english: '' },
        links: { lh: '' },
        isPartial: true,
    },
    '0610': {
        tibetan: [
            'བསོད་ནམས་ཆུང་ངུ་ཆོས་འདི་ལ། །',
            'ཐེ་ཚོམ་ཟ་བར་ཡང་མི་འགྱུར། །',
            'ཐེ་ཚོམ་ཟ་བར་ཙམ་ཞིག་གིས། །',
            'སྲིད་པ་ཧྲུལ་པོར་བྱས་པར་འགྱུར། །',
        ],
        english: [
            'Those with little merit will not',
            'Even wonder about these things.',
            'But merely to entertain doubts',
            'About saṃsāra will make it fall apart.',
        ],
        title: { tibetan: 'བཞི་བརྒྱ་པ།', english: 'Four Hundred Verses' },
        author: { tibetan: 'འཕགས་པ་ལྷ། ', english: 'Āryadeva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0611': {
        tibetan: [
            'བསོད་ནམས་མིན་པ་དང་པོར་བཟློག །',
            'བར་དུ་བདག་ནི་བཟློག་པ་དང༌། །',
            'ཕྱི་ནས་ལྟ་བ་ཀུན་བཟློག་པ། །',
            'གང་གིས་ཤེས་དེ་མཁས་པ་ཡིན། །',
        ],
        english: [
            'At first, turn away from non-virtue,',
            'In the middle, dispel misconceptions of self,',
            'Finally, go beyond all philosophical views—',
            'One who understands this is wise indeed.',
        ],
        title: { tibetan: 'བཞི་བརྒྱ་པ།', english: 'Four Hundred Verses' },
        author: { tibetan: 'འཕགས་པ་ལྷ། ', english: 'Āryadeva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0612': {
        tibetan: [
            'དངོས་པོ་གཅིག་གི་ལྟ་པོ་གང༌། །',
            'དེ་ནི་ཀུན་གྱི་ལྟ་པོར་བཤད། །',
            'གཅིག་གི་སྟོང་ཉིད་གང་ཡིན་པ། །',
            'དེ་ནི་ཀུན་གྱི་སྟོང་པ་ཉིད། །',
        ],
        english: [
            'Whoever sees the nature of one thing',
            'Is said to see the nature of everything.',
            'For the emptiness of one thing',
            'Is the emptiness of everything.',
        ],
        title: { tibetan: 'བཞི་བརྒྱ་པ།', english: 'Four Hundred Verses' },
        author: { tibetan: 'འཕགས་པ་ལྷ། ', english: 'Āryadeva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0613': {
        tibetan: [
            'སའམ་འོན་ཏེ་མཐོ་རིས་ན། །',
            'སྐྱེས་ནས་ལ་ལ་མ་ཤི་བ། །',
            'འགའ་ཞིག་ཁྱོད་ཀྱིས་མཐོང་བའམ། །',
            'ཐོས་སམ་འོན་ཏེ་ཐེ་ཚོམ་ཟ། །',
        ],
        english: [
            'Have you ever, on earth or in the heavens,',
            'Seen a being who was born but will not die?',
            'Have you ever heard that this had happened?',
            'Or even had suspicions that it might?',
        ],
        title: {
            tibetan: 'མྱ་ངན་བསལ་བའི་སྤྲིངས་ཡིག',
            english: 'Letter of Consolation',
        },
        author: { tibetan: 'རྟ་དབྱངས།', english: 'Aśvaghoṣa' },
        links: { lh: '' },
        isPartial: true,
    },
    '0614': {
        tibetan: [
            'དྲང་སྲོང་ཆེན་པོ་ལྔ་མངོན་ཤེས། །',
            'མཁའ་ལ་རྒྱང་རིང་འགྲོ་བ་ཡང་། །',
            'གང་ན་འཆི་མེད་སྤྱོད་ཡུལ་བའི། །',
            'གནས་དེར་འགྲོ་བ་ཡོད་མ་ཡིན།',
        ],
        english: [
            'Great ṛṣis with the five superknowledges,',
            'Can fly far and wide through the sky,',
            'Yet they will never reach a place',
            'Where they might live and never die.',
        ],
        title: {
            tibetan: 'མྱ་ངན་བསལ་བའི་སྤྲིངས་ཡིག',
            english: 'Letter of Consolation',
        },
        author: { tibetan: 'རྟ་དབྱངས།', english: 'Aśvaghoṣa' },
        links: { lh: '' },
        isPartial: true,
    },
    '0615': {
        tibetan: ['བླ་མའི་མཆོག་མཚང་ལ་རྒོལ་བ་ཡིན། །', 'གདམས་ངག་གི་མཆོག་མཚང་ཐོག་ཏུ་འབེབས་པ་ཡིན། །'],
        english: [
            'The best guru is one who attacks your hidden faults',
            'The best instructions are the ones that target those faults.',
        ],
        title: { tibetan: '', english: '' },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: { lh: '' },
        isPartial: '',
    },
    '0616': {
        tibetan: ['ཆུང་ངུ་འབྲིུང་དང་མཆོག་གྱུར་བའི། །', 'སྐྱེས་བུ་གསུམ་དུ་ཤེས་པར་བྱ། །'],
        english: [
            'You should know that beings are of three kinds—',
            'Those of lesser, intermediate and supreme capacity.',
        ],
        title: {
            tibetan: 'བྱང་ཆུབ་ལམ་སྒྲོན།',
            english: 'Lamp for the Path to Enlightenment',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: { lh: '' },
        isPartial: true,
    },
    '0617': {
        tibetan: [
            'གང་ཞིག་ཐབས་ནི་གང་དག་གིས། །',
            'འཁོར་བའི་བདེ་བ་ཙམ་དག་ལ། །',
            'རང་ཉིད་དོན་དུ་གཉེར་བྱེད་པ། །',
            'དེ་ནི་སྐྱེས་བུ་ཐ་མར་ཤེས། །',
        ],
        english: [
            'Those who strive by any means',
            'To gain only the pleasures of saṃsāra',
            'For themselves alone—',
            "Such people are called 'lesser' individuals.",
        ],
        title: {
            tibetan: 'བྱང་ཆུབ་ལམ་སྒྲོན།',
            english: 'Lamp for the Path to Enlightenment',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: { lh: '' },
        isPartial: true,
    },
    '0618': {
        tibetan: [
            'སྲིད་པའི་བདེ་ལ་རྒྱབ་ཕྱོགས་ཤིང་། །',
            'སྡིག་པའི་ལས་ལས་ལྡོག་བདག་ཉིད། །',
            'གང་ཞིག་རང་ཞི་ཙམ་དོན་གཉེར། །',
            'སྐྱེས་བུ་དེ་ནི་འབྲིང་ཞེས་བྱ། །',
        ],
        english: [
            'Those who turn their backs on worldly pleasures,',
            'And avoid any harmful actions,',
            'Striving for peace for themselves alone--',
            "Such individuals are said to be 'intermediate'.",
        ],
        title: {
            tibetan: 'བྱང་ཆུབ་ལམ་སྒྲོན།',
            english: 'Lamp for the Path to Enlightenment',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: { lh: '' },
        isPartial: true,
    },
    '0619': {
        tibetan: [
            'རང་རྒྱུད་གཏོགས་པའི་སྡུག་བསྔལ་གྱིས། །',
            'གང་ཞིག་གཞན་གྱི་སྡུག་བསྔལ་ཀུན། །',
            'ཡང་དག་ཟད་པར་ཀུན་ནས་འདོད། །',
            'སྐྱེས་བུ་དེ་ནི་མཆོག་ཡིན་ནོ། །',
        ],
        english: [
            'Those who long to put a complete end',
            'To all the sufferings of others',
            'Through the sufferings of their own experience--',
            'Individuals such as these are supreme.',
        ],
        title: {
            tibetan: 'བྱང་ཆུབ་ལམ་སྒྲོན།',
            english: 'Lamp for the Path to Enlightenment',
        },
        author: {
            tibetan: 'ཇོ་བོ་རྗེ་དཔལ་ལྡན་ཨ་ཏི་ཤ།',
            english: 'Atiśa Dīpaṃkara',
        },
        links: { lh: '' },
        isPartial: true,
    },
    '0620': {
        tibetan: [
            'ཡང་དག་ཀུན་རྫོབ་རྣམས་ཀྱི་སྐས། །',
            'མེད་པར་ཡང་དག་ཁང་ཆེན་གྱི། །',
            'སྟེང་དུ་འགྲོ་བར་བྱ་བ་ནི། །',
            'མཁས་ལ་རུང་བ་མ་ཡིན་ནོ། །',
        ],
        english: [
            'Trying to reach the great mansion',
            'Of the authentic nature of reality',
            'Without the steps of the authentic relative',
            'Is not an approach the wise should take.',
        ],
        title: { tibetan: 'དབུ་མ་སྙིང་པོ།', english: 'Heart of the Middle Way' },
        author: { tibetan: 'ལེགས་ལྡན་འབྱེད།', english: 'Bhāvaviveka' },
        links: { lh: '' },
        isPartial: '',
    },
    '0621': {
        tibetan: [
            'བདེར་གཤེགས་ལམ་བརྟེན་འགྲོ་བ་འདྲེན་པར་ཆས་གྱུར་ཅིང་། །',
            'སེམས་ཀྱི་སྟོབས་ཆེན་མི་ཡིས་རྙེད་པ་གང་ཡིན་པ། །',
            'ལམ་དེ་ལྷ་དང་ཀླུ་ཡིས་རྙེད་མིན་ལྷ་མིན་དང་། །',
            'མཁའ་ལྡིང་རིག་འཛིན་མི་འམ་ཅི་དང་ལྟོ་འཕྱེས་མིན། །',
        ],
        english: [
            'The path followed and taught by the Buddha in order to guide the world',
            'Is within the reach of human beings with strength of heart,',
            'But cannot be attained by the gods, nāgas,',
            'Asuras, garuḍas, vidyādharas, kinnaras or uragas.',
        ],
        title: { tibetan: 'སློབ་སྤྲིང་།', english: 'Letter to a Disciple' },
        author: { tibetan: 'ཙནྡྲ་གོ་མིན།', english: 'Candragomin' },
        links: { lh: '' },
        isPartial: '',
    },
    '0622': {
        tibetan: [
            'ཉན་ཐོས་སངས་རྒྱས་འབྲིང་རྣམས་ཐུབ་དབང་སྐྱེས། །',
            'སངས་རྒྱས་བྱང་ཆུབ་སེམས་དཔའ་ལས་འཁྲུངས་ཤིང༌། །',
            'སྙིང་རྗེའི་སེམས་དང་གཉིས་སུ་མེད་བློ་དང༌། །',
            'བྱང་ཆུབ་སེམས་ནི་རྒྱལ་སྲས་རྣམས་ཀྱི་རྒྱུ། །',
        ],
        english: [
            'Śrāvakas and intermediate buddhas are born from the mighty sages,',
            'And the fully awakened buddhas are born from the bodhisattvas;',
            'A compassionate mind, understanding of non-duality,',
            "And bodhichitta: these are the causes of the victors' heirs.",
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0623': {
        tibetan: [
            'གང་ཕྱིར་བརྩེ་ཉིད་རྒྱལ་བའི་ལོ་ཐོག་ཕུན་ཚོགས་འདིའི། །',
            'ས་བོན་དང་ནི་སྤེལ་ལ་ཆུར་འདྲ་ཡུན་རིང་དུ། །',
            'ལོངས་སྤྱོད་གནས་ལ་སྨིན་པ་ལྟ་བུར་འདོད་གྱུར་པ། །',
            'དེ་ཕྱིར་བདག་གིས་ཐོག་མར་སྙིང་རྗེ་བསྟོད་པར་བགྱི། །',
        ],
        english: [
            'Love is the seed of this abundant harvest of buddhahood.',
            'It is like the water which causes growth and expansion,',
            'And it ripens into the state of lasting enjoyment,',
            'Therefore at the outset I shall praise compassion!',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0624': {
        tibetan: [
            'དང་པོར་ང་ཞེས་བདག་ལ་ཞེན་གྱུར་ཞིང༌། །',
            'བདག་གི་འདི་ཞེས་དངོས་ལ་ཆགས་བསྐྱེད་པ། །',
            'ཟོ་ཆུན་འཕྱན་ལྟར་རང་དབང་མེད་པ་ཡི། །',
            'འགྲོ་ལ་སྙིང་རྗེར་གྱུར་གང་དེ་ལ་འདུད། །',
        ],
        english: [
            'Firstly with the thought of “I”, they cling to self,',
            'And then with “mine”, they grow attached to things,',
            'Helplessly, they wander like a turning waterwheel—',
            'To compassion for these beings, I bow down!',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0625': {
        tibetan: [
            'སོ་སོ་སྐྱེ་བོའི་དུས་ནའང་སྟོང་པ་ཉིད་ཐོས་ནས། །',
            'ནང་དུ་རབ་ཏུ་དགའ་བ་ཡང་དང་ཡང་དུ་འབྱུང༌། །',
            'རབ་ཏུ་དགའ་བ་ལས་བྱུང་མཆི་མས་མིག་བརླན་ཞིང༌། །',
            'ལུས་ཀྱི་བ་སྤུ་ལྡང་བར་འགྱུར་བ་གང་ཡིན་པ། །',
            'དེ་ལ་རྫོགས་པའི་སངས་རྒྱས་བློ་ཡིས་ས་བོན་ཡོད། །',
            'དེ་ཉིད་ཉེ་བར་བསྟན་པའི་སྣོད་ནི་དེ་ཡིན་ཏེ། །',
            'དེ་ལ་དམ་པའི་དོན་གྱི་བདེན་པ་བསྟན་པར་བྱ། །',
        ],
        english: [
            'Those who, when hearing about emptiness,',
            'Are inspired with great joy again and again,',
            'So that their eyes moisten with tears,',
            'And the hairs on their body stand on end,',
            'Possess the potential for complete awakening.',
            'They are the proper vessels for these teachings,',
            'To them one should teach the ultimate truth.',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0626': {
        tibetan: [
            'དངོས་ཀུན་ཡང་དག་བརྫུན་པ་མཐོང་བ་ཡིས། །',
            'དངོས་རྙེད་ངོ་བོ་གཉིས་ནི་འཛིན་པར་འགྱུར། །',
            'ཡང་དག་མཐོང་ཡུལ་གང་དེ་དེ་ཉིད་དེ། །',
            'མཐོང་བ་བརྫུན་པ་ཀུན་རྫོབ་བདེན་པར་གསུངས། །',
        ],
        english: [
            'All things may be seen correctly or incorrectly;',
            'And so it is that they possess a dual identity.',
            'The ultimate is what is seen correctly,',
            "The wrongly seen is superficial truth, it's said.",
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0627': {
        tibetan: [
            'སྐྱོན་ལྡན་དབང་ཅན་རྣམས་ཀྱི་ཤེས་པ་ནི། །',
            'དབང་པོ་ལེགས་གྱུར་ཤེས་ལྟོས་ལོག་པར་འདོད། །',
            'གནོད་པ་མེད་པའི་དབང་པོ་དྲུག་རྣམས་ཀྱིས། །',
            'གཟུང་བ་གང་ཞིག་འཇིག་རྟེན་གྱིས་རྟོགས་ཏེ། །',
            'འཇིག་རྟེན་ཉིད་ལས་བདེན་ཡིན་ལྷག་མ་ནི། །',
            'འཇིག་རྟེན་ཉིད་ལས་ལོག་པར་རྣམ་པར་བཞག །',
        ],
        english: [
            'Cognitions that derive from impaired faculties,',
            'Are false compared with healthy sense cognitions.',
            "All that's apprehended by the six undamaged senses,",
            'Is taken to be true, according to the world.',
            'The rest, according to the world, is false.',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0628': {
        tibetan: [
            'གཏི་མུག་རང་བཞིན་སྒྲིབ་ཕྱིར་ཀུན་རྫོབ་སྟེ། །',
            'དེས་གང་བཅོས་མ་བདེན་པར་སྣང་དེ་ནི། །',
            'ཀུན་རྫོབ་བདེན་ཞེས་ཐུབ་པ་དེས་གསུངས་ཏེ། །',
            'བཅོས་མར་གྱུར་པའི་དངོས་ནི་ཀུན་རྫོབ་ཏུའོ། །',
        ],
        english: [
            'The nature of things is obscured by delusion, so it is “all-concealed.”',
            'But this fabrication appears to us as true,',
            'And so the Buddha spoke of it as superficial truth.',
            'Entities that are thus contrived are “relatively” true.',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0629': {
        tibetan: ['བདེན་གཉིས་སུའང་རང་བཞིན་མེད་པའི་ཕྱིར། །', 'དེ་དག་རྟག་པ་མ་ཡིན་ཆད་པའང་མིན། །'],
        english: [
            'Since they lack true existence on both levels of reality,',
            'These phenomena are neither non-existent nor everlasting.',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0630': {
        tibetan: [
            'ཐ་སྙད་བདེན་པ་ཐབས་སུ་གྱུར་པ་དང༌། །',
            'དོན་དམ་བདེན་པ་ཐབས་བྱུང་གྱུར་པ་སྟེ། །',
            'བདེན་གཉིས་རྣམ་དབྱེ་གང་གིས་མི་ཤེས་པ། །',
            'དེ་ནི་རྣམ་རྟོག་ལོག་པའི་ལམ་དུ་ཞུགས། །',
        ],
        english: [
            'Conventional truth is the method;',
            'And the ultimate is its outcome.',
            'Not knowing how the two truths differ,',
            'Your thoughts will go astray.',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0701': {
        tibetan: [
            'བསྟན་བཅོས་ལས་དཔྱད་རྩོད་ལ་ཆགས་པའི་ཕྱིར། །',
            'མ་མཛད་རྣམ་གྲོལ་ཕྱིར་ནི་དེ་ཉིད་བསྟན། །',
            'གལ་ཏེ་དེ་ཉིད་རྣམ་པར་བཤད་པ་ན། །',
            'གཞན་གཞུང་འཇིག་པར་འགྱུར་ན་ཉེས་པ་མེད། །',
        ],
        english: [
            'The analyses in this treatise are not given',
            'Out of an excessive fondness for debate.',
            'It is not our fault if, in the course of this teaching,',
            'Other philosophical systems come to be destroyed.',
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0702': {
        tibetan: [
            'ནམ་མཁའ་མེད་པས་འདབ་ཆགས་ལྡོག་པར་མི་འགྱུར་གྱི། །',
            'འདི་ནི་རང་མཐུ་ཟད་པས་ལྡོག་པར་འགྱུར་དེ་བཞིན། །',
            'སློབ་མ་དང་བཅས་སངས་རྒྱས་སྲས་རྣམས་སངས་རྒྱས་ཀྱི། །',
            'ཡོན་ཏན་མཁའ་ལྟར་མཐའ་ཡས་མ་བརྗོད་ལྡོག་པར་འགྱུར། །',
        ],
        english: [
            't is not because they run out of sky that birds turn back,',
            'It is because they lack the strength to keep on flying.',
            'Just so, the śrāvaka disciples and the bodhisattvas,',
            "Can not express Buddha's qualities, as infinite as space.",
        ],
        title: {
            tibetan: 'དབུ་མ་ལ་འཇུག་པ།',
            english: 'Introduction to the Middle Way',
        },
        author: { tibetan: 'ཟླ་བ་གྲགས་པ།', english: 'Candrakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0703': {
        tibetan: ['སེམས་ཀྱི་རང་བཞིན་འོད་གསལ་ཏེ། །', 'དྲི་མ་རྣམས་ནི་གློ་བུར་བ། །'],
        english: ['The nature of mind is clear light,', 'Defilements are only adventitious.'],
        title: {
            tibetan: 'ཚད་མ་རྣམ་འགྲེལ།',
            english: 'Commentary on Valid Cognition',
        },
        author: { tibetan: 'ཆོས་ཀྱི་གྲགས་པ།', english: 'Dharmakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0704': {
        tibetan: [
            'བདག་ཡོད་ན་ནི་གཞན་དུ་ཤེས། །',
            'བདག་གཞན་ཆ་ལས་འཛིན་དང་སྡང་། །',
            'འདི་དག་དང་ནི་ཡོངས་འབྲེལ་ལས། །',
            'ཉེས་པ་ཐམས་ཅད་འབྱུང་བར་འགྱུར། །',
        ],
        english: [
            'When there is an “I”, there is a perception of other,',
            'And from the ideas of self and other come attachment and aversion,',
            'As a result of getting wrapped up in these,',
            'All possible faults come into being.',
        ],
        title: {
            tibetan: 'ཚད་མ་རྣམ་འགྲེལ།',
            english: 'Commentary on Valid Cognition',
        },
        author: { tibetan: 'ཆོས་ཀྱི་གྲགས་པ།', english: 'Dharmakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0705': {
        tibetan: ['དོན་དམ་དོན་བྱེད་ནུས་པ་གང༌། །', 'དེ་འདིར་དོན་དམ་ཡོད་པ་ཡིན། །', 'གཞན་ནི་ཀུན་རྫོབ་ཡོད་པ་སྟེ། །'],
        english: [
            'That which can ultimately perform a function',
            'Is here said to be ultimately existent.',
            'All else besides has relative existence.',
        ],
        title: {
            tibetan: 'ཚད་མ་རྣམ་འགྲེལ།',
            english: 'Commentary on Valid Cognition',
        },
        author: { tibetan: 'ཆོས་ཀྱི་གྲགས་པ།', english: 'Dharmakīrti' },
        links: { lh: '' },
        isPartial: true,
    },
    '0706': {
        tibetan: ['ངོ་རང་ཐོག་ཏུ་སྤྲད། །', 'ཐག་གཅིག་ཐོག་ཏུ་བཅད། །', 'གདེང་གྲོལ་ཐོག་ཏུ་བཅའ། །'],
        english: [
            'Introducing directly the face of rigpa in itself,',
            'Decide upon one thing and one thing only,',
            'Confidence directly in the liberation of rising thoughts.',
        ],
        title: {
            tibetan: 'ཚིག་གསུམ་གནད་དུ་བརྡེགས་པ།',
            english: 'The Three Statements that Strike the Vital Point',
        },
        author: { tibetan: 'དགའ་རབ་རྡོ་རྗེ།', english: 'Garab Dorjé' },
        links: {
            lh: 'https://www.lotsawahouse.org/indian-masters/garab-dorje/three-statements-that-strike-vital-point',
        },
        isPartial: true,
    },
    '0707': {
        tibetan: [
            'སེམས་ཀྱི་རང་བཞིན་ཡེ་ནས་བུདྡྷ་སྟེ༔',
            'སེམས་ལ་སྐྱེ་འགག་མེད་པ་ནམ་མཁའ་འདྲ༔',
            'ཆོས་ཀུན་མཉམ་ཉིད་ཡང་དག་དོན་རྟོགས་ནས༔',
            'དེ་ཉིད་མ་བཙལ་བཞག་ན་སྒོམ་པ་ཡིན༔',
        ],
        english: [
            "Mind's nature is and always has been Buddha.",
            'It has neither birth nor cessation, like space.',
            'When you realize the real meaning of the equal nature of all things,',
            'To remain in that state without searching is meditation.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'དགའ་རབ་རྡོ་རྗེ།', english: 'Garab Dorjé' },
        links: { lh: '' },
        isPartial: '',
    },
    '0708': {
        tibetan: [
            'གདོད་ནས་དག་པ་དབྱིངས་ཀྱི་ངང་ཉིད་ལས། །',
            'རིག་པ་ཐོལ་སྐྱེས་སྐད་ཅིག་དྲན་པ་དེ། །',
            'རྒྱ་མཚོའི་གཏིང་ནས་ནོར་བུ་རྙེད་པ་འདྲ། །',
            'སུས་ཀྱང་མ་བཅོས་མ་བྱས་ཆོས་ཀྱི་སྐུ། །',
        ],
        english: [
            'When, from out of the primordially pure Dharmadhātu,',
            'Suddenly awareness arises, and with it there is an instantaneous recognition, it is',
            'Like finding a precious jewel in the depths of the ocean,',
            'No-one has created it - it is just the Dharmakāya.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'དགའ་རབ་རྡོ་རྗེ།', english: 'Garab Dorjé' },
        links: { lh: '' },
        isPartial: '',
    },
    '0709': {
        tibetan: [
            'བདེན་གཉིས་རྣམ་དབྱེ་ཤེས་པ་དག །',
            'ཐུབ་པའི་བཀའ་ལ་མི་རྨོངས་ཏེ། །',
            'དེ་དག་མ་ལུས་ཚོགས་བསགས་ནས། །',
            'ཕུན་ཚོགས་ཕ་རོལ་འགྲོ་བ་ཉིད། །',
        ],
        english: [
            'Those who can distinguish between the two truths,',
            "Will not be confused about the Buddha's Words.",
            'Gathering all the accumulations of merit and wisdom,',
            'They will win perfection and reach the other shore.',
        ],
        title: {
            tibetan: 'བདེན་གཉིས་རྣམ་འབྱེད།',
            english: 'Distinguishing the Two Truths',
        },
        author: { tibetan: 'ཡེ་ཤེས་སྙིང་པོ།', english: 'Jñānagarbha' },
        links: { lh: '' },
        isPartial: true,
    },
    '0710': {
        tibetan: [
            'སྐྱེ་བ་ལ་སོགས་བཀག་པ་ཡང༌། །',
            'ཡང་དག་པ་དང་མཐུན་ཉིད་བཞེད། །',
            'སྐྱེ་མེད་སྤྲོས་ཀུན་ཞི་བ་ལ། །',
            'དོན་དམ་ཞེས་པའི་བརྡ་མཛད་དོ། །',
        ],
        english: [
            'Refutations of origination and so forth,',
            'Are in accordance with reality.',
            'Pacifying all notions of non-origination',
            'Is what we call the ultimate.',
        ],
        title: {
            tibetan: 'བདེན་གཉིས་རྣམ་འབྱེད།',
            english: 'Distinguishing the Two Truths',
        },
        author: { tibetan: 'ཡེ་ཤེས་སྙིང་པོ།', english: 'Jñānagarbha' },
        links: { lh: '' },
        isPartial: true,
    },
    '0711': {
        tibetan: [
            'སྣང་དུ་འདྲ་ཡང་དོན་བྱེད་དག །',
            'ནུས་པའི་ཕྱིར་དང་མི་ནུས་ཕྱིར། །',
            'ཡང་དག་ཡང་དག་མ་ཡིན་ཏེ། །',
            'ཀུན་རྫོབ་ཀྱི་ན་དབྱེ་བའང་བྱས། །',
        ],
        english: [
            'According to whether or not',
            'They can function as they appear,',
            'Relative phenomena are divided into',
            'The authentic and the inauthentic.',
        ],
        title: {
            tibetan: 'བདེན་གཉིས་རྣམ་འབྱེད།',
            english: 'Distinguishing the Two Truths',
        },
        author: { tibetan: 'ཡེ་ཤེས་སྙིང་པོ།', english: 'Jñānagarbha' },
        links: { lh: '' },
        isPartial: true,
    },
    '0712': {
        tibetan: [
            'དུ་མས་གཅིག་གི་དངོས་མི་བྱེད། །',
            'དུ་མས་དུ་མ་བྱེད་མ་ཡིན། །',
            'གཅིག་གིས་དུ་མའི་དངོས་མི་བྱེད། །',
            'གཅིག་གིས་གཅིག་བྱེད་པ་ཡང་མིན། །',
        ],
        english: [
            'Several things do not produce just one thing,',
            'And many things do not create a multiplicity.',
            'One thing is not produced by many things.',
            'And from a single thing, a single thing is not produced.',
        ],
        title: {
            tibetan: 'བདེན་གཉིས་རྣམ་འབྱེད།',
            english: 'Distinguishing the Two Truths',
        },
        author: { tibetan: 'ཡེ་ཤེས་སྙིང་པོ།', english: 'Jñānagarbha' },
        links: { lh: '' },
        isPartial: true,
    },
    '0713': {
        tibetan: [
            'ཕ་ཤ་ཟ་ཞིང་མ་ལ་རྡེག །',
            'ལས་ངན་དགྲ་བོ་པང་ན་བཟུང༌། །',
            'ཆུང་མས་ཁྱོ་ཡི་རུས་པ་འཆའ། །',
            'འཁོར་བའི་ཆོས་ལ་གད་མོ་བྲོ། །',
        ],
        english: [
            "He eats his father's flesh while striking his own mother,",
            'And cradles in his lap the enemy he killed,',
            "The wife is gnawing at her husband's bones.",
            'Saṃsāra is enough to make you laugh out loud!',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'ཀཱ་ཏྱཱ་ཡ་ན།', english: 'Kātyāyana' },
        links: { lh: '' },
        isPartial: '',
    },
    '0714': {
        tibetan: [
            'ཡི་གེ་འབྲི་མཆོད་སྦྱིན་པ་དང༌། །',
            'ཉན་དང་ཀློག་དང་ལེན་པ་དང༌། །',
            'འཆད་དང་ཁ་དོན་བྱེད་པ་དང༌། །',
            'དེ་སེམས་པ་དང་བསྒོམ་པའོ། །',
            'སྤྱོད་པ་དེ་བཅུའི་བདག་ཉིད་ནི། །',
            'བསོད་ནམས་ཕུང་པོ་དཔག་ཏུ་མེད། །',
        ],
        english: [
            'Copying texts, making offerings, charity,',
            'Study, reading, memorizing,',
            'Explaining, reciting aloud,',
            'Contemplating and meditating—',
            'These ten activities',
            'Bring merit beyond measure.',
        ],
        title: {
            tibetan: 'དབུས་མཐའ་རྣམ་འབྱེད།',
            english: 'Distinguishing the Middle from Extremes',
        },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0715': {
        tibetan: ['སེམས་བསྐྱེད་པ་ནི་གཞན་དོན་ཕྱིར། །', 'ཡང་དག་རྫོགས་པའི་བྱང་ཆུབ་འདོད། །'],
        english: ['Arousing bodhicitta is: For the sake of others,', 'Longing to attain complete enlightenment.'],
        title: {
            tibetan: 'མངོན་རྟོགས་རྒྱན།',
            english: 'Ornament of Clear Realization',
        },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0716': {
        tibetan: [
            'འདི་ལས་བསལ་བྱ་ཅི་ཡང་མེད། །',
            'གཞག་པར་བྱ་བ་ཅུང་ཟད་མེད། །',
            'ཡང་དག་ཉིད་ལ་ཡང་དག་ལྟ། །',
            'ཡང་དག་མཐོང་ན་རྣམ་པར་གྲོལ། །',
        ],
        english: [
            'In this, there is not a thing to be removed,',
            'Nor the slightest thing to be added.',
            'It is looking perfectly into reality itself,',
            'And when reality is seen, complete liberation.',
        ],
        title: {
            tibetan: 'མངོན་རྟོགས་རྒྱན།',
            english: 'Ornament of Clear Realization',
        },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0717': {
        tibetan: [
            'སྦྱིན་པ་མི་མཐུན་ཕྱོགས་རྣམས་དང་། །',
            'རྣམ་པར་མི་རྟོག་ཡེ་ཤེས་ལྡན། །',
            'འདོད་པ་ཐམས་ཅད་ཡོངས་རྫོགས་བྱེད། །',
            'སེམས་ཅན་རྣམ་སྨིན་བྱེད་རྣམ་གསུམ། །',
        ],
        english: [
            'Generosity in which adverse factors have disappeared,',
            'Endowed with wisdom that is non-conceptual,',
            'Completely fulfills all wishes,',
            'And brings all beings to maturity at the three levels.',
        ],
        title: {
            tibetan: 'མདོ་སྡེ་རྒྱན།',
            english: 'Ornament of Mahāyāna Sūtras',
        },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0718': {
        tibetan: [
            'བཤེས་གཉེན་དུལ་བ་ཞི་ཞིང་ཉེར་ཞི་བ། །',
            'ཡོན་ཏན་ལྷག་པ་བརྩོན་བཅས་ལུང་གིས་ཕྱུག །',
            'དེ་ཉིད་རབ་ཏུ་རྟོགས་པ་སྨྲ་མཁས་ལྡན། །',
            'བརྩེ་བའི་བདག་ཉིད་སྐྱོ་བ་སྤངས་ལ་བསྟེན། །',
        ],
        english: [
            'One should follow a spiritual teacher who is disciplined, peaceful, serene,',
            'Endowed with special qualities, diligent, rich in scriptural learning,',
            'Highly realized concerning the nature of reality, skilled in speaking,',
            'The embodiment of love, and indefatigable.',
        ],
        title: {
            tibetan: 'མདོ་སྡེ་རྒྱན།',
            english: 'Ornament of Mahāyāna Sūtras',
        },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0719': {
        tibetan: [
            'སྤངས་ཕྱིར་བསླུ་བའི་ཆོས་ཅན་ཕྱིར། །',
            'མེད་ཕྱིར་འཇིགས་དང་བཅས་པའི་ཕྱིར། །',
            'ཆོས་རྣམས་གཉིས་དང་དགེ་འདུན་ནི། །',
            'གཏན་གྱི་སྐྱབས་ཡུལ་མ་ཡིན་ནོ། །',
        ],
        english: [
            'Because it is discarded, because it is inconstant,',
            'Because they do not have, because they are afraid,',
            'The Dharma in its two aspects and the sangha',
            'Are not the ultimate and lasting refuge.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0720': {
        tibetan: [
            'དམ་པའི་དོན་དུ་འགྲོ་བ་ཡི། །',
            'སྐྱབས་ནི་སངས་རྒྱས་ཉག་གཅིག་ཡིན། །',
            'ཐུབ་པ་ཆོས་ཀྱི་སྐུ་ཅན་ཕྱིར། །',
            'ཚོགས་ཀྱང་དེ་ཡི་མཐར་ཐུག་ཕྱིར། །',
        ],
        english: [
            'On an ultimate level',
            'The only refuge is the Buddha.',
            'The Sage embodies the Dharma,',
            'And is thus the culmination of the Saṅgha.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0721': {
        tibetan: [
            'རྫོགས་སངས་སྐུ་ནི་འཕྲོ་ཕྱིར་དང༌། །',
            'དེ་བཞིན་ཉིད་དབྱེར་མེད་ཕྱིར་དང༌། །',
            'རིགས་ཡོད་ཕྱིར་ན་ལུས་ཅན་ཀུན། །',
            'རྟག་ཏུ་སངས་རྒྱས་སྙིང་པོ་ཅན། །',
        ],
        english: [
            "Because the perfect buddhas's kāya is all-pervading,",
            'Because reality is undifferentiated,',
            'And because they possess the potential,',
            'Beings always have the buddha nature.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0722': {
        tibetan: [
            'མ་དག་མ་དག་དག་པ་དང༌། །',
            'ཤིན་ཏུ་རྣམ་དག་གོ་རིམ་བཞིན། །',
            'སེམས་ཅན་བྱང་ཆུབ་སེམས་དཔའ་དང༌། །',
            'དེ་བཞིན་གཤེགས་པ་ཞེས་བརྗོད་དོ། །',
        ],
        english: [
            'According to the phases of impure,',
            'Partially pure and completely pure,',
            'We speak of sentient beings, bodhisattvas',
            'And the thus-gone buddhas.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0723': {
        tibetan: [
            'རྣམ་དབྱེ་བཅས་པའི་མཚན་ཉིད་ཅན། །',
            'གློ་བུར་དག་གིས་ཁམས་སྟོང་གི །',
            'རྣམ་དབྱེ་མེད་པའི་མཚན་ཉིད་ཅན། །',
            'བླ་མེད་ཆོས་ཀྱིས་སྟོང་མ་ཡིན། །',
        ],
        english: [
            'The disposition is empty of the adventitious stains,',
            'Which are characterized by their total separateness.',
            'But it is not empty of the unsurpassed qualities,',
            'Which have the character of total inseparability.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0724': {
        tibetan: ['མི་གཙང་བ་ལ་དྲི་ཞིམ་མེད་བཞིན་འགྲོ་ལྔ་དག་ན་བདེ་བ་མེད། །'],
        english: [
            'Just as there can be no pleasant fragrance in a cesspit,',
            'There is no joy among the five classes of beings.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0725': {
        tibetan: [
            'ནད་ནི་ཤེས་བྱ་ནད་ཀྱི་རྒྱུ་ནི་སྤང་བྱ་ལ། །',
            'བདེ་གནས་ཐོབ་བྱ་སྨན་ནི་བསྟེན་པར་བྱ་བ་ལྟར། །',
            'སྡུག་བསྔལ་རྒྱུ་དང་དེ་འགོག་པ་དང་དེ་བཞིན་ལམ། །',
            'ཤེས་བྱ་སྤང་བྱ་རིག་པར་བྱ་ཞིང་བསྟེན་པར་བྱ། །',
        ],
        english: [
            'Illness must be understood, its causes eliminated,',
            'Wellbeing must be attained, and medicine taken.',
            'Likewise, suffering, its causes, their cessation and the path',
            'Must in turn be understood, eliminated, realized and relied upon.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0726': {
        tibetan: [
            'སེར་སྣ་ལ་སོགས་རྣམ་རྟོག་གང༌། །',
            'དེ་ནི་ཉོན་མོངས་སྒྲིབ་པར་འདོད། །',
            'འཁོར་གསུམ་རྣམ་པར་རྟོག་པ་གང༌། །',
            'དེ་ནི་ཤེས་བྱའི་སྒྲིབ་པར་འདོད། །',
        ],
        english: [
            'Any thought such as miserliness and so on',
            'Is held to be an afflictive obscuration.',
            "Any thought of 'subject', 'object' and 'action'",
            'Is held to be a cognitive obscuration.',
        ],
        title: { tibetan: 'རྒྱུད་བླ་མ།', english: 'Sublime Continuum' },
        author: { tibetan: 'བྱམས་པ་མགོན་པོ།', english: 'Buddha Maitreya' },
        links: { lh: '' },
        isPartial: true,
    },
    '0727': {
        tibetan: [
            'ཕུང་པོ་རྣམ་དཔྱད་སྟོང་པ་ཉིད། །',
            'ཆུ་ཤིང་བཞིན་དུ་སྙིང་པོ་མེད། །',
            'རྣམ་པ་ཀུན་གྱི་མཆོག་ལྡན་པའི། །',
            'སྟོང་ཉིད་དེ་ལྟར་མ་ཡིན་ནོ། །',
        ],
        english: [
            'The aggregates, when analysed, are found to be emptiness,',
            'Devoid of any substance, like a hollow plantain tree,',
            'But this form of emptiness is not like the emptiness',
            'That is endowed with all the supreme attributes.',
        ],
        title: {
            tibetan: 'རང་གི་ལྟ་བའི་འདོད་པ་མདོར་བསྟན།',
            english: 'A Short Teaching Concerning our Assertions on the View',
        },
        author: { tibetan: 'འཇམ་དཔལ་གྲགས་པ།', english: 'Mañjuśrīkīrti' },
        links: { lh: '' },
        isPartial: '',
    },
    '0728': {
        tibetan: [
            'ཀུན་རྫོབ་སྟོང་པ་ཉིད་དུ་བཤད། །',
            'སྟོང་པ་ཁོ་ན་ཀུན་རྫོབ་ཡིན། །',
            'མེད་ན་མི་འབྱུང་ངེས་པའི་ཕྱིར༌། །',
            'བྱས་དང་མི་རྟག་ཇི་བཞིན་ནོ། །',
        ],
        english: [
            'The relative is stated to be emptiness,',
            'And emptiness itself is but the relative.',
            'Without the first, the second is not present,',
            'Just as with creation and impermanence.',
        ],
        title: {
            tibetan: 'བྱང་ཆུབ་སེམས་འགྲེལ།',
            english: 'Commentary on Bodhicitta',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0729': {
        tibetan: [
            'ཁ་ཐོན་མར་མེ་མེ་ལོང་རྒྱ། །',
            'མེ་ཤེལ་ས་བོན་སྐྱུར་དང་སྒྲས། །',
            'ཕུང་པོ་ཉིང་མཚམས་སྦྱོར་བ་ཡང༌། །',
            'མི་འཕོ་བར་ཡང་མཁས་རྟོགས་བྱ། །',
        ],
        english: [
            'Like a recitation, a candle, a mirror, a seal,',
            'A magnifying glass, a seed, sourness, or a sound,',
            'So also with the continuation of the aggregates—',
            'The wise should know they are not transferred.',
        ],
        title: {
            tibetan: 'རྟེན་འབྲེལ་སྙིང་པོ།',
            english: 'Heart of Dependent Origination',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0730': {
        tibetan: [
            'ཆགས་དང་ཞེ་སྡང་གཏི་མུག་དང༌། །',
            'དེས་བསྐྱེད་ལས་ནི་མི་དགེ་བ། །',
            'མ་ཆགས་ཞེ་སྡང་གཏི་མུག་མེད། །',
            'དེས་བསྐྱེད་ལས་ནི་དགེ་བ་ཡིན། །',
        ],
        english: [
            'Desire, hatred and ignorance,',
            'And the actions they generate are non-virtues.',
            'Non-desire, non-hatred, non-ignorance,',
            'And the actions they generate are virtues.',
        ],
        title: { tibetan: 'རིན་ཆེན་ཕྲེང་བ།', english: 'Jewel Garland' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0731': {
        tibetan: [
            'ཇི་སྲིད་ཕུང་པོར་འཛིན་ཡོད་པ། །',
            'དེ་སྲིད་དེ་ལས་ངར་འཛིན་ཡོད། །',
            'ངར་འཛིན་ཡོད་ན་ཡང་ལས་ཏེ། །',
            'དེ་ལས་ཡང་ནི་སྐྱེ་བ་ཡིན། །',
        ],
        english: [
            'As long as the aggregates are conceived,',
            'There will be a conception of “I”,',
            'And when there is a conception of “I”,',
            "There's karma, and from that, rebirth.",
        ],
        title: { tibetan: 'རིན་ཆེན་ཕྲེང་བ།', english: 'Jewel Garland' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0801': {
        tibetan: [
            'འཆི་བའི་རྐྱེན་ནི་མང་བ་སྟེ། །',
            'གསོ་བའི་རྒྱུ་ནི་ཉུང་ཟད་ཅིག །',
            'དེ་དག་ཉིད་ནི་འཆི་བའི་ཡང༌། །',
            'དེ་བས་རྟག་ཏུ་ཆོས་མཛོད་ཅིག །',
        ],
        english: [
            'Causes of death are numerous;',
            'While causes of life are few indeed,',
            'And even those may cause death,',
            'So practise Dharma at all times.',
        ],
        title: { tibetan: 'རིན་ཆེན་ཕྲེང་བ།', english: 'Jewel Garland' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0802': {
        tibetan: [
            'རྗེའུ་ཚོས་སུམ་བརྒྱའི་ཁ་ཟས་དག །',
            'ཉིན་རེ་དུས་གསུམ་སྦྱིན་པས་ཀྱང་། །',
            'བྱམས་པ་ཡུད་ཙམ་ཐང་ཅིག་གི། །',
            'བསོད་ནམས་དག་ལ་ཆར་མི་ཕོད། །',
        ],
        english: [
            'Offerings of three hundred pots of food',
            'Made three times each and every day,',
            'Could not match even a portion of the merit',
            "Gained from just a single moment's loving kindness.",
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0803': {
        tibetan: [
            'གང་ཞིག་སྔོན་ཆད་བག་མེད་གྱུར་པ་ལ། །',
            'ཕྱིས་ནས་བག་དང་ལྡན་པར་གྱུར་པ་དེ། །',
            'ཟླ་བ་སྤྲིན་བྲལ་ལྟ་བུར་རྣམ་མཛེས་ཏེ། །',
            'དགའ་བོ་སོར་ཕྲེང་མཐོང་ལྡན་བདེ་བྱེད་བཞིན། །',
        ],
        english: [
            'Someone who acted carelessly in the past',
            'And then becomes careful and attentive,',
            'Is as beautiful as the moon emerging from the clouds,',
            'Like Nanda, Aṅgulimāla, Darśaka and Śaṅkara.',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0804': {
        tibetan: [
            'ཁ་ཟས་སྨན་དང་འདྲ་བར་རིག་པ་ཡི། །',
            'འདོད་ཆགས་ཞེ་སྡང་མེད་པར་བརྟེན་བགྱི་སྟེ། །',
            'རྒྱགས་ཕྱིར་མ་ལགས་སྙེམས་པའི་ཕྱིར་མ་ལགས། །',
            'མཚག་ཕྱིར་མ་ལགས་ལུས་གནས་འབབ་ཞིག་ཕྱིར། །',
        ],
        english: [
            'Eat with the idea that your food is medicine,',
            'Not with feelings of attachment or aversion,',
            'Or out of conceit and pride in your appearance,',
            'Or a wish to gain weight; eat only to sustain the body.',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0805': {
        tibetan: [
            'ཚེ་འདི་གནོད་མང་རླུང་གིས་བཏབ་པ་ཡི། །',
            'ཆུ་ཡི་ཆུ་བུར་བས་ཀྱང་མི་རྟག་ན། །',
            'དབུགས་དབྱུང་དབུགས་རྔུབ་གཉིད་ཀྱིས་ལོག་པ་ལས། །',
            'སད་ཁོམས་གང་ལགས་དེ་ནི་ངོ་མཚར་ཆེ། །',
        ],
        english: [
            'This life is subject to many dangers,',
            'And as fragile as a bubble in the wind,',
            'While we sleep we breathe in and out,',
            'And it is wondrous that we wake again!',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0806': {
        tibetan: [
            'གང་ཞིག་གསེར་སྣོད་རིན་ཆེན་སྤྲས་པ་ཡིས། །',
            'ངན་སྐྱུགས་འཕྱག་པར་བགྱིས་པ་དེ་བས་ནི། །',
            'གང་ཞིག་མི་རུ་སྐྱེས་ལས་སྡིག་པ་དང༌། །',
            'བགྱིས་པ་དེ་ནི་ཆེས་རབ་བླུན་པ་ལགས། །',
        ],
        english: [
            'Even more stupid than one who cleans up vomit',
            'Using a golden vase bedecked with jewels,',
            'Is the one who, having been born a human,',
            'Devotes his life to committing harmful deeds.',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0807': {
        tibetan: ['མ་ཡིན་ཐུག་མཐའ་རྒྱ་ཤུག་ཚི་གུ་ཙམ། །', 'རིལ་བུར་དྲིལ་ཀྱང་ས་ཡིས་ལངས་མི་འགྱུར། །'],
        english: [
            'There is not enough soil in the world to make a tiny pellet,',
            'The size of a juniper berry, for each of your past mothers.',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0808': {
        tibetan: [
            'སྡིག་ཅན་དབུགས་འབྱུང་འགག་པ་ཙམ་ཞིག་གི །',
            'དུས་ཀྱི་བར་དུ་ཆོད་རྣམས་དམྱལ་བ་ཡི། །',
            'སྡུག་བསྔལ་གཞལ་ཡས་ཐོས་ནས་རྣམ་སྟོང་དུ། །',
            'མི་འཇིགས་གང་ལགས་རྡོ་རྗེའི་རང་བཞིན་ནོ། །',
        ],
        english: [
            'The ceasing of his breath is all that separates',
            "An evildoer from hell's boundless suffering.",
            'Anyone who hears this yet remains unmoved',
            'Must have a mind as hard as a diamond.',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0809': {
        tibetan: [
            'དམྱལ་བ་བྲིས་པ་མཐོང་དང་ཐོས་པ་དང༌། །',
            'དྲན་དང་ཀློག་དང་གཟུགས་སུ་བགྱིས་པས་ཀྱང༌། །',
            'འཇིགས་པ་སྐྱེ་བར་བྱེད་ན་མི་བཟད་པའི། །',
            'རྣམ་སྨིན་ཉམས་སུ་མྱོང་རྣམས་ཅི་བྱར་ཡོད། །',
        ],
        english: [
            'And if just seeing pictures of the hells, or hearing,',
            'Reading and thinking about them brings such terror,',
            'What will you do when you experience there',
            'The unbearable effects of your past actions?',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0810': {
        tibetan: [
            'རྟེན་ཅིང་འབྲེལ་བར་འབྱུང་འདི་སངས་རྒྱས་ཀྱི། །',
            'གསུང་གི་མཛོད་ཀྱི་གཅེས་པ་ཟབ་མོ་སྟེ། །',
            'གང་ཞིག་དེ་ནི་ཡང་དག་མཐོང་བ་དེ། །',
            'ཆོས་མཐོང་བ་དེ་དེ་བཞིན་གཤེགས་པ་མཐོང༌། །',
        ],
        english: [
            'Interdependent origination is a most profound',
            "And cherished treasure among Buddha's teaching.",
            'Anyone who understands it correctly,',
            'Will see the Dharma and see the Buddha.',
        ],
        title: { tibetan: 'བཤེས་སྤྲིངས།', english: 'Letter to a Friend' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0811': {
        tibetan: [
            'ཇི་ལྟར་མར་ངོའི་བཅུ་བཞི་ལ། །',
            'ཟླ་བ་ཅུང་ཞིག་སྣང་བ་ལྟར། །',
            'དེ་བཞིན་མོས་སྤྱོད་ཞུགས་རྣམས་ཀྱིས། །',
            'ཆོས་ཀྱི་སྐུ་ཡང་ཅུང་ཟད་སྣང༌། །',
        ],
        english: [
            'Just as on the fourteenth day of waning,',
            'The moon is barely visible at all,',
            'For those first practising with aspiration,',
            'The dharmakāya barely appears.',
        ],
        title: {
            tibetan: 'ཆོས་དབྱིངས་བསྟོད་པ།',
            english: 'Praise of the Dharmadhātu',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0812': {
        tibetan: [
            'ཅི་ལྟར་ཚེས་པའི་ཟླ་བ་ལ། །',
            'རིམ་གྱིས་རིམ་གྱིས་འཕེལ་བ་ལྟར། །',
            'དེ་བཞིན་ས་ལ་ཞུགས་རྣམས་ཀྱིས། །',
            'རིམ་གྱིས་རིམ་གྱིས་འཕེལ་བར་མཐོང༌། །',
        ],
        english: [
            'Just as the waxing moon',
            'Grows larger day by day,',
            "Those who've reached the bhūmis",
            'See the dharmakāya more and more.',
        ],
        title: {
            tibetan: 'ཆོས་དབྱིངས་བསྟོད་པ།',
            english: 'Praise of the Dharmadhātu',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0813': {
        tibetan: [
            'གལ་ཏེ་ངས་དམ་བཅས་འགའ་ཡོད། །',
            'དེས་ན་ང་ལ་སྐྱོན་དེ་ཡོད། །',
            'ང་ལ་དམ་བཅའ་མེད་པས་ན། །',
            'ང་ལ་སྐྱོན་མེད་ཁོ་ན་ཡིན། །',
        ],
        english: [
            'If I had a position,',
            'Then I would be at fault,',
            'But because I have no position,',
            'I can only be without fault.',
        ],
        title: { tibetan: 'རྩོད་པ་བཟློག་པ།', english: 'Refutation of Objections' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0814': {
        tibetan: [
            'གང་ལ་སྟོང་པ་ཉིད་སྲིད་པ། །',
            'དེ་ལ་དོན་རྣམས་ཐམས་ཅད་སྲིད། །',
            'གང་ལ་སྟོང་ཉིད་མི་སྲིད་པ། །',
            'དེ་ལ་ཅི་ཡང་མི་སྲིད་དོ། །',
        ],
        english: [
            'To whomever emptiness is possible,',
            'All things are possible.',
            'To whomever emptiness is not possible,',
            'Nothing is possible.',
        ],
        title: { tibetan: 'རྩོད་པ་བཟློག་པ།', english: 'Refutation of Objections' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0815': {
        tibetan: [
            'གང་ཞིག་སྟོང་དང་རྟེན་འབྱུང་དག །',
            'དབུ་མའི་ལམ་དུ་དོན་གཅིག་པར། །',
            'གསུང་མཆོག་མཚུངས་པ་མེད་པ་ཡི། །',
            'སངས་རྒྱས་དེ་ལ་ཕྱག་འཚལ་ལོ། །',
        ],
        english: [
            'To the one who taught this excellent teaching,',
            'In which emptiness and dependent origination,',
            'Are united in a Middle Way—',
            'To the Buddha, I pay homage.',
        ],
        title: { tibetan: 'རྩོད་པ་བཟློག་པ།', english: 'Refutation of Objections' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0816': {
        tibetan: [
            'གང་གིས་རྟེན་ཅིང་འབྲེལ་པར་འབྱུང༌། །',
            'འགག་པ་མེད་པ་སྐྱེ་མེད་པ། །',
            'ཆད་པ་མེད་པ་རྟག་མེད་པ། །',
            'འོང་བ་མེད་པ་འགྲོ་མེད་པ། །',
            'ཐ་དད་དོན་མིན་དོན་གཅིག་མིན། །',
            'སྤྲོས་པ་ཉེར་ཞི་ཞི་བསྟན་པ། །',
            'རྫོགས་པའི་སངས་རྒྱས་སྨྲ་རྣམས་ཀྱི། །',
            'དམ་པ་དེ་ལ་ཕྱག་འཚལ་ལོ། །',
        ],
        english: [
            'You showed that everything is interdependent:',
            'Unceasing and unborn,',
            'Neither non-existent nor everlasting,',
            'Neither coming nor going,',
            'Neither multiple nor single.',
            'With this teaching, all complexity is pacified,',
            'To you, the perfect buddha,',
            'Supreme among all teachers, I bow in homage!',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0817': {
        tibetan: [
            'བདག་ལས་མ་ཡིན་གཞན་ལས་མིན། །',
            'གཉིས་ལས་མ་ཡིན་རྒྱུ་མེད་མིན། །',
            'དངོས་པོ་གང་དག་གང་ན་ཡང༌། །',
            'སྐྱེ་བ་ནམ་ཡང་ཡོད་མ་ཡིན། །',
        ],
        english: [
            'Not from itself, nor from another,',
            'Not from both, nor without a cause,',
            'Does anything anywhere ever arise.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0818': {
        tibetan: [
            'གལ་ཏེ་སྟོང་མིན་ཅུང་ཟད་ཡོད། །',
            'སྟོང་པ་ཅུང་ཟད་ཡོད་པར་འགྱུར། །',
            'མི་སྟོང་ཅུང་ཟད་ཡོད་མིན་ན། །',
            'སྟོང་པ་ཡོད་པར་ག་ལ་འགྱུར། །',
        ],
        english: [
            'If there were the slightest thing which were not empty,',
            'Then there would be that much emptiness as well,',
            'But if there is not the slightest thing which is not empty',
            'How could emptiness exist?',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0819': {
        tibetan: [
            'རྒྱལ་བ་རྣམས་ཀྱིས་སྟོང་པ་ཉིད། །',
            'ལྟ་ཀུན་ངེས་པར་འབྱུང་བར་གསུངས། །',
            'གང་དག་སྟོང་པ་ཉིད་ལྟ་བ། །',
            'དེ་དག་བསྒྲུབ་ཏུ་མེད་པར་གསུངས། །',
        ],
        english: [
            'The victorious ones say that emptiness',
            'Undermines all dogmatic views,',
            'Those who take a dogmatic view of emptiness',
            'Are said to be incurable.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0820': {
        tibetan: [
            'ཡོད་ཅེས་བྱ་བ་རྟག་པར་འཛིན། །',
            'མེད་ཅེས་བྱ་བ་ཆད་པར་ལྟ། །',
            'དེ་ཕྱིར་ཡོད་དང་མེད་པ་ལ། །',
            'མཁས་པས་གནས་པར་མི་བྱའོ། །',
        ],
        english: [
            'To say “it is” is a conception of permanence,',
            'To say “it is not” is a view of nihilism,',
            'Therefore the learned should not dwell',
            'In either existence or non-existence.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0821': {
        tibetan: [
            'རྫོགས་སངས་རྒྱས་རྣམས་མ་བྱོན་ཞིང༌། །',
            'ཉན་ཐོས་རྣམས་ཀྱང་ཟད་པ་ན། །',
            'རང་སངས་རྒྱས་ཀྱི་ཡེ་ཤེས་ནི། །',
            'རྟེན་པ་མེད་ལས་རབ་ཏུ་སྐྱེ། །',
        ],
        english: [
            'In a land where the perfect buddhas have not come,',
            'And the śrāvakas too are nowhere to be found,',
            'The wisdom of the pratyekabuddhas',
            'Develops perfectly without reliance.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0822': {
        tibetan: [
            'སངས་རྒྱས་རྣམས་ཀྱིས་ཆོས་བསྟན་པ། །',
            'བདེན་པ་གཉིས་ལ་ཡང་དག་བརྟེན། །',
            'འཇིག་རྟེན་ཀུན་རྫོབ་བདེན་པ་དང༌། །',
            'དམ་པའི་དོན་གྱི་བདེན་པའོ། །',
        ],
        english: [
            'The teachings taught by the buddhas',
            'Entirely depend on the two truths:',
            'The relative truth of the world and',
            'The truth of the ultimate meaning.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0823': {
        tibetan: [
            'གང་དག་བདེན་པ་དེ་གཉིས་ཀྱི། །',
            'རྣམ་དབྱེ་རྣམ་པར་མི་ཤེས་པ། །',
            'དེ་དག་སངས་རྒྱས་བསྟན་པ་ནི། །',
            'ཟབ་མོའི་དེ་ཉིད་རྣམ་མི་ཤེས། །',
        ],
        english: [
            'Those who fail to understand',
            'The distinctions between these two truths',
            'Will fail to understand the meaning',
            'Of the profound teachings of the buddhas.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0824': {
        tibetan: [
            'ཐ་སྙད་ལ་ནི་མ་བརྟེན་པར། །',
            'དམ་པའི་དོན་ནི་རྟོགས་མི་འགྱུར། །',
            'དམ་པའི་དོན་ནི་མ་རྟོགས་པར། །',
            'མྱ་ངན་འདས་པ་ཐོབ་མི་འགྱུར། །',
        ],
        english: [
            'Without relying on the conventional,',
            'The ultimate meaning will not be realized,',
            'And if one does not realize the ultimate,',
            'One will not attain nirvāṇa.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0825': {
        tibetan: [
            'སྟོང་པ་ཉིད་ལ་བལྟ་ཉེས་ན། །',
            'ཤེས་རབ་ཆུང་རྣམས་ཕུང་བར་འགྱུར། །',
            'ཇི་ལྟར་སྦྲུལ་ལ་གཟུང་ཉེས་དང༌། །',
            'རིག་སྔགས་ཉེས་པར་བསྒྲུབས་པ་བཞིན། །',
        ],
        english: [
            'If they view emptiness in the wrong way,',
            'The less intelligent will be ruined,',
            'Like someone mishandling a poisonous snake,',
            'Or chanting a powerful spell incorrectly.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0826': {
        tibetan: [
            'གང་ཕྱིར་རྟེན་འབྱུང་མ་ཡིན་པའི། །',
            'ཆོས་འགའ་ཡོད་པ་མ་ཡིན་པ། །',
            'དེ་ཕྱིར་སྟོང་པ་མ་ཡིན་པའི། །',
            'ཆོས་འགའ་ཡོད་པ་མ་ཡིན་ནོ། །',
        ],
        english: [
            'There is not a single thing',
            'That does not arise interdependently.',
            'Therefore there is not a single thing',
            'That is not emptiness.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0827': {
        tibetan: [
            'གང་གི་ཐུགས་བརྩེ་ཉེར་བཟུང་ནས། །',
            'ལྟ་བ་ཐམས་ཅད་སྤང་བའི་ཕྱིར། །',
            'དམ་པའི་ཆོས་ནི་སྟོན་མཛད་པ། །',
            'གཽ་ཏ་མ་དེ་ལ་ཕྱག་འཚལ་ལོ། །',
        ],
        english: [
            'I prostrate to Gautama,',
            'Who, out of compassion,',
            'Taught the sacred Dharma',
            'That leads to the relinquishing of all views.',
        ],
        title: {
            tibetan: 'དབུ་མ་རྩ་བ་ཤེས་རབ།',
            english: 'Root Verses of the Middle Way',
        },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: true,
    },
    '0828': {
        tibetan: [
            'དམ་པས་མང་པོ་ཁ་འཆེ་མི་བྱེད་ཅིང༌། །',
            'གལ་ཏེ་དཀའ་ལས་ཁས་ནི་བླངས་གྱུར་ན། །',
            'རྡོ་ལ་རི་མོ་བྲིས་པ་ཇི་བཞིན་དུ། །',
            'ཤི་ཡང་གཞན་དུ་འགྱུར་བ་མ་ཡིན་ནོ། །',
        ],
        english: [
            'The wise do not make many promises,',
            'But once they have committed themselves,',
            'Then, as if the pledge were carved in stone,',
            'Even in the face of death, they will not fail.',
        ],
        title: { tibetan: 'ཤེས་རབ་སྡོང་བུ།', english: 'Staff of Wisdom' },
        author: { tibetan: 'མགོན་པོ་ཀླུ་སྒྲུབ།', english: 'Nāgārjuna' },
        links: { lh: '' },
        isPartial: '',
    },
    '0829': {
        tibetan: [
            'ཀྱེ་མ་བདག་ལ་འཆི་ཁ་བར་དོ་འཆར་དུས་འདིར༔',
            'ཀུན་ལ་ཆགས་སེམས་ཞེན་འཛིན་སྤངས་བྱས་ལ༔',
            'གདམས་ངག་གསལ་བའི་ལམ་ལ་མ་གཡེང་འཇུག༔',
            'རང་རིག་སྐྱེ་མེད་ནམ་མཁའི་དབྱིངས་སུ་འཕང་༔',
            'འདུས་བྱས་ཤ་ཁྲག་ལུས་དང་བྲལ་ལ་ཁད༔',
            'མི་རྟག་སྒྱུ་མ་ཡིན་པར་ཤེས་པར་བྱ༔',
        ],
        english: [
            'Now when the bardo of dying dawns upon me,',
            'I will abandon all grasping, yearning, and attachment,',
            'Enter undistracted into clear awareness of the teaching,',
            'And eject my consciousness into the space of unborn Rigpa;',
            'As I leave this compound body of flesh and blood',
            'I will know it to be a transitory illusion.',
        ],
        title: {
            tibetan: 'བར་དོ་དྲུག་གི་རྩ་ཚིག',
            english: 'Root Verses of the Bardos',
        },
        author: { tibetan: 'གུ་རུ་རིན་པོ་ཆེ།', english: 'Guru Padmasambhava' },
        links: { lh: '' },
        isPartial: true,
    },
    '0830': {
        tibetan: ['ལྟ་བ་ནམ་མཁའ་བས་ཀྱང་མཐོ་ན༔', 'ལས་རྒྱུ་འབྲས་བག་ཕྱེ་བས་ཀྱང་ཞིབ༔'],
        english: [
            'Although my view is higher than the sky,',
            'My respect for the cause and effect of actions is as fine as grains of flour.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'གུ་རུ་རིན་པོ་ཆེ།', english: 'Guru Padmasambhava' },
        links: { lh: '' },
        isPartial: '',
    },
    '0831': {
        tibetan: ['ང་མཐོང་སངས་རྒྱས་ཐམས་ཅད་མཐོང་༔', 'ང་བསྒྲུབས་སངས་རྒྱས་ཐམས་ཅད་འགྲུབ༔', 'ང་ནི་བདེ་གཤེགས་འདུས་པ་ཡིན༔'],
        english: [
            'Seeing me, all the buddhas are seen,',
            'Accomplishing my practice, the practice of all the buddhas is accomplished,',
            'For I am the embodiment of all the sugatas.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'གུ་རུ་རིན་པོ་ཆེ།', english: 'Guru Padmasambhava' },
        links: { lh: '' },
        isPartial: '',
    },
    '0901': {
        tibetan: [
            'གཟུང་བའི་ཡུལ་གྱིས་མ་གོས་ཤིང༌། །',
            'འཛིན་པའི་རྟོག་པས་མ་སླད་པས། །',
            'རིག་སྟོང་རྗེན་པར་སྐྱང་བ་ནི། །',
            'སངས་རྒྱས་ཀུན་གྱི་དགོངས་པའོ། །',
        ],
        english: [
            'Unstained by objective clinging,',
            'Unspoiled by the grasping mind,',
            'Sustaining the naked and empty awareness',
            'Is the wisdom mind of all the buddhas!',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'གུ་རུ་རིན་པོ་ཆེ།', english: 'Guru Padmasambhava' },
        links: { lh: '' },
        isPartial: '',
    },
    '0902': {
        tibetan: [
            'ཆོས་ཀྱི་རྩ་བ་མ་གཅོད་སེམས་ཀྱི་རྩ་བ་ཆོད༔',
            'སེམས་ཀྱི་རྩ་བ་ཆོད་ན་གཅིག་ཤེས་ཀུན་གྲོལ་འགྱུར༔',
            'སེམས་ཀྱི་རྩ་བ་མ་ཆོད་ཀུན་ཤེས་གཅིག་སྡུག་འགྱུར༔',
        ],
        english: [
            'Do not investigate phenomena: investigate the mind.',
            "If you investigate the mind, you'll know the one thing which resolves all.",
            "If you don't investigate the mind, you can know everything but be forever stuck on one.",
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'གུ་རུ་རིན་པོ་ཆེ།', english: 'Guru Padmasambhava' },
        links: { lh: '' },
        isPartial: '',
    },
    '0903': {
        tibetan: [
            'སྨྲ་བསམ་བརྗོད་མེད་ཤེས་རབ་ཕ་རོལ་ཕྱིན། །',
            'མ་སྐྱེས་མི་འགག་ནམ་མཁའི་ངོ་བོ་ཉིད། །',
            'སོ་སོ་རང་རིག་ཡེ་ཤེས་སྤྱོད་ཡུལ་བ། །',
            'དུས་གསུམ་རྒྱལ་བའི་ཡུམ་ལ་ཕྱག་འཚལ་ལོ། །',
        ],
        english: [
            'Beyond words, beyond thought, beyond description, prajñāparamitā,',
            'Unborn, unceasing, the very essence of space,',
            'Yet it can be experienced as the pure wisdom of our own awareness,',
            'Homage to the mother of the buddhas of the three times!',
        ],
        title: {
            tibetan: 'ཤེར་ཕྱིན་བསྟོད་པ།',
            english: 'Praise to Prajñāparamitā',
        },
        author: { tibetan: 'སྒྲ་གཅན་འཛིན་བཟང་པོ།', english: 'Rāhulabhadra' },
        links: { lh: '' },
        isPartial: '',
    },
    '0904': {
        tibetan: [
            'ལས་ཀྱི་འབྲས་བུ་ལྕི་གང་དང་། །',
            'ཉེ་བ་གང་དང་གོམས་པ་གང་། །',
            'སྔར་བྱས་གང་ཡིན་དེ་དག་ལས། །',
            'འབྲས་བུ་ཐོག་མར་སྨིན་པར་འགྱུར། །',
        ],
        english: [
            'Whichever actions carry the heaviest effect,',
            'Whichever are done closest to death or are most habitual,',
            'And those which were committed earliest—',
            'These will be the first to ripen.',
        ],
        title: { tibetan: '', english: '' },
        author: { tibetan: 'སྒྲ་གཅན་འཛིན་བཟང་པོ།', english: 'Rāhulabhadra' },
        links: { lh: '' },
        isPartial: '',
    },
    '0905': {
        tibetan: [
            'བདག་དང་གཞན་སྨྲས་དངོས་འདི་དག །',
            'ཡང་དག་ཏུ་ན་གཅིག་པ་དང༌། །',
            'དུ་མའི་རང་བཞིན་བྲལ་བའི་ཕྱིར། །',
            'རང་བཞིན་མེད་དེ་གཟུགས་བརྙན་བཞིན། །',
        ],
        english: [
            'These entities spoken of by ourselves and others',
            'Are in reality devoid of any intrinsic nature,',
            'Because they possess neither a nature as one',
            'Nor as many, and are just like reflections.',
        ],
        title: { tibetan: 'དབུ་མ་རྒྱན།', english: 'Ornament of the Middle Way' },
        author: { tibetan: 'ཞི་བ་འཚོ།', english: 'Śāntarakṣita' },
        links: { lh: '' },
        isPartial: true,
    },
    '0906': {
        tibetan: [
            'ཚུལ་གཉིས་ཤིང་རྟ་ཞོན་ནས་སུ། །',
            'རིགས་པའི་སྲབ་སྐྱོགས་འཇུ་བྱེད་པ། །',
            'དེ་དག་དེ་ཕྱིར་ཇི་བཞིན་དོན། །',
            'ཐེག་པ་ཆེན་པོ་པ་ཉིད་འཐོབ། །',
        ],
        english: [
            'Those who ride the chariot of the two approaches,',
            'And grasp the reins of logical reasoning,',
            'Will therefore in the truest sense be called',
            'Followers of the greater vehicle.',
        ],
        title: { tibetan: 'དབུ་མ་རྒྱན།', english: 'Ornament of the Middle Way' },
        author: { tibetan: 'ཞི་བ་འཚོ།', english: 'Śāntarakṣita' },
        links: { lh: '' },
        isPartial: true,
    },
    '0907': {
        tibetan: [
            'དལ་འབྱོར་འདི་ནི་རྙེད་པར་ཤིན་ཏུ་དཀའ། །',
            'སྐྱེས་བུའི་དོན་སྒྲུབ་ཐོབ་པར་གྱུར་པ་ལ། །',
            'གལ་ཏེ་འདི་ལ་ཕན་པ་མ་བསྒྲུབས་ན། །',
            'ཕྱིས་འདི་ཡང་དག་འབྱོར་པ་ག་ལ་འགྱུར། །',
        ],
        english: [
            'This free and well-favoured human form is difficult to obtain.',
            'Now that you have the chance to realise the full human potential',
            "If you don't make good use of this opportunity,",
            'How could you possibly expect to have such a chance again?',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0908': {
        tibetan: [
            'ཇི་ལྟར་མཚན་མོ་མུན་ནག་སྤྲིན་རུམ་ནས། །',
            'གློག་འགྱུ་སྐད་ཅིག་རབ་སྣང་སྟོན་པ་ལྟར། །',
            'དེ་བཞིན་སངས་རྒྱས་མཐུ་ཡིས་བརྒྱ་ལམ་ན། །',
            'འཇིག་རྟེན་བསོད་ནམས་བློ་གྲོས་ཐང་འགའ་འབྱུང༌། །',
        ],
        english: [
            'Like a flash of lightning on a dark and cloudy night,',
            'Which sheds its brilliant light for just an instant,',
            "Every now and then, through the buddhas' power,",
            'A mind of virtue occurs briefly to people of the world.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0909': {
        tibetan: [
            'བྱང་ཆུབ་སེམས་དེ་མདོར་བསྡུས་ན། །',
            'རྣམ་པ་གཉིས་སུ་ཤེས་བྱ་སྟེ། །',
            'བྱང་ཆུབ་སྨོན་པའི་སེམས་དང་ནི། །',
            'བྱང་ཆུབ་འཇུག་པ་ཉིད་ཡིན་ནོ། །',
        ],
        english: [
            'Understand that, briefly stated,',
            'Bodhicitta has two aspects:',
            'The mind aspiring to awaken,',
            'And actual application.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0910': {
        tibetan: [
            'འགྲོ་བར་འདོད་དང་འགྲོ་བ་ཡི། །',
            'བྱེ་བྲག་ཇི་ལྟར་ཤེས་པ་ལྟར། །',
            'དེ་བཞིན་མཁས་པས་འདི་གཉིས་ཀྱི། །',
            'བྱེ་བྲག་རིམ་བཞིན་ཤེས་པར་བྱ། །',
        ],
        english: [
            'Just as one understands the difference',
            'Between wishing to go and going on a journey,',
            'The wise should understand these two,',
            'Recognizing their difference and their order.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0911': {
        tibetan: [
            'སྡུག་བསྔལ་འདོར་འདོད་སེམས་ཡོད་ཀྱང༌། །',
            'སྡུག་བསྔལ་ཉིད་ལ་མངོན་པར་རྒྱུག །',
            'བདེ་བ་འདོད་ཀྱང་གཏི་མུག་པས། །',
            'རང་གི་བདེ་བ་དགྲ་ལྟར་འཇོམས། །',
        ],
        english: [
            'Though longing to be rid of suffering,',
            'They rush headlong towards suffering itself.',
            'Although longing to be happy, in their ignorance',
            'They destroy their own well-being, as if it were their worst enemy.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0912': {
        tibetan: [
            'བདག་ནི་མངོན་མེད་རྣམས་ཀྱི་མགོན། །',
            'ལམ་ཞུགས་རྣམས་ཀྱི་དེད་དཔོན་དང༌། །',
            'བརྒལ་འདོད་རྣམས་ཀྱི་གྲུ་དང་ནི། །',
            'གཟིངས་དང་ཟམ་པ་ཉིད་དུ་གྱུར། །',
        ],
        english: [
            'May I be a guard for those without one,',
            'A guide for all who journey on the road,',
            'May I become a boat, a raft or bridge,',
            'For all who wish to cross the water.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0913': {
        tibetan: [
            'གླིང་དོན་གཉེར་ལ་གླིང་དང་ནི། །',
            'མར་མེ་འདོད་ལ་མར་མེ་དང༌། །',
            'གནས་མལ་འདོད་ལ་གནས་མལ་དང༌། །',
            'བདག་ནི་ལུས་ཅན་བྲན་འདོད་པ། །',
            'ཀུན་གྱི་བྲན་དུ་གྱུར་བར་ཤོག །',
        ],
        english: [
            'May I be an isle for those desiring landfall,',
            'And a lamp for those who wish for light,',
            'May I be a bed for those who need to rest,',
            'And a servant for all who live in need.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0914': {
        tibetan: [
            'ཡིད་བཞིན་ནོར་དང་བུམ་པ་བཟང༌། །',
            'རིག་སྔགས་གྲུབ་དང་སྨན་ཆེན་དང༌། །',
            'དཔག་བསམ་གྱི་ནི་ཤིང་དག་དང༌། །',
            'ལུས་ཅན་རྣམས་ཀྱི་འདོད་འཇོར་གྱུར། །',
        ],
        english: [
            'May I become a wishing jewel, a magic vase,',
            'A powerful mantra and a medicine of wonder,',
            'May I be a tree of miracles granting every wish,',
            'And a cow of plenty sustaining all the world.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0915': {
        tibetan: [
            'སྐད་ཅིག་གཅིག་བྱས་སྡིག་པས་ཀྱང༌། །',
            'བསྐལ་པར་མནར་མེད་གནས་འགྱུར་ན། །',
            'ཐོག་མེད་འཁོར་བར་བསགས་སྡིག་གིས། །',
            'བདེ་འགྲོར་མི་འགྲོ་སྨོས་ཅི་དགོས། །',
        ],
        english: [
            "If a single moment's negativity,",
            'Can lead to an aeon in the Avīci hell,',
            'Then with all our harmful acts accumulated in saṃsāra without beginning,',
            'What chance is there of going to the higher realms?',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0916': {
        tibetan: [
            'ཀུན་ནས་དྲན་པའི་ཐག་པ་ཡིས། །',
            'སེམས་ཀྱི་གླང་པོ་དམ་བཏགས་ན། །',
            'འཇིགས་པ་ཐམས་ཅད་མེད་འགྱུར་ཞིང༌། །',
            'དགེ་བ་ཐམས་ཅད་ལག་ཏུ་འོང༌། །',
        ],
        english: [
            'If, with the rope of mindfulness,',
            'You bind firm the elephant of the mind,',
            'You will let go of every fear',
            'And find virtue close at hand.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0917': {
        tibetan: [
            'ས་སྟེང་འདི་དག་ཀོས་གཡོགས་སུ། །',
            'དེ་སྙེད་ཀོ་བས་ག་ལ་ལང༌། །',
            'ལྷམ་མཐིལ་ཙམ་གྱི་ཀོ་བས་ནི། །',
            'ས་སྟེང་ཐམས་ཅད་གཡོགས་དང་འདྲ། །',
        ],
        english: [
            'Where would I find enough leather',
            'To cover the entire surface of the earth?',
            'But with leather soles beneath my feet,',
            "It's as if the whole world had been covered.",
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0918': {
        tibetan: [
            'འཇིགས་དང་དགའ་སྟོན་སོགས་འབྲེལ་བར། །',
            'གལ་ཏེ་མི་ནུས་ཅི་བདེར་བྱ། །',
            'འདི་ལྟར་སྦྱིན་པའི་དུས་དག་ཏུ། །',
            'ཚུལ་ཁྲིམས་བཏང་སྙོམས་བྱ་བར་གསུངས། །',
        ],
        english: [
            'In fearful situations or during celebrations,',
            'If I am not able to concentrate like this,',
            'I may relax, because in times of generosity,',
            'Discipline may be suspended, it is said.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0919': {
        tibetan: [
            'མིག་གིས་སེམས་ཅན་ལྟ་ན་ཡང༌། །',
            'འདི་དག་ཉིད་ལ་བརྟེན་ནས་བདག །',
            'སངས་རྒྱས་ཉིད་དུ་འགྱུར་རོ་ཞེས། །',
            'དྲང་ཞིང་བྱམས་པའི་ཚུལ་གྱིས་བལྟ། །',
        ],
        english: [
            'Whenever I catch sight of others,',
            'By thinking, “It is through them,',
            'That I will reach awakening”,',
            "I'll look with sincerity and love.",
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0920': {
        tibetan: [
            'རྒྱལ་སྲས་རྣམས་ཀྱིས་མི་བསླབ་པ། །',
            'དེ་ནི་གང་ཡང་ཡོད་མིན་ཏེ། །',
            'དེ་ལྟར་གནས་པའི་མཁས་པ་ལ། །',
            'བསོད་ནམས་མི་འགྱུར་གང་ཡང་མེད། །',
        ],
        english: [
            'There is nothing whatsoever',
            'A bodhisattva should not learn.',
            'When one is skilful in all things,',
            'Nothing fails to be of merit.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0921': {
        tibetan: [
            'བསྐལ་པ་སྟོང་དུ་བསགས་པ་ཡི། །',
            'སྦྱིན་དང་བདེ་གཤེགས་མཆོད་ལ་སོགས། །',
            'ལེགས་སྤྱད་གང་ཡིན་དེ་ཀུན་ཀྱང་། །',
            'ཁོང་ཁྲོ་གཅིག་གིས་འཇོམས་པར་འགྱུར། །',
        ],
        english: [
            'All that you have accumulated over a thousand aeons—',
            'Generosity, offerings to the buddhas and the like,',
            'Whatever good you have done—all of it',
            'Is destroyed in a single burst of anger.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0922': {
        tibetan: [
            'ཞེ་སྡང་ལྟ་བུའི་སྡིག་པ་མེད། །',
            'བཟོད་པ་ལྟ་བུའི་དཀའ་ཐུབ་མེད། །',
            'དེ་བས་བཟོད་ལ་ནན་ཏན་ཏུ། །',
            'སྣ་ཚོགས་ཚུལ་གྱིས་བསྒོམ་པར་བྱ། །',
        ],
        english: [
            'There is no evil similar to anger,',
            'And no discipline like patience,',
            'Strive always therefore for tolerance,',
            'Cultivating it in varied ways.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0923': {
        tibetan: [
            'གལ་ཏེ་བཅོས་སུ་ཡོད་ན་ནི། །',
            'དེ་ལ་མི་དགར་ཅི་ཞིག་ཡོད། །',
            'གལ་ཏེ་བཅོས་སུ་མེད་ན་ནི། །',
            'དེ་ལ་མི་དགའ་བྱས་ཅི་ཕན། །',
        ],
        english: [
            'If a problem can be solved,',
            'What reason is there to be upset?',
            'If there is no possible solution,',
            'What use is there in being sad?',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0924': {
        tibetan: ['གོམས་ན་སླ་བར་མི་འགྱུར་བའི། །', 'དངོས་དེ་གང་ཡང་ཡོད་མ་ཡིན། །'],
        english: ['There is nothing whatsoever', 'That does not grow easier with practice.'],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0925': {
        tibetan: [
            'བསོད་ནམས་ཀྱིས་ནི་ལུས་བདེ་ལ། །',
            'མཁས་པ་ཡིས་ནི་སེམས་བདེ་ན། །',
            'གཞན་དོན་འཁོར་བར་གནས་ནས་ཀྱང༌། །',
            'སྙིང་རྗེ་ཅན་དག་ཅི་སྟེ་སྐྱོ། །',
        ],
        english: [
            'When their bodies are well because of their merit,',
            'And their minds are happy because of their wisdom,',
            'Even if they stay on in saṃsāra for the benefit of others,',
            'How could the compassionate ones ever be despondent?',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0926': {
        tibetan: [
            'དེ་བས་སྐྱོ་ངལ་ཀུན་སེལ་བའི། །',
            'བྱང་ཆུབ་སེམས་ཀྱི་རྟ་ཞོན་ནས། །',
            'བདེ་ནས་བདེ་བར་འགྲོ་བ་ལ། །',
            'སེམས་ཤེས་སུ་ཞིག་སྒྱིད་ལུག་འགྱུར། །',
        ],
        english: [
            'Riding upon the horse of bodhicitta',
            'That dispels all weariness and fatigue,',
            'Who could ever feel depressed,',
            'While travelling from joy to joy?',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0927': {
        tibetan: [
            'ཤི་བའི་སྦྲུལ་དང་ཕྲད་གྱུར་ན། །',
            'ཁྭ་ཡང་ཁ་ལྡིང་ལྟ་བུར་སྤྱོད། །',
            'གལ་ཏེ་བདག་ཉིད་ཉམ་ཆུང་ན། །',
            'ལྟུང་བ་ཆུང་ངུའང་གནོད་པར་བྱེད། །',
        ],
        english: [
            'When they come upon a serpent near to death,',
            'Even common crows behave like mighty eagles.',
            'Likewise, if I am weak and faint of heart,',
            'Even minor faults will become great setbacks.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0928': {
        tibetan: [
            'ཞི་གནས་རབ་ཏུ་ལྡན་པའི་ལྷག་མཐོང་གིས། །',
            'ཉོན་མོངས་རྣམ་པར་འཇོམས་པར་ཤེས་བྱས་ནས། །',
            'ཐོག་མར་ཞི་གནས་བཙལ་བྱ་དེ་ཡང་ནི། །',
            'འཇིག་རྟེན་ཆགས་པ་མེད་ལ་མངོན་དགས་འགྲུབ། །',
        ],
        english: [
            "Knowing that the mind's afflictions are overcome",
            'Through penetrating insight suffused with stable calm,',
            'You should first seek the peace of calm abiding,',
            'Which is found in joy and non-attachment to the world.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0929': {
        tibetan: [
            'གང་ཞིག་བདག་དང་གཞན་རྣམས་ནི། །',
            'མྱུར་དུ་བསྐྱབ་པར་འདོད་པ་དེས། །',
            'བདག་དང་གཞན་དུ་བརྗེ་བྱ་བ། །',
            'གསང་བའི་དམ་པ་སྤྱད་པར་བྱ། །',
        ],
        english: [
            'Whoever wishes to quickly afford protection',
            'To both himself and others',
            'Should practise that most noble secret:',
            'The exchanging of oneself for others.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
    '0930': {
        tibetan: [
            'འཇིག་རྟེན་བདེ་བ་ཇི་སྙེད་པ། །',
            'དེ་ཀུན་གཞན་བདེ་འདོད་ལས་བྱུང༌། །',
            'འཇིག་རྟེན་སྡུག་བསྔལ་ཇི་སྙེད་པ། །',
            'དེ་ཀུན་རང་བདེ་འདོད་ལས་བྱུང༌། །',
        ],
        english: [
            'Whatever joy there is in this world,',
            'All comes from wanting others to be happy.',
            'Whatever suffering there is in this world,',
            'All comes from wanting oneself to be happy.',
        ],
        title: {
            tibetan: 'སྤྱོད་འཇུག',
            english: "Introduction to the Bodhisattva's Way of Life",
        },
        author: { tibetan: 'ཞི་བ་ལྷ།', english: 'Śāntideva' },
        links: { lh: '' },
        isPartial: true,
    },
};
