import { TIB_NUMBERS } from '../constants';

/**
 * converts a number to tibetan script numbers
 * @param {number} number
 * @returns {string}
 */
export const getNumberInTibetan = (number) => {
    const str = `${number}`;
    return str
        .split('')
        .map((digit) => TIB_NUMBERS[digit])
        .join('');
};

/**
 * Gets the modifier to be added to month number such as 1st, 2nd etc
 * @param {number} number the number of the month 1-12
 * @returns {'st'|'nd'|'rd'|'th'}
 */
export const getMonthNumberEnding = (number) => {
    switch (number) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};
