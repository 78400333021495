import React from 'react';
import PropTypes from 'prop-types';
import DakiniIcon from './icons/dakini.png';
import AmitabhaIcon from './icons/Amitabha.png';
import MedicineBuddhaIcon from './icons/MedicineBuddha.png';
import GuruRinpocheIcon from './icons/GuruRinpoche.png';
import ProtectorsIcon from './icons/Protectors.png';
import ShakyamuniBuddhaIcon from './icons/ShakyamuniBuddha.png';
import TaraIcon from './icons/Tara.png';
import FlowerIcon from './icons/flower.png';
import WheelIcon from './icons/ThinBareWheel.png';
import NewMoonIcon from './icons/NewMoon.png';
import FullMoonIcon from './icons/FullMoon.png';

const PNG_FILTERS = {
    red: 'invert(10%) sepia(91%) saturate(3760%) hue-rotate(355deg) brightness(95%) contrast(88%)',
    green: 'invert(40%) sepia(18%) saturate(1869%) hue-rotate(85deg) brightness(95%) contrast(95%)',
    blue: 'invert(22%) sepia(85%) saturate(1757%) hue-rotate(219deg) brightness(89%) contrast(100%)',
    gold: 'invert(76%) sepia(48%) saturate(5471%) hue-rotate(360deg) brightness(104%) contrast(101%)',
};

const ICONS = {
    DAKINI: {
        src: DakiniIcon,
        defaultColor: 'red',
        alt: 'Dakini',
    },
    MEDICINE: {
        src: MedicineBuddhaIcon,
        defaultColor: 'blue',
        alt: 'Medicine Buddha',
    },
    GR: {
        src: GuruRinpocheIcon,
        defaultColor: 'red',
        alt: 'Guru Rinpoche',
    },
    AMITABHA: {
        src: AmitabhaIcon,
        defaultColor: 'red',
        alt: 'Amitabha',
    },
    PROTECTOR: {
        src: ProtectorsIcon,
        defaultColor: 'blue',
        alt: 'Protector',
    },
    BUDDHA: {
        src: ShakyamuniBuddhaIcon,
        defaultColor: 'gold',
        alt: 'Shakyamuni Buddha',
    },
    TARA: {
        src: TaraIcon,
        defaultColor: 'green',
        alt: 'Tara',
    },
    FLOWER: {
        src: FlowerIcon,
        defaultColor: 'red',
        alt: 'Flower',
    },
    WHEEL: {
        src: WheelIcon,
        defaultColor: 'red',
        alt: 'Wheel',
    },
    NEW_MOON: {
        src: NewMoonIcon,
        alt: 'New moon',
    },
    FULL_MOON: {
        src: FullMoonIcon,
        alt: 'Full moon',
    },
};

const Icon = (props) => {
    const { type, color, style, ...rest } = props;
    const icon = ICONS[type];

    let pngStyle;
    if (!color) {
        pngStyle = { filter: PNG_FILTERS[icon.defaultColor] };
    } else if (color === 'none') {
        pngStyle = {};
    } else {
        pngStyle = { filter: PNG_FILTERS[color] };
    }
    const extendedStyle = { ...pngStyle, ...style };

    return <img alt={icon.alt} src={icon.src} style={extendedStyle} {...rest} />;
};

Icon.propTypes = {
    /** the type of the icon */
    type: PropTypes.oneOf([
        'AMITABHA',
        'BUDDHA',
        'DAKINI',
        'FLOWER',
        'FULL_MOON',
        'GR',
        'MEDICINE',
        'NEW_MOON',
        'PROTECTOR',
        'TARA',
        'WHEEL',
    ]),
    /** the color of the icon */
    color: PropTypes.oneOf(['none', 'red', 'green', 'blue', 'gold', '']),
};

Icon.defaultProps = {
    type: 'FLOWER',
    color: '',
};

export default Icon;
