import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const Button = (props) => {
    const { children, className, context, As = 'button', badge, ...rest } = props;

    return (
        <As className={[styles.button, styles[context], className].join(' ')} {...rest}>
            {badge && <span className={styles.badge} />}
            {children}
        </As>
    );
};

Button.propTypes = {
    /** the content to be shown inside the button */
    children: PropTypes.any.isRequired,
    /** additional className to be applied to the main element */
    className: PropTypes.string,
    /** render context */
    context: PropTypes.oneOf(['nav', 'sideNav', 'large', 'default', 'outline']),
    /** As which HTML element should the button be rendered. Default: button */
    As: PropTypes.string,
    /** a red dot to be shown */
    badge: PropTypes.oneOf(['red']),
    /** additional props to be added to the button */
};

Button.defaultProps = {
    className: undefined,
    context: 'default',
    As: 'button',
    badge: undefined,
};

export default Button;
