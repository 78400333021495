import React from 'react';
import PropTypes from 'prop-types';
import { QUOTES } from '../../data';
import { PechaFrame, Frame } from '../../ui-components';
import styles from './Quote.module.scss';

/**
 * Generates html for given quote id
 */
const Quote = ({ id, isPecha }) => {
    if (!id) {
        return null;
    }

    const quote = QUOTES[id];
    if (!quote) {
        return null;
    }

    const As = isPecha ? PechaFrame : Frame;

    const engTitle = (() => {
        if (quote.title.english) {
            return quote.links?.lh ? <a href={quote.links.lh}>{quote.title.english}</a> : quote.title.english;
        }
        return null;
    })();

    const tibTitle = (() => {
        if (quote.title.tibetan) {
            return quote.links?.lh ? <a href={quote.links.lh}>{quote.title.tibetan}</a> : quote.title.tibetan;
        }
        return null;
    })();

    const engAuthor = quote.author?.english;
    const tibAuthor = quote.author?.tibetan;

    return (
        <div className={styles.wrapper}>
            <p className={styles.refEng}>
                {quote.isPartial ? 'From ' : ''}
                {engTitle}
                {!!(engTitle && engAuthor) && <br />}
                {engAuthor && `by ${engAuthor}`}
            </p>
            <As>
                <p className="tib-long-text">༄༅། །{quote.tibetan.join('')}</p>
                <p>{quote.english.join(' | ')}</p>
            </As>
            <p className={`${styles.refTib} tib-text-small`}>
                {tibTitle}
                {!!(tibTitle && tibAuthor) && <br />}
                {tibAuthor}
            </p>
        </div>
    );
};

Quote.propTypes = {
    /** The id of the quote needed */
    id: PropTypes.string,
    /** pecha frame or fancy one */
    isPecha: PropTypes.bool,
};

Quote.defaultProps = {
    id: null,
    isPecha: false,
};

export default Quote;
