import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import './index.scss';
import App from './App';
import { UpdateAvailableModal } from './components/SW';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
ReactModal.setAppElement(container);
const root = createRoot(container);
root.render(<App />);

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', (event) => {
                if (event.target.state === 'activated') {
                    ReactDOM.render(<UpdateAvailableModal isOpen />, container);
                }
            });
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
    },
});
