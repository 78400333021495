/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import * as React from 'react';
import PropTypes from 'prop-types';
import { FaCut, FaPrayingHands } from 'react-icons/fa';
import { DayNav } from '../Navigation';
import Icon from '../Icon';
import Anniversary from '../Anniversary';
import Quote from '../Quote';
import { Divider } from '../../ui-components';
import { repeatedMonthly, repeatedYearly, getIconColor, sortAttributesByType } from '../../utils';
import styles from './SingleDay.module.scss';

const STANDARD_TEMPLATE_TYPES = [
    'holiday',
    'anniversary',
    'birthday',
    'western-anniversary',
    'western-birthday',
    'moon',
];

const SingleDay = React.forwardRef(({ date, onNavigateNext, onNavigatePrev, ...rest }, ref) => {
    const specialsUnSorted = [...repeatedYearly(date), ...repeatedMonthly(date)];
    const specials = sortAttributesByType(specialsUnSorted, [
        ['moon'],
        ['holiday'],
        ['anniversary', 'birthday', 'western-anniversary', 'western-birthday'],
        ['haircutting'],
    ]);
    const getQuoteId = () => {
        const { westernDateStr } = date;
        return westernDateStr.slice(5).replace(/-/g, '');
    };

    return (
        <div ref={ref} {...rest}>
            <DayNav
                onNavigatePrev={onNavigatePrev}
                onNavigateNext={onNavigateNext}
                currentDate={date}
                className={styles.dayNav}
            />
            <div className={styles.dayDetails}>
                {date.isDoubledDay && (
                    <div key="double-day" className={styles.dayInfo}>
                        * This day is doubled in the Tibetan calendar
                    </div>
                )}
                {date.isPreviousSkipped && (
                    <div key="skipped-prev" className={styles.dayInfo}>
                        * The previous day is skipped in the Tibetan calendar
                    </div>
                )}
                {specials.map((sp, i) => {
                    if (STANDARD_TEMPLATE_TYPES.includes(sp.type)) {
                        const spImg = sp.img ? sp.img({ year: date.year }) : undefined;
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`sp.type-${i}`} className={styles.detailSection}>
                                <Divider>
                                    <Icon type={sp.icon} color={getIconColor(sp)} />
                                </Divider>
                                <div className={styles.moreDetail}>
                                    {['anniversary', 'birthday', 'western-anniversary', 'western-birthday'].includes(
                                        sp.type
                                    ) ? (
                                        <Anniversary
                                            {...sp.description}
                                            isBday={sp.type === 'western-birthday' || sp.type === 'birthday'}
                                            isWestern={
                                                sp.type === 'western-birthday' || sp.type === 'western-anniversary'
                                            }
                                        />
                                    ) : (
                                        <>
                                            <h2>{sp.description.title}</h2>
                                            {sp.description.details}
                                            {spImg && <img src={spImg} alt={sp.imgAlt} width="100%" />}
                                            {sp.prayer && (
                                                <Quote
                                                    id={sp.prayer.replace(
                                                        '{month}',
                                                        String(date.month).padStart(2, '0')
                                                    )}
                                                    isPecha
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
                <div key="quote" className={styles.detailSection}>
                    <Divider>
                        <FaPrayingHands />
                    </Divider>
                    <Quote id={getQuoteId()} isPecha />
                </div>
                {specials.map((sp) => {
                    if (sp.type === 'haircutting') {
                        const cn = sp.isGood ? styles.goodCut : styles.badCut;
                        return (
                            <div key={`cutting-${cn}`} className={styles.detailSection}>
                                <Divider>
                                    <FaCut className={cn} />
                                </Divider>
                                <span className={styles.moreDetail}>
                                    The result of cutting hair on this day is:{' '}
                                    <span className={cn}>{sp.description}</span>
                                </span>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
});

SingleDay.propTypes = {
    /** TibetanDate object if different from current date - a month containing that day will be rendered */
    date: PropTypes.object.isRequired,
    /** Callback when navigation to next day happens */
    onNavigateNext: PropTypes.func.isRequired,
    /** Callback when navigation to previous day happens */
    onNavigatePrev: PropTypes.func.isRequired,
};

export default SingleDay;
