import React from 'react';
import PropTypes from 'prop-types';
import TOLLogo from './images/TOL-logo.png';
import LHLogo from './images/LH-logo.png';
import RigoaLogo from './images/rigpa-logo.png';
import styles from './Anniversary.module.scss';

const SITE_NAMES = {
    tol: 'Treasury of Lives',
    lh: 'Lotsawahouse',
    rigpa: 'Rigpa wiki',
};

const SITE_LOGO = {
    tol: TOLLogo,
    lh: LHLogo,
    rigpa: RigoaLogo,
};

/**
 * Generates an entry for anniversaries and birthdays in the format:
 * _occasion_ of _name_ (_dates_) according to _type_ calendar
 */
const Anniversary = ({ name, links, dates, isBday = false, isWestern = false }) => {
    const handleClick = (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
    };

    const renderLink = (link, site) => (
        <a className={styles.link} target="_blank" rel="noopener noreferrer" href={link} onClick={handleClick}>
            <img alt={SITE_NAMES[site]} src={SITE_LOGO[site]} width={10} height={10} />
            {SITE_NAMES[site]}
        </a>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.small}>
                {isBday ? 'Birthday' : 'Anniversary'} according to the {isWestern ? 'western' : 'Tibetan'} calendar
            </div>
            <div className={styles.name}>
                <strong>{name}</strong> {dates && `(${dates})`}
            </div>

            <div className={styles.links}>
                {links.rigpa && renderLink(links.rigpa, 'rigpa')}
                {links.tol && renderLink(links.tol, 'tol')}
                {links.lh && renderLink(links.lh, 'lh')}
            </div>
        </div>
    );
};

Anniversary.propTypes = {
    /** The name of the person who's anniversary or birthday it is */
    name: PropTypes.string.isRequired,
    /** A url to read more about the person */
    links: PropTypes.objectOf(PropTypes.string).isRequired,
    /** birth (and death) dates as to be shown */
    dates: PropTypes.string,
    /** Birthday or Anniversary */
    isBday: PropTypes.bool,
    /** Is the date according to Western or Tibetan calendar */
    isWestern: PropTypes.bool,
};

Anniversary.defaultProps = {
    isBday: false,
    isWestern: false,
    dates: null,
};

export default Anniversary;
