import React from 'react';
import { Button, Frame } from '../../ui-components';
import styles from './TextPages.module.scss';

const Feedback = () => (
    <div className={styles.container}>
        <Frame>
            <h1>Help us</h1>
            <h2>Offer your feedback</h2>
            <p>
                We are continuing to develop the app and very much appreciate your feedback and suggestions to help us
                do this.
            </p>
            <Button
                context="large"
                As="a"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdgNMzgeVEJUS644arSmGXqDamNGY6WS5roVp4ezAiY66PT5g/viewform?usp=sf_link"
            >
                Share Feedback
            </Button>
            <h2>Offer your time and expertise</h2>
            <p>
                We are looking for UI/UX designers, frontend developers, content editors and more help. Please contact
                us if you would like to volunteer and join this effort
            </p>
            <Button
                context="large"
                As="a"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdEjpd3HUkCjK1yJGPhqtMohh2yT-NkFeY4RnrOboRaACHGpQ/viewform?usp=sf_link"
            >
                Volunteer
            </Button>
        </Frame>
    </div>
);

export default Feedback;
