import * as React from 'react';
import PropsTypes from 'prop-types';
import styles from './Frame.module.scss';

const Frame = (props) => {
    const { children, ...rest } = props;

    return (
        <div className={styles.wrapper} {...rest}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={styles.svg}>
                <mask id="m" fill="#fff">
                    <rect id="r" width="100%" height="100%" />
                    <circle id="c" r="10" fill="#000" />
                    <use xlinkHref="#c" x="100%" />
                    <use xlinkHref="#c" y="100%" />
                    <use xlinkHref="#c" x="100%" y="100%" />
                </mask>
                <mask id="m2" fill="#fff">
                    <rect id="r2" width="calc(100% - 2px)" height="calc(100% - 2px)" x="1px" y="1px" />
                    <use xlinkHref="#c" x="1px" y="1px" />
                    <use xlinkHref="#c" x="calc(100% - 1px)" y="1px" />
                    <use xlinkHref="#c" x="1px" y="calc(100% - 1px)" />
                    <use xlinkHref="#c" x="calc(100% - 1px)" y="calc(100% - 1px)" />
                </mask>
                <mask id="m3" fill="#fff">
                    <rect id="r3" width="calc(100% - 6px)" height="calc(100% - 6px)" x="3px" y="3px" />
                    <use xlinkHref="#c" x="3px" y="3px" />
                    <use xlinkHref="#c" x="calc(100% - 3px)" y="3px" />
                    <use xlinkHref="#c" x="3px" y="calc(100% - 3px)" />
                    <use xlinkHref="#c" x="calc(100% - 3px)" y="calc(100% - 3px)" />
                </mask>
                <mask id="m4" fill="#fff">
                    <rect id="r4" width="calc(100% - 12px)" height="calc(100% - 12px)" x="6px" y="6px" />
                    <use xlinkHref="#c" x="6px" y="6px" />
                    <use xlinkHref="#c" x="calc(100% - 6px)" y="6px" />
                    <use xlinkHref="#c" x="6px" y="calc(100% - 6px)" />
                    <use xlinkHref="#c" x="calc(100% - 6px)" y="calc(100% - 6px)" />
                </mask>
                <use xlinkHref="#r" fill="#C3AC70" mask="url(#m)" />
                <use xlinkHref="#r2" fill="#fff" mask="url(#m2)" />
                <use xlinkHref="#r3" fill="#C3AC70" mask="url(#m3)" />
                <use xlinkHref="#r4" fill="#fff" mask="url(#m4)" />
            </svg>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

Frame.propTypes = {
    /** The content to be shown inside the frame */
    children: PropsTypes.any,
    /** additional props will be applied to the top container */
};

Frame.defaultProps = {
    children: null,
};

export default Frame;
