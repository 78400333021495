import React from 'react';
import PropTypes from 'prop-types';
import { getNumberInTibetan } from '../../utils';
import Animals from './Animals';
import { Modal } from '../../ui-components';
import styles from './YearDetailsModal.module.scss';

const YearDetailsModal = (props) => {
    const { isOpen = false, onRequestClose, year, ...rest } = props;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={`Details of the year ${year.tibYearNum}`}
            {...rest}
        >
            <div className={styles.yearDetails}>
                <p>
                    <b>
                        Year of the {year.gender} {year.element} {year.animal}
                    </b>
                </p>
                <div className={styles.middle}>
                    <div className={styles.rowItem}>
                        <span className="tib-tex">{getNumberInTibetan(year.tibYearNum)}</span>
                        <br />
                        {year.tibYearNum}
                    </div>
                    <Animals name={year.animal} />
                    <div className={styles.rowItem}>
                        {year.westernYear} -<br /> {year.westernYear + 1}
                    </div>
                </div>
                <p>
                    The {year.rabjungYear} year of the {year.rabjungCycle} rabjung cycle
                </p>
            </div>
        </Modal>
    );
};

YearDetailsModal.propTypes = {
    /** should the modal be shown */
    isOpen: PropTypes.bool.isRequired,
    /** callback to be called when close was requested */
    onRequestClose: PropTypes.func.isRequired,
    /** the TibetanYear object describing the current year */
    year: PropTypes.object.isRequired,
};

export default YearDetailsModal;
