/* eslint-disable import/prefer-default-export */
export const HAIR_CUTTING = {
    1: [{ type: 'haircutting', isGood: false, description: 'short life' }],
    2: [{ type: 'haircutting', isGood: false, description: 'slander' }],
    3: [{ type: 'haircutting', isGood: true, description: 'wealth' }],
    4: [{ type: 'haircutting', isGood: true, description: 'increase in virtue' }],
    5: [{ type: 'haircutting', isGood: true, description: 'increase in wealth' }],
    6: [
        {
            type: 'haircutting',
            isGood: true,
            description: 'increased sensory clarity',
        },
    ],
    7: [{ type: 'haircutting', isGood: false, description: 'lawsuit' }],
    8: [{ type: 'haircutting', isGood: true, description: 'long life' }],
    9: [{ type: 'haircutting', isGood: true, description: 'attractive energy' }],
    10: [{ type: 'haircutting', isGood: true, description: 'greater wang tang' }],
    11: [{ type: 'haircutting', isGood: true, description: 'intelligence' }],
    12: [{ type: 'haircutting', isGood: false, description: 'poverty' }],
    13: [{ type: 'haircutting', isGood: true, description: 'increase in longevity' }],
    14: [
        {
            type: 'haircutting',
            isGood: true,
            description: 'increase in comfort and wealth',
        },
    ],
    15: [{ type: 'haircutting', isGood: true, description: 'auspiciousness' }],
    16: [{ type: 'haircutting', isGood: false, description: 'obstacles' }],
    17: [{ type: 'haircutting', isGood: true, description: 'virtue' }],
    18: [{ type: 'haircutting', isGood: false, description: 'loss' }],
    19: [{ type: 'haircutting', isGood: true, description: 'increase in longevity' }],
    20: [{ type: 'haircutting', isGood: true, description: 'wealth' }],
    21: [{ type: 'haircutting', isGood: false, description: 'fear of sickness' }],
    22: [
        {
            type: 'haircutting',
            isGood: true,
            description: 'gaining clothes and food',
        },
    ],
    23: [
        {
            type: 'haircutting',
            isGood: true,
            description: 'increase in comfort and wealth',
        },
    ],
    24: [{ type: 'haircutting', isGood: true, description: 'happiness' }],
    25: [{ type: 'haircutting', isGood: false, description: 'sickness' }],
    26: [{ type: 'haircutting', isGood: true, description: 'wellbeing' }],
    27: [{ type: 'haircutting', isGood: true, description: 'joy and happiness' }],
    28: [{ type: 'haircutting', isGood: false, description: 'strife and quarrels' }],
    29: [{ type: 'haircutting', isGood: false, description: 'bad food' }],
    30: [
        {
            type: 'haircutting',
            isGood: true,
            description: 'purification of negative actions and obscurations',
        },
    ],
};
