import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TibetanDate } from 'tibetan-date-calculator';
import { Frame, Divider } from '../../ui-components';
import Icon from '../Icon';
import { TIBETAN_ANNIVERSARIES, WESTERN_ANNIVERSARIES, YEARLY_HOLIDAYS } from '../../data';
import { MONTHS } from '../../constants';
import textPageStyles from '../TextPages/TextPages.module.scss';
import styles from './YearlyEventsOverview.module.scss';
import { getNextOccurrence, getNumberInTibetan } from '../../utils';

/**
 * A page to display all anniversaries mentioned in the calendar
 */
const YearlyEventsOverview = ({ globalSetters: { setDate } }) => {
    const navigate = useNavigate();
    const [search, setSearch] = React.useState('');
    const [holidays, setHolidays] = React.useState(YEARLY_HOLIDAYS);
    const [tibAnnies, setTibAnnies] = React.useState(TIBETAN_ANNIVERSARIES);
    const [westAnnies, setWestAnnies] = React.useState(WESTERN_ANNIVERSARIES);

    const filterFn = React.useCallback(
        (data) => {
            const filtered = {};
            Object.keys(data).forEach((month) => {
                const monthData = {};
                Object.keys(data[month]).forEach((day) => {
                    const dayData = data[month][day].filter((anni) =>
                        anni.description.name
                            ? anni.description.name.toLowerCase().includes(search.toLowerCase())
                            : anni.description.title.toLowerCase().includes(search.toLowerCase())
                    );
                    if (dayData.length) {
                        monthData[day] = dayData;
                    }
                });
                if (Object.keys(monthData).length) {
                    filtered[month] = { ...monthData };
                }
            });
            return filtered;
        },
        [search]
    );

    React.useEffect(() => {
        setHolidays(filterFn(YEARLY_HOLIDAYS));
        setTibAnnies(filterFn(TIBETAN_ANNIVERSARIES));
        setWestAnnies(filterFn(WESTERN_ANNIVERSARIES));
    }, [search, filterFn]);

    const handleAnniversaryClick = (month, day, isWestern) => {
        let dayToGo = getNextOccurrence(month, day);
        if (isWestern) {
            const today = new Date(Date.now());
            let year = today.getFullYear();
            if (today.getMonth() > month) {
                year += 1;
            } else if (today.getMonth() === month && today.getDate() > day) {
                year += 1;
            }
            dayToGo = new TibetanDate(`${year}-${month}-${day}`);
        }
        setDate(dayToGo);
        navigate('/day');
    };

    const renderMonth = (monthData, month, isWestern = false) =>
        Object.keys(monthData).map((day) => (
            <div className={styles.dayLine}>
                <div className={styles.date}>{isWestern ? `${day}.` : `${day} | ${getNumberInTibetan(day)}`}</div>
                <div className={styles.details}>
                    {monthData[day].map((anni) => (
                        // eslint-disable-next-line
                        <div
                            key={anni.description.title || anni.description.name}
                            className={styles.detail}
                            role="button"
                            onClick={() => {
                                handleAnniversaryClick(+month, +day, isWestern);
                            }}
                        >
                            {anni.description.title || anni.description.name}
                            {anni.description.dates && ` (${anni.description.dates})}`}
                        </div>
                    ))}
                </div>
            </div>
        ));

    return (
        <div className={textPageStyles.container}>
            <Frame>
                <h1>Yearly events overview</h1>
                <input
                    type="search"
                    placeholder="Search"
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    className={styles.search}
                />
                <Divider>
                    <Icon type="FLOWER" color="gold" />
                </Divider>
                <h2>Special days</h2>
                {Object.keys(holidays).map((month) =>
                    Object.keys(holidays[month]).length ? (
                        <>
                            <div className={styles.monthLine}>
                                {month}. month | བོད་ཟླ་ {getNumberInTibetan(month)}
                            </div>
                            {renderMonth(holidays[month], month)}
                        </>
                    ) : null
                )}
                <Divider style={{ marginTop: '20px' }}>
                    <Icon type="FLOWER" color="red" />
                </Divider>
                <h2>Anniversaries and birthdays according to the Tibetan calendar</h2>
                {Object.keys(tibAnnies).map((month) =>
                    Object.keys(tibAnnies[month]).length ? (
                        <>
                            <div className={styles.monthLine}>
                                {month}. month | བོད་ཟླ་ {getNumberInTibetan(month)}
                            </div>
                            {renderMonth(tibAnnies[month], month)}
                        </>
                    ) : null
                )}
                <Divider style={{ marginTop: '20px' }}>
                    <Icon type="FLOWER" color="blue" />
                </Divider>
                <h2>Anniversaries and birthdays according to the Western calendar</h2>
                {Object.keys(westAnnies).map((month) =>
                    Object.keys(westAnnies[month]).length ? (
                        <>
                            <div className={styles.monthLine}>{MONTHS[month - 1]}</div>
                            {renderMonth(westAnnies[month], month, true)}
                        </>
                    ) : null
                )}
            </Frame>
        </div>
    );
};

export default YearlyEventsOverview;
