import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { logPage } from '../../utils';

/**
 * Sets global GA listener to record page navigation
 */
const PageTracker = () => {
    const location = useLocation();

    React.useEffect(() => {
        logPage();
    }, [location]);

    return null;
};

export default PageTracker;
