/* eslint-disable import/prefer-default-export */
export const TIBETAN_ANNIVERSARIES = {
    1: {
        1: [
            {
                type: 'anniversary',
                description: {
                    name: '6th Dzogchen Rinpoche, Jikdral Changchub Dorjé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jikdral_Changchub_Dorje',
                        tol: 'https://treasuryoflives.org/biographies/view/Sixth-Dzogchen-Drubwang-Jikdrel-Jangchub-Dorje/10169',
                    },
                    dates: '1935-1959',
                },
            },
        ],
        3: [
            {
                type: 'anniversary',
                description: {
                    name: '11th Minling Trichen, Gyurme Kunzang Wangyal',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Minling_Trichen_Rinpoche' },
                    dates: '1931-2008',
                },
            },
        ],
        5: [
            {
                type: 'anniversary',
                description: {
                    name: '1st Dzogchen Rinpoche, Pema Rigdzin',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Dzogchen_Pema_Rigdzin',
                        tol: 'https://treasuryoflives.org/biographies/view/Sixth-Dzogchen-Drubwang-Jikdrel-Jangchub-Dorje/10169',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/dzogchen-pema-rigdzin/',
                    },
                    dates: '1625-1697',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Rigdzin Kunzang Sherab',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Rigdzin_Kunzang_Sherab' },
                    dates: '1636-1699',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Khenpo Shenga',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Khenpo_Shenga',
                        tol: 'https://treasuryoflives.org/biographies/view/Zhenpen-Chokyi-Nangwa/9622',
                        lh: 'https://www.lotsawahouse.org/bo/tibetan-masters/khenpo-shenga/',
                    },
                    dates: '1871-1927',
                    prayer: {
                        tibetan:
                            'རྒྱལ་བའི་བསྟན་ལ་ཀླུ་ཐོགས་རྣམ་གཉིས་སྲོལ། །འཆད་རྩོམ་སྤེལ་བས་ས་གསུམ་ཁྱབ་པར་མཛད། །ཐུབ་དབང་བསྟན་ལ་ཐུབ་པའི་མཛད་པ་ཅན། །སྙིགས་དུས་འདི་ན་སངས་རྒྱས་གཞན་ཕན་པ། །',
                        english:
                            "Through explanation, composition and propagation, you spread the teachings of the Victorious One, | Particularly the traditions of Nāgārjuna and Asaṅga, throughout the three planes of existence, | Your activity for the Lord of Sage's teachings comparable to the Sage's own: | Shenpenpa, bringer of benefit to others, a buddha for this degenerate age!",
                    },
                },
            },
        ],
        10: [
            {
                type: 'anniversary',
                description: {
                    name: 'Tertön Sogyal, Lerab Lingpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Terton_Sogyal',
                        tol: 'https://treasuryoflives.org/biographies/view/Lerab-Lingpa/8538',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/terton-sogyal/',
                    },
                    dates: '1856-1926',
                    prayer: {
                        tibetan:
                            'རྩ་གསུམ་ཀུན་འདུས་རྒྱལ་དབང་པདྨ་དང༌། ། ལྷག་པའི་ལྷ་མཆོག་རྡོ་རྗེ་གཞོན་ནུ་ཡིས། ། རྗེས་བཟུང་ཟབ་གཏེར་སྒོ་འབྱེད་རིག་པ་འཛིན། ། ལས་རབ་གླིང་པའི་ཞབས་ལ་གསོལ་བ་འདེབས། །',
                        english:
                            'Both Padmasambhava, who embodies all gurus, devas and ḍākinīs, | And your supreme and special deity Vajrakumāra (Vajrakīla), | Hold you close to their hearts; you are the vidyādhara who reveals the profound termas, | Lerab Lingpa, to you I pray!',
                    },
                },
            },
            {
                type: 'birthday',
                description: {
                    name: '12th Gyalwang Drukpa',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Gyalwang_Drukchen_Rinpoche' },
                    dates: '1963-',
                },
            },
        ],
        13: [
            {
                type: 'anniversary',
                description: {
                    name: '1st Dodrupchen, Jikmé Trinlé Özer',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Dodrupchen_Jikme_Trinle_%C3%96zer',
                        tol: 'https://treasuryoflives.org/biographies/view/Dodrubchen-01-Jigme-Trinle-Ozer/4676',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/dodrupchen-I/',
                    },
                    dates: '1745-1821',
                    prayer: {
                        tibetan: `དུས་གསུམ་ཀུན་མཁྱེན་པདྨ་འབྱུང་གནས་ཀྱིས། །
                        ཟབ་གཏེར་བདག་པོར་མངའ་གསོལ་འཁོར་ལོས་སྒྱུར། །
                        སངས་རྒྱས་གླིང་པའི་ཡང་སྤྲུལ་སྦས་ཚུལ་འཆང༌། །
                        ཀུན་བཟང་གཞན་ཕན་ཞབས་ལ་གསོལ་བ་འདེབས། །`,
                        english: `Empowered by Padmasambhava, omniscient in past, present and future, 
                        As master over the profound treasures, universal king of Dharma, 
                        The reincarnation of Sangyé Lingpa, you who follow a hidden way, 
                        Kunzang Shyenpen, at your feet I pray!`,
                    },
                },
            },
        ],
        14: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jetsün Milarepa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Milarepa',
                        tol: 'https://treasuryoflives.org/biographies/view/Milarepa/3178',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/milarepa/',
                    },
                    dates: '1040-1123',
                    prayer: {
                        tibetan: `བྱང་ཕྱོགས་མུན་པའི་སྨག་རུམ་ན། །
                        གངས་ལ་ཉི་མ་ཤར་འདྲ་བའི། །
                        ཐོས་པ་དགའ་ཞེས་བྱ་བ་ཡི། །
                        སྐྱེས་བུ་དེ་ལ་ཕྱག་འཚལ་ལོ། །`,
                        english: `In the darkness of the lands to the North 
                        Is one just like a snow-capped peak in the rising sun, 
                        He who is known as Töpa Ga, 'Joyous to Hear,' 
                        To that great being, I pay homage!`,
                    },
                },
            },
        ],
        15: [
            {
                type: 'birthday',
                description: {
                    name: 'Garab Dorjé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Garab_Dorje',
                        lh: 'https://www.lotsawahouse.org/indian-masters/garab-dorje/',
                    },
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Marpa Lotsawa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Marpa_Ch%C3%B6kyi_Lodr%C3%B6',
                        tol: 'https://treasuryoflives.org/biographies/view/Marpa-Chokyi-Lodro/4354',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/marpa-chokyi-lodro/',
                    },
                    dates: '1012-1097',
                },
            },
        ],
        21: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jamyang Khyentsé Wangpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jamyang_Khyentse_Wangpo',
                        tol: 'https://treasuryoflives.org/biographies/view/Jamyang-Khyentse-Wangpo/4291',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-wangpo/',
                    },
                    dates: '1820-1892',
                    prayer: {
                        tibetan: `སྔོན་སྨོན་རྣམ་པར་དཀར་བའི་རིགས་མཆོག་སད། །
                        བླ་མ་ལྷག་པའི་ལྷ་ཡིས་རྗེས་སུ་བཟུང༌། །
                        ཡོངས་རྫོགས་བསྟན་ལ་སྨན་པའི་ཕྲིན་ལས་ཅན། །
                        འཇམ་དབྱངས་མཁྱེན་བརྩེའི་དབང་པོར་གསོལ་བ་འདེབས། །`,
                        english: `You who have awakened your enlightened nature, purified through past aspirations, 
                        Cared for by your lamas and special deities, 
                        Your activity is like a medicine for the entire teaching of Buddha, 
                        Jamyang Khyentsé Wangpo, to you I pray!`,
                    },
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kenchen Pema Tsewang',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Khenpo_Petse_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/khenpo-petse/',
                    },
                    dates: '1931-2002',
                },
            },
        ],
    },
    2: {
        1: [
            {
                type: 'anniversary',
                description: {
                    name: 'Penor Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Penor_Rinpoche' },
                    dates: '1932-2009',
                },
            },
        ],
        2: [
            {
                type: 'anniversary',
                description: {
                    name: 'Orgyen Terdak Lingpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Minling_Terchen_Gyurme_Dorje',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/minling-terchen/',
                    },
                    dates: '1646-1714',
                    prayer: {
                        tibetan: `མཁས་དང་གྲུབ་པའི་འཁོར་ལོས་བསྒྱུར། །
                        བཀའ་གཏེར་བསྟན་པའི་མེ་རོ་སློང་། །
                        ངོ་མཚར་བཀའ་དྲིན་བསམ་མི་ཁྱབ། །
                        འགྱུར་མེད་རྡོ་རྗེ་ལ་ཕྱག་འཚལ། །`,
                        english: `Wheel-turning monarch of learning and realization, 
                        Who rekindled the teachings of kama and terma, 
                        You whose wondrous kindness is beyond imagining— 
                        Gyurme Dorje, to you I pay homage!`,
                    },
                },
            },
        ],
        5: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jamyang Shyepa Dorjé Ngawang Tsöndru',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jamyang_Shyepa_Ngawang_Ts%C3%B6ndr%C3%BC',
                        tol: 'https://treasuryoflives.org/biographies/view/Jamyang-Zhepai-Dorje/6646',
                    },
                    dates: '1648-1722',
                },
            },
        ],
        8: [
            {
                type: 'anniversary',
                description: {
                    name: 'Tenga Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Tenga_Rinpoche' },
                    dates: '1932-2012',
                },
            },
        ],
        12: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jetsün Drakpa Gyaltsen',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jets%C3%BCn_Drakpa_Gyaltsen',
                        tol: 'https://treasuryoflives.org/biographies/view/Drakpa-Gyeltsen/2915',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/jetsun-drakpa-gyaltsen/',
                    },
                    dates: '1147-1216',
                    prayer: {
                        tibetan: `ཆོས་རྣམས་ཀུན་གྱི་དེ་ཉིད་གཟིགས ། །
                        གསང་སྔགས་རྒྱ་མཚོའི་ཕ་རོལ་སོན། །
                        རྡོ་རྗེ་འཛིན་པ་ཀུན་གྱི་རྗེ། །
                        གྲགས་པ་རྒྱལ་མཚན་ལ་ཕྱག་འཚལ། །`,
                        english: `You saw the nature of all phenomena 
                        And traversed the ocean of secret mantra, 
                        Lord of all vajra holders— 
                        Drakpa Gyaltsen, to you I pay homage.`,
                    },
                },
            },
        ],
        15: [
            {
                type: 'anniversary',
                description: {
                    name: 'Gyaltön Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Gyalt%C3%B6n_Rinpoche' },
                    dates: '1908-1970',
                },
            },
        ],
        19: [
            {
                type: 'anniversary',
                description: {
                    name: 'Chakdzö Tséwang Paljor',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Tsewang_Paljor_(1909-1999)_A_Tribute_to_a_Remarkable_Life',
                    },
                    dates: '1909-1999',
                },
            },
        ],
        20: [
            {
                type: 'anniversary',
                description: {
                    name: 'Do Khyentsé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Do_Khyentse_Yeshe_Dorje',
                        tol: 'https://treasuryoflives.org/biographies/view/Do-Khyentse-Yeshe-Dorje/9612',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/do-khyentse/',
                    },
                    dates: '1800-1866',
                    prayer: {
                        tibetan: `འགྲོ་བའི་མགོན་པོ་བྱང་ཆུབ་རྡོ་རྗེ་ཡི། །
                        སྨིན་གྲོལ་གདམས་པའི་བཅུད་ཀྱི་དྲོད་ཐོབ་པ། ། 
                        ཕྱོགས་ལས་རྣམ་རྒྱལ་བརྟུལ་ཞུགས་འཛིན་པའི་དཔལ། ། 
                        ཡེ་ཤེས་རྡོ་རྗེའི་ཞབས་ལ་གསོལ་བ་འདེབས། །`,
                        english: `From the protector of beings Changchup Dorjé, 
                        You who received and realized the essential instructions on ripening and liberation, 
                        Supremely victorious, glorious master of crazy wisdom, 
                        Yeshé Dorjé, at your feet I pray!`,
                    },
                },
            },
        ],
        24: [
            {
                type: 'anniversary',
                description: {
                    name: '3rd Jamgön Kongtrul Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Jamg%C3%B6n_Kongtrul_Rinpoche' },
                    dates: '1954-1992',
                },
            },
        ],
        26: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jetsün Taranatha',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Taranatha',
                        tol: 'https://treasuryoflives.org/biographies/view/Taranata/2712',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/taranatha/',
                    },
                    dates: '1575-1634',
                    prayer: {
                        tibetan: `དཔལ་ལྡན་བརྟན་གཡོ་ཀུན་ཁྱབ་རྡོ་རྗེ་སེམས། །
                        དངོས་ཀུན་བདེ་བ་ཆེན་པོ་སྡོམ་མཛད་པ། །
                        ཁྱབ་བདག་བླ་མ་ཡེ་ཤེས་སྒྱུ་མའི་སྐུ། །
                        རྗེ་བཙུན་ཆེན་པོ་ཀུན་དགའ་སྙིང་པོར་བསྟོད། །`,
                        english: `Glorious one, pervading all the world and its inhabitants, 
                        Vajrasattva, who binds the whole of reality in great bliss, 
                        Omnipresent guru, in your illusory wisdom form— 
                        Great Jetsün Kunga Nyingpo, to you I offer praise.`,
                    },
                },
            },
        ],
        30: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jamyang Loter Wangpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jamyang_Loter_Wangpo',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-loter-wangpo/',
                    },
                    dates: '1847-1914',
                    prayer: {
                        tibetan: `འཇམ་དབྱངས་བླ་མའི་བྱིན་རླབས་ཐུགས་ལ་ཞུགས། །
                        ཟབ་རྒྱས་ཆོས་ཚུལ་མཁྱེན་པའི་རྣམ་དཔྱོད་ཅན། །
                        གསུམ་ལྡན་རྡོ་རྗེ་འཛིན་པའི་མངའ་བདག་ཆེ། །
                        འཇམ་དབྱངས་བློ་གཏེར་དབང་པོར་གསོལ་བ་འདེབས། །`,
                        english: `The blessings of the Mañjughoṣa guru entered your heart, 
                        And you gained discerning knowledge of Dharma, profound and vast, 
                        Great sovereign, vajra holder of the three sets of vows— 
                        Jamyang Loter Wangpo, to you I pray.`,
                    },
                },
            },
        ],
    },
    3: {
        5: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kalu Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Kalu_Rinpoche',
                        tol: 'https://treasuryoflives.org/biographies/view/kalu-rinpoche/12180',
                    },
                    dates: '1905-1989',
                },
            },
        ],
        16: [
            {
                type: 'anniversary',
                description: {
                    name: 'Tertön Mingyur Dorjé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Tert%C3%B6n_Mingyur_Dorj%C3%A9',
                        tol: 'https://treasuryoflives.org/biographies/view/Namcho-Mingyur-Dorje/9190',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/terton-mingyur-dorje/',
                    },
                    dates: '1645-1667',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Dezhung Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Dezhung_Rinpoche' },
                    dates: '1906-1987',
                },
            },
        ],
        22: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jigdral Dagchen Sakya Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Sakya_Dagchen_Rinpoche' },
                    dates: '1929-2016',
                },
            },
        ],
        24: [
            {
                type: 'anniversary',
                description: {
                    name: 'Khandro Tsering Chödrön',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Khandro_Tsering_Ch%C3%B6dr%C3%B6n',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/khandro-tsering-chodron/',
                    },
                    dates: '1929-2011',
                    prayer: {
                        tibetan: `ཡེ་ཤེས་ཌཱ་ཀི་བདེ་ཆེན་མཚོ་རྒྱལ་གྱིས། །
                        བྱིན་བརླབས་ཤེལ་དཀར་བཟའ་ཡི་རྣམ་པར་འཕྲུལ། །
                        ཨཱ་ཡུ་དྷརྨ་དཱི་པཾ་ལ་གསོལ་བ་འདེབས། །
                        བསམ་དོན་ཆོས་བཞིན་འགྲུབ་པར་བྱིན་གྱིས་རློབས། །`,
                        english: `With the blessings of Tsogyal, wisdom ḍākinī of great bliss, 
                        Emanation of the lady of Shelkar, 
                        Āyu Dharma Dīpam (Long Life, Lamp of the Dharma), to you I pray. 
                        Grant your blessing so that all our wishes in accordance with the Dharma be fulfilled.`,
                    },
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'The Great Fifth Dalai Lama',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Ngawang_Lobsang_Gyatso',
                        tol: 'https://treasuryoflives.org/biographies/view/Ngawang-Lobzang-Gyatso/6065',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/fifth-dalai-lama/',
                    },
                    dates: '1617-1682',
                    prayer: {
                        tibetan: `ཆོས་རྒྱལ་ཕྲིན་ལས་རྣམ་རོལ་ཟ་ཧོར་གདུང་། །
                        རྩ་གསུམ་སྲུང་མས་ཞལ་བསྟན་རྒྱ་ཅན་ཆོས། །
                        ཉེར་ལྔའི་སྒོ་འབྱེད་གངས་ཅན་བསྟན་འགྲོའི་མགོན། །
                        ཀུན་མཁྱེན་རྒྱལ་མཆོག་ལྔ་པ་ལ་ཕྱག་འཚལ། །`,
                        english: `Activity emanation of the Dharma-king, born into the Zahor line, 
                        You to whom the Three Roots and Guardians appeared, 
                        You who revealed twenty-five sealed Dharmas, Lord of the teachings and beings in this Land of Snows— 
                        Omniscient Great Fifth, to you I pay homage!`,
                    },
                },
            },
        ],
        29: [
            {
                type: 'anniversary',
                description: {
                    name: 'Gatön Ngawang Lekpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Gat%C3%B6n_Ngawang_Lekpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Gaton-Ngawang-Lekpa/10815',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/gaton-ngawang-lekpa/',
                    },
                    dates: '1867-1941',
                    prayer: {
                        tibetan: `ངག་གི་དབང་ཕྱུག་འཇམ་དབྱངས་ས་སྐྱ་པའི། །
                    ཟབ་གསང་སྙན་བརྒྱུད་རྒྱ་མཚོའི་མཛོད་བཟུང་ནས། །
                    ལེགས་བཤད་དགའ་སྟོན་འགྱེད་ལ་ཟླ་མེད་པ། །
                    དཔལ་ལྡན་བླ་མའི་ཞབས་ལ་གསོལ་བ་འདེབས། །`,
                        english: `Holding the oceanic treasury of the profound and secret aural lineage 
                        Of the lords of speech, Mañjughoṣas of the Sakya tradition, 
                        You are without equal in creating a feast of excellent explanation, 
                        Glorious guru, at your feet I pray!`,
                    },
                },
            },
        ],
        30: [
            {
                type: 'anniversary',
                description: {
                    name: 'Tertön Sangyé Lingpa',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Sangye_Lingpa' },
                    dates: '1340-1396',
                },
            },
        ],
    },
    4: {
        14: [
            {
                type: 'anniversary',
                description: {
                    name: '14th Kunzig Shamar Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Mipham_Ch%C3%B6kyi_Lodr%C3%B6' },
                    dates: '1952-2014',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Patrul Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Patrul_Rinpoche',
                        tol: 'https://treasuryoflives.org/biographies/view/Patrul-Orgyen-Jigme-Chokyi-Wangpo/4425',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/patrul-rinpoche/',
                    },
                    dates: '1808-1887',
                    prayer: {
                        tibetan: `ཕྱི་ལྟར་རྒྱལ་བའི་སྲས་པོ་ཞི་བ་ལྷ། །
                        ནང་ལྟར་གྲུབ་པའི་དབང་ཕྱུག་ཤཱ་བ་རི། །
                        གསང་བ་འཕགས་མཆོག་སྡུག་བསྔལ་རང་གྲོལ་དངོས། །
                        འཇིགས་མེད་ཆོས་ཀྱི་དབང་པོར་གསོལ་བ་འདེབས། །`,
                        english: `Outwardly you are the bodhisattva Śāntideva, 
                        Inwardly the lord of incarnations, Śāvaripa, 
                        Secretly the supreme and noble Self-liberation of Suffering—Avalokiteśvara—in person: 
                        Jikmé Chökyi Wangpo, to you I pray!`,
                    },
                },
            },
        ],
        23: [
            {
                type: 'anniversary',
                description: {
                    name: 'the enlightenment of Mahasiddha Birupa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Virupa',
                        lh: 'https://www.lotsawahouse.org/indian-masters/virupa/',
                    },
                    dates: '837-909',
                },
                prayer: {
                    tibetan: `གངྒཱ་བཟློག་ཅིང་མ་རུངས་རྒྱལ་པོ་བཏུལ། །
                    ཉི་མ་བཟུང་ནས་ཡུལ་ཁམས་མྱོས་བྱེད་གསོལ། །
                    མྱོས་མིན་ལིངྒ་རབ་བཀས་གཏུམ་མོ་བཏུལ། །
                    མཐུ་སྟོབས་དབང་ཕྱུག་གྲགས་ལ་མགོས་ཕྱག་འཚལ། །`,
                    english: `You reversed the Ganges and tamed a malevolent king; 
                    Halting the sun, you drank a region's liquor yet remained sober; 
                    You shattered a liṅgam and subjugated the goddess Caṇḍikā— 
                    Renowned Tutop Wangchuk, I bow down to you in homage.`,
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'Drikung Kyobpa Jigten Sumgön',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Drikung_Kyobpa_Jikten_Sumg%C3%B6n',
                        tol: 'https://treasuryoflives.org/biographies/view/Jigten-Gonpo-Rinchen-Pel/2899',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/drikung-kyobpa-jikten-sumgon/',
                    },
                    dates: '1143-1217',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Ngorchen Kunga Zangpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Ngorchen_Kunga_Zangpo',
                        tol: 'https://treasuryoflives.org/biographies/view/Ngorchen-Kunga-Zangpo/2387',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/ngorchen-kunga-zangpo/',
                    },
                    dates: '1382-1456',
                },
            },
        ],
        29: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jamgön Mipham Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Mipham_Rinpoche',
                        tol: 'https://treasuryoflives.org/biographies/view/Mipam-Gyatso/4228',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/mipham/',
                    },
                    dates: '1846-1912',
                    prayer: {
                        tibetan: `རིག་སྟོང་འཇམ་དཔལ་གཞོན་ནུའི་བྱིན་རླབས་ལས། །
                        དགོངས་པའི་ཀློང་ལས་སྤོབས་པའི་གཏེར་བརྒྱད་གྲོལ། །
                        ལུང་རྟོགས་ཆོས་མཛོད་རྒྱ་མཚོའི་མངའ་དབང་བསྒྱུར། །
                        མི་ཕམ་འཇམ་པའི་དབྱངས་ལ་གསོལ་བ་འདེབས། །`,
                        english: `Through the blessings of the youthful Mañjuśrī, the union of awareness and emptiness, 
                        You released the eight brilliant treasures, 
                        Master of an ocean of treasure-like teachings of the Dharma in both aspects, transmission and realization— 
                        To you, Mipham Rinpoche, Mañjuśrī in person, I pray!`,
                    },
                },
            },
        ],
        30: [
            {
                type: 'anniversary',
                description: {
                    name: 'Nyala Pema Dündul',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Nyala_Pema_D%C3%BCndul',
                        tol: 'https://treasuryoflives.org/biographies/view/Pema-Dudul/4125',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/nyala-pema-dundul/',
                    },
                    dates: '1816-1872',
                    prayer: {
                        tibetan: `བསྐལ་བཟང་སངས་རྒྱས་སྟོང་གི་ངོ་བོ་ཉིད། །
                        ལྷང་བྲག་གྲུབ་ཆེན་ཞབས་ལ་གསོལ་བ་འདེབས། །
                        འདི་ཕྱི་བར་དོ་གསུམ་ལ་ཐུགས་རྗེས་བཟུང་། །
                        ཐར་པའི་ལམ་སྣ་ཟིན་པར་པར་བྱིན་གྱིས་རློབས། །`,
                        english: `You who embody the one thousand buddhas of this Fortunate Age, 
                        Great siddha of Lhangdrak—Pema Dündul—to you I pray: 
                        In this life and the next, and in the bardo state, hold me with your compassion, 
                        Inspire me with your blessing, to take the path to liberation!`,
                    },
                },
            },
        ],
    },
    5: {
        1: [
            {
                type: 'anniversary',
                description: {
                    name: 'Terchen Chokgyur Lingpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Chokgyur_Dechen_Lingpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Chokgyur-Lingpa/8181',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/chokgyur-dechen-lingpa/',
                    },
                    dates: '1829-1870',
                    prayer: {
                        tibetan: `དཀྱིལ་འཁོར་ཀུན་བདག་མཚོ་སྐྱེས་རྡོ་རྗེ་འཆང༌། །
                        མཆོག་གི་རིག་པ་འཛིན་པའི་སྐུར་བཞེངས་པ། །
                        གངས་ཅན་སྐྱེ་རྒུའི་སྐྱབས་གཅིག་གཏེར་ཆེན་རྗེ། །
                        མཆོག་གྱུར་གླིང་པའི་ཞབས་ལ་གསོལ་བ་འདེབས། །`,
                        english: `Lord of all the maṇḍalas, Lake-born Vajradhara, 
                        Manifesting in the form of a supreme vidyādhara, 
                        Great lord of tertöns, sole refuge of all who live in the Land of Snows, 
                        Chokgyur Lingpa, at your feet I pray!`,
                    },
                },
            },
        ],
        6: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jamyang Khyentsé Chökyi Lodrö',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jamyang_Khyentse_Ch%C3%B6kyi_Lodr%C3%B6',
                        tol: 'https://treasuryoflives.org/biographies/view/Jamyang-Khyentse-Chokyi-Lodro/P733',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/jamyang-khyentse-chokyi-lodro/',
                    },
                    dates: '1893-1959',
                    prayer: {
                        tibetan: `འཇམ་དཔལ་དབྱངས་སོགས་སྲས་བཅས་རྒྱལ་ཀུན་གྱི། །
                        ཡེ་ཤེས་གཅིག་བསྡུས་མཁྱེན་བརྩེའི་དབང་པོ་ཡི། །
                        བྱིན་རླབས་ཐུགས་རྗེས་རང་གཟུགས་སྤྲུལ་པའི་སྐུ། །
                        བློ་གྲོས་རྒྱ་མཚོའི་ཞབས་ལ་གསོལ་བ་འདེབས། །`,
                        english: `Compassionate incarnation of the blessings of Khyentse Wangpo, 
                        In whom the wisdom of Mañjughoṣa and the rest, 
                        All the buddhas and bodhisattvas are gathered together, 
                        Lodrö Gyatso, at your feet I pray!`,
                    },
                },
            },
        ],
        10: [
            {
                type: 'birthday',
                description: {
                    name: 'Dilgo Khyentsé Yangsi Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Khyentse_Yangsi_Rinpoche' },
                    dates: '1993-',
                },
            },
        ],
        17: [
            {
                type: 'anniversary',
                description: {
                    name: 'Nyoshul Lungtok',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Nyoshul_Lungtok_Tenp%C3%A9_Nyima',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/nyoshul-lungtok/',
                    },
                    dates: '1829-1901',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Shechen Gyaltsab Gyurme Pema Namgyal',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Gyurme_Pema_Namgyal',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/shechen-gyaltsab/',
                    },
                    dates: '1871-1926',
                },
            },
        ],
        19: [
            {
                type: 'anniversary',
                description: {
                    name: 'Khenpo Ngakchung',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Khenpo_Ngawang_Palzang',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/ngawang-palzang/',
                    },
                    dates: '1879-1941',
                    prayer: {
                        tibetan: `ས་མཆོག་དཀྱིལ་འཁོར་མངོན་གྱུར་བི་མ་ལ། །
                        ཀུན་བཟང་ཆོས་སྐུའི་སྟོན་པ་ཀློང་ཆེན་པ། །
                        དབྱེར་མེད་ང༵ག་གི༵་དབ༵ང་པོ༵ར་གསོལ་བ་འདེབས། །
                        ཐུགས་ཡིད་གཅིག་ཏུ་འདྲེས་པར་བྱིན་གྱིས་རློབས། །`,
                        english: `Vimalamitra realized the maṇḍala of the supreme state, 
                        Longchenpa revealed the dharmakāya Samantabhadra, 
                        And you, Ngaki Wangpo, are inseparable from them both—to you I pray: 
                        Inspire me with your blessings so that my own mind and your wisdom-mind merge as one!`,
                    },
                },
            },
        ],
        23: [
            {
                type: 'anniversary',
                description: {
                    name: 'Khenpo Akhyuk Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Akhyuk_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/akhyuk-rinpoche-lungtok-gyaltsen/',
                    },
                    dates: '1927-2011',
                },
            },
        ],
        24: [
            {
                type: 'anniversary',
                description: {
                    name: 'Lungtok Shedrup Tenpé Nyima',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Shedrup_Tenp%C3%A9_Nyima' },
                    dates: '1920-?',
                },
            },
        ],
    },
    6: {
        4: [
            {
                type: 'birthday',
                description: {
                    name: 'Drikung Kyabgön Chetsang Rinpoche',
                    links: { rigpa: 'https://www.drikungkc.org/drikung-kyabgn-chetsang' },
                    dates: '1946-',
                },
            },
        ],
        14: [
            {
                type: 'anniversary',
                description: {
                    name: '3rd Karmapa, Rangjung Dorjé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Rangjung_Dorje',
                        tol: 'https://treasuryoflives.org/biographies/view/Third-Karmapa-Rangjung-Dorje/9201',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/third-karmapa-rangjung-dorje/',
                    },
                    dates: '1284-1339',
                },
            },
        ],
        15: [
            {
                type: 'anniversary',
                description: {
                    name: 'Gampopa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Gampopa_S%C3%B6nam_Rinchen',
                        tol: 'https://treasuryoflives.org/biographies/view/Gampopa-Sonam-Rinchen/3168',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/gampopa-sonam-rinchen/',
                    },
                    dates: '1079-1153',
                },
            },
        ],
    },
    7: {
        5: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kabjé Trulshik Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Trulshik_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/trulshik-rinpoche/',
                    },
                    dates: '1924-2011',
                },
            },
        ],
        10: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jomo Menmo',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Jomo_Menmo' },
                    dates: '1248–1283',
                },
            },
        ],
        15: [
            {
                type: 'anniversary',
                description: {
                    name: "Drapa Ngönshe's discovery of the Four Medical Tantras",
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Four_Medical_Tantras' },
                    dates: '1012–1090',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Tsarchen Losal Gyatso',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Tsarchen_Losal_Gyatso' },
                    dates: '1502-1566',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Kunkhyen Pema Karpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Pema_Karpo',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/pema-karpo/',
                    },
                    dates: '1527-1592',
                },
            },
        ],
        16: [
            {
                type: 'anniversary',
                description: {
                    name: 'Nyoshul Khenpo Jamyang Dorjé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Nyoshul_Khen_Rinpoche',
                        tol: 'https://treasuryoflives.org/biographies/view/Nyoshul-Khenpo-Jamyang-Dorje/8177',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/nyoshul-khenpo-jamyang-dorje/',
                    },
                    dates: '1932-1999',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Bairo Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Bairo_Rinpoche' },
                    dates: '1933-2017',
                },
            },
        ],
        24: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kyabjé Menri Trizin Rinpoche',
                    links: { rigpa: 'http://shenten.org/?option=com_content&id=117' },
                    dates: '1929-2017',
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'Phagmodrupa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Phagmodrupa_Dorje_Gyalpo',
                        tol: 'https://treasuryoflives.org/biographies/view/Pakmodrupa-Dorje-Gyelpo/2539',
                    },
                    dates: '1110-1170',
                },
            },
        ],
    },
    8: {
        10: [
            {
                type: 'birthday',
                description: {
                    name: 'Neten Chokling Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Neten_Chokling_Rinpoche' },
                    dates: '1973-',
                },
            },
        ],
        14: [
            {
                type: 'anniversary',
                description: {
                    name: 'Domang Yangthang Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Yangthang_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/yangthang-rinpoche/',
                    },
                    dates: '1930–2016',
                },
            },
        ],
        19: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kyabje Dilgo Khyentse Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Dilgo_Khyentse_Rinpoche',
                        tol: 'https://treasuryoflives.org/biographies/view/Dilgo-Khyentse-Tashi-Peljor/8825',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/dilgo-khyentse/',
                    },
                    dates: '1910-1991',
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'Rigdzin Kumaradza',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Rigdzin_Kumaradza',
                        tol: 'https://treasuryoflives.org/biographies/view/Kumaradza/4043',
                    },
                    dates: '1266-1343',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Katok Tsewang Norbu',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Katok_Tsewang_Norbu',
                        tol: 'https://treasuryoflives.org/biographies/view/Rigdzin-Tsewang-Norbu/9372',
                        lh: 'https://www.lotsawahouse.org//tibetan-masters/katok-rigdzin-tsewang-norbu/',
                    },
                    dates: '1698-1755',
                },
            },
        ],
    },
    9: {
        3: [
            {
                type: 'anniversary',
                description: {
                    name: 'Rigdzin Jigmé Lingpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jikme_Lingpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Jigme-Lingpa/5457',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/jigme-lingpa/',
                    },
                    dates: '1729-1798',
                },
            },
        ],
        9: [
            {
                type: 'anniversary',
                description: {
                    name: '16th Gyalwang Karmapa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Karmapa_Rangjung_Rigp%C3%A9_Dorje',
                        tol: 'https://treasuryoflives.org/biographies/view/Sixteenth-Karmapa-rang-byung-rig-pai-rdo-rje/11385',
                    },
                    dates: '1924-1981',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Khenpo Karthar Rinpoche',
                    links: { rigpa: 'https://en.wikipedia.org/wiki/Khenpo_Karthar_Rinpoche' },
                    dates: '1924-2019',
                },
            },
        ],
        14: [
            {
                type: 'anniversary',
                description: {
                    name: 'Khön Könchok Gyalpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Kh%C3%B6n_K%C3%B6nchok_Gyalpo',
                        tol: 'https://treasuryoflives.org/biographies/view/Khon-Konchok-Gyelpo/6100',
                    },
                    dates: '1034-1102',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Khyungpo Naljor',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Khyungpo_Naljor',
                        tol: 'https://treasuryoflives.org/biographies/view/Khyungpo-Naljor/6285',
                    },
                    dates: 'c. 1050-1140',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Sachen Kunga Nyingpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Sachen_Kunga_Nyingpo',
                        tol: 'https://treasuryoflives.org/biographies/view/Sachen-Kunga-Nyingpo/2916',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/sachen-kunga-nyingpo/',
                    },
                    dates: '1092-1158',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Atiśa Dīpaṃkara',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Atisha',
                        tol: 'https://treasuryoflives.org/biographies/view/Atisha-Dipamkara/5717',
                        lh: 'https://www.lotsawahouse.org/indian-masters/atisha/',
                    },
                    dates: '982-1054',
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'Adzom Drukpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Adzom_Drukpa_Drodul_Pawo_Dorje',
                        tol: 'https://treasuryoflives.org/biographies/view/Adzom-Drukpa-Pawo-Dorje/8574',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/adzom-drukpa/',
                    },
                    dates: '1842-1924',
                },
            },
        ],
    },
    10: {
        10: [
            {
                type: 'anniversary',
                description: {
                    name: 'Tulku Pegyal Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Tulku_Pegyal_Rinpoche' },
                    dates: '1931-2001',
                },
            },
        ],
        24: [
            {
                type: 'anniversary',
                description: {
                    name: 'Chamchen Chöjé',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Chamchen_Ch%C3%B6j%C3%A9' },
                    dates: '1354-1435',
                },
            },
        ],
        25: [
            {
                type: 'anniversary',
                description: {
                    name: 'Jé Tsongkhapa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Tsongkhapa_Lobzang_Drakpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Tsongkhapa-Lobzang-Drakpa/8986',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/tsongkhapa/',
                    },
                    dates: '1357-1419',
                },
            },
        ],
    },
    11: {
        3: [
            {
                type: 'anniversary',
                description: {
                    name: 'Thinley Norbu Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Thinley_Norbu_Rinpoche' },
                    dates: '1931-2011',
                },
            },
        ],
        4: [
            {
                type: 'anniversary',
                description: {
                    name: 'Garje Khamtrul Rinpoche Jamyang Döndrup',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Garje_Khamtrul_Rinpoche' },
                    dates: '1928-2019',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Tsikey Chokling Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Tsikey_Chokling_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/tsikey-chokling-rinpoche/',
                    },
                    dates: '1953-2020',
                },
            },
        ],
        11: [
            {
                type: 'anniversary',
                description: {
                    name: 'Lopön Sönam Tsemo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=S%C3%B6nam_Tsemo',
                        tol: 'https://treasuryoflives.org/biographies/view/Sonam-Tsemo/2919',
                    },
                    dates: '1142-1182',
                },
            },
        ],
        13: [
            {
                type: 'anniversary',
                description: {
                    name: 'Taklung Tsetrul Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Taklung_Tsetrul_Rinpoche' },
                    dates: '1926–2015',
                },
            },
        ],
        14: [
            {
                type: 'anniversary',
                description: {
                    name: 'Sakya Pandita Kunga Gyaltsen',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Sakya_Pandita_Kunga_Gyaltsen',
                        tol: 'https://treasuryoflives.org/biographies/view/Sakya-Pandita-Kunga-Gyeltsen/2137',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/sakya-pandita/',
                    },
                    dates: '1182-1251',
                },
            },
        ],
        15: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kyabjé Khenchen Jigme Phuntsok Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Khenpo_Jikme_Phuntsok',
                        tol: 'https://treasuryoflives.org/biographies/view/Khenpo-Jigme-Puntsok/10457',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/khenpo-jigme-phuntsok/',
                    },
                    dates: '1933-2004',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kyabje Dudjom Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Dudjom_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/dudjom-rinpoche/',
                    },
                    dates: '1904-1987',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: 'Khamtrul Döngyü Nyima Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Khamtrul_Rinpoche_Dongy%C3%BC_Nyima' },
                    dates: '1931-1980',
                },
            },
        ],
        19: [
            {
                type: 'anniversary',
                description: {
                    name: 'Taklung Thangpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Taklung_Thangpa_Tashi_Pal',
                        tol: 'https://treasuryoflives.org/biographies/view/Taklungtangpa-Tashi-Pel-/TBRC_P2649',
                    },
                    dates: '1142-1210',
                },
            },
        ],
        20: [
            {
                type: 'anniversary',
                description: {
                    name: 'Chadral Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Chatral_Sangye_Dorje',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/chatral-rinpoche/',
                    },
                    dates: '1913–2015',
                },
            },
        ],
        22: [
            {
                type: 'anniversary',
                description: {
                    name: 'Drogön Chögyal Pakpa',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Ch%C3%B6gyal_Pakpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Pakpa-Lodro-Gyeltsen/2051',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/chogyal-pakpa-lodro-gyaltsen/',
                    },
                    dates: '1235-1280',
                },
            },
        ],
        23: [
            {
                type: 'anniversary',
                description: {
                    name: 'Khenpo Appey',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Khenpo_Appey' },
                    dates: '1927-2010',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: '4th Dodrupchen, Tubten Trinlé Pal Zangpo',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Dodrupchen_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/fourth-dodrupchen-rinpoche/',
                    },
                    dates: '1927-2022',
                },
            },
        ],
        26: [
            {
                type: 'anniversary',
                description: {
                    name: '1st Jamgön Kongtrul Lodrö Thayé',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Jamg%C3%B6n_Kongtrul_Lodr%C3%B6_Tay%C3%A9',
                        tol: 'https://treasuryoflives.org/biographies/view/Jamgon-Kongtrul-Lodro-Taye/4358',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/jamgon-kongtrul/',
                    },
                    dates: '1813-1899',
                },
            },
        ],
    },
    12: {
        3: [
            {
                type: 'anniversary',
                description: {
                    name: '1st Chetsang Rinpoche, Drikung Könchok Ratna',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Drikung_Chetsang_Incarnation_Line' },
                    dates: '1590-1655',
                },
            },
        ],
        11: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kangyur Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Kangyur_Rinpoche' },
                    dates: '1898-1975',
                },
            },
        ],
        18: [
            {
                type: 'anniversary',
                description: {
                    name: 'Kunkhyen Longchen Rabjam',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Longchenpa',
                        tol: 'https://treasuryoflives.org/biographies/view/Longchenpa-Drime-Wozer/P1583',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/longchen-rabjam/',
                    },
                    dates: '1308-1364',
                },
            },
            {
                type: 'anniversary',
                description: {
                    name: '1st Chungtsang Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Drikung_Chungtsang_Incarnation_Line' },
                    dates: '1595-1659',
                },
            },
        ],
        19: [
            {
                type: 'anniversary',
                description: {
                    name: 'Neten Chokling Pema Gyurmé',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Neten_Chokling_Pema_Gyurme' },
                    dates: '1928-1974',
                },
            },
        ],
        20: [
            {
                type: 'anniversary',
                description: {
                    name: 'Gelek Rinpoche',
                    links: { rigpa: 'https://en.wikipedia.org/wiki/Gelek_Rimpoche' },
                    dates: '1939-2017',
                },
            },
        ],
        21: [
            {
                type: 'anniversary',
                description: {
                    name: 'Dorzong Rinpoche',
                    links: { rigpa: 'https://www.rigpawiki.org/index.php?title=Dorzong_Rinpoche' },
                    dates: '1943-2017',
                },
            },
        ],
        24: [
            {
                type: 'anniversary',
                description: {
                    name: 'Tulku Urgyen Rinpoche',
                    links: {
                        rigpa: 'https://www.rigpawiki.org/index.php?title=Tulku_Urgyen_Rinpoche',
                        lh: 'https://www.lotsawahouse.org/tibetan-masters/tulku-urgyen-rinpoche/',
                    },
                    dates: '1920–1996',
                },
            },
        ],
    },
};
