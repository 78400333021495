import React from 'react';
import { Frame } from '../../ui-components';
import Icon from '../Icon';
import commonStyles from './TextPages.module.scss';
import styles from './Legend.module.scss';

const Legend = () => (
    <div className={commonStyles.container}>
        <Frame>
            <h1>Key to icons</h1>
            <p className={styles.raw}>
                <Icon type="TARA" className={styles.icon} /> Tara day
            </p>
            <p className={styles.raw}>
                <Icon type="MEDICINE" className={styles.icon} /> Medicine Buddha day
            </p>
            <p className={styles.raw}>
                <Icon type="BUDDHA" className={styles.icon} /> Buddha day
            </p>
            <p className={styles.raw}>
                <Icon type="AMITABHA" className={styles.icon} /> Amithaba Buddha day
            </p>
            <p className={styles.raw}>
                <Icon type="GR" className={styles.icon} /> Guru Rinpoche day
            </p>
            <p className={styles.raw}>
                <Icon type="DAKINI" className={styles.icon} /> Dakini day
            </p>
            <p className={styles.raw}>
                <Icon type="PROTECTOR" className={styles.icon} /> Dharmapalas day
            </p>
            <p className={styles.raw}>
                <Icon type="WHEEL" className={styles.icon} /> Wheel day
            </p>
            <p className={styles.raw}>
                <Icon type="FLOWER" className={styles.icon} /> Anniversary or birthday according to the Tibetan calendar
            </p>
            <p className={styles.raw}>
                <Icon type="FLOWER" className={styles.icon} color="blue" /> Anniversary or birthday according to the
                western calendar
            </p>
            <p className={styles.raw}>
                <Icon type="FLOWER" className={styles.icon} color="gold" /> Other holidays (Losar, gutor and so on)
            </p>
        </Frame>
    </div>
);

export default Legend;
