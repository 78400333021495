import { TibetanDate, TibetanMonth } from 'tibetan-date-calculator';

/**
 * gets the number of the following month
 * @param {number} month a western month number
 * @returns {number} next month
 */
export const getNextWesternMonth = (month) => (month + 1) % 12;

/**
 * gets the month object for the next tibetan month
 * @param {TibetanMonth} month a tibetan month object for the current month
 * @returns {TibetanMonth} a tibetan month object for the next month
 */
export const getNextTibetanMonth = (month) => {
    let newMonth;
    const currentTYear = month.year;
    const currentTMonth = month.month;

    if (month.isDoubledMonth && month.isLeapMonth) {
        newMonth = new TibetanMonth({
            year: currentTYear,
            month: currentTMonth,
            isLeapMonth: false,
        });
    } else if (currentTMonth === 12) {
        newMonth = new TibetanMonth({ year: currentTYear + 1, month: 1 });
    } else {
        newMonth = new TibetanMonth({
            year: currentTYear,
            month: currentTMonth + 1,
        });
    }

    if (newMonth.isDoubledMonth && newMonth.month !== currentTMonth) {
        newMonth = new TibetanMonth({
            year: newMonth.year,
            month: newMonth.month,
            isLeapMonth: true,
        });
    }

    return newMonth;
};

/**
 * gets the month object for the previous tibetan month
 * @param {TibetanMonth} month a tibetan month object for the current month
 * @returns {TibetanMonth} a tibetan month object for the previous month
 */
export const getPreviousTibetanMonth = (month) => {
    let newMonth;
    const currentTYear = month.year;
    const currentTMonth = month.month;

    if (month.isDoubledMonth && !month.isLeapMonth) {
        newMonth = new TibetanMonth({
            year: currentTYear,
            month: currentTMonth,
            isLeapMonth: true,
        });
    } else if (currentTMonth === 1) {
        newMonth = new TibetanMonth({ year: currentTYear - 1, month: 12 });
    } else {
        newMonth = new TibetanMonth({
            year: currentTYear,
            month: currentTMonth - 1,
        });
    }

    return newMonth;
};

/**
 * gets the day object for the next tibetan day
 * @param {TibetanDate} date a tibetan day object for the current day
 * @returns {TibetanDate} a tibetan day object for the next day
 */
export const getNextTibetanDate = (date) => {
    const daysInMonth = date.monthObj.getDays();
    // debugger; // eslint-disable-line no-debugger
    const currentDateIdx = daysInMonth.findIndex((day) => day.toString() === date.toString());

    if (currentDateIdx !== daysInMonth.length - 1) {
        return daysInMonth[currentDateIdx + 1];
    }

    const nextMonth = getNextTibetanMonth(date.monthObj);
    return nextMonth.getDays()[0];
};

/**
 * gets the day object for the next tibetan day
 * @param {TibetanDate} date a tibetan day object for the current day
 * @returns {TibetanDate} a tibetan day object for the next day
 */
export const getPreviousTibetanDate = (date) => {
    const daysInMonth = date.monthObj.getDays();
    const currentDateIdx = daysInMonth.findIndex((day) => day.toString() === date.toString());

    if (currentDateIdx !== 0) {
        return daysInMonth[currentDateIdx - 1];
    }

    const nextMonth = getPreviousTibetanMonth(date.monthObj);
    const nextMonthDays = nextMonth.getDays();
    return nextMonthDays[nextMonthDays.length - 1];
};

/**
 * gets next real occurrence of vague date
 * e.g if date is missing or double, finds closest actual date
 * @param {number} year a tibetan year number
 * @param {number} month a tibetan month number
 * @param {number} day a tibetan day number
 */
export const getOccurringDate = (year, month, day) => {
    let expectedDate = new TibetanDate({ year, month, day, isLeapDay: true, isLeapMonth: true });
    if (expectedDate.isSkippedDay) {
        expectedDate = new TibetanDate({ year, month, day: day + 1 });
    }
    return expectedDate;
};

/**
 * gets the next occurrence of a date (month, day)
 * @param {number} month a tibetan month number
 * @param {number} day a tibetan day number
 */
export const getNextOccurrence = (month, day) => {
    const today = new TibetanDate();
    let { year } = today;
    if (today.month > month) {
        year += 1;
    } else if (today.month === month && today.date > day) {
        year += 1;
    }
    const nextDay = getOccurringDate(year, month, day);
    return nextDay;
};
